// import { getData } from '../lib/api/users.js'
// import ReactTooltip from 'react-tooltip'
import axios from "axios";
import "moment-timezone";
import React, { useEffect, useState } from "react";
// import Filter from "../../components/Filter";
import "react-accessible-accordion/dist/fancy-example.css";
// import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Moment from "react-moment";
import Breadcrumbs from "../../components/Breadcrumbs";
import Sidebar from "../../components/Sidebar";
import Table from "../../components/Table";
import { url_path } from "../../constant";
// import moment from "moment";
// import classNames from "classnames";
import { getCookie } from "../../lib/helpers/cookies";
// import { FaCalendar } from "react-icons/fa";
import { authenticateMemberPage } from "../../lib/helpers/validation";
// import { ReactComponent as IconTransaction } from '../assets/images/icon-transaction.svg'
// import { ReactComponent as IconDelete } from '../assets/images/icon-delete.svg'
// import { ReactComponent as IconEdit } from '../assets/images/icon-edit.svg'

const MemberAccountTransactions = ({ history }) => {
  useEffect(() => {
    authenticateMemberPage(history);
  }, []);
  // States
  const [tableData, setTableData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [startDate] = useState("");
  const [endDate] = useState("");
  const [filter] = useState(false);
  const [pages, setPages] = useState({});
  const [isClassic, setIsClassic] = useState(true);
  // const [filterStartDate] = useState();
  // const [filterEndDate] = useState();

  // const filterContent = {
  //   title: "Filter",
  // };

  // const handleSubmit = (event) => {
  //   event.preventDefault();
  //   console.log(filterStartDate);
  //   console.log(filterEndDate);
  // };

  // const handleDateChangeRaw = (e) => {
  //   e.preventDefault();
  // };

  const tableHeader = [
    {
      Header: "Date",
      id: "date",
      Cell: (data) => (
        <Moment date={(data.original[0] ? data.original[0] : '')} format="MMMM DD YYYY HH:mm:ss" />
      ),
    },
    {
      Header: "Description",
      id: "description",
      Cell: (data) => data.original[1] ? data.original[1] : '',
    },
    {
      Header: "Purchase Amount in Peso(s)",
      id: "purchase_amount",
      Cell: (data) => data.original[2] ? data.original[2]: '-',
    },
    {
      Header: "Points",
      id: "points",
      Cell: (data) => data.original[3] ? data.original[3] : '-',
    },
    {
      Header: "Bonus Points",
      id: "bonus_points",
      Cell: (data) => data.original[4] ? data.original[4] : '-',
    },
    {
      Header: "Total Points",
      id: "total_points",
      Cell: (data) => data.original[5] ? data.original[5] : '-',
    },
    {
      Header: "Balance",
      id: "balance",
      Cell: (data) => data.original[6] ? data.original[6] : '-',
    },
  ];

  // console.log(tab)

  useEffect(() => {
    getTransactionData(1, filter, startDate, endDate);
  }, []);

  const getTransactionData = async (
    pageNumber = 1,
    filter = filter,
    start_date = startDate,
    end_date = endDate
  ) => {
    setLoading(true);
    const token = getCookie("token");

    const res_user_info = await axios.get(`${url_path}/api/auth`, {
      headers: { "x-auth-token": token },
    });
    const user_id = res_user_info.data.user._id;
    res_user_info.data.user.user_type == "classic" ? setIsClassic(true) : setIsClassic(false);
    const req = {
      type: "transaction",
      filter,
      start_date,
      end_date,
      by: "date name and amount",
      pageNumber,
    };

    try {
      const res_transactions = await axios.post(
        `${url_path}/api/transactions/member/${user_id}`,
        req
      );
      const transactions = res_transactions.data.transaction;

      let data = [];
      console.log(transactions)
      transactions.map((item) => {
        if (item.description === "Received Points from Referral") {
          data.push([
            item.created_at,
            item.description,
            0,
            "+" + item.total_points_earned,
            0,
            item.total_points_earned,
            item.balance,
          ]);
        } else if (item.description === "Redeem") {
          data.push([
            item.created_at,
            item.description,
            0,
            item.amount,
            item.bonus,
            item.total_points,
            item.balance,
          ]);
        } else if (item.description === "Received from Transfer Points") {
          data.push([
            item.created_at,
            item.description,
            item.amount,
            item.points,
            item.bonus,
            item.total_points,
            item.balance,
          ]);
        } else if (item.description === "Payment") {
          data.push([
            item.created_at,
            item.description,
            item.total_points_spent,
            0,
            0,
            0,
            0,
          ]);
        } else if (item.description === "Transfer Points") {
          data.push([
            item.created_at,
            item.description,
            parseInt(item.amount) - parseInt(item.service_charge),
            0,
            0,
            0,
            item.balance,
          ]);
        } else if (item.description === "Tier Bonus") {
          data.push([
            item.created_at,
            item.description,
            0,
            "+" + item.total_points_earned,
            0,
            0,
            item.balance,
          ]);
        } else if (item.description === "Encash Points") {
          data.push([
            item.created_at,
            item.description,
            parseInt(item.amount),
            0,
            0,
            0,
            item.balance,
          ]);
        } else if (item.description === "Deducted Points by Admin") {
          data.push([
            item.created_at,
            item.description,
            item.amount,
            "-" + item.points,
            item.bonus,
            item.total_points,
            item.balance,
          ]);
        } else if (item.description === "Managed Purchase") {
          data.push([
            item.created_at,
            item.description,
            "-" + item.statement_amount,
            "-" + item.points,
            item.bonus,
            item.total_points,
            item.balance,
          ]);
        } else if (item.description === "Managed Redeem") {
          data.push([
            item.created_at,
            item.description,
            0,
            "+" + item.total_points_spent,
            item.bonus,
            item.total_points,
            item.balance,
          ]);
        } else {
          data.push([
            item.created_at,
            item.description,
            item.amount,
            parseFloat(item.points ? "+" + item.points : "-" + item.total_points_spent),
            item.bonus,
            item.total_points,
            item.balance,
          ]);
        }
      });

      setTableData(data);
      setPages(res_transactions.data.paginate);
      setLoading(false);
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <main className="member-transactions member-dashboard">
      <Sidebar history={history} />
      <div className="content-wrapper">
        {isClassic == true ? (
            <Breadcrumbs
            size="text-large"
            placeholder="Account Transactions"
            targetLink="/member-dashboard"
            hasButton="false"
          />
          ):(
            <Breadcrumbs
            size="text-large"
            placeholder="Account Transactions"
            targetLink="/my-infinity-account"
            hasButton="false"
          />
          )
          
        }

        <div className="container custom-container">
          {/* <Filter
            filterDetails={filterContent}
            content={
              <form className="form-wrapper statement" onSubmit={handleSubmit}>
                <div className="filter-wrapper">
                  <div className="group-wrapper">
                    <p className="filter-heading">Filter by date</p>
                    <div className="form-group">
                      <div className="form-field calendar">    
                        <p className="input-label">From</p>    
                        <label htmlFor="startDate">
                          <p className="label-calendar"><FaCalendar/></p>
                        </label>              
                        <DatePicker
                          selected={filterStartDate}
                          onChange={date => setFilterStartDate(date)}
                          selectsEnd
                          dateFormat="MMM dd, yyyy"
                          endDate={filterStartDate}
                          minDate={filterStartDate}
                          onChangeRaw={handleDateChangeRaw} 
                          className="form-input date"
                          name="filterStartDate"
                          placeholderText="Select a date between today and 5 days in the future"
                          // placeholderText={date}
                          autoComplete="off"
                          id="filterStartDate"
                          required
                        />                                                  
                      </div>
                      <div className="form-field calendar">
                        <p className="input-label">To</p>
                        <label htmlFor="endDate">
                          <p className="label-calendar"><FaCalendar/></p>
                        </label>                     
                        <DatePicker
                          selected={filterEndDate}
                          onChange={date => setFilterEndDate(date)}
                          selectsEnd
                          dateFormat="MMM dd, yyyy"
                          endDate={filterEndDate}
                          minDate={filterStartDate}
                          onChangeRaw={handleDateChangeRaw} 
                          className="form-input date"
                          name="filterEndDate"
                          // placeholderText={date}
                          autoComplete="off"
                          id="filterEndDate"
                          required
                        />                      
                      </div>
                    </div>
                  </div>
                    <input className="btn-blue form-deal-submit" type="submit" value="Apply"/>
                </div>
              </form>
            }
          /> */}
          <div className="section-table">
            {loading ? (
              <div className="lds-ellipsis">
                <div className="dots"></div>
                <div className="dots"></div>
                <div className="dots"></div>
                <div className="dots"></div>
              </div>
            ) : (
              <Table
                tableHeader={tableHeader}
                tableData={tableData}
                paginate={pages}
                pageFunction={getTransactionData}
              />
            )}
          </div>
        </div>
      </div>
    </main>
  );
};

export default MemberAccountTransactions;
