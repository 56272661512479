import React, { useState, useEffect } from "react";
import { url_path } from "../../constant";
import axios from "axios";
import moment from "moment";
import Breadcrumbs from "../../components/Breadcrumbs";
import { authenticateBusinessPage } from "../../lib/helpers/validation";
import { getCookie } from "../../lib/helpers/cookies";
import { successNotif, errorNotifIcon } from "../../lib/helpers/notifications";

const PurchaseDetails = ({ history, location }) => {
  const [discount_points, set_discount_points] = useState();
  const [points_earned, set_points_earned] = useState();
  const [business_partner_id, set_business_partner_id] = useState();
  const [partner_staff_id, set_partner_staff_id] = useState();
  const [business_partner_name, set_business_partner_name] = useState();
  const [business_name, set_business_name] = useState();
  const [name, set_name] = useState();
  const [reference_number, set_reference_number] = useState();
  const [ch_id, set_ch_id] = useState();
  const [expiration, set_expiration] = useState();
  const [tier_level, set_tier_level] = useState();
  const [tier_category_name, set_tier_category_name] = useState();
  const [offer_id, set_offer_id] = useState();
  const [offer_title, set_offer_title] = useState();
  const [loading, set_loading] = useState(true);
  const [isButtonLoading, setIsButtonLoading] = useState(false);

  // Updates
  const [points_to_earn, setPointsToEarn] = useState("0");
  const [offer_list, setOfferList] = useState([]);
  const [discount_list, setDiscountList] = useState([]);
  const [selected_offer, setSelectedOffer] = useState("");
  const [selected_discount, setSelectedDiscount] = useState("");

  // Redirect if passed state is empty/undefined
  if (!location.state) {
    history.push("/manual-purchase");
  }

  const { bp_id, cardNo, amount, invoice, notes } = location.state;

  const getInfo = async () => {
    try {
      // Get Staff details
      const token = getCookie("token");
      const { data: user_info } = await axios.get(`${url_path}/api/auth`, {
        headers: { "x-auth-token": token },
      });

      const req = {
        business_partner_id: user_info.user.business_partner_id,
        card_number: cardNo,
      };

      const { data: business_request } = await axios.post(
        `${url_path}/api/business_request/purchaseGetData`,
        req
      );

      if (business_request.status === "success") {
        const points_earned_req = {
          offer_id: business_request.data.offer_id,
          payment: amount,
        };

        const { data: points_data } = await axios.post(
          `${url_path}/api/purchase/points_earned/${business_request.data.ch_id}`,
          points_earned_req
        );

        const discount_req = {
          payment: amount,
        };

        const { data: discount } = await axios.post(
          `${url_path}/api/purchase/points_discount/${business_request.data.business_partner_id}`,
          discount_req
        );

        if (discount.status === "success") {
          set_discount_points(discount.discount_points.toString());
          set_points_earned(points_data.points);
          set_business_partner_id(business_request.data.business_partner_id);
          set_partner_staff_id(user_info.user._id);
          set_business_partner_name(
            user_info.user.user_first_name + " " + user_info.user.user_last_name
          );
          set_business_name(business_request.data.business_name);
          set_name(business_request.data.ch_name);
          set_reference_number(business_request.data.ch_reference_number);
          set_ch_id(business_request.data.ch_id);
          set_expiration(business_request.data.ch_expiration);
          set_tier_level(business_request.data.tier_level);
          set_tier_category_name(business_request.data.tier_category_name);
          set_offer_id(business_request.data.offer_id);
          set_offer_title(business_request.data.offer_title);

          getOffersAndDiscounts(business_request.data.ch_id);
        } else {
          const title = "Oops!";
          const msg = "Please set Points Discount first.";
          errorNotifIcon(title, msg);
        }
      } else {
        const title = "Oops!";
        const msg = business_request.msg;
        errorNotifIcon(title, msg);
      }
    } catch (err) {
      console.error(err);
    }
  };

  const getOffersAndDiscounts = async (ch_id) => {
    set_loading(true);
    try {
      const { data: offer_list } = await axios.get(
        `${url_path}/api/point_offer/${bp_id}`
      );
      if (offer_list.status === "success") {
        const offers = offer_list.offer_data.map((offer) => {
          return { ...offer.offer_id };
        });

        if (offers.length === 1) {
          setSelectedOffer(offers[0]._id);

          try {
            const { data: points_data } = await axios.post(
              `${url_path}/api/purchase/points_earned/${ch_id}`,
              {
                offer_id: offers[0]._id,
                payment: amount,
              }
            );

            setPointsToEarn(points_data.points);
            setIsButtonLoading(false);
          } catch (err) {
            console.error(err);
          }
        }
        setOfferList(offers);
      }

      const { data: discount_list } = await axios.get(
        `${url_path}/api/business_discount/${bp_id}`
      );
      if (discount_list.status === "success") {
        if (discount_list.business_discount.length === 1) {
          setSelectedDiscount(discount_list.business_discount[0]._id);
        }

        setDiscountList(discount_list.business_discount);
      }

      set_loading(false);
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    authenticateBusinessPage(history);
    getInfo();
    // getOffersAndDiscounts();
    // console.log(discount_points)
  }, []);

  // Handlers
  const handleOnSubmit = async (event) => {
    event.preventDefault();

    setIsButtonLoading(true);

    try {
      // Make Transaction
      const req = {
        offer_id: selected_offer,
        discount_id: selected_discount,
        payment: amount,
        purchase_detail: notes,
      };

      const { data } = await axios.post(
        `${url_path}/api/scanner/purchase/${business_partner_id}/${partner_staff_id}/${cardNo}`,
        req
      );
      if(data.status==="warning"){

        errorNotifIcon("Oops!", data.msg);
        
        return 0;
      }


       // Send notification
       const notification_req = {
        notification_user_id: ch_id,
        title: business_name + " (Purchase)",
        description: `You purchased ${amount} and you earned ${points_to_earn} points.`,
        status: false,
      };

      await axios.post(`${url_path}/api/notification`, notification_req);

      successNotif("Well done!", '');
      history.push("/manual-purchase");
    } catch (err) {
      console.error(err);
      errorNotifIcon("Oops!", "Something went wrong :( please try again.");
      
      setIsButtonLoading(false);
    }
  };

  const handleOfferChange = async (event) => {
    const { value: offer_id } = event.target;

    setIsButtonLoading(true);

    setSelectedOffer(offer_id);

    if (offer_id) {
      try {
        const { data: points_data } = await axios.post(
          `${url_path}/api/purchase/points_earned/${ch_id}`,
          {
            offer_id: offer_id,
            payment: amount,
          }
        );

        setPointsToEarn(points_data.points);
        setIsButtonLoading(false);
      } catch (err) {
        console.error(err);
      }
    } else {
      setPointsToEarn("0");
      setIsButtonLoading(false);
    }
  };

  const handleDiscountChange = async (event) => {
    const { value } = event.target;

    setSelectedDiscount(value);
  };

  return (
    <main className="partner-dashboard purchase-redeem-details">
      {loading ? (
        <div className="lds-ellipsis">
          <div className="dots"></div>
          <div className="dots"></div>
          <div className="dots"></div>
          <div className="dots"></div>
        </div>
      ) : (
        <div className="container custom-container">
          <div className="content-wrapper">
            <Breadcrumbs
              size="text-large"
              placeholder="Back"
              targetLink="/manual-purchase"
            />
            <div className="purchase-content">
              <p className="heading">Purchase Details</p>
              <form className="details-wrapper" onSubmit={handleOnSubmit}>
                <div className="infinity-card-img">
                  <img
                    src={require(`../../assets/images/cards/${tier_category_name.toLowerCase()}/tier-${tier_level}.jpg`)}
                    className="img-card"
                    alt=""
                  />
                  <img
                    src={require("../../assets/images/logo-white.png")}
                    className="card-logo"
                    alt="Infinity Privilege"
                  />
                  <div className="card-details">
                    <div className="member-card-info">
                      <p className="member-name">{name}</p>
                      <p className="member-card-number">{cardNo}</p>
                      <div className="member-bottom-info">
                        <p className="card-bottom-text">
                          Expires: {moment(new Date(expiration)).format("l")}
                        </p>
                        <p className="card-bottom-text">
                          Reference: {reference_number}
                        </p>
                      </div>
                    </div>
                    <div className="tier-wrapper">
                      <img
                        src={require("../../assets/images/card-tier.jpg")}
                        className="card-tier"
                        alt="Infinity Privilege"
                      />
                      <p className="tier-text">{tier_level}</p>
                    </div>
                  </div>
                </div>
                <div className="form-wrapper">
                  <div className="form-field">
                    <input
                      className="form-input"
                      name="amount"
                      value={amount}
                      disabled={true}
                      readOnly
                    />
                    <label htmlFor="amount">Sale Amount</label>
                  </div>
                  <div className="form-field">
                    <select
                      id="point_offer"
                      className="form-input select"
                      name="point_offer"
                      value={selected_offer}
                      onChange={handleOfferChange}
                      required
                    >
                      {offer_list.length !== 1 && (
                        <option value="">Select offer</option>
                      )}
                      {offer_list.map((offer, index) => (
                        <option value={offer._id} key={index}>
                          {offer.title}
                        </option>
                      ))}
                    </select>
                    <label htmlFor="point_offer">Point Offer</label>
                  </div>
                  <div className="form-field">
                    <input
                      className="form-input"
                      value={points_to_earn}
                      name="points_earned"
                      disabled={true}
                    />
                    <label htmlFor="points_earned">Points Earned</label>
                  </div>
                  <div className="form-field">
                    <select
                      id="discount_deal"
                      className="form-input select"
                      name="discount_deal"
                      value={selected_discount}
                      onChange={handleDiscountChange}
                      required
                    >
                      {discount_list.length !== 1 && (
                        <option value="">Select discount</option>
                      )}
                      {discount_list.map((discount, index) => (
                        <option value={discount._id} key={index}>
                          {discount.discount}
                        </option>
                      ))}
                    </select>
                    <label htmlFor="discount_deal">Discount Deal (%)</label>
                  </div>
                  <div className="form-field">
                    <input
                      className="form-input"
                      value={invoice}
                      name="invoice"
                      disabled={true}
                    />
                    <label htmlFor="invoice">Invoice no.</label>
                  </div>
                  <div className="form-field">
                    <input
                      className="form-input"
                      value={notes}
                      name="notes"
                      disabled={true}
                      readOnly
                    />
                    <label htmlFor="notes">Notes</label>
                  </div>
                </div>
                {isButtonLoading ? (
                  <button className="btn-blue-loading disabled">
                    <span className="load open"></span> Please wait...
                  </button>
                ) : (
                  <button
                    type="submit"
                    className="btn-blue"
                    onClick={handleOnSubmit}
                    disabled={!selected_offer || !selected_discount}
                  >
                    Apply
                  </button>
                )}
              </form>
            </div>
          </div>
        </div>
      )}
    </main>
  );
};

export default PurchaseDetails;
