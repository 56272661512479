import React, { useState, useEffect } from "react";
import { url_path } from "../../constant";
import axios from "axios";
import Card from "../../components/Card";
import useForm from "../../lib/hooks/useForm";
import Breadcrumbs from "../../components/Breadcrumbs";
import { authenticateBusinessPage } from "../../lib/helpers/validation";
import { errorNotifIcon } from "../../lib/helpers/notifications";

import { getCookie } from "../../lib/helpers/cookies";

const ManualPurchase = ({ history }) => {
  useEffect(() => {
    authenticateBusinessPage(history);
  }, []);

  const [isLoading, setIsLoading] = useState(false);

  var numRegex = /^[0-9]*$/;

  const cardInfo = {
    title: "Purchase",
  };

  const stateSchema = {
    cardNo: { value: "", error: "" },
    amount: { value: "", error: "" },
    confirmAmount: { value: "", error: "" },
    invoiceNo: { value: "", error: "" },
    notes: { value: "", error: "" },
  };

  const stateValidatorSchema = {
    cardNo: {
      required: true,
      validator: {
        func: (value) =>
          parseInt(value.length) === 4 ? (values.cardNo = "asdasdsad") : null,
      },
    },
    amount: {
      required: true,
      validator: {
        func: (value) => numRegex.test(value),
        error: "Amount Only Accept Numbers",
      },
    },
    confirmAmount: {
      required: true,
      validator: {
        func: (value) => value === amount && numRegex.test(value),
        error: "Amounts don't match / Amount Only Accept Numbers",
      },
    },
    invoiceNo: {
      required: false,
    },
    notes: {
      required: false,
    },
  };

  const onSubmitForm = async (event) => {
    event.preventDefault();
    // const req = {cardNo, amount, confirmAmount, invoiceNo, notes};
    setIsLoading(true);
    values.notes = "";
    axios.get(`${url_path}/api/scanner/${cardNo}`).then((res) => {
      const scannerData = res.data;

      console.warn(scannerData.data);
      if (scannerData.data.status === "error") {
        console.log(scannerData.data.msg);
        const title = "Oops!";
        const msg = scannerData.data.msg;
        errorNotifIcon(title, msg);
        setIsLoading(false);
      } else {
        if (scannerData.scanner.user_role !== "Member") {
          const title = "Oops!";
          const msg = "Card Number is not Valid";
          errorNotifIcon(title, msg);
          setIsLoading(false);
        } else {
          checkDiscounts();
        }
      }
    });
  };

  const {
    // state,
    values,
    errors,
    handleOnChange,
    disable,
  } = useForm(stateSchema, stateValidatorSchema, onSubmitForm);

  const { cardNo, amount, confirmAmount, invoiceNo, notes } = values;

  const checkDiscounts = () => {
    // get the user detail
    const token = getCookie("token");
    axios
      .get(`${url_path}/api/auth`, { headers: { "x-auth-token": token } })
      .then((res) => {
        const user_info = res.data;

        const req = {
          business_partner_id: user_info.user.business_partner_id,
          card_number: cardNo,
        };
        axios
          .post(`${url_path}/api/business_request/purchaseGetData`, req)
          .then((res) => {
            const business_request = res.data;
            if (business_request.status !== "success") {
              const title = "Oops!";
              const msg = business_request.msg;
              return errorNotifIcon(title, msg);
            }

            const pointsEarnedReq = {
              offer_id: business_request.data.offer_id,
              payment: amount,
            };
            // get points in total
            axios
              .post(
                `${url_path}/api/purchase/points_earned/${business_request.data.ch_id}`,
                pointsEarnedReq
              )
              .then((res) => {
                // const pointsData = res.data;

                const discount_req = {
                  payment: amount,
                };

                // get discount in total
                axios
                  .post(
                    `${url_path}/api/purchase/points_discount/${business_request.data.business_partner_id}`,
                    discount_req
                  )
                  .then((res) => {
                    const discount = res.data;
                    if (discount.status !== "success") {
                      const title = "Oops!";
                      const msg = "Please set Points Discount first.";
                      return errorNotifIcon(title, msg);
                    } else {
                      history.push({
                        pathname: "/purchase-details",
                        state: {
                          bp_id: user_info.user.business_partner_id,
                          cardNo,
                          amount,
                          invoice: invoiceNo ? invoiceNo : "No invoice",
                          notes: notes ? notes : "",
                        },
                      });
                    }
                  });
              });
          });
      });
  };

  const formatCardNumber = (value) => {
    let cardNumber = value.replace(/ /g, "");

    const formatedCardNumber =
      cardNumber.substring(0, 4).replace(/\d{3}(?=.)/, "$& ") +
      cardNumber.substring(4, 8).replace(/\d{2}(?=.)/, "$& ") +
      cardNumber.substring(8, 10);

    return formatedCardNumber;
  };

  const validateNumberInput = (event) => {
    const { key } = event;

    const validKeys = [
      "Backspace",
      "Delete",
      "ArrowLeft",
      "ArrowRight",
      "Tab",
      "Enter",
    ];

    if (!key.match(/\d/) && !validKeys.includes(key)) {
      event.preventDefault();
    }
  };

  return (
    <main className="partner-dashboard manual-purchase">
      <div className="container custom-container">
        <div className="content-wrapper">
          <Breadcrumbs
            size="text-large"
            placeholder="Back"
            targetLink="/manual-input"
            customClassName={"m-3"}
          />
          <div className="card-body">
            <Card
              cardDetails={cardInfo}
              form={
                <>
                  <form className="form-wrapper" onSubmit={onSubmitForm}>
                    <div className="form-field">
                      <input
                        type="text"
                        name="cardNo"
                        className="form-input"
                        id="cardNo"
                        value={formatCardNumber(cardNo)}
                        onChange={handleOnChange}
                        onKeyDown={validateNumberInput}
                        required
                      />
                      <label htmlFor="cardNo">Enter Card No.</label>
                    </div>
                    <div className="form-field">
                      <input
                        type="number"
                        name="amount"
                        className="form-input"
                        id="amount"
                        value={amount}
                        onChange={handleOnChange}
                        onKeyDown={validateNumberInput}
                        required
                      />
                      <label htmlFor="amount">Amount</label>
                      {errors.amount && (
                        <span className="form-error">{errors.amount}</span>
                      )}
                    </div>
                    <div className="form-field">
                      <input
                        type="number"
                        name="confirmAmount"
                        className="form-input"
                        id="confirmAmount"
                        value={confirmAmount}
                        onChange={handleOnChange}
                        onKeyDown={validateNumberInput}
                        required
                      />
                      <label htmlFor="confirmAmount">Confirm amount</label>
                      {errors.confirmAmount && (
                        <span className="form-error">
                          {errors.confirmAmount}
                        </span>
                      )}
                    </div>
                    <div className="form-field">
                      <input
                        type={"text"}
                        name="invoiceNo"
                        className={`form-input ${
                          !invoiceNo ? "not-required" : ""
                        }`}
                        id="invoiceNo"
                        value={invoiceNo}
                        onChange={handleOnChange}
                      />
                      <label htmlFor="invoiceNo">Invoice no. (optional)</label>
                    </div>
                    <div className="form-field">
                      <textarea
                        type={"text"}
                        name="notes"
                        className="form-input not-required"
                        id="notes"
                        value={notes}
                        onChange={handleOnChange}
                      />
                      <label htmlFor="notes">Notes (optional)</label>
                    </div>
                    {isLoading ? (
                      <button className="btn-blue-loading disabled">
                        <span className="load open"></span> Please wait...
                      </button>
                    ) : (
                      <button
                        className="btn-blue"
                        type="submit"
                        disabled={disable}
                        onClick={onSubmitForm}
                      >
                        Submit
                      </button>
                    )}
                  </form>
                </>
              }
            />
          </div>
        </div>
      </div>
    </main>
  );
};

export default ManualPurchase;
