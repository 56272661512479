import React, { useState, useEffect } from "react";
import { url_path } from "../../constant";
// import { Link } from 'react-router-dom'
import Tab from "react-bootstrap/Tab";
import Nav from "react-bootstrap/Nav";
import Breadcrumbs from "../../components/Breadcrumbs";
// import '../../stylesheets/main.scss';
import axios from "axios";

import BasicInformation from "../../tabs/partner/BasicInformation";
// import CategoryInformation from "../../tabs/partner/CategoryInformation";
import StaffInformation from "../../tabs/partner/StaffInformation";
import BusinessContactDetails from "../../tabs/partner/BusinessContactDetails";
// import Photos from "../../tabs/partner/Photos";
import DiscountDeals from "../../tabs/partner/DiscountDeals";
import InfinityTransaction from "../../tabs/partner/InfinityTransaction";
import InfinityStatement from "../../tabs/partner/InfinityStatement";
import PointOffer from "../../tabs/partner/PointOffer";
import ManagePurchase from "../../tabs/partner/ManagePurchase";
import ManageRedeem from "../../tabs/partner/ManageRedeem";
import { authenticateAdminPage } from "../../lib/helpers/validation";

const content = [
  {
    name: "Basic Information",
    eventKey: "basic-info",
  },
  // {
  //   name: "Category Info",
  //   eventKey: "category-info"
  // },
  {
    name: "Staff",
    eventKey: "staff-info",
  },
  {
    name: "Contact Details",
    eventKey: "contact-info",
  },
  // {
  //   name: 'Offer Photos',
  //   eventKey: 'offer-photos'
  // },
  {
    name: "Points Offer",
    eventKey: "point-offer",
  },
  {
    name: "Discount Deals",
    eventKey: "discount-deals",
  },
  {
    name: "Infinity Transaction",
    eventKey: "infinity-transaction",
  },
  {
    name: "Infinity Statement",
    eventKey: "infinity-statement",
  },
  {
    name: "Manage Purchase Transaction",
    eventKey: "manage-purchase-transaction",
  },
  {
    name: "Manage Redeem Transaction",
    eventKey: "manage-redeem-transaction",
  },
  // {
  //   name: "Help",
  //   eventKey: "help"
  // }
];

const tabPane = {
  basicInfo: {
    title: "Basic Information",
  },
  // categoryInfo: {
  //   title: "Category Information"
  // },
  contactInfo: {
    title: "Contact Details",
  },
  staffInfo: {
    title: "Staff",
  },
  // offerPhotoInfo: {
  //   title: 'Offer Photos'
  // },
  pointOfferInfo: {
    title: "Points Offer",
  },
  discountDealsInfo: {
    title: "Discount Deals",
  },
  infinityTransactionInfo: {
    title: "Infinity Transaction",
  },
  infinityStatementInfo: {
    title: "Infinity Statement",
  },
  managePurchaseTransaction: {
    title: "Manage Purchase Transaction",
  },
  manageRedeemTransaction: {
    title: "Manage Redeem Transaction",
  },
  help: {
    title: "Help",
  },
};

const BusinessProfile = (props) => {
  useEffect(() => {
    authenticateAdminPage(props);
  }, []);
  const [profileData, setProfileData] = useState();
  const URL_ID = props.match.params.id;

  // console.log(props.match.params.id)

  // if(props) {
  //   console.log(props.location.state.data)
  // }

  // 5def610a52b9b00138271b82

  useEffect(() => {
    axios
      .get(`${url_path}/api/business_partners/${URL_ID}`)
      .then((response) => {
        setProfileData(response.data);
        console.log(response.data);
      })
      .catch(function (error) {
        console.log(error);
      });
  }, []);

  return (
    <main className="business-profile">
      <div className="container custom-container">
        <Breadcrumbs
          size="text-large"
          placeholder="Business Profile"
          targetLink="/manage-business-partners"
          customClassName="m-3"
        />
        {profileData ? (
          <div className="section-business-profile">
            <div className="container-fluid">
              <Tab.Container
                id="business-profile-tabs"
                defaultActiveKey="basic-info"
                mountOnEnter
              >
                <div className="section-tab">
                  <div className="tab-nav">
                    <Nav className="flex-column">
                      {content.map(function (item, i) {
                        return (
                          <Nav.Item key={i}>
                            <Nav.Link eventKey={item.eventKey}>
                              {item.name}
                            </Nav.Link>
                          </Nav.Item>
                        );
                      })}
                    </Nav>
                  </div>
                  <div className="tab-content-holder">
                    <Tab.Content>
                      <Tab.Pane eventKey="basic-info">
                        <BasicInformation
                          data={tabPane.basicInfo}
                          businessPartnerData={profileData}
                          partner_id={URL_ID}
                          readOnly
                        />
                      </Tab.Pane>
                      {/* <Tab.Pane eventKey="category-info">
                        <CategoryInformation
                          data={tabPane.categoryInfo}
                          partner_id={URL_ID}
                        />
                      </Tab.Pane> */}
                      <Tab.Pane eventKey="staff-info">
                        <StaffInformation
                          data={tabPane.staffInfo}
                          user_id={URL_ID}
                        />
                      </Tab.Pane>
                      <Tab.Pane eventKey="contact-info">
                        <BusinessContactDetails
                          data={tabPane.contactInfo}
                          user_id={URL_ID}
                        />
                      </Tab.Pane>
                      {/* <Tab.Pane eventKey='offer-photos'>
                        <Photos
                          data={tabPane.offerPhotoInfo}
                          URL_ID={props.match.params.id}
                        />
                      </Tab.Pane> */}
                      <Tab.Pane eventKey="point-offer">
                        <PointOffer
                          data={tabPane.pointOfferInfo}
                          user_id={URL_ID}
                        />
                      </Tab.Pane>
                      <Tab.Pane eventKey="discount-deals">
                        <DiscountDeals
                          data={tabPane.discountDealsInfo}
                          user_id={URL_ID}
                        />
                      </Tab.Pane>
                      <Tab.Pane eventKey="infinity-transaction">
                        <InfinityTransaction
                          data={tabPane.infinityTransactionInfo}
                          user_id={URL_ID}
                          profileData={profileData}
                        />
                      </Tab.Pane>
                      <Tab.Pane eventKey="infinity-statement">
                        <InfinityStatement
                          data={tabPane.infinityStatementInfo}
                          user_id={URL_ID}
                          profileData={profileData}
                        />
                      </Tab.Pane>
                      <Tab.Pane eventKey="manage-purchase-transaction">
                        <ManagePurchase
                          data={tabPane.managePurchaseTransaction}
                          business_partner_id={URL_ID}
                        />
                      </Tab.Pane>
                      <Tab.Pane eventKey="manage-redeem-transaction">
                        <ManageRedeem
                          data={tabPane.manageRedeemTransaction}
                          business_partner_id={URL_ID}
                        />
                      </Tab.Pane>
                    </Tab.Content>
                  </div>
                </div>
              </Tab.Container>
            </div>
          </div>
        ) : (
          <div className="lds-ellipsis">
            <div className="dots"></div>
            <div className="dots"></div>
            <div className="dots"></div>
            <div className="dots"></div>
          </div>
        )}
      </div>
    </main>
  );
};

export default BusinessProfile;
