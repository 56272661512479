// import { storeUser } from "../lib/api/users.js";
import { successNotif } from "../../lib/helpers/notifications.js";
import {url_path} from '../../constant';
import useForm from "../../lib/hooks/useForm.js";
import React, { useState, useEffect } from "react";
import Table from "../../components/Table";
import Card from "../../components/Card";
import Breadcrumbs from "../../components/Breadcrumbs";
import Sweetalert2 from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import ReactTooltip from "react-tooltip";
import axios from "axios";
// import Moment from "react-moment";
import Modal from "react-bootstrap/Modal";
// import 'react-phone-input-2/dist/style.css';
import "moment-timezone";
import { ReactComponent as IconDelete } from "../../assets/images/icon-delete.svg";
import { ReactComponent as IconEdit } from "../../assets/images/icon-edit.svg";
import {authenticateAdminPage} from '../../lib/helpers/validation'
// import { ReactComponent as IconCaretDown } from "../assets/images/icon-caret-down.svg";
const Swal = withReactContent(Sweetalert2);

const editInfo = {
  title: "Update User",
  hasClose: true,
  hasMessage: false,
  customClassName: "no-margin no-shadow"
};

function ManageOffer(props) {
  useEffect(() => {
    authenticateAdminPage(props)
  }, []);
  // const [data, setData] = useState([])
  const [OfferData, setOfferData] = useState([]);
  const [modalState, setModalState] = useState(false);
  const [currentEdit_ID, setCurrentEdit_ID] = useState("");
  // const [inputType, setInputType] = useState(true);
  // const iconShow = require("../assets/images/icon-show.png");
  // const iconHide = require("../assets/images/icon-hide.png");
  // const [isRoleDisabled, setRoleIsDisabled] = useState(false);

  const [loader, setLoader] = useState(false);

  /*
   * Regex Validations to Inputs
   */

  // var nameRegex = /^[a-zA-Z ]+$/;

  /*
   * Initialize input state schema
   */

  const stateSchema = {
    title: { value: "", error: "" },
    amount: { value: "", error: "" },
    points: { value: "", error: "" }
  };

  /*
   * Create a schema for all inputs
   */

  const stateValidatorSchema = {
    title: {
      required: true
    },
    amount: {
      required: true
    },
    points: {
      required: true
    }
  };

  /*
   * On Submit Form
   */

  function onSubmitForm(state) {
    /*
     * Destructuring state Values
     */

    const { title, amount, points } = state;

    /*
     * get data from state(Input) and set to variable
     */

    const offer = {
      title: title,
      amount: amount,
      points: points
    };

    if (currentEdit_ID !== "") {
      // console.log("Edit");
      // storeUser(currentEdit_ID, offer).then(res => {
      //   let title = "Well Done!";
      //   let message = "User has been updated";
      //   successNotif(title, message);
      //   hideModal();
      //   getData();
      //   console.log(res.data);
      // });
      axios.post(`${url_path}/api/offer/update/${currentEdit_ID}`, offer).then(res => {
        let title = "Well Done!";
        let message = "User has been updated";
        successNotif(title, message);
        hideModal();
        getData();
        // console.log(res.data);
      });
    } else {
      // console.log("Add");
      axios.post(`${url_path}/api/offer/`, offer).then(res => {
        // console.log(res);
        let title = "Well Done!";
        let message = "New deal has been created!";
        successNotif(title, message);
        hideModal();
        getData();
        appendNewUser(res.data);
        // console.log(res.data.deal);
        // console.log(res.data);
      });
    }
  }

  // console.log("Running");
  // console.log(currentEdit_ID);

  /*
   * Get values, errors, handling events to custom useForm Hooks
   */

  const { values, errors, handleOnChange, handleOnSubmit, disable } = useForm(
    stateSchema,
    stateValidatorSchema,
    onSubmitForm
  );

  /*
   * Get values to custom useForm Hooks
   * Pass it to input values
   */

  const { title, amount, points } = values;

  // var status = values.status.toString();

  /*
   * Toggle Input Password Type and Icon
   */

  // function togglePassword() {
  //   inputType ? setInputType(false) : setInputType(true);
  // }

  /*
   * Get User Data to Database
   * Set Data to State
   */

  function getData() {
    axios
      .get(`${url_path}/api/offer/`)
      .then(response => {
        setOfferData(response.data);
        setLoader(true);
      })
      .catch(function(error) {
        console.log(error);
      });
  }

  /*
   * Callback funciton to Add
   * Append data to state[data]
   */

  function appendNewUser(data) {
    let offer = {
      _id: data._id,
      title: data.title,
      amount: data.amount,
      points: data.points
    };

    setOfferData([...OfferData, offer]);
  }

  /*
   * Mount User Data
   * Clear and Set Initial Data to Inputs
   */

  useEffect(() => {
    getData();
    // setOfferData(OfferData)
  }, []);

  /*
   * Modal Actions Show/Hide
   */

  const hideModal = () => {
    setModalState(false);
    setCurrentEdit_ID("");
  };

  const showModal = () => {
    setModalState(true);
  };

  /*
   * Action Function Handlers
   */

  const handleShowCreate = () => {
    showModal();
    editInfo.title = "Create New Offer";

    values.title = "";
    values.amount = "";
    values.points = "";

    errors.title = "";
    errors.amount = "";
    errors.points = "";
  };

  const handleShowEdit = () => {
    showModal();
    editInfo.title = "Update Offer";
  };

  /*
   * Disallow non valid keys to input number
   */

  // const handleInputNumberAllowedCharacter = event => {
  //   var invalidChars = ["-", "e"];

  //   if (invalidChars.includes(event.key)) {
  //     event.preventDefault();
  //   }
  // };

  const heading = [
    {
      Header: "ID",
      accessor: "_id",
      width: 300,
      // filterMethod: (filter, row) =>
      //   row._original.title.startsWith(filter.value) ||
      //   row._original.description.startsWith(filter.value)
    },
    {
      Header: "Title",
      accessor: "title"

      // filterMethod: (filter, row) =>
      //   row._original.title.startsWith(filter.value) ||
      //   row._original.amount.startsWith(filter.value)
    },
    {
      Header: "Amount",
      accessor: "amount"
    },
    {
      Header: "Price",
      accessor: "points"
    },
    {
      Header: "Actions",
      sortable: false,
      // filterable: false,
      Cell: row => (
        <div className="button-wrapper">
          <div className="button-holder">
            <button
              className="btn-icon"
              onClick={() => {
                handleShowEdit();
                // getData()

                /*
                 * Get data from offer datas
                 */

                let datas = [...OfferData];

                /*
                 * Initialize CurrentID
                 */

                setCurrentEdit_ID(datas[row.index]._id);

                /*
                 * Add shorthand notation value
                 */

                const { title, amount, points } = datas[row.index];

                /*
                 * Set User Data value to Current Input Fields
                 */

                values.title = title;
                values.amount = amount;
                values.points = points;
              }}
            >
              <span className="icon-holder">
                <IconEdit className="icon-actions" data-tip data-for="edit" />
              </span>
            </button>
            <ReactTooltip id="edit" type="warning" effect="solid">
              <span>Edit</span>
            </ReactTooltip>
          </div>
        </div>
      )
    }
  ];

  return (
    <main className="manage-users">
      <div className="container custom-container">
      <Breadcrumbs
        size="text-large"
        placeholder="Offer"
        targetLink="/admin-dashboard"
        hasButton="true"
        buttonTitle="Create New Offer"
        customClassName="m-3"
        propsFunction={handleShowCreate}
      />
      <div className="section-table">
        <div className="container">
          {loader ? (
            <Table tableHeader={heading} tableData={OfferData} />
          ) : (
            <div className="lds-ellipsis">
              <div className="dots"></div>
              <div className="dots"></div>
              <div className="dots"></div>
              <div className="dots"></div>
            </div>
          )}
        </div>
      </div>
        <Modal
          show={modalState}
          onHide={hideModal}
          className="modal-dark- opacity"
        >
          <Card
            cardDetails={editInfo}
            form={
              <form className="form-wrapper" onSubmit={handleOnSubmit}>
                <div className="form-field">
                  <input
                    id="title"
                    className="form-input"
                    type="text"
                    name="title"
                    value={title}
                    onChange={handleOnChange}
                    required
                  />
                  <label htmlFor="title">Title</label>
                  {errors.title && (
                    <span className="form-error">{errors.title}</span>
                  )}
                </div>
                <div className="form-field">
                  <input
                    id="amount"
                    className="form-input"
                    type="text"
                    name="amount"
                    value={amount}
                    onChange={handleOnChange}
                    required
                  />
                  <label htmlFor="amount">Amount</label>
                  {errors.amount && (
                    <span className="form-error">{errors.amount}</span>
                  )}
                </div>
                <div className="form-field">
                  <input
                    id="points"
                    className="form-input"
                    type="text"
                    name="points"
                    value={points}
                    onChange={handleOnChange}
                    required
                  />
                  <label htmlFor="points">Points</label>
                  {errors.points && (
                    <span className="form-error">{errors.points}</span>
                  )}
                </div>

                <div className="form-btn-wrapper form-btn-dual-wrapper">
                  <input
                    type="button"
                    className="btn-d btn-gray"
                    value="Cancel"
                    onClick={hideModal}
                  ></input>
                  <input
                    type="submit"
                    className="btn-d btn-blue"
                    value="Save Changes"
                    disabled={disable}
                  />
                </div>
              </form>
            }
          />
        </Modal>
      </div>
    </main>
  );
}

export default ManageOffer;
