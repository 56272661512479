import { successNotif } from "../../lib/helpers/notifications.js";
import { url_path } from "../../constant";
import React, { useState, useEffect } from "react";
import axios from "axios";
import Card from "../../components/Card";
import "../../stylesheets/main.scss";
import useForm from "../../lib/hooks/useForm.js";
import PhoneInput from 'react-phone-input-2'

// import { ReactComponent as IconCaretDown } from "../../assets/images/icon-caret-down.svg";

const ContactDetails = ({ data, user_id }) => {
  const [userData, setUserData] = useState({});
  const [loader, setLoader] = useState(false);
  const [readOnly, setReadOnly] = useState(true);

  /*
   * Regex Validations to Inputs
   */

  // var nameRegex = /^[a-zA-Z ]+$/;
  // var numRegex = /^[0-9]*$/;

  /*
   * Initialize input state schema
   */

  const stateSchema = {
    card_number: { value: userData.card_number, error: "" },
    first_name: { value: userData.first_name, error: "" },
    last_name: { value: userData.last_name, error: "" },
    gender: { value: userData.gender, error: "" },
    title: { value: userData.title, error: "" },
    company: { value: userData.company, error: "" },
    occupation: { value: userData.occupation, error: "" },
    position: { value: userData.position, error: "" }
  };

  /*
   * Create a schema for all inputs
   */

  const stateValidatorSchema = {
    email: {
      required: true
      // validator: {
      //   func: value => nameRegex.test(value),
      //   error: "Invalid Name"
      // }
    },
    phone: {
      required: true
      // validator: {
      //   func: value => nameRegex.test(value),
      //   error: "Invalid Name"
      // }
    },
    house_number: {
      required: true
      // validator: {
      //   func: value => nameRegex.test(value),
      //   error: "Invalid Name"
      // }
    },
    street: {
      required: true
      // validator: {
      //   func: value => nameRegex.test(value),
      //   error: "Invalid Gender"
      // }
    },
    city: {
      required: true
      // validator: {
      //   func: value => nameRegex.test(value),
      //   error: "Invalid Title"
      // }
    },
    province: {
      required: false
      // validator: {
      //   func: value => nameRegex.test(value),
      //   error: "Invalid Company"
      // }
    },
    country: {
      required: false
      // validator: {
      //   func: value => nameRegex.test(value),
      //   error: "Invalid Occupation"
      // }
    },
    zip: {
      required: false
      // validator: {
      //   func: value => nameRegex.test(value),
      //   error: "Invalid Position"
      // }
    }
  };

  function onSubmitForm(state) {
    const {
      email = userData.user_email,
      phone = userData.user_phone,
      house_number = userData.house_number,
      street = userData.street,
      city = userData.city,
      province = userData.province,
      country = userData.country,
      zip = userData.zipcode
    } = state;
    const request = {
      email,
      phone,
      house_number,
      street,
      city,
      province,
      country,
      zip
    };
    axios
      .put(
        `${url_path}/api/admin_request/member_profile_contact_details/${user_id}`,
        request
      )
      .then(res => {
        console.log(res.data);
        if (res.data.data.status === "success") {
          const title = "Awesome!";
          const msg = res.data.data.msg;
          successNotif(title, msg);
          setReadOnly(true);
          getData();
        }
      });
    console.log(request);
  }

  const { values, handleOnChange, handleOnSubmit } = useForm(
    stateSchema,
    stateValidatorSchema,
    onSubmitForm
  );

  const {
    email = userData.user_email,
    phone = userData.user_phone,
    house_number = userData.house_number,
    street = userData.street,
    city = userData.city,
    province = userData.province,
    country = userData.country,
    zip = userData.zipcode
  } = values;

  const handleButtonEdit = () => {
    setReadOnly(false);
  };
  const handleButtonCancel = () => {
    values.email = userData.user_email;
    values.phone = userData.user_phone;
    values.house_number = userData.house_number;
    values.street = userData.street;
    values.city = userData.city;
    values.province = userData.province;
    values.country = userData.country;
    values.zip = userData.zipcode;
    setReadOnly(true);
  };

  function getData() {
    axios
      .get(`${url_path}/api/member_additional_contact_details/${user_id}`)
      .then(res => {
        setUserData(res.data);
        setLoader(true);
      });
  }

  const handlePhoneChange = value => {
    const event = {
      target: {
        name: 'phone',
        value: value.replace(/ /g, '')
      }
    }

    handleOnChange(event)
  }

  useEffect(() => {
    getData();
  }, []);

  return (
    // <div className="tab-contact-details">
    //   <h4>Contact Details</h4>
    //   {loader ? (
    //     <div>
    //       <h4>Email</h4>
    //       <p>{userData.user_email}</p>
    //       <h4>Phone</h4>
    //       <p>{userData.user_phone}</p>
    //       <h4>House Number</h4>
    //       <p>{userData.house_number}</p>
    //       <h4>Street</h4>
    //       <p>{userData.street}</p>
    //       <h4>City</h4>
    //       <p>{userData.city}</p>
    //       <h4>Province</h4>
    //       <p>{userData.province}</p>
    //       <h4>Country</h4>
    //       <p>{userData.country}</p>
    //       <h4>Zip Code</h4>
    //       <p>{userData.zipcode}</p>
    //     </div>
    //   ) : (
    //     <div className="lds-ellipsis">
    //       <div className="dots"></div>
    //       <div className="dots"></div>
    //       <div className="dots"></div>
    //       <div className="dots"></div>
    //     </div>
    //   )}
    // </div>
    <Card
      cardDetails={data}
      form={
        <form className="form-wrapper" onSubmit={handleOnSubmit}>
          {!readOnly ? <p>Update Contact Details</p> : null}

          <div className="form-field">
            <input
              id="email"
              className="form-input"
              type="text"
              name="email"
              value={email}
              onChange={handleOnChange}
              required
              readOnly={readOnly}
              disabled={readOnly}
            />
            <label htmlFor="email">Email</label>
            {/* {errors.email && (
              <span className="form-error">{errors.email}</span>
            )} */}
          </div>
          <div className="form-field">
            {/* <input
              id="phone"
              className="form-input"
              type="text"
              name="phone"
              value={phone}
              onChange={handleOnChange}
              required
              readOnly={readOnly}
              disabled={readOnly}
            />
            <label htmlFor="phone">Phone</label>
            {errors.phone && (
              <span className="form-error">{errors.phone}</span>
            )} */}
            <PhoneInput
              inputClass={["form-input"]}
              enableAreaCodes={true}
              countryCodeEditable={false}
              autoFormat={true}
              country={'ph'}
              value={phone}
              enableSearch={true}
              onChange={ handlePhoneChange }
              required
              readOnly={readOnly}
              disabled={readOnly}
            />
            <label htmlFor="phone" className="label-fixed">Phone</label>
          </div>
          <div className="form-field">
            <input
              id="house_number"
              className="form-input"
              type="text"
              name="house_number"
              value={house_number}
              onChange={handleOnChange}
              required
              readOnly={readOnly}
              disabled={readOnly}
            />
            <label htmlFor="house_number">House Number</label>
          </div>
          <div className="form-field">
            <input
              id="street"
              className="form-input"
              type="text"
              name="street"
              value={street}
              onChange={handleOnChange}
              readOnly={readOnly}
              disabled={readOnly}
            />
            <label htmlFor="street">Street</label>
          </div>
          <div className="form-field">
            <input
              id="city"
              className="form-input"
              type="text"
              name="city"
              value={city}
              onChange={handleOnChange}
              readOnly={readOnly}
              disabled={readOnly}
            />
            <label htmlFor="city">City</label>
            {/* {errors.city && (
              <span className="form-error">{errors.city}</span>
            )} */}
          </div>
          <div className="form-field">
            <input
              id="province"
              className="form-input"
              type="text"
              name="province"
              value={province}
              onChange={handleOnChange}
              readOnly={readOnly}
              disabled={readOnly}
            />
            <label htmlFor="province">Province</label>
            {/* {errors.province && (
              <span className="form-error">{errors.province}</span>
            )} */}
          </div>
          <div className="form-field">
            <input
              id="country"
              className="form-input"
              type="text"
              name="country"
              value={country}
              onChange={handleOnChange}
              readOnly={readOnly}
              disabled={readOnly}
            />
            <label htmlFor="country">Country</label>
            {/* {errors.country && (
              <span className="form-error">{errors.country}</span>
            )} */}
          </div>
          <div className="form-field">
            <input
              id="zip"
              className="form-input"
              type="text"
              name="zip"
              value={zip}
              onChange={handleOnChange}
              readOnly={readOnly}
              disabled={readOnly}
            />
            <label htmlFor="zip">Zip</label>
            {/* {errors.zip && (
              <span className="form-error">{errors.zip}</span>
            )} */}
          </div>
          {!readOnly ? (
            <>
              <input
                type="submit"
                className="btn-blue full-width addSpace"
                value="Save"
                // disabled={disable}
              />
              <input
                type="button"
                className="btn-blue-outline full-width"
                value="Cancel"
                onClick={handleButtonCancel}
                // disabled={disable}
              />
            </>
          ) : (
            <input
              type="button"
              className="btn-blue full-width"
              value="Edit"
              onClick={handleButtonEdit}
              // disabled={disable}
            />
          )}
        </form>
      }
    />
  );
};

export default ContactDetails;
