import {
  successNotif,
  errorNotifIcon,
} from "../../lib/helpers/notifications.js";
import { url_path } from "../../constant";
import useForm from "../../lib/hooks/useForm.js";
import React, { useState, useEffect } from "react";
import Table from "../../components/Table";
import { Link } from "react-router-dom";
import Card from "../../components/Card";
import Breadcrumbs from "../../components/Breadcrumbs";
import Sweetalert2 from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import ReactTooltip from "react-tooltip";
import axios from "axios";
import Moment from "react-moment";
import Modal from "react-bootstrap/Modal";
import PhoneInput from "react-phone-input-2";
import DatePicker from "react-datepicker"; 
import "moment-timezone";
import { ReactComponent as IconDelete } from "../../assets/images/icon-delete.svg";
import { ReactComponent as IconEdit } from "../../assets/images/icon-edit.svg";
import { ReactComponent as IconSettings } from "../../assets/images/icon-settings.svg";
import { ReactComponent as IconCaretDown } from "../../assets/images/icon-caret-down.svg";
import { ReactComponent as IconCalendar } from "../../assets/images/icon-calendar.svg";
import { authenticateAdminPage } from "../../lib/helpers/validation";

const Swal = withReactContent(Sweetalert2);

const editInfo = {
  title: "Update User",
  hasClose: true,
  hasMessage: false,
  customClassName: "no-margin no-shadow",
};

const settingInfo = {
  title: "Set Access Control",
  hasClose: true,
  hasMessage: false,
  customClassName: "no-margin no-shadow",
};

// let test = [];
function ManageUsers(props) {
  useEffect(() => {
    authenticateAdminPage(props);
  }, []);

  // const [data, setData] = useState([])
  const [userData, setUserData] = useState([]);
  const [modalState, setModalState] = useState(false);
  const [modalSettingState, setModalSettingState] = useState(false);
  const [currentEdit_ID, setCurrentEdit_ID] = useState("");
  const [inputType, setInputType] = useState(true);
  const iconShow = require("../../assets/images/icon-show.png");
  const iconHide = require("../../assets/images/icon-hide.png");
  const [roleIsDisabled, setRoleIsDisabled] = useState(false);

  const [loading, setLoading] = useState(true);
  const [pagerData, setPagerData] = useState([]);
  const [searchInput, setSearchInput] = useState("");
  const [isDisabled, setIsDisabled] = useState(false);

  const [step, setStep] = useState(1);
  const [phone, setPhone] = useState();
  const [dialCode, setDialCode] = useState();
  const [birthDate, setBirthDate] = useState();
  const [onLoad, setOnload] = useState(false);

  const [control, setControl] = useState([]);

  const [first_name, setFirstName] = useState("TEMPORARY");
  const [last_name, setLastName] = useState("CARD");
  const [iterations, setClassicQuantity] = useState();
  const [password, setPassword] = useState("P@u#R4p%L6eYK4!#s7S");

  /*
   * Regex Validations to Inputs
   */

  var nameRegex = /^[a-zA-Z ]+$/;
  var digitonly = /[^0-9]+/;
  var passwordMinChar = /^.{8,}$/;

  /*
   * Initialize input state schema
   */

  const stateSchema = {
    user_type: { value: "Sub Admin", error: "" },
    first_name: { value: "Temporary", error: "" },
    last_name: { value: "User", error: "" },
    phone_number: { value: "", error: "" },
    email: { value: "", error: "" },
    password: { value: "", error: "" },
    address: { value: "", error: "" },
    title: { value: "Mr.", error: "" },
    gender: { value: "Male", error: "" },
    status: { value: false, error: "" },
    iterations: { value: "", error: ""}
  };

  /*
   * Create a schema for all inputs
   */

  const stateValidatorSchema = {
    first_name: {
      required: true,
      validator: {
        func: (value) => nameRegex.test(value),
        error: "Invalid first name format.",
      },
    },
    last_name: {
      required: true,
      validator: {
        func: (value) => nameRegex.test(value),
        error: "Invalid last name format.",
      },
    },
    iterations: {
      required: true,
      validator: {
        func: (value) => digitonly.test(value),
        error: "Invalid number format.",
      },
    },
  };

  /*
   * On Submit Form
   */

  function onSubmitForm(state) {
    /*
     * get data from state(Input) and set to variable
     */
    setIsDisabled(true)
    const values = {
      first_name,
      last_name,
      iterations,
    }
    console.log(values);
    // const user = {
    //   user_role: "Administrator",
    //   user_first_name: first_name,
    //   user_last_name: last_name,
    //   user_iterations: iterations
    // };
    // return console.log(user);

    if (!first_name || !last_name || !iterations) {
      errorNotifIcon("Error Saving!", "One of the required fields is missing.");
      return;
    }
    console.log("Add");
    axios.post(`${url_path}/api/classic-members/new`, values).then((res) => {
      if (res.statusText === "OK") {
        console.log(res);
        let title = "Well Done!";
        let message = "New user has been created successfully.";
        successNotif(title, message);
        hideModal();
        getData(1, "");
        appendNewUser(res.data);
        setIsDisabled(false);
        // console.log(res.data.user);
        console.log(res.data);
      } else {
        console.log(res);
        let title = "Oops!";
        let message = res.data.data.msg;
        errorNotifIcon(title, message);
      }
      
    });
  }

  const { values, errors, handleOnChange, handleOnSubmit, disable } = useForm(
    stateSchema,
    stateValidatorSchema,
    onSubmitForm
  );

  // const {
  //   first_name,
  //   last_name,
  //   iterations,
  // } = values;

  var status = values.status.toString();

  /*
   * Toggle Input Password Type and Icon
   */

  function togglePassword() {
    inputType ? setInputType(false) : setInputType(true);
  }

  /*
   * Get User Data to Database
   * Set Data to State
   */

  function getData(pageNumber = 1, search = searchInput) {
    setLoading(true);

    const req = { pageNumber, search };
    axios
      .post(`${url_path}/api/classic-members/`, req)
      .then((response) => {
        setUserData(response.data.users);
        setPagerData(response.data.paginate);
        setLoading(false);
        console.log(response.data.users);
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  /*
   * Callback funciton to Add
   * Append data to state[data]
   */

  function appendNewUser(data) {
    let user = {
      _id: data._id,
      user_position: data.user_position,
      user_first_name: data.user_first_name,
      user_last_name: data.user_last_name,
      user_phone: data.user_phone,
      user_email: data.user_email,
      user_password: data.user_password,
      user_address: data.address,
      user_title: data.title,
      user_gender: data.gender,
      user_birthdate: data.birthdate,
      user_status: data.user_status,
    };

    setUserData([...userData, user]);
  }

  /*
   * Mount User Data
   * Clear and Set Initial Data to Inputs
   */

  useEffect(() => {
    getData(1, "");

    // setUserData(userData)
  }, []);

  /*
   * Modal Actions Show/Hide
   */

  const hideModal = () => {
    setModalState(false);
    setCurrentEdit_ID("");
  };

  const showModal = () => {
    setStep(1);
    setModalState(true);
  };

  const hideSettingModal = () => {
    setModalSettingState(false);
    setCurrentEdit_ID("");
  };

  const showSettingModal = () => {
    setModalSettingState(true);
    // getAccessControl();
  };
  // DatePicker
  const handleDateChangeRaw = (e) => {
    e.preventDefault();
  };
  /*
   * Action Function Handlers
   */

  const handleShowCreate = () => {
    showModal();
    editInfo.title = "Create New Classic Member";
    // console.log(values)
    // console.log(errors)
    setRoleIsDisabled(false);
    setPhone("");
    // birthDate();
    values.first_name = "";
    values.last_name = "";
    values.phone_number = "";
    values.email = "";
    values.password = "";
    values.address = "";
    values.title = "Mr.";
    values.birthdate = "";
    values.gender = "Male";
    values.status = false;

    errors.first_name = "";
    errors.last_name = "";
    errors.phone_number = "";
    errors.email = "";
    errors.password = "";
    errors.address = "";
    errors.title = "";
    errors.birthdate = "";
    errors.gender = "";
    errors.status = "";
  };

  const handleShowEdit = () => {
    showModal();
    editInfo.title = "Update User";
    setRoleIsDisabled(true);
    setPhone("");

    values.first_name = "";
    values.last_name = "";
    values.phone_number = "";
    values.email = "";
    values.password = "";
    values.address = "";
    values.title = "Mr.";
    values.birthdate = "";
    values.gender = "Male";
    values.status = false;

    errors.first_name = "";
    errors.last_name = "";
    errors.phone_number = "";
    errors.email = "";
    errors.password = "";
    errors.address = "";
    errors.title = "";
    errors.birthdate = "";
    errors.gender = "";
    errors.status = "";
  };

  const handleShowAccessControl = () => {
    showSettingModal();
  };

  /*
   * Disallow non valid keys to input number
   */

  // const handleInputNumberAllowedCharacter = (event) => {
  //   var invalidChars = ["-", "e"];

  //   if (invalidChars.includes(event.key)) {
  //     event.preventDefault();
  //   }
  // };

  const handleSearchInput = (event) => {
    setSearchInput(event.target.value);
  };

  const handleSearchSubmit = (event) => {
    event.preventDefault();

    getData(1, searchInput);
  };
  const handleNext = () => {
    // event.preventDefault()

    setStep(2);
  };
  const handlePrev = () => {
    // event.preventDefault()

    setStep(1);
  };

  const handleCheckChieldElement = (event) => {
    // setModalSettingState(false);
    setOnload(true);
    let controls = control;
    controls.forEach((item) => {
      if (item.value === event.target.value)
        item.isChecked = event.target.checked;
    });
    setControl(controls);
    // this.forceUpdate();
    // this.props.render();
    // setModalSettingState(true);
    setOnload(false);
    console.log(controls);
    // this.setState({fruites: fruites})
  };

  const handleSetAccessControl = () => {
    const req = { controls: control };
    axios
      .put(`${url_path}/api/users/access_control_update/${currentEdit_ID}`, req)
      .then((response) => {
        // setUserData(response.data.users);
        // setPagerData(response.data.paginate);
        // setLoading(false);
        if (response.data.data.status === "success") {
          let title = "Well Done!";
          let message = "Acess Control has been updated successfully.";
          successNotif(title, message);
          hideSettingModal();
        }
        console.log(response.data);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const handlePhoneChanges = (value, data, event) => {
    // console.log(data.dialCode)
    // console.log(value);
    setPhone(value.replace(/ /g, ""));
    setDialCode(data.dialCode);
    // console.log(countryCode);
  };

  /*
   * Table Data
   * Includes Headings
   */

  const heading = [
    {
      Header: "Name",
      id: "fullName",
      accessor: (row) => {
        return (
          <Link to={"/member-profile/" + row.user_id._id}>
            {row.user_id.user_first_name +
              " " +
              row.user_id.user_last_name}
          </Link>
        );
      },
      // `${row.user_id.user_first_name} ${row.user_id.user_last_name}`,
    },
    {
      Header: "Card Number",
      id: "user_email",
      accessor: (row) => `${row.user_id.card_number} `,
    },
    {
      Header: "Referral Code",
      id: "reference_number",
      accessor: (row) => `${row.user_id.reference_number} `,
    },
    {
      Header: "Status",
      id: "status",
      accessor: (d) => d.user_status,
      Cell: (status) => (
        <div className="active-holder">
          {status.original.user_id.user_status ? (
            <span className="table-status-active">Active</span>
          ) : (
            <span className="table-status-inactive">Inactive</span>
          )}
        </div>
      ),
    },
    {
      Header: "Created On",
      id: "createdOn",
      accessor: (c) => <Moment format="ll" date={c.subscription_start} />,
    }
  ];

  return (
    <main className="manage-pages">
      <div className="container custom-container">
        <Breadcrumbs
          size="text-large"
          placeholder="Manage Classic Members"
          targetLink="/admin-dashboard"
          hasButton="true"
          buttonTitle="Create New Classic Member"
          customClassName="m-3"
          propsFunction={handleShowCreate}
        />
        <div className="section-table">
          {/* <div className="container"> */}
          <form onSubmit={handleSearchSubmit}>
            <div className="search-holder">
              <label htmlFor="searchInput">Search: </label>
              <input
                size="large"
                name="searchInput"
                label="Search"
                value={searchInput}
                onChange={handleSearchInput}
                className="search-input"
              />
              <input type="submit" className="btn-blue" value="Search" />
            </div>
          </form>
          {loading ? (
            <div className="lds-ellipsis">
              <div className="dots"></div>
              <div className="dots"></div>
              <div className="dots"></div>
              <div className="dots"></div>
            </div>
          ) : (
            <Table
              tableHeader={heading}
              tableData={userData}
              paginate={pagerData}
              pageFunction={getData}
            />
          )}
          {/* </div> */}
        </div>
        <Modal
          show={modalState}
          onHide={hideModal}
          className="modal-dark- opacity"
        >
          <Card
            cardDetails={editInfo}
            form={
              <form className="form-wrapper" onSubmit={handleOnSubmit} autoComplete="off">
                <>
                  <div className="form-field">
                      <input
                        id="first_name"
                        className="form-input"
                        type="text"
                        name="first_name"
                        value={first_name}
                        onChange={(e) => setFirstName(e.target.value)}
                        required
                      />
                      <label htmlFor="first_name">
                        First name<span className="text-required">*</span>
                      </label>
                      {errors.first_name && (
                        <span className="form-error">{errors.first_name}</span>
                      )}
                  </div>
                  <div className="form-field">
                    <input
                      id="last_name"
                      className="form-input"
                      type="text"
                      name="last_name"
                      value={last_name}
                      onChange={(e) => setLastName(e.target.value)}
                      required
                    />
                    <label htmlFor="last_name">
                      Last name<span className="text-required">*</span>
                    </label>
                    {errors.last_name && (
                      <span className="form-error">{errors.last_name}</span>
                    )}
                  </div>
                  <div className="form-field">
                    <input
                      id="iterations"
                      className="form-input"
                      type="number"
                      name="iterations"
                      value={iterations}
                      pattern="[^0-9]*"
                      max={100}
                      onChange={(e) => setClassicQuantity(e.target.value)}
                      required
                    />
                    <label htmlFor="last_name">
                      How many Classic Members<span className="text-required">*</span>
                    </label>
                    {errors.last_name && (
                      <span className="form-error">{errors.last_name}</span>
                    )}
                    
                  </div>
                  <div className="form-field">
                    <input
                      id="password"
                      className="form-input"
                      type="password"
                      name="password"
                      value={password}
                      hidden
                    />
                  </div>    
                  <div className="form-btn-wrapper form-btn-dual-wrapper">
                    <input
                      type="button"
                      className="btn-d btn-gray"
                      value="Cancel"
                      onClick={hideModal}
                    ></input>
                    <input
                      type="button"
                      className="btn-d btn-blue"
                      value="Continue"
                      onClick={onSubmitForm}
                      disabled={isDisabled}
                    ></input>
                  </div>
                  </>          
              </form>
            }
          />
        </Modal>
        <Modal
          show={modalSettingState}
          onHide={hideSettingModal}
          className="modal-dark- opacity"
        >
          <Card
            cardDetails={settingInfo}
            form={
              <form className="form-wrapper" onSubmit={handleOnSubmit}>
                {control
                  ? control.map((items) => {
                      // console.log(items.isChecked);
                      return (
                        <li>
                          <input
                            key={items.id}
                            type="checkbox"
                            onChange={handleCheckChieldElement}
                            checked={items.isChecked}
                            value={items.value}
                          />{" "}
                          {items.name}
                        </li>
                      );
                    })
                  : null}
                <div className="form-btn-wrapper form-btn-dual-wrapper">
                  <input
                    type="button"
                    className="btn-d btn-blue"
                    value="Save"
                    onClick={handleSetAccessControl}
                  ></input>
                </div>
              </form>
            }
          />
        </Modal>
      </div>
    </main>
  );
}

export default ManageUsers;
