import React from "react";
import { url_path } from "../constant";
import useForm from "../lib/hooks/useForm.js";
import { successNotif, errorNotifIcon } from "../lib/helpers/notifications.js";
import axios from "axios";
import Card from "../components/Card";
import "../stylesheets/main.scss";
import Breadcrumbs from "../components/Breadcrumbs";
import { withRouter } from "react-router";
import { Helmet } from "react-helmet";

function ForgotPassword(props) {
  var emailRegex = /\S+@\S+\.\S+/;

  // const { close } = props;
  // const [inputType, setInputType] = useState(true);

  const stateSchema = {
    email: { value: "", error: "" },
  };

  const stateValidatorSchema = {
    email: {
      required: true,
      validator: {
        func: (value) => emailRegex.test(value),
        error: "Invalid email",
      },
    },
  };
  const { values, errors, handleOnChange, disable } = useForm(
    stateSchema,
    stateValidatorSchema
    // onSubmitForm
  );

  const { email } = values;

  function handleSubmit() {
    console.log(email);
    const req = { to_email: email };
    axios
      .post(`${url_path}/api/verification_link/send_verification`, req)
      .then((res) => {
        const verificationData = res.data;
        if (verificationData.data.status === "success") {
          let title = "Well Done!";
          let message = "We've sent you an email for your password reset.";
          successNotif(title, message);
          props.history.push("/forgot-password-success");
        } else {
          let title = "Sorry!";
          let message = verificationData.data.msg;
          errorNotifIcon(title, message);
        }
      });
  }

  // const required = (value) => {
  //   if (!value.toString().trim().length) {
  //     return <span className="form-error is-visible">Required*</span>;
  //   }
  // };

  // const validateForm = (value) => {
  //   if (!validator.isEmail(value)) {
  //     return <span className="form-error is-visible">Invalid</span>
  //   }
  // else if (!validator.isMobilePhone(value)) {
  //   return <span className="form-error is-visible">Invalid</span>
  // }
  // };

  const cardInfo = {
    title: "Forgot Password",
    hasMessage: true,
    message:
      "Enter your email or phone number and we'll send you a link to reset your password",
  };

  // return (
  //   <div className="card-body">
  //     <Card cardDetails={cardInfo}
  //     form={
  //       <form className="form-wrapper">
  //         <div className="form-field">
  //           <input
  //             type='text'
  //             name='email'
  //             className='form-input'
  //             id='email'
  //             value={email}
  //             onChange={handleOnChange}
  //             required
  //           />
  //           <label htmlFor="email">Email or Phone Number</label>
  //           {/* <input type="text" className="form-input" {...bindInput}></input> */}
  //         </div>
  //         <button className="btn-blue" onClick={handleSubmit}>Send</button>

  //       </form>
  //     }/>
  //   </div>
  // )
  return (
    <main className="login forgot-password">
      <Helmet>
        {/* <title>Infinity Privilege</title> */}
        <meta name="viewport" content="width=device-width, initial-scale=1" />
      </Helmet>
      <div className="card-body">
        <Card
          cardDetails={cardInfo}
          form={
            <>
              <form className="form-wrapper" onSubmit={handleSubmit}>
                <Breadcrumbs size="text-large" targetLink="/" />
                <div className="form-field">
                  <input
                    type="text"
                    name="email"
                    className="form-input"
                    id="email"
                    value={email}
                    onChange={handleOnChange}
                    required
                  />
                  <label htmlFor="email">Email address</label>
                  {errors.email && (
                    <span className="form-error">{errors.email}</span>
                  )}
                </div>
                {/* <input type="submit" className='btn-blue' disabled={disable} value="Generate User"/> */}
              </form>
              <button
                className="btn-blue"
                disabled={disable}
                onClick={handleSubmit}
              >
                Send
              </button>
            </>
          }
        />
      </div>
    </main>
  );
}

export default withRouter(ForgotPassword);
