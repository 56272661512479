import React, { useEffect, useState } from "react";
import Moment from "react-moment";
import Breadcrumbs from "../../components/Breadcrumbs";
import Table from "../../components/Table";
import Card from "../../components/Card";
import Modal from "react-bootstrap/Modal";
import ReactTooltip from "react-tooltip";
import { ReactComponent as IconDelete } from "../../assets/images/icon-delete.svg";
import { ReactComponent as IconEdit } from "../../assets/images/icon-edit.svg";
import axios from "axios";

import { authenticateAdminPage } from "../../lib/helpers/validation";
import { url_path } from "../../constant";

const SendNotification = ({ user_id },props) => {
  // States
  
  const [announcements, setAnnouncements] = useState([]);
  const [loading, setLoading] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const [notification, setNotification] = useState({
    title: "",
    description: "",
  });
  const [notificationID, setNotificationID] = useState("");
  const [notificationUserID, setNotificationUserID] = useState("");
  // Variables
  const modalInfo = {
    title: "Create Notification",
    hasClose: true,
    hasMessage: false,
    customClassName: "no-margin no-shadow",
  };

  const tableHeader = [
    {
      Header: "Date",
      accessor: "created_at",
      sortable: true,
      Cell: (row) => (
        <Moment format="MMMM DD, YYYY hh:mm A" date={row.created_at} />
      ),
    },
    {
      Header: "Title",
      accessor: "title",
    },
    {
      Header: "Description",
      accessor: "description",
    },
    {
      Header: "Action",
      Cell: (row) => (
        <div className="button-wrapper">
          <div className="button-holder">
            <button
              className="btn-icon"
              onClick={() => handleEditAnnouncement(row.index)}
            >
              <span className="icon-holder">
                <IconEdit className="icon-actions" data-tip data-for="edit" />
              </span>
            </button>
            <ReactTooltip id="edit" type="warning" effect="solid">
              <span>Edit</span>
            </ReactTooltip>
          </div>
          <div className="button-holder">
            <button
              className="btn-icon"
              onClick={() => handleDeleteAnnouncement(row.original._id)}
            >
              <span className="icon-holder">
                <IconDelete
                  className="icon-actions"
                  data-tip
                  data-for="delete"
                />
              </span>
            </button>
            <ReactTooltip id="delete" type="warning" effect="solid">
              <span>Delete</span>
            </ReactTooltip>
          </div>
        </div>
      ),
    },
  ];

  // Handlers
  const getAnnouncements = async () => {
    setLoading(true);
    
    try {
      const { data: announcements } = await axios.get(
        `${url_path}/api/notification/${user_id}`
      );
      setAnnouncements(announcements);
      setLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  const handleOnChange = (event) => {
    const { name, value } = event.target;

    setNotification({
      ...notification,
      [name]: value,
    });
  };

  const handleShowModal = () => {
    setShowModal(true);
  };

  const handleHideModal = () => {
    setNotificationID("");
    setNotification({ title: "", description: "" });
    setShowModal(false);
  };

  const handleCreateAnnouncement = () => {
    modalInfo.title = "New Announcement";
    setNotificationUserID(user_id);
    handleShowModal();
  };

  const handleEditAnnouncement = (index) => {
    const { _id, title, description } = announcements[index];
    setNotificationID(_id);
    setNotification({ title, description });
    modalInfo.title = "Edit Announcement";
    handleShowModal();
  };

  const handleOnSubmit = async (event) => {
    event.preventDefault();

    const req = {
      notification_id: notificationID,
      notification_user_id: notificationUserID,
      title: notification.title,
      description: notification.description,
    };
    console.log(req);
    try {

      if (!notificationID) {
        const { data } = await axios.post(
          `${url_path}/api/notification`,
          req
        );
        console.log(data);
      } else {
        const { data } = await axios.put(
          `${url_path}/api/notification/announcements`,
          req
        );
        console.log(data);
      }

      getAnnouncements();
      handleHideModal();
    }
     catch (error) {
      console.log(error);
    }
  };

  const handleDeleteAnnouncement = async (notification_id) => {
    try {
      const { data } = await axios.delete(
        `${url_path}/api/notification/announcements/${notification_id}`
      );
      console.log(data);
      getAnnouncements();
    } catch (error) {
      console.log(error);
    }
  };

  // Lifecycle
  useEffect(() => {
    authenticateAdminPage(props);

    getAnnouncements();
  }, []);

  return (
    <main className="manage-annoucements">
      <div className="container custom-container">
        <Breadcrumbs
          customClassName="m-3"
          size="text-large"
          placeholder="Back"
          targetLink="/admin-dashboard"
          hasButton="true"
          buttonTitle="Create New Announcement"
          propsFunction={handleCreateAnnouncement}
        />
        {loading ? (
          <div className="lds-ellipsis">
            <div className="dots"></div>
            <div className="dots"></div>
            <div className="dots"></div>
            <div className="dots"></div>
          </div>
        ) : (
          <div className="section-table">
            <Table tableHeader={tableHeader} tableData={announcements} />
          </div>
        )}

        <Modal
          show={showModal}
          onHide={handleHideModal}
          className="modal-dark- opacity"
        >
          <Card
            cardDetails={modalInfo}
            form={
              <form className="form-wrapper" onSubmit={handleOnSubmit}>
                <div className="form-field">
                  <input
                    id="title"
                    className="form-input"
                    type="text"
                    name="title"
                    value={notification.title}
                    onChange={handleOnChange}
                    required
                  />
                  <label htmlFor="title">Title</label>
                </div>
                <div className="form-field">
                  <input
                    id="description"
                    className="form-input"
                    type="text"
                    name="description"
                    value={notification.description}
                    onChange={handleOnChange}
                    required
                  />
                  <label htmlFor="description">Description</label>
                </div>
                <div className="form-btn-wrapper form-btn-dual-wrapper">
                  <input
                    type="button"
                    className="btn-d btn-gray"
                    value="Cancel"
                    onClick={handleHideModal}
                  ></input>
                  <input
                    type="submit"
                    className="btn-d btn-blue"
                    value="Submit"
                    disabled={!notification.title || !notification.description}
                  />
                </div>
              </form>
            }
          />
        </Modal>
      </div>
    </main>
  );
};

export default SendNotification;
