import React, { useEffect, useState } from "react";
import { url_path } from "../../constant";
import Breadcrumbs from "../../components/Breadcrumbs";
import Sidebar from "../../components/Sidebar";
import { getCookie } from "../../lib/helpers/cookies";
import axios from "axios";
import { authenticateMemberPage } from "../../lib/helpers/validation";
import {
  EmailShareButton,
  FacebookShareButton,
  TwitterShareButton,
  ViberShareButton,
  WhatsappShareButton,
  EmailIcon,
  FacebookIcon,
  TwitterIcon,
  ViberIcon,
  WhatsappIcon,
} from "react-share";

const InviteFriend = ({ history }) => {
  useEffect(() => {
    authenticateMemberPage(history);
  }, []);
  // States
  const [loading, setLoading] = useState(false);
  const [userId, setUserId] = useState("");
  const [referrenceNumber, setReferrenceNumber] = useState("");
  const defaultMessage =
    "I am delighted to share with you a new exciting and rewarding program; Visit Infinity Privilege today, benefit from the special offer, signup and enjoy the infinite reward. you will only need reference " +
    referrenceNumber +
    " to complete signup.";
  const defaultUrl = "https://infinityprivilege.com/#/signup";
  const defaultTitle = "Infinity Privilege Invitation";
  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    setLoading(true);

    const token = getCookie("token");
    try {
      const { data: user_info } = await axios.get(`${url_path}/api/auth`, {
        headers: { "x-auth-token": token },
      });
      setUserId(user_info.user._id);
      setReferrenceNumber(user_info.user.reference_number);

      // const { data: notif_info } = await axios.get(`${url_path}/api/notification/${ user_info.user._id }`)
      // setNotifications(notif_info)
      setLoading(false);
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <main className="notifications member-dashboard">
      <Sidebar history={history} />
      <div className="content-wrapper">
        <Breadcrumbs
          size="text-large"
          placeholder="Dashboard"
          targetLink="/member-dashboard"
          hasButton="false"
        />

        {loading ? (
          <div className="lds-ellipsis">
            <div className="dots"></div>
            <div className="dots"></div>
            <div className="dots"></div>
            <div className="dots"></div>
          </div>
        ) : (
          <div className="notification-wrapper">
            <h2 className="title">Invite Friends</h2>
            <div className="flex-button-share-container">
              <div>
                <EmailShareButton
                  url={defaultUrl}
                  subject={defaultTitle}
                  body={defaultMessage}
                >
                  <EmailIcon size={50} round />
                </EmailShareButton>
              </div>
              <div>
                <FacebookShareButton url={defaultUrl} quote={defaultMessage}>
                  <FacebookIcon size={50} round />
                </FacebookShareButton>
              </div>
              <div>
                <TwitterShareButton
                  url={defaultUrl}
                  title={defaultTitle}
                  via={defaultMessage}
                >
                  <TwitterIcon size={50} round />
                </TwitterShareButton>
              </div>
              <div>
                <ViberShareButton
                  url={defaultUrl}
                  title={defaultTitle}
                  separator={defaultMessage}
                >
                  <ViberIcon size={50} round />
                </ViberShareButton>
              </div>
              <div>
                <WhatsappShareButton
                  url={defaultUrl}
                  title={defaultTitle}
                  separator={defaultMessage}
                >
                  <WhatsappIcon size={50} round />
                </WhatsappShareButton>
              </div>
            </div>
          </div>
        )}
      </div>
    </main>
  );
};

export default InviteFriend;
