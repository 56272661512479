import axios from "axios";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import Swiper from "react-id-swiper";
import "swiper/css/swiper.css";
import Breadcrumbs from "../../components/Breadcrumbs";
import Sidebar from "../../components/Sidebar";
import { url_path } from "../../constant";
// import QRCode from 'qrcode.react'
import { getCookie } from "../../lib/helpers/cookies";
import { authenticateMemberPage } from "../../lib/helpers/validation";


function MemberDetails({
  name,
  tier,
  cardNo,
  referenceNo,
  expiration,
  history,
}) {
  useEffect(() => {
    authenticateMemberPage(history);
  }, []);


    return (
      <div className="member-details-wrapper">
        <div className="member-details-holder">
          <p className="label">Cardholder's name</p>
          <p className="member-details">{name}</p>
        </div>
        <div className="member-details-holder">
          <p className="label">Card number</p>
          <p className="member-details">{cardNo}</p>
        </div>
        <div className="member-details-holder">
          <p className="label">Referral Code</p>
          <p className="member-details">{referenceNo}</p>
        </div>
        <div className="member-details-holder">
          <p className="label">Expires on</p>
          <p className="member-details">
            {moment(new Date(expiration)).format("LL")}
          </p>
        </div>
        <div className="member-details-holder">
          <p className="label">Tier</p>
          <p className="member-details">Tier {tier}</p>
        </div>
     
      </div>
    );
}

function InfinityCard({ history }) {

    // States
	const [ loading, setLoading ] = useState(true)
	const [ name, setName ] = useState('')
	const [ cardNo, setCardNo] = useState('')
	const [ referenceNo, setReferenceNo ] = useState('')
	const [ tier, setTier ] = useState('')
	const [ tierCategory, setTierCategory ] = useState('')
  const [ expiration, setExpiration ] = useState('')
  const [ userData, setUserData ] = useState({})
  const [card_selected, setCardSelected] = useState('')
  const [isQualified, setIsQualified] = useState(false);

  const [swiper, updateSwiper] = useState(null);

  const params = {
    spaceBetween: 12,
    slidesPerView: "auto",
    centeredSlides: true,
    loop: true,
    breakpoints: {
      1199: {
        slidesPerView: 1,
      },
    },
    pagination: {
      el: ".swiper-pagination",
      type: "bullets",
      clickable: true,
    },
  };

  const goNext = () => {
    if (swiper !== null) {
      swiper.slideNext();
    }
  };

  const goPrev = () => {
    if (swiper !== null) {
      swiper.slidePrev();
    }
  };

  // Variables
  var QRCode = require("qrcode.react");
  // var Barcode = require("react-barcode");

  // const value = "063 000 0001";

  // ComponentDidMount
  useEffect(() => {
    getMyInfinityCardData();
  }, []);

  const getMyInfinityCardData = async () => {
    setLoading(true);
    const token = getCookie("token");

    try {
      // Get User data
      const user_data = {};

      const { data: res } = await axios.get(`${url_path}/api/auth`, {
        headers: { "x-auth-token": token },
      });

      user_data.name = res.user.user_first_name + " " + res.user.user_last_name;
      user_data.cardNo = res.user.card_number;
      user_data.referenceNo = res.user.reference_number;

      // Get User Tier/Tier Category id's
      const { data: user_tier } = await axios.get(
        `${url_path}/api/tier/user_tier/v2/${res.user._id}`
      );
        
      const { data: customer_bank } = await axios.get(
        `${url_path}/api/customerBank/${res.user._id}`
      );
      console.log(customer_bank.points_balance);
      // if(user_data.points_available == 0) 
      parseInt(customer_bank.points_balance) >= 250 ? setIsQualified(true) : setIsQualified(false);
    
      // Get Tier Level
      user_data.tier_level = user_tier.tier_level
      // Get Tier Category Level
      user_data.category_name = user_tier.category_name
      // Get Membership Expiration
      const user_subscription = await axios.get(`${url_path}/api/userSubscription/${ res.user._id }`)
      user_data.expiration = moment(new Date(user_subscription.data.subscription_expiration)).format('l')
      
      if(card_selected){
        const { data: card_selected_ } = await axios.get(`${url_path}/api/card-imgur/${res.user.card_selected}`)
        setCardSelected(card_selected_)
      }
      setUserData(user_data)
      setLoading( false )
      
console.log(card_selected)
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <main className="infinity-card member-dashboard">
      <Helmet>
        {/* <title>My Infinity Card - Infinity Privilege</title> */}
      </Helmet>
      <Sidebar history={history} />
      <div className="content-wrapper">
        <Breadcrumbs
          placeholder="Back"
          size="text-large"
          targetLink="/member-dashboard"
        />
        {loading ? (
          <div className="lds-ellipsis">
            <div className="dots"></div>
            <div className="dots"></div>
            <div className="dots"></div>
            <div className="dots"></div>
          </div>
        ) : (
          <div className="container">
            <div className="swiper-content-wrapper">
              <div className="card-swiper dashboard-swiper">
                <Swiper {...params} getSwiper={updateSwiper}>
                  <div className="card-swiper-holder">
                    
                     {/* <p className="heading">My Infinity Card</p> */}
                     <div className="infinity-card-img">
                      {
                        (userData.user_type == 'classic') ? (
                          <>
                          <img 
                            src={require(`../../assets/images/cards/${ userData.category_name.toLowerCase() }/tier-1`)} 
                            className="img-card"
                            alt="" 
                           
                          />
                          <img src={require('../../assets/images/logo-white.png')} className="card-logo" alt="Infinity Privilege"/>
                      </>
                        ): (
                        (card_selected !== '') ? (
                          <img 
                            src={card_selected} 
                            className="img-card"
                            alt=""
                      />
                        ):(
                          <>
                          <img 
                            src={require(`../../assets/images/cards/${ userData.category_name.toLowerCase() }/tier-${ userData.tier_level }.jpg`)} 
                            className="img-card"
                            alt="" 
                           
                          />
                          <img src={require('../../assets/images/logo-white.png')} className="card-logo" alt="Infinity Privilege"/>
                      </>
                        ))
                        
                      }
                    
                   
                      
                      <div className="card-details">
                        <div className="member-card-info">
                          <p className="member-name">{userData.name}</p>
                          <p className="member-card-number">
                            {userData.cardNo}
                          </p>
                          <div className="member-bottom-info">
                            <p className="card-bottom-text">
                              Expires {userData.expiration}
                            </p>
                            <p className="card-bottom-text">
                              Reference {userData.referenceNo}
                            </p>
                          </div>
                        </div>
                        <div className="tier-wrapper">
                          <img
                            src={require("../../assets/images/card-tier.jpg")}
                            className="card-tier"
                            alt="Infinity Privilege"
                          />
                          <p className="tier-text">{userData.tier_level}</p>
                        </div> 
                      </div>
                    </div>
                  </div>
                  <div className="card-swiper-holder">
                    {/* <p className="heading">My Infinity QR Code</p> */}
                    <div className="background">
                      <QRCode
                        bgColor="transparent"
                        renderAs="svg"
                        level="H"
                        size="320"
                        value={userData.cardNo}
                      />
                    </div>
                  </div>
                  {/* <div className="card-swiper-holder">
                    <div className="background">
                      <Barcode 
                        background="transparent"
                        width="3"
                        height="150"
                        value={ userData.cardNo }
                      />
                    </div>
                  </div> */}
                </Swiper>

                <div className="navigations-wrapper">
                  <span className="back-btn" onClick={goPrev}></span>
                  <span className="back-btn next-btn" onClick={goNext}></span>
                </div>
              </div>
              {/* {isQualified ? (
                  <div className="member-details-wrapper">
                    <div className="member-details-holder" style={{paddingTop: "10px"}}>
                      <p className="congratulation-greeting" style={{fontFamily: "Gotham-Bold"}}>
                        Congratulations on reaching 250 points! you can now apply for a card<Upgrade></Upgrade>
                      </p>
                    </div>
                  </div>
                ) : ""}       */}
              <MemberDetails
                name={userData.name}
                tier={userData.tier_level}
                cardNo={userData.cardNo}
                referenceNo={userData.referenceNo}
                expiration={userData.expiration}
              />
            </div>
          </div>
        )}
      </div>
    </main>
  );
}

export default InfinityCard;
