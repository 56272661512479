import React, { useEffect, useState } from "react";
import { url_path } from "../../constant";
import Card from "../../components/Card";
import useForm from "../../lib/hooks/useForm";
import axios from "axios";

import Modal from "react-bootstrap/Modal";
import Table from "../../components/Table";
import ReactTooltip from "react-tooltip";
import {
  successNotif,
  errorNotifIcon,
} from "../../lib/helpers/notifications.js";
import Sweetalert2 from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

import { ReactComponent as IconDelete } from "../../assets/images/icon-delete.svg";
import { ReactComponent as IconEdit } from "../../assets/images/icon-edit.svg";

import PhoneInput from "react-phone-input-2";
import { ReactComponent as IconCalendar } from "../../assets/images/icon-calendar.svg";
import DatePicker from "react-datepicker";
import { ReactComponent as IconCaretDown } from "../../assets/images/icon-caret-down.svg";
import BootstrapSwitchButton from "bootstrap-switch-button-react";

import Moment from "react-moment";
const Swal = withReactContent(Sweetalert2);
const modalInfo = {
  title: "New Staff",
  customClassName: "no-margin no-shadow",
};

const ContactDetails = ({ data, user_id }) => {
  // States
  const [tableData, setTableData] = useState([]);
  const [loader, setLoader] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const [editId, setEditId] = useState("");

  const [phone, setPhone] = useState();
  const [dialCode, setDialCode] = useState();
  const [inputType, setInputType] = useState(true);
  const iconShow = require("../../assets/images/icon-show.png");
  const iconHide = require("../../assets/images/icon-hide.png");
  const [birthDate, setBirthDate] = useState();
  // Form Validations

  // Regular Expressions
  var nameRegex = /^[a-zA-Z ]+$/;
  var emailRegex = /\S+@\S+\.\S+/;
  var passwordMinChar = /^.{8,}$/;

  const stateSchema = {
    first_name: { value: "", error: "" },
    last_name: { value: "", error: "" },
    phone_number: { value: "", error: "" },
    email: { value: "", error: "" },
    password: { value: "", error: "" },
    title: { value: "", error: "" },
    birthdate: { value: "", error: "" },
    gender: { value: "", error: "" },
    status: { value: "", error: "" },
  };

  const stateValidatorSchema = {
    first_name: {
      required: true,
      validator: {
        func: (value) => nameRegex.test(value),
        error: "Invalid name format!",
      },
    },
    last_name: {
      required: true,
      validator: {
        func: (value) => nameRegex.test(value),
        error: "Invalid name format!",
      },
    },
    email: {
      required: true,
      validator: {
        func: (value) => emailRegex.test(value),
        error: "Invalid email format!",
      },
    },
    password: {
      required: true,
      validator: {
        func: (value) => passwordMinChar.test(value),
        error: "Minimum 8 characters.",
      },
    },
    title: {
      required: true,
    },
    gender: {
      required: true,
    },
    status: {
      required: true,
    },
  };
  // console.log(user_id);
  const onSubmitForm = (state) => {
    setLoader(true);

    const {
      first_name,
      last_name,
      email,
      password,
      title,
      gender,
      status,
      // birthdate,
    } = state;

    if (editId === "") {
      // const user = {
      //   user_role: 'Business Partner',
      //   user_first_name: first_name,
      //   user_last_name: last_name,
      //   user_phone: phone_number,
      //   user_email: email,
      //   user_password: password,
      //   user_title: title,
      //   user_birthdate: birthdate,
      //   user_gender: gender,
      //   user_status: status
      // };
      const req = {
        user_role: "Business Partner",
        user_first_name: first_name,
        user_last_name: last_name,
        user_phone: phone,
        user_email: email,
        user_password: password,
        user_title: title,
        user_birthdate: birthDate,
        user_gender: gender,
        user_status: status,
        business_partner_id: user_id,
        dial_code: dialCode,
      };
      // return console.log(req);
      axios
        .post(`${url_path}/api/users/add`, req)
        .then((res) => {
          setLoader(false);
          if (res.data.data.status === "success") {
            let title = "Well Done!";
            let msg = "New Staff has been added successfully.";
            successNotif(title, msg);
            getData(1, "");
            handleHideModal();
          } else {
            let title = "Oops!";
            let msg = res.data.data.msg;
            errorNotifIcon(title, msg);
          }
        })
        .catch((err) => console.log(err));
    } else {
      const req = {
        user_role: "Business Partner",
        user_first_name: first_name,
        user_last_name: last_name,
        user_phone: phone,
        user_email: email,
        user_password: password,
        user_title: title,
        user_birthdate: birthDate,
        user_gender: gender,
        user_status: status,
        business_partner_id: user_id,
      };

      axios
        .put(`${url_path}/api/users/update/${editId}`, req)
        .then((res) => {
          setLoader(false);
          let title = "Well Done!";
          let msg = "Staff has been updated successfully.";
          successNotif(title, msg);
          getData(1, "");
          handleHideModal();
          setEditId("");
        })
        .catch((err) => console.log(err));
    }
  };

  // Retrieve values, errors, handlers, etc... from useForm hooks
  const { values, errors, handleOnChange, handleOnSubmit, disable } = useForm(
    stateSchema,
    stateValidatorSchema,
    onSubmitForm
  );

  const {
    first_name,
    last_name,
    email,
    password,
    title,
    gender,
    status,
  } = values;

  function togglePassword() {
    inputType ? setInputType(false) : setInputType(true);
  }

  // DatePicker
  const handleDateChangeRaw = (e) => {
    e.preventDefault();
  };

  const handleHideModal = () => {
    setShowModal(false);

    values.contact_person = "";
    values.email = "";
    values.contact_number = "";
    values.position = "";

    errors.contact_person = "";
    errors.email = "";
    errors.contact_number = "";
    errors.position = "";
    modalInfo.title = "";
  };

  const handleCreateModal = () => {
    setShowModal(true);

    modalInfo.title = "New Staff";
    values.title = "Mr.";
    values.gender = "Male";
    values.status = true;
  };

  const handleEditModal = (index) => {
    setShowModal(true);

    modalInfo.title = "Edit Staff Information";

    setEditId(tableData[index]._id);

    values.first_name = tableData[index].user_first_name;
    values.last_name = tableData[index].user_last_name;
    setPhone(tableData[index].user_phone);
    values.email = tableData[index].user_email;
    setBirthDate(new Date(tableData[index].user_birthdate));
    values.title = tableData[index].user_title;
    values.gender = tableData[index].user_gender;
    values.status = tableData[index].user_status;
  };

  const handleDelete = (index) => {
    const deleteId = tableData[index]._id;

    Swal.fire({
      title: "Are you sure?",
      message: "This can't be undone!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#286df9",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((res) => {
      if (res.value) {
        setLoader(false);
        axios
          .delete(`${url_path}/api/users/${deleteId}`)
          .then((res) => {
            let title = "Well Done!";
            let msg = "Staff has been deleted successfully.";
            successNotif(title, msg);
            getData(1, "");
            console.log(res);
          })
          .catch((err) => console.log(err));
      }
    });
  };

  // const handleInputChar = (evt) => {
  //   if (["-", "e"].includes(evt.key)) evt.preventDefault();
  // };

  const getData = (pageNumber, search) => {
    // return console.log(user_id);
    const req = { pageNumber, search, business_partner_id: user_id };
    axios
      .post(`${url_path}/api/users/get_partners_staff`, req)
      .then((response) => {
        console.log(response.data.users);
        setTableData(response.data.users);
        setLoader(false);
      })
      .catch(function (error) {
        console.log(error);
      });
    // axios.get(`${url_path}/api/business_contact/${user_id}`)
    //   .then(res => {
    //     setTableData(res.data.contact_list);
    //     setLoader(true);
    //     // console.log(res.data);
    //   })
    //   .catch(err => console.log(err));
  };

  const handlePhoneChanges = (value, data, event) => {
    // console.log(data.dialCode)
    // console.log(value);
    setPhone(value);
    setDialCode(data.dialCode);
    // console.log(countryCode);
  };

  useEffect(() => {
    getData(1, "");
  }, []);

  // Table Header
  const tableHeader = [
    {
      Header: "Name",
      accessor: "contact_person",
      Cell: (row) =>
        row.original.user_first_name + " " + row.original.user_last_name,
      width: 200,
    },
    {
      Header: "Email",
      accessor: "email",
      width: 300,
      Cell: (row) => row.original.user_email,
    },
    {
      Header: "Contact Number",
      accessor: "contact_number",
      Cell: (row) => row.original.user_phone,
      width: 150,
    },
    {
      Header: "Referral Code",
      accessor: "reference_number",
      width: 150,
    },
    {
      Header: "Birthdate",
      accessor: "user_birthdate",
      Cell: (row) => (
        <Moment format="MMMM DD, YYYY" date={row.original.user_birthdate} />
      ),
      width: 150,
    },
    {
      Header: "Status",
      width: 250,
      maxWidth: 250,
      id: "user_status",
      Cell: (row) => (
        <div className="featured-button-holder">
          <span>Deactivate</span>
          <BootstrapSwitchButton
            checked={row.original.user_status}
            onlabel=" "
            offlabel=" "
            onChange={(checked) => handleUserStatus(row.original._id, checked)}
          />
          <span>Activate</span>
        </div>
      ),
    },
    {
      Header: "Action",
      Cell: (row) => (
        <div className="button-wrapper">
          <div className="button-holder">
            <button
              className="btn-icon"
              onClick={() => handleEditModal(row.index)}
            >
              <span className="icon-holder">
                <IconEdit className="icon-actions" data-tip data-for="edit" />
              </span>
            </button>
            <ReactTooltip id="edit" type="warning" effect="solid">
              <span>Edit</span>
            </ReactTooltip>
          </div>
          <div className="button-holder">
            <button
              className="btn-icon"
              onClick={() => handleDelete(row.index)}
            >
              <span className="icon-holder">
                <IconDelete
                  className="icon-actions"
                  data-tip
                  data-for="delete"
                />
              </span>
            </button>
            <ReactTooltip id="delete" type="warning" effect="solid">
              <span>Delete</span>
            </ReactTooltip>
          </div>
        </div>
      ),
    },
  ];

  const handleUserStatus = (user_id, status) => {
    const req = {
      user_id,
      status,
    };
    // return console.log(req)

    axios.patch(`${url_path}/api/member/status_update`, req).then((res) => {
      console.log(res);
      let title = "Well Done!";
      let msg = status
        ? "Account has been Activated."
        : "Account has been Deactivated.";
      successNotif(title, msg);
      getData(1, "");
    });
  };

  return (
    <div className="tab-contact-details">
      <p className="heading">Staff</p>
      {!loader ? (
        <>
          <div className="section-table">
            <Table tableHeader={tableHeader} tableData={tableData}></Table>
          </div>

          <button className="btn-blue right" onClick={handleCreateModal}>
            New Staff
          </button>

          <Modal
            show={showModal}
            onHide={handleHideModal}
            className="modal-dark- opacity"
          >
            <Card
              cardDetails={modalInfo}
              form={
                <form className="form-wrapper" onSubmit={handleOnSubmit}>
                  <div className="form-field">
                    <input
                      id="first_name"
                      className="form-input"
                      type="text"
                      name="first_name"
                      value={first_name}
                      onChange={handleOnChange}
                      required
                    />
                    <label htmlFor="first_name">First name</label>
                    {errors.first_name && (
                      <span className="form-error">{errors.first_name}</span>
                    )}
                  </div>
                  <div className="form-field">
                    <input
                      id="last_name"
                      className="form-input"
                      type="text"
                      name="last_name"
                      value={last_name}
                      onChange={handleOnChange}
                      required
                    />
                    <label htmlFor="last_name">Last name</label>
                    {errors.last_name && (
                      <span className="form-error">{errors.last_name}</span>
                    )}
                  </div>
                  <div className="form-field">
                    <PhoneInput
                      inputClass={["form-input"]}
                      enableAreaCodes={true}
                      autoFormat={true}
                      country={"ph"}
                      // regions={'asia'}
                      value={phone}
                      onChange={handlePhoneChanges}
                      required
                    />
                    {/* <input
                      id='tel'
                      className='form-input'
                      type='number'
                      name='tel'
                      value={tel}
                      onChange={handleOnChange}
                      required
                    /> */}
                  </div>
                  <div className="form-field">
                    <input
                      id="email"
                      className="form-input"
                      type="text"
                      name="email"
                      value={email}
                      onChange={handleOnChange}
                      required
                    />
                    <label htmlFor="email">Email</label>
                    {errors.email && (
                      <span className="form-error">{errors.email}</span>
                    )}
                  </div>
                  <div className="form-field">
                    <input
                      id="password"
                      className={`form-input ${
                        !password ? "not-required" : ""
                      }`}
                      type={inputType ? "password" : "text"}
                      name="password"
                      value={password}
                      onChange={handleOnChange}
                    />
                    <label htmlFor="password">Password</label>
                    <span className="password__show" onClick={togglePassword}>
                      <img src={inputType ? iconHide : iconShow} alt="" />
                    </span>
                    {errors.password && (
                      <span className="form-error">{errors.password}</span>
                    )}
                  </div>
                  {/* <div className="form-field">
                    <input
                      id="address"
                      className="form-input"
                      type="text"
                      name="address"
                      value={address}
                      onChange={handleOnChange}
                      required
                    />
                    <label htmlFor="adress">Adress</label>
                    {errors.adress && (
                      <span className="form-error">{errors.adress}</span>
                    )}
                  </div> */}
                  <div className="form-field calendar">
                    <label htmlFor="startDate">
                      Date of Birth<span className="text-required">*</span>
                      <IconCalendar className="form-input-calendar" />
                    </label>
                    <DatePicker
                      selected={birthDate}
                      onChange={(date) => setBirthDate(date)}
                      peekNextMonth
                      showMonthDropdown
                      showYearDropdown
                      dropdownMode="select"
                      // selectsStart
                      // dateFormat="MMM dd, yyyy"
                      // startDate={startDate}
                      // endDate={endDate}
                      // maxDate={endDate}
                      onChangeRaw={handleDateChangeRaw}
                      className="form-input date"
                      name="startDate"
                      placeholderText="Oct 29, 2019"
                      autoComplete="off"
                      id="startDate"
                      required
                    />
                  </div>
                  <div className="form-field select">
                    <select
                      id="title"
                      className="form-input select"
                      name="title"
                      value={title}
                      onChange={handleOnChange}
                    >
                      <option value=""></option>
                      <option value="Mr">Mr.</option>
                      <option value="Mrs.">Mrs.</option>
                      <option value="Ms">Ms</option>
                      <option value="Miss">Miss</option>
                    </select>
                    <label htmlFor="title">
                      Title
                      <IconCaretDown className="form-select-caret-down" />
                    </label>
                  </div>
                  <div className="form-field select">
                    <select
                      id="gender"
                      className="form-input select"
                      name="gender"
                      value={gender}
                      onChange={handleOnChange}
                    >
                      <option value="Male">Male</option>
                      <option value="Female">Female</option>
                    </select>
                    <label htmlFor="gender">
                      Gender
                      <IconCaretDown className="form-select-caret-down" />
                    </label>
                  </div>
                  <div className="form-field select">
                    <select
                      id="status"
                      className="form-input select"
                      name="status"
                      value={status}
                      onChange={handleOnChange}
                    >
                      <option value="true">Active</option>
                      <option value="false">Inactive</option>
                    </select>
                    <label htmlFor="province">
                      Status
                      <IconCaretDown className="form-select-caret-down" />
                    </label>
                  </div>

                  <input
                    type="submit"
                    className="btn-blue full-width"
                    value="Save Changes"
                    disabled={disable}
                  />
                  <input
                    type="button"
                    className="btn-blue-outline full-width"
                    value="Cancel"
                    onClick={handleHideModal}
                  ></input>
                </form>
              }
            ></Card>
          </Modal>
        </>
      ) : (
        <div className="lds-ellipsis">
          <div className="dots"></div>
          <div className="dots"></div>
          <div className="dots"></div>
          <div className="dots"></div>
        </div>
      )}
    </div>
  );
};

export default ContactDetails;
