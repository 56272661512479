import {
  successNotif,
  errorNotifIcon,
} from "../../lib/helpers/notifications.js";
import { url_path } from "../../constant";
import useForm from "../../lib/hooks/useForm.js";
import React, { useState, useEffect } from "react";
import Table from "../../components/Table";
import Card from "../../components/Card";
import Breadcrumbs from "../../components/Breadcrumbs";
import Sweetalert2 from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import ReactTooltip from "react-tooltip";
import axios from "axios";
import Moment from "react-moment";
import Modal from "react-bootstrap/Modal";
import PhoneInput from "react-phone-input-2";
import DatePicker from "react-datepicker";
import "moment-timezone";
import { ReactComponent as IconDelete } from "../../assets/images/icon-delete.svg";
import { ReactComponent as IconEdit } from "../../assets/images/icon-edit.svg";
import { ReactComponent as IconSettings } from "../../assets/images/icon-settings.svg";
import { ReactComponent as IconCaretDown } from "../../assets/images/icon-caret-down.svg";
import { ReactComponent as IconCalendar } from "../../assets/images/icon-calendar.svg";
import { authenticateAdminPage } from "../../lib/helpers/validation";

const Swal = withReactContent(Sweetalert2);

const editInfo = {
  title: "Update User",
  hasClose: true,
  hasMessage: false,
  customClassName: "no-margin no-shadow",
};

const settingInfo = {
  title: "Set Access Control",
  hasClose: true,
  hasMessage: false,
  customClassName: "no-margin no-shadow",
};

// let test = [];
function ManageUsers(props) {
  useEffect(() => {
    authenticateAdminPage(props);
  }, []);

  // const [data, setData] = useState([])
  const [userData, setUserData] = useState([]);
  const [modalState, setModalState] = useState(false);
  const [modalSettingState, setModalSettingState] = useState(false);
  const [currentEdit_ID, setCurrentEdit_ID] = useState("");
  const [inputType, setInputType] = useState(true);
  const iconShow = require("../../assets/images/icon-show.png");
  const iconHide = require("../../assets/images/icon-hide.png");
  const [roleIsDisabled, setRoleIsDisabled] = useState(false);

  const [loading, setLoading] = useState(true);
  const [pagerData, setPagerData] = useState([]);
  const [searchInput, setSearchInput] = useState("");

  const [step, setStep] = useState(1);
  const [phone, setPhone] = useState();
  const [dialCode, setDialCode] = useState();
  const [birthDate, setBirthDate] = useState();
  const [onLoad, setOnload] = useState(false);

  const [control, setControl] = useState([]);

  /*
   * Regex Validations to Inputs
   */

  var nameRegex = /^[a-zA-Z ]+$/;
  var emailRegex = /\S+@\S+\.\S+/;
  var numberRegex = /^(09|\+639|9)\d{9}$/;
  var passwordMinChar = /^.{8,}$/;

  /*
   * Initialize input state schema
   */

  const stateSchema = {
    user_type: { value: "Sub Admin", error: "" },
    first_name: { value: "", error: "" },
    last_name: { value: "", error: "" },
    phone_number: { value: "", error: "" },
    email: { value: "", error: "" },
    password: { value: "", error: "" },
    address: { value: "", error: "" },
    title: { value: "Mr.", error: "" },
    gender: { value: "Male", error: "" },
    status: { value: false, error: "" },
  };

  /*
   * Create a schema for all inputs
   */

  const stateValidatorSchema = {
    first_name: {
      required: true,
      validator: {
        func: (value) => nameRegex.test(value),
        error: "Invalid first name format.",
      },
    },
    last_name: {
      required: true,
      validator: {
        func: (value) => nameRegex.test(value),
        error: "Invalid last name format.",
      },
    },
    phone_number: {
      required: true,
      validator: {
        func: (value) => numberRegex.test(value),
        error: "Invalid phone number format.",
      },
    },
    email: {
      required: true,
      validator: {
        func: (value) => emailRegex.test(value),
        error: "Invalid email.",
      },
    },
    password: {
      required: true,
      validator: {
        func: (value) => passwordMinChar.test(value),
        error: "Minimum 8 characters.",
      },
    },
    address: {
      required: true,
    },
    title: {
      required: true,
    },
    gender: {
      required: true,
    },
    status: {
      required: true,
    },
  };

  /*
   * On Submit Form
   */

  function onSubmitForm(state) {
    /*
     * Destructuring state Values
     */

    const {
      user_type,
      first_name,
      last_name,
      email,
      password,
      address,
      gender,
      status,
      title,
    } = state;

    /*
     * get data from state(Input) and set to variable
     */

    const user = {
      user_role: "Administrator",
      user_type: user_type,
      user_first_name: first_name,
      user_last_name: last_name,
      user_phone: phone,
      user_email: email,
      user_password: password,
      user_address: address,
      user_title: title,
      user_birthdate: birthDate,
      user_gender: gender,
      user_status: status,
      dial_code: dialCode,
    };
    // return console.log(user);

    if (!first_name || !last_name || !email || !password) {
      errorNotifIcon("Error Saving!", "One of the required fields is missing.");
      return;
    }
    if (currentEdit_ID !== "") {
      console.log("Edit");
      axios
        .put(`${url_path}/api/users/update/${currentEdit_ID}`, user)
        .then((res) => {
          let title = "Well Done!";
          let message = "User has been updated successfully.";
          successNotif(title, message);
          hideModal();
          getData(1, "");
          console.log(res.data);
        });
      // storeUser(currentEdit_ID, user).then(res => {
      //   let title = 'Well Done!';
      //   let message = 'User has been updated';
      //   successNotif(title, message);
      //   hideModal();
      //   getData();
      //   console.log(res.data);
      // });
    } else {
      console.log("Add");
      axios.post(`${url_path}/api/users/add`, user).then((res) => {
        if (res.data.data.status === "success") {
          console.log(res);
          let title = "Well Done!";
          let message = "New user has been created successfully.";
          successNotif(title, message);
          hideModal();
          getData(1, "");
          appendNewUser(res.data);
          // console.log(res.data.user);
          console.log(res.data);
        } else {
          console.log(res);
          let title = "Oops!";
          let message = res.data.data.msg;
          errorNotifIcon(title, message);
          // hideModal();
          // getData(1, '');
          // appendNewUser(res.data);
          // console.log(res.data.user);
          // console.log(res.data);
        }
      });
    }
  }

  // console.log("Running");
  // console.log(currentEdit_ID);

  /*
   * Get values, errors, handling events to custom useForm Hooks
   */

  const { values, errors, handleOnChange, handleOnSubmit, disable } = useForm(
    stateSchema,
    stateValidatorSchema,
    onSubmitForm
  );

  /*
   * Get values to custom useForm Hooks
   * Pass it to input values
   */

  const {
    first_name,
    last_name,
    email,
    password,
    address,
    title,
    gender,
  } = values;

  var status = values.status.toString();

  /*
   * Toggle Input Password Type and Icon
   */

  function togglePassword() {
    inputType ? setInputType(false) : setInputType(true);
  }

  /*
   * Get User Data to Database
   * Set Data to State
   */

  function getData(pageNumber = 1, search = searchInput) {
    setLoading(true);

    const req = { pageNumber, search };
    axios
      .post(`${url_path}/api/users/`, req)
      .then((response) => {
        setUserData(response.data.users);
        setPagerData(response.data.paginate);
        setLoading(false);
        console.log(response.data.users);
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  /*
   * Callback funciton to Add
   * Append data to state[data]
   */

  function appendNewUser(data) {
    let user = {
      _id: data._id,
      user_position: data.user_position,
      user_first_name: data.user_first_name,
      user_last_name: data.user_last_name,
      user_phone: data.user_phone,
      user_email: data.user_email,
      user_password: data.user_password,
      user_address: data.address,
      user_title: data.title,
      user_gender: data.gender,
      user_birthdate: data.birthdate,
      user_status: data.user_status,
    };

    setUserData([...userData, user]);
  }

  /*
   * Mount User Data
   * Clear and Set Initial Data to Inputs
   */

  useEffect(() => {
    getData(1, "");

    // setUserData(userData)
  }, []);

  /*
   * Modal Actions Show/Hide
   */

  const hideModal = () => {
    setModalState(false);
    setCurrentEdit_ID("");
  };

  const showModal = () => {
    setStep(1);
    setModalState(true);
  };

  const hideSettingModal = () => {
    setModalSettingState(false);
    setCurrentEdit_ID("");
  };

  const showSettingModal = () => {
    setModalSettingState(true);
    // getAccessControl();
  };
  // DatePicker
  const handleDateChangeRaw = (e) => {
    e.preventDefault();
  };
  /*
   * Action Function Handlers
   */

  const handleShowCreate = () => {
    showModal();
    editInfo.title = "Create New User";
    // console.log(values)
    // console.log(errors)
    setRoleIsDisabled(false);
    setPhone("");
    // birthDate();
    values.first_name = "";
    values.last_name = "";
    values.phone_number = "";
    values.email = "";
    values.password = "";
    values.address = "";
    values.title = "Mr.";
    values.birthdate = "";
    values.gender = "Male";
    values.status = false;

    errors.first_name = "";
    errors.last_name = "";
    errors.phone_number = "";
    errors.email = "";
    errors.password = "";
    errors.address = "";
    errors.title = "";
    errors.birthdate = "";
    errors.gender = "";
    errors.status = "";
  };

  const handleShowEdit = () => {
    showModal();
    editInfo.title = "Update User";
    setRoleIsDisabled(true);
    setPhone("");

    values.first_name = "";
    values.last_name = "";
    values.phone_number = "";
    values.email = "";
    values.password = "";
    values.address = "";
    values.title = "Mr.";
    values.birthdate = "";
    values.gender = "Male";
    values.status = false;

    errors.first_name = "";
    errors.last_name = "";
    errors.phone_number = "";
    errors.email = "";
    errors.password = "";
    errors.address = "";
    errors.title = "";
    errors.birthdate = "";
    errors.gender = "";
    errors.status = "";
  };

  const handleShowAccessControl = () => {
    showSettingModal();
  };

  /*
   * Disallow non valid keys to input number
   */

  // const handleInputNumberAllowedCharacter = (event) => {
  //   var invalidChars = ["-", "e"];

  //   if (invalidChars.includes(event.key)) {
  //     event.preventDefault();
  //   }
  // };

  const handleSearchInput = (event) => {
    setSearchInput(event.target.value);
  };

  const handleSearchSubmit = (event) => {
    event.preventDefault();

    getData(1, searchInput);
  };
  const handleNext = () => {
    // event.preventDefault()

    setStep(2);
  };
  const handlePrev = () => {
    // event.preventDefault()

    setStep(1);
  };

  const handleCheckChieldElement = (event) => {
    // setModalSettingState(false);
    setOnload(true);
    let controls = control;
    controls.forEach((item) => {
      if (item.value === event.target.value)
        item.isChecked = event.target.checked;
    });
    setControl(controls);
    // this.forceUpdate();
    // this.props.render();
    // setModalSettingState(true);
    setOnload(false);
    console.log(controls);
    // this.setState({fruites: fruites})
  };

  const handleSetAccessControl = () => {
    const req = { controls: control };
    axios
      .put(`${url_path}/api/users/access_control_update/${currentEdit_ID}`, req)
      .then((response) => {
        // setUserData(response.data.users);
        // setPagerData(response.data.paginate);
        // setLoading(false);
        if (response.data.data.status === "success") {
          let title = "Well Done!";
          let message = "Acess Control has been updated successfully.";
          successNotif(title, message);
          hideSettingModal();
        }
        console.log(response.data);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const handlePhoneChanges = (value, data, event) => {
    // console.log(data.dialCode)
    // console.log(value);
    setPhone(value.replace(/ /g, ""));
    setDialCode(data.dialCode);
    // console.log(countryCode);
  };

  /*
   * Table Data
   * Includes Headings
   */

  const heading = [
    {
      Header: "Name",
      id: "fullName",
      accessor: (row) => `${row.user_first_name} ${row.user_last_name}`,
      // filterMethod: (filter, row) =>
      //   row._original.user_first_name.startsWith(filter.value) ||
      //   row._original.user_last_name.startsWith(filter.value)
    },
    {
      Header: "Email",
      accessor: "user_email",
    },
    {
      Header: "Status",
      id: "status",
      accessor: (d) => d.user_status,
      Cell: (status) => (
        <div className="active-holder">
          {status.original.user_status ? (
            <span className="table-status-active">Active</span>
          ) : (
            <span className="table-status-inactive">Inactive</span>
          )}
        </div>
      ),
    },
    {
      Header: "Created On",
      id: "createdOn",
      accessor: (c) => <Moment format="ll" date={c.created_at} />,
      // {Moment(new Date(c.created_at)).format('ll')}
      // Cell: row => (
      //   <span>
      //     {<Moment format='L' date={row.created_at} />}
      //   </span>
      // )
    },
    {
      Header: "Actions",
      sortable: false,
      // filterable: false,
      Cell: (row) =>
        // row.original.user_role !== 'Member' &&
        row.original.user_type === "Main Admin" ? null : (
          <div className="button-wrapper">
            <div className="button-holder">
              <button
                className="btn-icon"
                onClick={() => {
                  handleShowEdit();
                  // getData()

                  /*
                   * Get data from user datas
                   */

                  let datas = [...userData];
                  console.log({ USERS: datas[row.index] });
                  let status = datas[row.index].user_status.toString();
                  let number = datas[row.index].user_phone.toString();

                  /*
                   * Initialize CurrentID
                   */

                  setCurrentEdit_ID(datas[row.index]._id);

                  /*
                   * Add shorthand notation value
                   */

                  const {
                    user_position,
                    user_first_name,
                    user_last_name,
                    user_address,
                    user_birthdate,
                    // user_phone,
                    user_email,
                    // user_password
                    // user_status
                  } = datas[row.index];

                  /*
                   * Set User Data value to Current Input Fields
                   */
                  values.user_position = user_position;
                  values.first_name = user_first_name;
                  values.last_name = user_last_name;
                  values.phone_number = number;
                  values.email = user_email;
                  values.address = user_address;
                  setBirthDate(new Date(user_birthdate));
                  // values.birthdate = user_birthdate;
                  values.password = "";
                  values.status = status;
                }}
              >
                <span className="icon-holder">
                  <IconEdit className="icon-actions" data-tip data-for="edit" />
                </span>
              </button>
              <ReactTooltip id="edit" type="warning" effect="solid">
                <span>Edit</span>
              </ReactTooltip>
            </div>
            {/* for Controll Access */}
            <div className="button-holder">
              <button
                className="btn-icon"
                onClick={() => {
                  let datas = [...userData];
                  // console.log({ USERS: datas[row.index] });
                  setCurrentEdit_ID(datas[row.index]._id);
                  axios
                    .get(
                      `${url_path}/api/users/admin_access_control/${
                        datas[row.index]._id
                      }`
                    )
                    .then((response) => {
                      setControl(response.data);
                      // setUserData(response.data.users);
                      // setPagerData(response.data.paginate);
                      // setLoading(false);
                    })
                    .catch(function (error) {
                      console.log(error);
                    });

                  // getAccessControl(datas[row.index]._id);
                  handleShowAccessControl();
                }}
              >
                <span className="icon-holder">
                  <IconSettings
                    className="icon-actions"
                    data-tip
                    data-for="settings"
                  />
                </span>
              </button>
              <ReactTooltip id="settings" type="warning" effect="solid">
                <span>Settings</span>
              </ReactTooltip>
            </div>
          </div>
        ),
    },
  ];

  return (
    <main className="manage-pages">
      <div className="container custom-container">
        <Breadcrumbs
          size="text-large"
          placeholder="Manage Users"
          targetLink="/admin-dashboard"
          hasButton="true"
          buttonTitle="Create New User"
          customClassName="m-3"
          propsFunction={handleShowCreate}
        />
        <div className="section-table">
          {/* <div className="container"> */}
          <form onSubmit={handleSearchSubmit}>
            <div className="search-holder">
              <label htmlFor="searchInput">Search: </label>
              <input
                size="large"
                name="searchInput"
                label="Search"
                value={searchInput}
                onChange={handleSearchInput}
                className="search-input"
              />
              <input type="submit" className="btn-blue" value="Search" />
            </div>
          </form>
          {loading ? (
            <div className="lds-ellipsis">
              <div className="dots"></div>
              <div className="dots"></div>
              <div className="dots"></div>
              <div className="dots"></div>
            </div>
          ) : (
            <Table
              tableHeader={heading}
              tableData={userData}
              paginate={pagerData}
              pageFunction={getData}
            />
          )}
          {/* </div> */}
        </div>
        <Modal
          show={modalState}
          onHide={hideModal}
          className="modal-dark- opacity"
        >
          <Card
            cardDetails={editInfo}
            form={
              <form className="form-wrapper" onSubmit={handleOnSubmit}>
                {step === 1 ? (
                  <>
                    {/* <div className="form-field select">
                      <input
                        id="user_position"
                        className="form-input"
                        type="text"
                        name="user_position"
                        value={user_position}
                        onChange={handleOnChange}
                        required
                      />
                      <label htmlFor="user_position">
                        Position
                      </label>
                    </div> */}
                    <div className="form-field select">
                      <select
                        id="title"
                        className="form-input select"
                        name="title"
                        value={title}
                        onChange={handleOnChange}
                      >
                        <option value=""></option>
                        <option value="Mr">Mr.</option>
                        <option value="Mrs.">Mrs.</option>
                        <option value="Ms">Ms</option>
                        <option value="Miss">Miss</option>
                      </select>
                      <label htmlFor="title">
                        Title
                        <IconCaretDown className="form-select-caret-down" />
                      </label>
                    </div>
                    <div className="form-field">
                      <input
                        id="first_name"
                        className="form-input"
                        type="text"
                        name="first_name"
                        value={first_name}
                        onChange={handleOnChange}
                        required
                      />
                      <label htmlFor="first_name">
                        First name<span className="text-required">*</span>
                      </label>
                      {errors.first_name && (
                        <span className="form-error">{errors.first_name}</span>
                      )}
                    </div>
                    <div className="form-field">
                      <input
                        id="last_name"
                        className="form-input"
                        type="text"
                        name="last_name"
                        value={last_name}
                        onChange={handleOnChange}
                        required
                      />
                      <label htmlFor="last_name">
                        Last name<span className="text-required">*</span>
                      </label>
                      {errors.last_name && (
                        <span className="form-error">{errors.last_name}</span>
                      )}
                    </div>
                    <div className="form-field">
                      <PhoneInput
                        inputClass={["form-input"]}
                        enableAreaCodes={true}
                        autoFormat={true}
                        country={"ph"}
                        countryCodeEditable={true}
                        value={phone}
                        onChange={handlePhoneChanges}
                        required
                      />
                      <label htmlFor="phone" className="label-fixed">
                        Phone
                      </label>
                      {/* <input
                        id='tel'
                        className='form-input'
                        type='number'
                        name='tel'
                        value={tel}
                        onChange={handleOnChange}
                        required
                      /> */}
                    </div>
                    <div className="form-field">
                      <input
                        id="address"
                        className="form-input"
                        type="text"
                        name="address"
                        value={address}
                        onChange={handleOnChange}
                        required
                      />
                      <label htmlFor="address">
                        Address<span className="text-required">*</span>
                      </label>
                      {errors.address && (
                        <span className="form-error">{errors.address}</span>
                      )}
                    </div>

                    <div className="form-field calendar">
                      <label htmlFor="startDate">
                        Date of Birth<span className="text-required">*</span>
                        <IconCalendar className="form-input-calendar" />
                      </label>
                      <DatePicker
                        selected={birthDate}
                        onChange={(date) => setBirthDate(date)}
                        peekNextMonth
                        showMonthDropdown
                        showYearDropdown
                        dropdownMode="select"
                        // selectsStart
                        // dateFormat="MMM dd, yyyy"
                        // startDate={startDate}
                        // endDate={endDate}
                        // maxDate={endDate}
                        onChangeRaw={handleDateChangeRaw}
                        className="form-input date"
                        name="startDate"
                        placeholderText="Oct 29, 2019"
                        autoComplete="off"
                        id="startDate"
                        required
                      />
                    </div>

                    <div className="form-btn-wrapper form-btn-dual-wrapper">
                      <input
                        type="button"
                        className="btn-d btn-gray"
                        value="Cancel"
                        onClick={hideModal}
                      ></input>
                      <input
                        type="button"
                        className="btn-d btn-blue"
                        value="Continue"
                        onClick={handleNext}
                      ></input>
                    </div>
                  </>
                ) : (
                  <>
                    <div className="form-field select">
                      <select
                        id="gender"
                        className="form-input select"
                        name="gender"
                        value={gender}
                        onChange={handleOnChange}
                      >
                        <option value="Male">Male</option>
                        <option value="Female">Female</option>
                      </select>
                      <label htmlFor="gender">
                        Gender
                        <IconCaretDown className="form-select-caret-down" />
                      </label>
                    </div>
                    <div className="form-field">
                      <input
                        id="email"
                        className="form-input"
                        type="text"
                        name="email"
                        value={email}
                        onChange={handleOnChange}
                        required
                      />
                      <label htmlFor="email">
                        Email<span className="text-required">*</span>
                      </label>
                      {errors.email && (
                        <span className="form-error">{errors.email}</span>
                      )}
                    </div>

                    <div className="form-field">
                      <input
                        id="password"
                        className={`form-input ${
                          !password ? "not-required" : ""
                        }`}
                        type={inputType ? "password" : "text"}
                        name="password"
                        value={password}
                        onChange={handleOnChange}
                      />
                      <label htmlFor="password">
                        Password<span className="text-required">*</span>
                      </label>
                      <span className="password__show" onClick={togglePassword}>
                        <img src={inputType ? iconHide : iconShow} alt="" />
                      </span>
                      {errors.password && (
                        <span className="form-error">{errors.password}</span>
                      )}
                    </div>

                    {/* <div className="form-field">
                      
                        <h5>Cards Access</h5>
                          <div className = 'checkbox_container'>
                            <input
                                type={this.props.type}
                                name={this.props.name}
                                checked={this.props.checked}
                                onChange={this.props.onChange}
                            />
                            <input type="checkbox" className="cred_checkbox" value='as'/>
                            <span>Manage Users</span>
                          </div>
                          
                          <div className = 'checkbox_container'>
                            <input type="checkbox" className="cred_checkbox" value='as'/>
                            <span>Manage Business Partners</span>
                          </div>
                          <div className = 'checkbox_container'>
                            <input type="checkbox" className="cred_checkbox" value='as'/>
                            <span>Manage Members</span>
                          </div>
                          
                          <div className = 'checkbox_container'>
                            <input type="checkbox" className="cred_checkbox" value='as'/>
                            <span>Transactions</span>
                          </div>
                          <div className = 'checkbox_container'>
                            <input type="checkbox" className="cred_checkbox" value='as'/>
                            <span>Promo Code</span>
                          </div>
                          
                          <div className = 'checkbox_container'>
                            <input type="checkbox" className="cred_checkbox" value='as'/>
                            <span>Payment Request</span>
                          </div>
                      </div> */}

                    <div className="form-field select">
                      <select
                        id="status"
                        className="form-input select"
                        name="status"
                        value={status}
                        onChange={handleOnChange}
                      >
                        <option value="true">Active</option>
                        <option value="false">Inactive</option>
                      </select>
                      <label htmlFor="province">
                        Status
                        <IconCaretDown className="form-select-caret-down" />
                      </label>
                    </div>
                    <div className="form-btn-wrapper form-btn-dual-wrapper">
                      <input
                        type="button"
                        className="btn-d btn-gray"
                        value="Back"
                        onClick={handlePrev}
                      ></input>
                      <input
                        type="submit"
                        className="btn-d btn-blue"
                        value="Save Changes"
                        disabled={disable}
                      />
                    </div>
                  </>
                )}
              </form>
            }
          />
        </Modal>
        <Modal
          show={modalSettingState}
          onHide={hideSettingModal}
          className="modal-dark- opacity"
        >
          <Card
            cardDetails={settingInfo}
            form={
              <form className="form-wrapper" onSubmit={handleOnSubmit}>
                {control
                  ? control.map((items) => {
                      // console.log(items.isChecked);
                      return (
                        <li>
                          <input
                            key={items.id}
                            type="checkbox"
                            onChange={handleCheckChieldElement}
                            checked={items.isChecked}
                            value={items.value}
                          />{" "}
                          {items.name}
                        </li>
                      );
                    })
                  : null}
                <div className="form-btn-wrapper form-btn-dual-wrapper">
                  <input
                    type="button"
                    className="btn-d btn-blue"
                    value="Save"
                    onClick={handleSetAccessControl}
                  ></input>
                </div>
              </form>
            }
          />
        </Modal>
      </div>
    </main>
  );
}

export default ManageUsers;
