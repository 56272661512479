import { url_path } from "../../constant";
import { setCookie, getCookie } from "./cookies";
import { errorNotifIcon } from "./notifications";
import axios from "axios";

// const user_type = localStorage.getItem("type");
// const register_id = localStorage.getItem('register_id')

export function PublicAuth(props) {
  // console.log(isLoggedIn())
  const token = getCookie("token");
  // console.log(token)
  if (token) {
    axios
      .get(`${url_path}/api/auth`, { headers: { "x-auth-token": token } })
      .then((res) => {
        if (res.data.user.user_role === "Administrator") {
          // return <Redirect to="/admin-dashboard" />
          return props.history.push("/admin-dashboard");
        } else if (res.data.user.user_role === "Business Partner") {
          // return <Redirect to="/partner-dashboard" />
          return props.history.push("/partner-dashboard");
        } else if (res.data.user.user_role === "Member") {
          // return <Redirect to="/member-dashboard" />
          return props.history.push("/member-dashboard");
        }
      })
      .catch((err) => {
        // console.log('Invalid Token!')
        const title = "Oops!";
        const msg = "Session Expired!";
        errorNotifIcon(title, msg);
        setCookie("token", null, null);
        return props.history.push("/");
      });
  }
}

export function authenticateAdminPage(props) {
  const token = getCookie("token");
  // console.log(token)
  if (token) {
    axios
      .get(`${url_path}/api/auth`, { headers: { "x-auth-token": token } })
      .then((res) => {
        // console.log(res.data)
        if (res.data.user.user_role === "Business Partner") {
          // return <Redirect to="/partner-dashboard" />
          return props.history.push("/partner-dashboard");
        } else if (res.data.user.user_role === "Member") {
          // return <Redirect to="/member-dashboard" />
          return props.history.push("/member-dashboard");
        }
      })
      .catch((err) => {
        // console.log(err)
        const title = "Oops!";
        const msg = "Session Expired!";
        errorNotifIcon(title, msg);
        setCookie("token", null, null);
        return props.history.push("/");
      });
  } else {
    return props.history.push("/");
  }
}

export function authenticateMemberPage(history) {
  const token = getCookie("token");
  if (token) {
    axios
      .get(`${url_path}/api/auth`, { headers: { "x-auth-token": token } })
      .then((res) => {
        // console.log(res.data)
        if (res.data.user.user_role === "Administrator") {
          // return <Redirect to="/admin-dashboard" />
          return history.push("/admin-dashboard");
        } else if (res.data.user.user_role === "Business Partner") {
          // return <Redirect to="/partner-dashboard" />
          return history.push("/partner-dashboard");
        }
      })
      .catch((err) => {
        // console.log('Invalid Token!')
        const title = "Oops!";
        const msg = "Session Expired!";
        errorNotifIcon(title, msg);
        setCookie("token", null, null);
        return history.push("/");
      });
  } else {
    return history.push("/");
  }
}

export function authenticateBusinessPage(history) {
  const token = getCookie("token");
  if (token) {
    axios
      .get(`${url_path}/api/auth`, { headers: { "x-auth-token": token } })
      .then((res) => {
        // console.log(res.data)
        if (res.data.user.user_role === "Administrator") {
          // return <Redirect to="/admin-dashboard" />
          return history.push("/admin-dashboard");
        } else if (res.data.user.user_role === "Member") {
          // return <Redirect to="/member-dashboard" />
          return history.push("/member-dashboard");
        }
      })
      .catch((err) => {
        // console.log('Invalid Token!')
        const title = "Oops!";
        const msg = "Session Expired!";
        errorNotifIcon(title, msg);
        setCookie("token", null, null);
        return history.push("/");
      });
  } else {
    return history.push("/");
  }
}
