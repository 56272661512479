import axios from "axios";
import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import Dashboard from "../../components/Dashboard";
import DashboardSwiper from "../../components/DashboardSwiper";
import Sidebar from "../../components/Sidebar";
import { url_path } from "../../constant";
import {
  getCookie
} from "../../lib/helpers/cookies";
import { authenticateMemberPage } from "../../lib/helpers/validation";

const MemberDashboard = ({ history }) => {
  // console.log(props)
  useEffect(() => {
    authenticateMemberPage(history);
  }, []);
  // States
  let linksList = [];
  const [userId, setUserId] = useState("");
  const [favorites, setFavorites] = useState([]);
  const [offers, setOffers] = useState([]);
  const [loading, setLoading] = useState(true);

  const [dealsContent, setDeals] = useState([]);
  const [isClassic, setIsClassic] = useState();

  // var deals_array = [];

  // Functions
  const getData = async () => {
    setLoading(true);

    const token = getCookie("token");

    try {
      const { data: user_data } = await axios.get(`${url_path}/api/auth`, {
        headers: { "x-auth-token": token },
      });
      const user_id = user_data.user._id;
      setUserId(userId);
      user_data.user.user_type == "classic" ? setIsClassic(true) : setIsClassic(false);
      const req = { user_id };
      const { data: member_deals } = await axios.post(
        `${url_path}/api/member_deal/latest`,
        req
      );
      const { data: favorite_deals } = await axios.get(
        `${url_path}/api/favorite_deal/${user_id}`
      );
      setOffers(member_deals);
      setFavorites(favorite_deals);
      
    } catch (err) {
      console.log(err);
    }
  };
   
  linksList = [
    {
      title: "My Infinity Card",
      routeTo: "/my-infinity-card",
      icon: require("../../assets/images/dashboard-card.png")
    },
    {
      title: "My Infinity Transactions",
      routeTo: "/my-infinity-account-transaction",
      icon: require("../../assets/images/dashboard-account.png")
    }
  ]
  if(isClassic == false){
    linksList.push(
      {
        title: "Request A Plastic Card",
        routeTo: "/request",
        icon: require("../../assets/images/dashboard-request.png"),
      },
      {
        title: "Invite Friends",
        routeTo: "/invite",
        icon: require("../../assets/images/dashboard-statement.png"),
      },
    )
  }


  console.log(JSON.stringify(dealsContent));
  const fetchData = async () => {
    try {
      const { data } = await axios.get(`${url_path}/api/carousel-images`);
      // const collection = [];

      const collection = data.map((d) => {
        return {
          title: "Special offer",
          description: "Special offer for the month",
          image_url: d,
          routeTo: "",
        };
      });

      setDeals(collection);
    } catch (error) {
      // handleErrors(error);
    }
  };

  useEffect(() => {
    getData();
    fetchData();
  }, []);

  return (
    <main className="member-dashboard">
      <Helmet>
        <title>Infinity Privilege</title>
        <meta name="viewport" content="width=device-width, initial-scale=1" />
      </Helmet>
      <Sidebar history={history} />
      <div className="content-wrapper">
        {dealsContent.length > 0 && <DashboardSwiper deals={dealsContent} />}

        <Dashboard links={linksList} />
      </div>
    </main>
  );
};

export default MemberDashboard;
