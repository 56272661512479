import React from 'react'
import { Link } from 'react-router-dom'

function Card(props) {
  const { cardDetails, form, divider, icon } = props
  
  return (
    <div className={`card-wrapper ${cardDetails.customClassName}`}>
      {
        cardDetails.hasBack === true ? 
        <h2 className="title margin">
          {cardDetails.title}
          <Link to="/forgot-password"><span className="back-btn"></span></Link>
        </h2> 
        : 
        <h2 className="title">  
          {cardDetails.title}
        </h2>
      }
      {divider}
      {icon}
      {cardDetails.hasMessage === true ? <p className="message">{cardDetails.message}</p> : ''}
      {form}
    </div>
  )
}

export default Card
