import React, { useEffect } from "react";
import { url_path } from "../../constant";
import axios from "axios";
import { Link } from "react-router-dom";
import {  errorNotifIcon } from "../../lib/helpers/notifications";
import Card from "../../components/Card";
import "../../stylesheets/main.scss";
import queryString from "query-string";

function ForgotSuccess(props) {
  // const { back, button } = props;

  const cardInfo = {
    title: "Payment Successful",
    // closeLink: '/forgot-password',
    // hasBack: true,
    hasMessage: true,
    message:
      "Thank you for choosing Infinity Privilege. You may now login to your account",
    // customClassName: 'has-back-button'
  };
  function getData() {
    let params = queryString.parse(props.location.search);
    console.log(params);

    // activate pendingMember if successful
    axios
      .post(`${url_path}/api/users/get_pending_member/${params.paymentId}`)
      .then((res) => {
        const userData = res.data;
        // activate pendingMember if successful
        if (userData.data.status === "success") {
          // save now to as real user
          var deliver_address = "work";
          if (userData.memberData.is_home) {
            deliver_address = "home";
          }
          const user = {
            user_title: userData.memberData.title,
            user_first_name: userData.memberData.first_name,
            user_middle_name: userData.memberData.middle_name,
            user_last_name: userData.memberData.last_name,
            user_birthdate: userData.memberData.birthdate,
            user_gender: userData.memberData.gender,
            user_email: userData.memberData.email,
            user_password: userData.memberData.password,
            user_phone: userData.memberData.contact_no,
            user_address: userData.memberData.address,
            referal_code: userData.memberData.referral_code,
            promo_code: userData.memberData.promo_code,
            user_role: userData.memberData.role,
            is_home: userData.memberData.is_home,
            register_type: "manual",
            address: userData.memberData.address,
            city: userData.memberData.city,
            zip: userData.memberData.zip,
            province: userData.memberData.province,
            country_code: userData.memberData.country,
            dial_code: userData.memberData.dial_code,

            deliverAddress: deliver_address,
            companyName: userData.memberData.company,
            bldg_no: userData.memberData.bldg_no,
            houseNo: userData.memberData.house_no,
            street: userData.memberData.street,

            form_of_payment: userData.memberData.form_of_payment,
            receipt_no: userData.memberData.form_of_payment,
          };
          const req = {
            payerId: params.PayerID,
            paymentId: params.paymentId,
            promo_code: userData.memberData.promo_code,
          };
          axios
            .post(`${url_path}/api/payment_method/success`, req)
            .then((res) => {
              const paymentData = res.data;
              if (paymentData.data.status === "success") {
                axios
                  .post(`${url_path}/api/users/add`, user)
                  .then((res) => {
                    if (res.data.data.status !== "success") {
                      // console.log(res);
                      let title = "Oops!";
                      let message = res.data.data.msg;
                      errorNotifIcon(title, message);
                      // console.log('added');
                      // hideModal();
                      // console.log(res.data.user);
                      // console.log(res.data);
                    }
                  })
                  .catch((error) => {
                    console.log(error.response.data.msg);
                    console.log("wew");
                    // setNotificationError(error.response.data.msg);
                  });
                console.log(user);
                console.log(paymentData);
              } else {
                let title = "Oops!";
                let message = res.data.data.msg;
                errorNotifIcon(title, message);
              }
              console.log(paymentData);
            });
          // activate account
          axios
            .post(
              `${url_path}/api/users/activate_pending_member/${userData.memberData._id}`
            )
            .then((res) => {
              const userAccountData = res.data;
              if (userAccountData.data.status === "success") {
                // console.log(user);
                console.log(userAccountData);
              } else {
                console.log("account data: " + userAccountData.data.msg);
              }
            });
        }
        console.log(userData);
      });
  }
  useEffect(() => {
    getData();
  }, []);

  return (
    <main className="login success-payment">
      <div className="card-body">
        <Card
          cardDetails={cardInfo}
          form={
            <span>
              {/* <Breadcrumbs 
              size = 'text-large'
              // placeholder = 'Back'
              targetLink = '/forgot-password'
            /> */}
              {/* {button} */}
              <Link to="/">
                <button className="btn-blue">Back to login</button>
              </Link>
            </span>
          }
        />
      </div>
    </main>
  );
}

export default ForgotSuccess;
