import React, { useState, useEffect } from "react";
import { url_path } from "../../constant";
import Card from "../../components/Card";
import "../../stylesheets/main.scss";

import { withRouter } from "react-router";
import axios from "axios";
import { getCookie } from "../../lib/helpers/cookies";
import Sweetalert2 from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { authenticateMemberPage } from "../../lib/helpers/validation";
const Swal = withReactContent(Sweetalert2);
const user_id = getCookie("user_id");

function MemberTemporaryPage({ history }) {
  useEffect(() => {
    authenticateMemberPage(history);
  }, []);
  const [totalPayment, setTotalPayment] = useState();
  const [formData, setFormData] = useState({});
  const [errorMessage, setErrorMessage] = useState();
  // const [id, setId] = useState();
  const cardInfo = {
    title: "Pending Account",
    hasMessage: true,
    message: "You can pay using credit/debit card",
  };

  const { card_number, exp_month, exp_year, cvc } = formData;

  const onFormChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  function getPayment() {
    const token = getCookie("token");
    if (user_id) {
      let data = {};
      axios
        .get(`${url_path}/api/auth`, { headers: { "x-auth-token": token } })
        .then((tokenResult) => {
          data = tokenResult.data.user;
        });
      axios
        .get(`${url_path}/api/promo_code/get/user_promo/${data._id}`)
        .then((res) => {
          // console.log(res);
          const userData = res.data;
          console.log(user_id);
          if (userData.data.status === "success") {
            setTotalPayment(userData.total_payment); // discounted
          } else {
            setTotalPayment(userData.total_payment); // no discount
          }
          // console.log(userData);
          // else if (userData.data.status == 'warning') {
          //   const textError ='This user is not active, please contact your administrator/support';
          //   setNotificationError(textError)
          // }

          // console.log(res.data);
        });
    } else {
      console.log("Please Reload");
    }
  }
  useEffect(() => {
    getPayment();
  }, []);
  // console.log(totalPayment);
  function handlePayment() {
    console.log(user_id);
    const req = {
      card_number,
      exp_month,
      exp_year,
      cvc,
    };

    if (
      card_number === null ||
      exp_month === null ||
      exp_year === null ||
      cvc === null
    ) {
      // console.log('Fields are Required')
      setErrorMessage("Fields are Required");
    } else {
      setErrorMessage("");
      // return console.log(user_id);
      axios.put(`${url_path}/api/users/payment/${user_id}`, req).then((res) => {
        // console.log(res);
        const userData = res.data;
        if (userData.data.status === "success") {
          Swal.fire({
            title: "Congratulations",
            text: userData.data.msg,
            icon: "success",
            showCancelButton: false,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "OK",
          }).then((result) => {
            if (result.value) {
              history.push("/member-dashboard");
              console.log("confirmed");
            } else {
              console.log("not confirmed");
            }
          });
        } else {
          // show message for an error
          Swal.fire({
            title: "Oops!",
            text: userData.data.msg,
            icon: "error",
            showCancelButton: false,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "OK",
          }).then((result) => {
            if (result.value) {
              // removeStorage();
              // props.history.push('/')
              console.log("confirmed");
            } else {
              console.log("not confirmed");
            }
          });
        }
      });
    }
  }

  function requestLink() {
    // return console.log(totalPayment);
    const req = {
      user_id,
      total_payment: totalPayment,
    };
    axios.post(`${url_path}/api/payment_request/add`, req).then((res) => {
      // console.log(res);
      const paymentData = res.data;
      if (paymentData.data.status === "success") {
        Swal.fire({
          title: "Congratulations!",
          text: paymentData.data.msg,
          icon: "success",
          showCancelButton: false,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "OK",
        }).then((result) => {
          if (result.value) {
            // removeStorage();
            // props.history.push('/')
            console.log("confirmed");
          } else {
            console.log("not confirmed");
          }
        });
      } else {
        Swal.fire({
          title: "Oops..",
          text: paymentData.data.msg,
          icon: "warning",
          showCancelButton: false,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "OK",
        }).then((result) => {
          if (result.value) {
            // removeStorage();
            // props.history.push('/')
            console.log("confirmed");
          } else {
            console.log("not confirmed");
          }
        });
      }
    });
    console.log("requested");
  }
  function handleLogout() {
    // errorNotifIcon('Confirm', 'are you sure to cancel this process. if you confirm this email you use will be remove and you need to verify it again.');
    Swal.fire({
      title: "Are you sure?",
      text: "yes",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.value) {
        removeStorage();
        history.push("/");
        console.log("confirmed");
      } else {
        console.log("not confirmed");
      }
    });
  }

  const removeStorage = function () {
    localStorage.removeItem("type");
    localStorage.removeItem("user_id");
    localStorage.removeItem("register_id");
  };

  return (
    <div className="card-body">
      <Card
        cardDetails={cardInfo}
        divider={
          <span>
            <p className="signup-option">
              Request for payment method {/* <Link to="/signup-with-phone"> */}
              <span onClick={requestLink}>click here</span>
              {/* </Link> */}
            </p>
            <span className="divider"></span>
            <p>or</p>
          </span>
        }
        form={
          <>
            <div className="form-wrapper">
              <span className="form-error">{errorMessage}</span>
              <div className="form-field">
                <input
                  id="card_number"
                  className="form-input"
                  type="text"
                  name="card_number"
                  value={card_number}
                  onChange={(e) => onFormChange(e)}
                  required
                />
                <label htmlFor="firstName">
                  Card Number<span className="text-required">*</span>
                </label>
              </div>
              <div className="form-field">
                <input
                  id="exp_month"
                  className="form-input"
                  type="text"
                  name="exp_month"
                  value={exp_month}
                  onChange={(e) => onFormChange(e)}
                  required
                />
                <label htmlFor="exp_month">
                  Exp Month<span className="text-required">*</span>
                </label>
              </div>
              <div className="form-field">
                <input
                  id="exp_year"
                  className="form-input"
                  type="text"
                  name="exp_year"
                  value={exp_year}
                  onChange={(e) => onFormChange(e)}
                  required
                />
                <label htmlFor="exp_year">
                  Exp Year<span className="text-required">*</span>
                </label>
                {/* {errors.firstName && (
                        <span className="form-error">{errors.firstName}</span>
                      )} */}
              </div>
              <div className="form-field">
                <input
                  id="cvc"
                  className="form-input"
                  type="text"
                  name="cvc"
                  value={cvc}
                  onChange={(e) => onFormChange(e)}
                  required
                />
                <label htmlFor="cvc">
                  CVC<span className="text-required">*</span>
                </label>
                {/* {errors.firstName && (
                        <span className="form-error">{errors.firstName}</span>
                      )} */}
              </div>
              <div className="form-field">
                <input
                  id="totalPayment"
                  // className={`form-input pointer-disabled ${
                  //   !totalPayment ? 'not-required' : ''
                  // }`}
                  className={`form-input pointer-disabled`}
                  type="text"
                  // name='totalPayment'
                  value={totalPayment}
                  // onChange={handleOnChange}
                />
                <label htmlFor="totalPayment">Total Payment</label>

                {/* {errors.totalPayment && (
                        <span className='form-error'>{errors.totalPayment}</span>
                      )} */}
              </div>
            </div>
            <button className="btn-blue addSpace" onClick={handlePayment}>
              Submit
            </button>
            <button className="btn-red" onClick={handleLogout}>
              Logout
            </button>
          </>
        }
      />
    </div>
  );
}

export default withRouter(MemberTemporaryPage);
