import { successNotif } from "../../lib/helpers/notifications.js";
import { url_path } from "../../constant";
import useForm from "../../lib/hooks/useForm.js";
import React, { useState, useEffect } from "react";
import Table from "../../components/Table";
import Card from "../../components/Card";
import Breadcrumbs from "../../components/Breadcrumbs";
import Sweetalert2 from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import ReactTooltip from "react-tooltip";
import axios from "axios";
// import Moment from "react-moment";
import Modal from "react-bootstrap/Modal";
// import 'react-phone-input-2/dist/style.css';
import "moment-timezone";
import { ReactComponent as IconDelete } from "../../assets/images/icon-delete.svg";
import { ReactComponent as IconEdit } from "../../assets/images/icon-edit.svg";
// import { ReactComponent as IconCaretDown } from "../../assets/images/icon-caret-down.svg";
import { authenticateAdminPage } from "../../lib/helpers/validation";
import BootstrapSwitchButton from "bootstrap-switch-button-react";
const Swal = withReactContent(Sweetalert2);

const editInfo = {
  title: "Update Promo",
  hasClose: true,
  hasMessage: false,
  customClassName: "no-margin no-shadow",
};

function ManagePromoCode(props) {
  useEffect(() => {
    authenticateAdminPage(props);
  }, []);
  // const [data, setData] = useState([])
  const [promoData, setPromoData] = useState([]);
  const [modalState, setModalState] = useState(false);
  const [currentEdit_ID, setCurrentEdit_ID] = useState("");
  const [roleIsDisabled, setRoleIsDisabled] = useState(false);

  const [loader, setLoader] = useState(false);

  /*
   * Regex Validations to Inputs
   */

  // var nameRegex = /^[a-zA-Z ]+$/;
  // var emailRegex = /\S+@\S+\.\S+/;
  // var numberRegex = /^(09|\+639|9)\d{9}$/;
  // var passwordMinChar = /^.{8,}$/;

  /*
   * Initialize input state schema
   */

  const stateSchema = {
    title: { value: "", error: "" },
    description: { value: "", error: "" },
    discount: { value: "", error: "" },
  };

  /*
   * Create a schema for all inputs
   */

  const stateValidatorSchema = {
    title: {
      required: true,
    },
    description: {
      required: true,
    },
    discount: {
      required: true,
      validator: {
        func: (value) => !(parseInt(value) > 100 || parseInt(value) < 1),
        error: "Discount must be equal or between 1 to 100",
      },
    },
  };

  /*
   * On Submit Form
   */

  function onSubmitForm(state) {
    /*
     * Destructuring state Values
     */

    const { title, description, discount } = state;

    /*
     * get data from state(Input) and set to variable
     */
    let total = 0;
    if (discount !== 100) {
      total = 500 - 500 * parseFloat("." + discount);
    }
    const promo = {
      title: title,
      description: description,
      discount: discount,
      amount_to_pay: total,
    };

    if (currentEdit_ID !== "") {
      console.log("Edit");
      axios
        .put(`${url_path}/api/promo_code/update/${currentEdit_ID}`, promo)
        .then((res) => {
          let title = "Well Done!";
          let message = "Promo has been updated successfully.";
          successNotif(title, message);
          hideModal();
          getData();
          console.log(res.data);
        });
      // storeUser(currentEdit_ID, user).then(res => {
      //   let title = 'Well Done!';
      //   let message = 'User has been updated';
      //   successNotif(title, message);
      //   hideModal();
      //   getData();
      //   console.log(res.data);
      // });
    } else {
      console.log("Add");
      axios.post(`${url_path}/api/promo_code/`, promo).then((res) => {
        console.log(res);
        let title = "Well Done!";
        let message = "New Promo has been added successfully.";
        successNotif(title, message);
        hideModal();
        getData();
        appendNewUser(res.data);
        // console.log(res.data.user);
        console.log(res.data);
      });
    }
  }

  // console.log("Running");
  // console.log(currentEdit_ID);

  /*
   * Get values, errors, handling events to custom useForm Hooks
   */

  const { values, errors, handleOnChange, handleOnSubmit, disable } = useForm(
    stateSchema,
    stateValidatorSchema,
    onSubmitForm
  );

  /*
   * Get values to custom useForm Hooks
   * Pass it to input values
   */

  const { title, description, discount } = values;

  function getData() {
    axios
      .get(`${url_path}/api/promo_code/`)
      .then((response) => {
        setPromoData(response.data);
        setLoader(true);
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  /*
   * Callback funciton to Add
   * Append data to state[data]
   */

  function appendNewUser(data) {
    let promo = {
      _id: data._id,
      title: data.title,
      description: data.description,
      discount: data.discount,
    };

    setPromoData([...promoData, promo]);
  }

  /*
   * Mount User Data
   * Clear and Set Initial Data to Inputs
   */

  useEffect(() => {
    getData();
    // setUserData(userData)
  }, []);

  /*
   * Modal Actions Show/Hide
   */

  const hideModal = () => {
    setModalState(false);
    setCurrentEdit_ID("");
  };

  const showModal = () => {
    setModalState(true);
  };

  /*
   * Action Function Handlers
   */

  const handleShowCreate = () => {
    showModal();
    editInfo.title = "Create New Promo";
    // console.log(values)
    // console.log(errors)
    setRoleIsDisabled(false);

    values.title = "";
    values.description = "";
    values.discount = "";

    errors.title = "";
    errors.description = "";
    errors.discount = "";
  };

  const handleShowEdit = () => {
    showModal();
    editInfo.title = "Update Promo";
    setRoleIsDisabled(true);
  };

  /*
   * Disallow non valid keys to input number
   */

  // const handleInputNumberAllowedCharacter = (event) => {
  //   var invalidChars = ["-", "e"];

  //   if (invalidChars.includes(event.key)) {
  //     event.preventDefault();
  //   }
  // };

  /*
   * Table Data
   * Includes Headings
   */
  const handleUserStatus = (promo_id, status) => {
    const req = {
      promo_id,
      status,
    };
    // return console.log(req)

    axios.patch(`${url_path}/api/promo_code/status_update`, req).then((res) => {
      console.log(res);
      let title = "Well Done!";
      let msg = status
        ? "Promo has been Activated."
        : "Promo has been Deactivated.";
      successNotif(title, msg);
      // getMemberData(1, '');
    });
  };

  const heading = [
    {
      Header: "Title",
      id: "title",
      accessor: "title",
    },
    {
      Header: "Description",
      accessor: "description",
    },
    {
      Header: "Promo Code",
      accessor: "code",
    },
    {
      Header: "Discount",
      accessor: "discount",
    },
    {
      Header: "Amount to Pay",
      accessor: "amount_to_pay",
    },
    {
      Header: "Status",
      id: "status",
      width: 250,
      Cell: (row) => (
        <div className="featured-button-holder">
          <span>Deactivate</span>
          <BootstrapSwitchButton
            checked={row.original.status}
            onlabel=" "
            offlabel=" "
            onChange={(checked) => handleUserStatus(row.original._id, checked)}
          />
          <span>Activate</span>
        </div>
      ),
    },
    {
      Header: "Actions",
      sortable: false,
      // filterable: false,
      Cell: (row) => (
        <div className="button-wrapper">
          <div className="button-holder">
            <button
              className="btn-icon"
              onClick={() => {
                handleShowEdit();

                let datas = [...promoData];

                setCurrentEdit_ID(datas[row.index]._id);

                const { title, description, discount } = datas[row.index];

                /*
                 * Set User Data value to Current Input Fields
                 */
                values.title = title;
                values.description = description;
                values.discount = discount;
              }}
            >
              <span className="icon-holder">
                <IconEdit className="icon-actions" data-tip data-for="edit" />
              </span>
            </button>
            <ReactTooltip id="edit" type="warning" effect="solid">
              <span>Edit</span>
            </ReactTooltip>
          </div>
          <div className="button-holder">
            <button
              className="btn-icon"
              onClick={() => {
                let datas = [...promoData];

                console.log(datas[row.index]._id);
                const deleteID = datas[row.index]._id;

                Swal.fire({
                  title: "Are you sure?",
                  text: "You won't be able to revert this!",
                  icon: "warning",
                  showCancelButton: true,
                  confirmButtonColor: "#3085d6",
                  cancelButtonColor: "#d33",
                  confirmButtonText: "Yes, delete it!",
                }).then((result) => {
                  if (result.value) {
                    axios
                      .delete(`${url_path}/api/promo_code/${deleteID}`)
                      .then((response) => {
                        console.log(response);
                        getData();
                      });
                    let title = "Deleted!";
                    let message = "Promo has been deleted successfully.";
                    successNotif(title, message);
                    // Swal.fire(
                    //   'Deleted!',
                    //   'User has been deleted successfully!',
                    //   'success'
                    // )
                  }
                });
              }}
            >
              <span className="icon-holder">
                <IconDelete
                  className="icon-actions"
                  data-tip
                  data-for="delete"
                />
              </span>
            </button>
            <ReactTooltip id="delete" type="warning" effect="solid">
              <span>Delete</span>
            </ReactTooltip>
          </div>
        </div>
      ),
    },
  ];

  return (
    <main className="manage-pages">
      <div className="container custom-container">
        <Breadcrumbs
          size="text-large"
          placeholder="Promo"
          targetLink="/admin-dashboard"
          hasButton="true"
          buttonTitle="Create New Promo"
          customClassName="m-3"
          propsFunction={handleShowCreate}
        />
        <div className="section-table">
          {/* <div className="container"> */}
          {loader ? (
            <Table tableHeader={heading} tableData={promoData} />
          ) : (
            <div className="lds-ellipsis">
              <div className="dots"></div>
              <div className="dots"></div>
              <div className="dots"></div>
              <div className="dots"></div>
            </div>
          )}
          {/* </div> */}
        </div>
        <Modal
          show={modalState}
          onHide={hideModal}
          className="modal-dark- opacity"
        >
          <Card
            cardDetails={editInfo}
            form={
              <form className="form-wrapper" onSubmit={handleOnSubmit}>
                <div className="form-field">
                  <input
                    id="title"
                    className="form-input"
                    type="text"
                    name="title"
                    value={title}
                    onChange={handleOnChange}
                    required
                  />
                  <label htmlFor="title">Title</label>
                  {errors.title && (
                    <span className="form-error">{errors.title}</span>
                  )}
                </div>
                <div className="form-field">
                  <input
                    id="description"
                    className="form-input"
                    type="text"
                    name="description"
                    value={description}
                    onChange={handleOnChange}
                    required
                  />
                  <label htmlFor="description">Description</label>
                  {errors.description && (
                    <span className="form-error">{errors.description}</span>
                  )}
                </div>
                <div className="form-field">
                  <input
                    id="discount"
                    className="form-input"
                    type="text"
                    name="discount"
                    value={discount}
                    onChange={handleOnChange}
                    required
                  />
                  <label htmlFor="discount">Discount( % )</label>
                  {/* {discount ? 'Total: '+(500 - (500 * (parseFloat('.'+discount)))) : null} */}
                  {errors.discount && (
                    <span className="form-error">{errors.discount}</span>
                  )}
                </div>
                <div className="form-btn-wrapper form-btn-dual-wrapper">
                  <input
                    type="button"
                    className="btn-d btn-gray"
                    value="Cancel"
                    onClick={hideModal}
                  ></input>
                  <input
                    type="submit"
                    className="btn-d btn-blue"
                    value="Save Changes"
                    disabled={disable}
                  />
                </div>
              </form>
            }
          />
        </Modal>
      </div>
    </main>
  );
}

export default ManagePromoCode;
