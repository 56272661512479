import React from 'react';

import DatePicker from 'react-datepicker';
import { FaCalendar } from "react-icons/fa";

const DateFilter = ({startDate, endDate, onChangeStartDate, onChangeEndDate, onApply}) => {

  // Handlers
  const handleDateChangeRaw = (event) => {
    event.preventDefault();
  }

  return (
    <div className="form-wrapper">
      <div className="filter-wrapper">
        <div className="group-wrapper date">
          <p className="filter-heading">Filter by date</p>
          <div className="form-group">
            <div className="form-field calendar">
              <p className="input-label">From</p>
              <label htmlFor="startDate">
                <p className="label-calendar">
                  <FaCalendar />
                </p>
              </label>
              <DatePicker
                selected={startDate}
                onChange={(date) => onChangeStartDate(date)}
                dateFormat="MMM dd, yyyy"
                onChangeRaw={handleDateChangeRaw}
                className="form-input date"
                autoComplete="off"
                isClearable
              />
            </div>
            <div className="form-field calendar">
              <p className="input-label">To</p>
              <label htmlFor="endDate">
                <p className="label-calendar">
                  <FaCalendar />
                </p>
              </label>
              <DatePicker
                selected={endDate}
                onChange={(date) => onChangeEndDate(date)}
                dateFormat="MMM dd, yyyy"
                minDate={startDate}
                onChangeRaw={handleDateChangeRaw}
                className="form-input date"
                autoComplete="off"
                isClearable
              />
            </div>
          </div>
        </div>
        <input
          className="btn-blue form-deal-submit"
          type="button"
          onClick={() => onApply()}
          value="Apply"
        />
      </div>
    </div>
  )
}

export default DateFilter;