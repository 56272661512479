import React from 'react'
import ReactTable from 'react-table'
import 'react-table/react-table.css'
import '../stylesheets/components/_table.scss'

function Table({ tableRef, tableHeader, tableData, paginate, pageFunction }) {

  return (
    <>
      <ReactTable
        ref={tableRef}
        columns={tableHeader}
        data={tableData}
        showPagination={false}
        minRows={0}
        resizable={false}

      // filterable
      // defaultFilterMethod={(filter, row) =>
      //   String(row[filter.id]) === filter.value}
      >
      </ReactTable>

      { paginate && paginate.totalPages > 1 ?
        <ul className="custom-pagination">
          { paginate.prevPage ? <>
            <li onClick={() => pageFunction(paginate.firstPage)}>First</li>
            <li onClick={() => pageFunction(paginate.prevPage)}>Previous</li>
          </> : <>
            <li className='active'>First</li>
            <li className='active'>Previous</li>
          </>}
          { paginate.midPages.map((page, index) => 
              paginate.currentPage !== page ? 
                <li onClick={() => pageFunction(page)} key={index}>{page}</li>
              :
                <li className='active' key={index}>{page}</li> 
          )}
          { paginate.nextPage ? <>
            <li onClick={() => pageFunction(paginate.nextPage)}>Next</li>
            <li onClick={() => pageFunction(paginate.lastPage)}>Last</li>
          </> : <>
            <li className='active'>Next</li>
            <li className='active'>Last</li>
          </>}
          <p className="total-docs-label">Total Documents: {paginate.totalDocuments}</p>
        </ul>
      : <ul className="custom-pagination">
        </ul>
      }
    </>
  )
}

export default Table
