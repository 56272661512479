import React, { useEffect } from 'react'
import CardFaq from '../../components/CardFaq'
import Breadcrumbs from '../../components/Breadcrumbs'
import {authenticateMemberPage} from '../../lib/helpers/validation'
const faqList = [
  {
    caption: 'Make it a rewarding experience',
    image: require('../../assets/images/help-img1.png'),
    title: 'How the Program Works'
  },
  {
    caption: 'The perks beyond your points',
    image: require('../../assets/images/help-img2.png'),
    title: 'Promos & Discounts'
  },
  {
    caption: 'Rewards that suit different lifestyles',
    image: require('../../assets/images/help-img3.png'),
    title: 'Perks & Privileges'
  },
  {
    caption: 'Make everything count',
    image: require('../../assets/images/help-img4.png'),
    title: 'How to Earn and Redeem Points'
  }
]

function Help({history}) {
  useEffect(() => {
    authenticateMemberPage(history)
  }, []);
  return (
    <main className="help">
      <Breadcrumbs 
        size = 'text-large'
        placeholder = 'Help'
        targetLink = '/member-dashboard'
      />
      <CardFaq faq = {faqList} />
    </main>
  )
}

export default Help
