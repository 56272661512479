import React, { useEffect, useState } from 'react'
import {url_path} from '../../constant';
import Breadcrumbs from '../../components/Breadcrumbs'
import Sidebar from '../../components/Sidebar'
import { getCookie } from '../../lib/helpers/cookies'
import axios from 'axios'
import moment from 'moment'
import {authenticateMemberPage} from '../../lib/helpers/validation'
const Notifications = ({ history }) => {
  useEffect(() => {
    authenticateMemberPage(history)
  }, []);
  // States
  const [ loading, setLoading ] = useState(true)
  const [ userId, setUserId ] = useState('')
  const [ notifications, setNotifications ] = useState([])

  // Functions
  const getNotifications = async () => {
    setLoading(true)

    const token = getCookie('token')
    try {
      const { data: user_info } = await axios.get(`${url_path}/api/auth`, { headers: { 'x-auth-token': token } })
      setUserId(user_info.user._id)

      const { data: notif_info } = await axios.get(`${url_path}/api/notification/${ user_info.user._id }`)
      setNotifications(notif_info)
      setLoading(false)
    } catch(err) { console.log(err) }
  }

  // Delete all notifications
  const handleClearAllNotification = async () => {
    try {
      const { data } = await axios.delete(`${url_path}/api/notification/${ userId }`)
      console.log(data)
      getNotifications()
    } catch(err) { console.log(err) }
  }

  // Delete specific notification
  const handleClearNotifcation = async (notifId) => {
    try {
      // axios.post()
      const { data } = await axios.delete(`${url_path}/api/notification/one/${ notifId }`, { data: { user_id: userId } } )
      console.log(data)
      getNotifications()
    } catch(err) { console.log(err) }
  }

  useEffect(() => {
    getNotifications()
  }, [])

  return (
    <main className='notifications member-dashboard'>
      <Sidebar history={history} />
      <div className='content-wrapper'>
        <Breadcrumbs 
          size = 'text-large'
          placeholder = 'Dashboard'
          targetLink = '/member-dashboard'
          hasButton = 'false'
        />
        { loading ?
          <div className="lds-ellipsis">
            <div className="dots"></div>
            <div className="dots"></div>
            <div className="dots"></div>
            <div className="dots"></div>
          </div>
        :
          <div className="notification-wrapper">
            <h2 className="title">Notifications</h2>
            <span className="notif-clear-btn" onClick={ handleClearAllNotification }>Clear All</span>
            { notifications.length > 0 ? (
              notifications.map((notif, i) => {
                return (
                  <div className="notification-holder" key={ i }>
                    <h4 className="notif-title">{ notif.title }</h4>
                    <span className="notif-remove" onClick={ () => handleClearNotifcation(notif._id) }>&times;</span>
                    <p className="notif-body">{ notif.description }</p>
                    <small className="notif-date">{ moment(new Date(notif.created_at)).format('LL') }</small>
                  </div>
                )
              })
            ) : (
              <span className="no-notif">No notifications</span>
            )}
          </div>
        }
      </div>
    </main>
  )
}

export default Notifications
