import React, { useEffect, useState } from "react";
import { url_path } from "../../constant";
import axios from "axios";
import Moment from "react-moment";
import useForm from "../../lib/hooks/useForm";
import Modal from "react-bootstrap/Modal";
import Card from "../../components/Card";
import Table from "../../components/Table";
import { successNotif, errorNotifIcon } from "../../lib/helpers/notifications";

const modalInfo = {
  title: "Transfer Points",
  customClassName: "no-margin no-shadow",
};

const TransferPoints = ({ user_id }) => {
  // States
  const [tableData, setTableData] = useState([]);
  const [loader, setLoader] = useState(true);
  const [pagerData, setPagerData] = useState({});
  const [showModal, setShowModal] = useState(false);

  const tableHeader = [
    {
      Header: "Date",
      id: "created_at",
      width: 130,
      accessor: (data) => <Moment date={data.created_at} format="MMMM DD YYYY HH:mm:ss" />,
    },
    {
      Header: "Transfer Points from Card Holder Name",
      id: "card_holder_name",
      width: 325,
      accessor: (data) => data.card_holder_name,
    },
    {
      Header: "Transfer Points from Card Holder No",
      id: "card_holder_number",
      width: 175,
      accessor: (data) => data.card_holder_number,
    },
    {
      Header: "No of Points to Transfer",
      id: "no_of_points",
      width: 150,
      accessor: (data) => data.no_of_points,
    },
    {
      Header: "Reciever Card Holder Name",
      id: "reciever_card_holder_name",
      width: 325,
      accessor: (data) => data.reciever_card_holder_name,
    },
    {
      Header: "Reciever Card Holder No",
      id: "reciever_card_holder_number",
      width: 175,
      accessor: (data) => data.reciever_card_holder_number,
    },
    {
      Header: "No of Points to Recieve",
      id: "no_of_points_recieve",
      width: 150,
      accessor: (data) => data.no_of_points_recieve,
    },
    {
      Header: "Description",
      id: "description",
      width: 250,
      accessor: (data) => data.description,
    },
  ];

  const stateSchema = {
    card_holder_number: { value: "", error: "" },
    reciever_card_holder_number: { value: "", error: "" },
    no_of_points: { value: "", error: "" },
    description: { value: "", error: "" },
  };

  const stateValidatorSchema = {
    card_holder_number: {
      required: true,
    },
    reciever_card_holder_number: {
      required: true,
    },
    no_of_points: {
      required: true,
    },
    description: {
      required: false,
    },
  };

  useEffect(() => {
    getPointTransaction();
  }, []);

  const getPointTransaction = (pageNumber = 1) => {
    setLoader(true);

    var search = "";
    var dateSearch = "";
    var status = "transfer";

    const req = {
      pageNumber,
      search,
      dateSearch,
      status,
    };

    axios.post(`${url_path}/api/manage_points/show`, req).then((res) => {
      const data = res.data.data;

      if (data.status === "success") {
        const transData = data.point_transactions;
        const pagination = data.paginate;
        setTableData(transData);
        setPagerData(pagination);
        setLoader(false);
      } else {
        setLoader(false);
      }
    });
  };

  const onSubmitForm = (state) => {
    const status = "deduct";
    let desc = "";
    if (description !== "") {
      desc = description;
    }
    const req = {
      card_holder_number,
      no_of_points,
      reciever_card_holder_number,
      description: desc,
      status,
    };

    axios.put(`${url_path}/api/manage_points/transfer`, req).then((res) => {
      const data = res.data.data;

      if (data.status === "success") {
        setLoader(true);
        getPointTransaction();
        successNotif("Awesome!", data.msg);
        setShowModal(false);
        setLoader(false);
      } else if (data.status === "warning") {
        errorNotifIcon("Oops!", data.msg);
      }
    });
  };

  const handleCreateModal = () => {
    setShowModal(true);

    values.card_holder_number = "";
    values.reciever_card_holder_number = "";
    values.no_of_points = "";
    values.description = "";
  };

  const handleHideModal = () => {
    setShowModal(false);
  };

  const formatCardNumber = (value) => {
    let cardNumber = value.replace(/ /g, "");

    const formatedCardNumber =
      cardNumber.substring(0, 4).replace(/\d{3}(?=.)/, "$& ") +
      cardNumber.substring(4, 8).replace(/\d{2}(?=.)/, "$& ") +
      cardNumber.substring(8, 10);

    return formatedCardNumber;
  };

  const validateNumberInput = (event) => {
    const { key } = event;

    const validKeys = ["Backspace", "Delete", "ArrowLeft", "ArrowRight"];

    if (!key.match(/\d/) && !validKeys.includes(key)) {
      event.preventDefault();
    }
  };

  // Retrieve values, errors, handlers, etc... from useForm hooks
  const { values, errors, handleOnChange, handleOnSubmit, disable } = useForm(
    stateSchema,
    stateValidatorSchema,
    onSubmitForm
  );

  const {
    card_holder_number,
    reciever_card_holder_number,
    no_of_points,
    description,
  } = values;

  return (
    <>
      <div className="member-transaction tab-member-account">
        <p className="heading">Transfer Point Transactions</p>
        {!loader ? (
          <>
            <div className="section-table">
              <Table
                tableHeader={tableHeader}
                tableData={tableData}
                paginate={pagerData}
                pageFunction={getPointTransaction}
              />
            </div>

            <button className="btn-blue-no-width" onClick={handleCreateModal}>
              Transfer Points
            </button>
          </>
        ) : (
          <div className="lds-ellipsis">
            <div className="dots"></div>
            <div className="dots"></div>
            <div className="dots"></div>
            <div className="dots"></div>
          </div>
        )}
      </div>
      <Modal
        show={showModal}
        onHide={handleHideModal}
        className="modal-dark- opacity"
      >
        <Card
          cardDetails={modalInfo}
          form={
            <form className="form-wrapper" onSubmit={handleOnSubmit}>
              <div className="form-field">
                <input
                  id="card_holder_number"
                  className="form-input"
                  type="text"
                  name="card_holder_number"
                  value={formatCardNumber(card_holder_number)}
                  onChange={handleOnChange}
                  onKeyDown={validateNumberInput}
                  required
                />
                <label htmlFor="card_holder_number">
                  Transfer Points From Card Holder Number
                </label>
                {errors.card_holder_number && (
                  <span className="form-error">
                    {errors.card_holder_number}
                  </span>
                )}
              </div>
              <div className="form-field">
                <input
                  id="reciever_card_holder_number"
                  className="form-input"
                  type="text"
                  name="reciever_card_holder_number"
                  value={formatCardNumber(reciever_card_holder_number)}
                  onChange={handleOnChange}
                  onKeyDown={validateNumberInput}
                  required
                />
                <label htmlFor="reciever_card_holder_number">
                  Reciever Card Holder Number
                </label>
                {errors.reciever_card_holder_number && (
                  <span className="form-error">
                    {errors.reciever_card_holder_number}
                  </span>
                )}
              </div>
              <div className="form-field">
                <input
                  id="no_of_points"
                  className="form-input"
                  type="text"
                  name="no_of_points"
                  value={no_of_points}
                  onChange={handleOnChange}
                  onKeyDown={validateNumberInput}
                  required
                />
                <label htmlFor="no_of_points">No of Points</label>
                {errors.no_of_points && (
                  <span className="form-error">{errors.no_of_points}</span>
                )}
              </div>

              <div className="form-field">
                <input
                  id="description"
                  className="form-input"
                  type="text"
                  name="description"
                  value={description}
                  onChange={handleOnChange}
                />
                <label htmlFor="description">Description</label>
                {errors.description && (
                  <span className="form-error">{errors.description}</span>
                )}
              </div>

              <input
                type="submit"
                className="btn-blue full-width"
                value="Continue"
                disabled={disable}
              />
              <input
                type="button"
                className="btn-blue-outline full-width"
                value="Cancel"
                onClick={handleHideModal}
              ></input>
            </form>
          }
        />
      </Modal>
    </>
  );
};

export default TransferPoints;
