import React, { useState, useEffect } from 'react'
import Table from "../../components/Table"
import Breadcrumbs from "../../components/Breadcrumbs"
import {authenticateBusinessPage} from '../../lib/helpers/validation'
function TransactionHistory(props) {
  useEffect(() => {
    authenticateBusinessPage(props)
  }, []);
  const [data, setData] = useState([])

  const [currentUser, setCurrentUser] = useState('')

  const dataVal = [
    { 
      id: 1, 
      details: "Buy 2 Cokes, Get 1 FREE", 
      points: '500',
      amount: '1999',
      date: '10/20/2019'
    }, { 
      id: 2, 
      details: "Purchase 1 body wash get 50% off hand soaps", 
      points: '500',
      amount: '1999',
      date: '10/20/2019'
    }
  ]

  const heading = [
    {
      Header: "Details",
      accessor: 'details',
      width: 500
    }, {
      Header: "Points",
      accessor: 'points',
      className: 'table-point-plus',
    }, {
      Header: "Amount",
      accessor: 'amount',
      className: 'table-amount-peso',
    }, {
      Header: "Date",
      accessor: 'date'
    }
  ]

  useEffect(() => {
    setData(dataVal)
    setCurrentUser('Jannete David')
  }, [])

  var currentTransactionUser = currentUser + '’s' 

  return (
    <main className="transaction-history">
      <Breadcrumbs 
        size = 'text-large'
        placeholder = {currentTransactionUser + ' Transaction History'} 
        targetLink = '/partner-dashboard'
        hasButton = 'false'
      />
      <div className="section-table">
        <div className="container">
          <Table 
            tableHeader={ heading }
            tableData={ data }
          />
        </div>
      </div>
    </main>
  )
}

export default TransactionHistory
