import React, { useState, useEffect } from "react";
import { url_path } from "../../constant";
import axios from "axios";
import Card from "../../components/Card";
import useForm from "../../lib/hooks/useForm";
import Breadcrumbs from "../../components/Breadcrumbs";
import { authenticateBusinessPage } from "../../lib/helpers/validation";
import { errorNotifIcon } from "../../lib/helpers/notifications";

import { getCookie } from "../../lib/helpers/cookies";

const ManualRedeemOTP = (props) => {

  
  const { card_no, user_id, bp_id, amount, invoice, notes } = props.match.params;

  const [isLoading, setIsLoading] = useState(false);
  const [businessPartnerId, setBusinessPartnerId] = getCookie("bp_id");
  const [userId, setUserId] = user_id;
  const [cardNo, setCardNo] = useState(card_no);

  const cardInfo = {
    title: "OTP Authentication",
  };

  const stateSchema = {
    otp: { value: "", error: "" },
  };

  const stateValidatorSchema = {
    otp: {
      required: true,
    },
  };


  useEffect(() => {
    authenticateBusinessPage(props);
    const req = {
      business_partner_id: getCookie("bp_id"),
      user_id: user_id,
      action: "send"
    }
    console.log(req);
    axios
      .post(`${url_path}/api/otp`, req)
      .then(res => {
        console.log(res); 
    });
  }, []);

  const onSubmitForm = async (event) => {
    event.preventDefault();
    setIsLoading(true);
    const req = {
      otp: otp, 
      business_partner_id: getCookie("bp_id"),
      user_id: user_id,
      action: "verify"
    }
    
    axios
      .post(`${url_path}/api/otp`, req)
      .then(res => {
        setIsLoading(false);
        if(res.data.message == 'OTP Mismatch/Expired'){
          return errorNotifIcon("Sorry!", "OTP Mismatch/Expired");
        }
        props.history.push(
          `/redeem-details/${card_no}/${amount}/${
            invoice ? invoice : "no invoice"
          }/${notes ? notes : "no notes"}`
        );
      });
  };

  const {
    // state,
    values,
    errors,
    handleOnChange,
    disable,
  } = useForm(stateSchema, stateValidatorSchema, onSubmitForm);

  const { otp } = values;

  return (
    <main className="partner-dashboard manual-purchase">
      <div className="container custom-container">
        <div className="content-wrapper">
          <Breadcrumbs
            size="text-large"
            placeholder="Back"
            targetLink="/manual-input"
            customClassName={"m-3"}
          />
          <div className="card-body">
            <Card
              cardDetails={cardInfo}
              form={
                <>
                  <form className="form-wrapper" onSubmit={onSubmitForm}>
                    <div className="form-field">
                      <input
                        type="text"
                        name="otp"
                        className="form-input"
                        id="otp"
                        value={otp}
                        onChange={handleOnChange}
                        required
                      />
                      <label htmlFor="cardNo">Enter OTP Code.</label>
                    </div>
                    {isLoading ? (
                      <button className="btn-blue-loading disabled">
                        <span className="load open"></span> Please wait...
                      </button>
                    ) : (
                      <button
                        className="btn-blue"
                        type="submit"
                        disabled={disable}
                        onClick={onSubmitForm}
                      >
                        Submit
                      </button>
                    )}
                  </form>
                </>
              }
            />
          </div>
        </div>
      </div>
    </main>
  );
};

export default ManualRedeemOTP;
