import React, { useEffect, useState } from "react";
import { url_path } from "../../constant";
import {
  successNotif,
  errorNotifIcon,
  submitConfimation,
} from "../../lib/helpers/notifications.js";
import useForm from "../../lib/hooks/useForm.js";
import Card from "../../components/Card";
import { ReactComponent as IconCaretDown } from "../../assets/images/icon-caret-down.svg";
import axios from "axios";

const ManageRedeem = ({ data, business_partner_id }) => {
  // Life-cycles
  useEffect(() => {
    getStaffData();
  }, []);
  // const [isLoading, setIsLoading] = useState(false);

  // const [points_earned, set_points_earned] = useState();
  // const [business_name, set_business_name] = useState();
  // const [ch_id, set_ch_id] = useState();
  const [staffData, setStaffData] = useState([]);
  const [staff, setStaff] = useState("default");
  // const [offer_id, set_offer_id] = useState();
  const [isButtonLoading, setIsButtonLoading] = useState(false);

  const [pointsBalance, setPointsBalance] = useState("0");

  var numRegex = /^[0-9]*$/;

  const stateSchema = {
    cardNo: { value: "", error: "" },
    amount: { value: "", error: "" },
    confirmAmount: { value: "", error: "" },
    invoiceNo: { value: "", error: "" },
    notes: { value: "", error: "" },
  };

  const stateValidatorSchema = {
    cardNo: {
      required: true,
    },

    referralCode: {
      required: true,
    },
    amount: {
      required: true,
      validator: {
        func: (value) => numRegex.test(value),
        error: "Amount Only Accept Numbers",
      },
    },
    confirmAmount: {
      required: true,
      validator: {
        func: (value) => value === amount && numRegex.test(value),
        error: "Amount don't match / Only Accept Number",
      },
    },
    invoiceNo: {
      required: false,
    },
    notes: {
      required: false,
    },
  };

  const onCardnumberChange = () => {
    console.log(cardNo);
    // this.setState({cardNo})
    if (cardNo.length === 12) {
      axios
        .post(`${url_path}/api/customerBank/getBank/${cardNo}`)
        .then((res) => {
          const data = res.data;
          // this.setState({points_balance: data.points_balance});
          setPointsBalance(data.points_balance);
          // console.log(res.data);
        });
    } else {
      setPointsBalance("0");
    }
  };

  const { values, errors, handleOnChange, disable } = useForm(
    stateSchema,
    stateValidatorSchema,
    onCardnumberChange
  );

  const {
    cardNo,
    referralCode,
    amount,
    confirmAmount,
    invoiceNo,
    notes,
  } = values;
  const handleSubmit = () => {
    // console.log(notes)

    console.log(amount)
    if (cardNo == "" || cardNo == null || amount == "" || amount == null || confirmAmount == "" || confirmAmount == null || staff == "default"){
      return errorNotifIcon("Oops!", "Please check the following! (Staff, Card No, Amount, Confirm Amount)");
    }
    else{
      if (cardNo == "" || amount == "") {
        return errorNotifIcon("Oops!", "Card No. & Amount is required");
      }
      if (amount != confirmAmount) {
        return errorNotifIcon("Oops!", "Amount do not match!");
      }
      if (staff == "default") {
        return errorNotifIcon("Oops!", "Please Select Staff!");
      }
      setIsButtonLoading(true);
      axios.get(`${url_path}/api/scanner/${cardNo}`).then((res) => {
        const scanner = res.data;
        // return console.warn(scanner.data.msg);
        if (scanner.data.status === "error") {
          errorNotifIcon("Oops!", scanner.data.msg);
          setIsButtonLoading(false);
        } else {
          // console.log(this.state.referenceNo.toUpperCase() + ' ' + scanner.scanner.reference_number);
          if (referralCode.toUpperCase() == scanner.scanner.reference_number) {
            if (parseInt(pointsBalance) >= parseInt(amount)) {
              submitConfimation(
                "Are you sure!",
                "Cardholder points will be deducted",
                onApply,
                ""
              );
              // props.history.push(`/redeem-details/${cardNo}/${amount}/${invoiceNo ? invoiceNo : 'no invoice'}/${notes ? notes : 'no notes'}`);
            } else {
              setIsButtonLoading(false);
              errorNotifIcon("Oops!", "Not enough points");
            }
          } else {
            setIsButtonLoading(false);
            errorNotifIcon("Sorry!", "User Not Found!");
          }
        }
      });
    }
  };
  // If the submit button clicked
  const onApply = (a) => {
    // Make Transaction
    // setIsButtonLoading(true);
    const req = {
      amount: amount,
      notes: notes,
      status: "reverse",
    };
    axios
      .post(
        `${url_path}/api/scanner/redeem/${business_partner_id}/${staff}/${cardNo}`,
        req
      )
      .then((res) => {
        const redeem_detail = res.data;
        console.log(redeem_detail);
        if (redeem_detail.data.status === "success") {
          // send notification
          //   const notifReq = {
          //     notification_user_id: customer_id,
          //     title:
          //       business_name !== ''
          //         ? business_name + ' (Redeem)'
          //         : 'Business Name not Set',
          //     description:
          //       'you redeemed ' +
          //       amount +
          //       ' from ' +
          //       business_name,
          //     status: false,
          //   };
          //   axios
          //     .post(`${url_path}/api/notification`, notifReq)
          //     .then(res => {
          //       const notification = res.data;
          //       // console.log(notification)
          //     });
          values.cardNo = "";
          values.referralCode = "";
          values.amount = "";
          values.confirmAmount = "";
          values.invoiceNo = "";
          values.notes = "";
          setPointsBalance("0");
          setStaff("default");
          setIsButtonLoading(false);
          successNotif("Well Done!", redeem_detail.data.msg);
          //   props.history.push(`/manual-redeem`);
        } else {
          setIsButtonLoading(false);
          errorNotifIcon("Oops!", redeem_detail.data.msg);
        }
      });
  };

  function getStaffData() {
    let req = {
      business_partner_id,
    };
    try {
      axios.put(`${url_path}/api/business_partner_staff`, req).then((res) => {
        const result = res.data;
        if (result.data.status === "success") {
          setStaffData(result.data.userData);
        }
      });
    } catch (error) {}
  }

  const formatCardNumber = (value) => {
    let cardNumber = value.replace(/ /g, "");

    const formatedCardNumber =
      cardNumber.substring(0, 4).replace(/\d{3}(?=.)/, "$& ") +
      cardNumber.substring(4, 8).replace(/\d{2}(?=.)/, "$& ") +
      cardNumber.substring(8, 10);

    return formatedCardNumber;
  };

  const validateNumberInput = (event) => {
    const { key } = event;

    const validKeys = ["Backspace", "Delete", "ArrowLeft", "ArrowRight"];

    if (!key.match(/\d/) && !validKeys.includes(key)) {
      event.preventDefault();
    }
  };

  const selectOnChange = (e) => {
    const value = e.target.value;
    setStaff(value);
    console.log(value);
  };

  return (
    <div className="tab-point-offer">
      <Card
        cardDetails={data}
        form={
          <>
            <p>Note: This is a Reverse Transaction of Redeem.</p>
            <form className="form-wrapper" onSubmit={handleSubmit}>
              <div className="partner-dashboard manual-redeem">
                <div className="container custom-container">
                  <div className="content-wrapper">
                    <div className="card-body">
                      <div className="points-wrapper">
                        <p className="points-text">{pointsBalance}</p>
                        <p className="points-subtext">Available points</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="form-field select">
                <select
                  id="staff"
                  className="form-input select"
                  name="staff"
                  value={staff}
                  onChange={(e) => selectOnChange(e)}
                  required
                  // disabled={isRoleDisabled}
                >
                  <option value="default">Select Staff</option>
                  {staffData.length >= 1 ? (
                    staffData.map((item, index) => {
                      return (
                        <option value={item._id} key={index}>
                          {item.user_first_name + " " + item.user_last_name}
                        </option>
                      );
                    })
                  ) : (
                    <option value="default">No Staff</option>
                  )}
                </select>
                <label htmlFor="staff">
                  Select Staff
                  <IconCaretDown className="form-select-caret-down" />
                </label>
              </div>
              <div className="form-field">
                <input
                  type="text"
                  name="cardNo"
                  className="form-input"
                  id="cardNo"
                  value={formatCardNumber(cardNo)}
                  onChange={handleOnChange}
                  onKeyUp={onCardnumberChange}
                  onKeyDown={validateNumberInput}
                  onKeyPress={validateNumberInput}
                  required
                />
                <label htmlFor="cardNo">Enter card no.</label>
              </div>
              <div className="form-field">
                <input
                  type={"text"}
                  name="referralCode"
                  className="form-input"
                  id="referralCode"
                  value={referralCode}
                  onChange={handleOnChange}
                  required
                />
                <label htmlFor="referralCode">Referral Code</label>
              </div>
              <div className="form-field">
                <input
                  type="number"
                  name="amount"
                  className="form-input"
                  id="amount"
                  value={amount}
                  onChange={handleOnChange}
                  onKeyDown={validateNumberInput}
                  required
                />
                <label htmlFor="amount">Amount</label>
                {errors.amount && (
                  <span className="form-error">{errors.amount}</span>
                )}
              </div>
              <div className="form-field">
                <input
                  type="number"
                  name="confirmAmount"
                  className={"form-input"}
                  id="confirmAmount"
                  value={confirmAmount}
                  onChange={handleOnChange}
                  onKeyDown={validateNumberInput}
                  required
                />
                <label htmlFor="confirmAmount">Confirm amount</label>
                {errors.confirmAmount && (
                  <span className="form-error">{errors.confirmAmount}</span>
                )}
              </div>
              <div className="form-field">
                <input
                  type={"text"}
                  name="invoiceNo"
                  // className={'form-input not-required'}
                  className={`form-input ${!invoiceNo ? "not-required" : ""}`}
                  id="invoiceNo"
                  value={invoiceNo}
                  onChange={handleOnChange}
                />
                <label htmlFor="invoiceNo">Invoice no.</label>
              </div>
              <div className="form-field form-textarea">
                <textarea
                  type={"text"}
                  name="notes"
                  className="form-input not-required"
                  id="notes"
                  value={notes}
                  onChange={handleOnChange}
                />
                <label htmlFor="notes">Notes (optional)</label>
              </div>
              {/* <input type="submit" className='btn-blue' disabled={disable} value="Submit"/> */}
            </form>
            {isButtonLoading ? (
              <button class="btn-blue-loading disabled">
                <span class="load open"></span> Please wait...
              </button>
            ) : (
              <button
                className="btn-blue"
                disabled={disable}
                onClick={handleSubmit}
              >
                Submit
              </button>
            )}
          </>
        }
      />
    </div>
  );
};

export default ManageRedeem;
