import React, { useState, useEffect } from 'react'
import Table from "../../components/Table"
import { Link } from 'react-router-dom'
import Points from "../../components/Points"
import Breadcrumbs from '../../components/Breadcrumbs'
import {authenticateMemberPage} from '../../lib/helpers/validation'
function InfinityPoints({history}) {
  useEffect(() => {
    authenticateMemberPage(history)
  }, []);
  const [data, setData] = useState([])

  const dataVal = [
    { 
      id: 1, 
      transId: '1',
      details: 'Buy 3 coffees and get 1 FREE',
      points: '+500',
      date: '10/20/2015'
    }, { 
      id: 2, 
      transId: '2',
      details: 'Redeemed from ABC Shop',
      points: '-2000',
      date: '11/23/2015'
    }, { 
      id: 3, 
      transId: '3',
      details: 'Referral Bonus',
      points: '+100',
      date: '11/25/2015'
    }, { 
      id: 4, 
      transId: '4',
      details: 'Referral Bonus',
      points: '+100',
      date: '10/26/2015'
    },
  ]

  const heading = [
    {
      Header: "ID",
      accessor: 'transId',
      width: 70
    }, {
      Header: "Details",
      accessor: 'details',
      width: 500
    }, {
      Header: "Points In/Out",
      accessor: 'points'
    }, {
      Header: "Date",
      accessor: 'date',
      width: 170
    }
  ]

  useEffect(() => {
    setData(dataVal)
  }, [])

  return (
    <main className="infinity-points">
      <Breadcrumbs 
        size = 'text-large'
        placeholder = 'Back'
        targetLink = '/member-dashboard'
      />
      <div className="section-infinity-points">
        <div className="container">
          <div className="content-wrapper">
            <h3 className="title-centered">My Infinity Points</h3>
            <p className="desc">(1 point = P1)</p>
            <Points 
              available = '265'
              redeemed = '-2,800'
              earned = '895'
            />
            <div className="history-wrapper">
              <div className="section-table">
                <h4 className="title-black">Transaction History</h4>
                <Table 
                  tableHeader={ heading }
                  tableData={ data }
                />
              </div>
              <div className="link-holder">
                <Link to="/my-infinity-points" className="link-blue">View more</Link>
              </div>
            </div>

            <div className="info-wrapper">
              <div className="info-holder">
                <h4 className="title-black">Earn Point</h4>
                <ul className="list-unstyled list-info">
                  <li className="item-info">Get points from Infinity business partners.</li>
                  <li className="item-info">Refer your friend and get 100 points.</li>
                  <li className="item-info">One Point equals P1.00.</li>
                </ul>
              </div>
              <div className="info-holder">
                <h4 className="title-black">Use Point</h4>
                <ul className="list-unstyled list-info">
                  <li className="item-info">You can use points to pay for purchases at any Infinity business partner.</li>
                </ul>
                <Link to="/my-infinity-points" className="link-blue">Click here to find deals and promos</Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  )
}

export default InfinityPoints
