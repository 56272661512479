import React, { useState, useEffect } from 'react';
import {url_path} from '../constant';
import Card from '../components/Card';
import '../stylesheets/main.scss';
import useForm from '../lib/hooks/useForm.js';
// import { Progress } from 'react-sweet-progress';
// import Input from 'react-validation/build/input'
// import Form from 'react-validation/build/form'
// import Button from 'react-validation/build/button'
// import validator from 'validator'
import { withRouter } from 'react-router';
// import { Link } from 'react-router-dom';
import axios from 'axios';
import { successNotif } from '../lib/helpers/notifications.js';
import { errorNotifIcon } from '../lib/helpers/notifications.js';
function Login(props) {
//   const type = localStorage.getItem("type");
//   if (type == 'Admin') {
//     props.history.push('/admin-dashboard');
//   }


const [loader, setLoader] = useState(false)

  // const [inputs, setInputs] = useState({});
  const [notificationError, setNotificationError] = useState('')
  // const {cta, forgotPassword, close } = props;

  // const required = (value) => {
  //   if (!value.toString().trim().length) {
  //     return <span className="form-error is-visible">Required*</span>;
  //   }
  // };

  // const email = (value) => {
  //   if (!validator.isEmail(value)) {
  //     return <span className="form-error is-visible">{value} is not a valid email.</span>
  //   }
  // };

  useEffect(() => {
    setNotificationError('')
  }, [])

  // const [inputType, setInputType] = useState(true);

  // function togglePassword() {
  //   inputType ? setInputType(false) : setInputType(true);
  // }

  // const handleSubmit = () => {
  //   props.history.push('/admin-dashboard');
  // };

  // const iconShow = require('../assets/images/icon-show.png');
  // const iconHide = require('../assets/images/icon-hide.png');
  // const icon2 = require('../assets/images/image-tip.png')

  const cardInfo = {
    title: 'Test Set Discount to Business Partner',
    hasMessage: true,
    message: 'This page is for testing only!'
  };

  // const handleInputChange = (event) => {
  //   event.persist();
  //   console.log(event.target.name)
  //   console.log(event.target.value)
  //   let name = event.target.name
  //   let value = event.target.value
  //   setInputs(inputs => ({...inputs, [name]: value}));
  // }


  /*
  * Regex Validations to Inputs
  */


//   var emailRegex = /\S+@\S+\.\S+/;
  
  /*
  * Initialize input state schema
  */

  const stateSchema = {
    business_partner_id: { value: "", error: "" },
    title: { value: "", error: "" },
    discount: { value: "", error: "" },
  }

  const stateValidatorSchema = {
    business_partner_id: {
      required: true,
      
    },
    title: {
      required: false,
    },
    discount: {
      required: false,
    }
  }
  function onSubmitForm(state) {
    setLoader(true);
    const {
      business_partner_id,
      title,
      discount
    } = state;
    // return console.log(title);
    const req = {
      business_partner_id,
      title,
      discount: '.'+discount,
      status: true
    };


    axios.post(`${url_path}/api/business_discount`, req).then(res => {
        const userData = res.data;
        if (userData.msg[0].status === 'success') {
          setLoader(false);
          let title = 'Well Done!';
          let message = 'Data Generated';
          successNotif(title, message);
        }else{
          setLoader(false);
          let title = 'Sorry!';
          let message = userData.data.msg;
          errorNotifIcon(title, message);
        }
        
        
    });
    
  }
  // function handleGenerate(){
  //   setLoader(true);
  //   // console.log('generate')
  //   axios.post(`${url_path}/api/generate_default_data`).then(res => {
  //       const userData = res.data;
  //       setLoader(false);
  //       if (userData.msg[0].status == 'success') {
  //           let title = 'Well Done!';
  //           let message = 'Data Generated';
  //           successNotif(title, message);
  //       }else{
  //           let title = 'Sorry!';
  //           let message = 'you already generated default data!';
  //           errorNotifIcon(title, message);
  //       }
  //     });
  // }
  
  const { values, errors, handleOnChange, handleOnSubmit, disable } = useForm(
    stateSchema,
    stateValidatorSchema,
    onSubmitForm
  )

  const { 
    business_partner_id, 
    title,
    discount

  } = values;

  return (
    <main>
      <div className='card-body'>
        {!notificationError ? '' : 
          <div className="section-notification">
            <div className="container">
              <div className="notification-holder">
                <p className="notification-text">{notificationError}</p>
              </div>
            </div>
          </div>
        }
          {
          !loader ? 
          <Card
              cardDetails={cardInfo}
              form={
                  
              <form className='form-wrapper' onSubmit={handleOnSubmit}>
                  
                  <div className='form-field'>
                    <input
                        type='text'
                        name='business_partner_id'
                        className='form-input'
                        id='business_partner_id'
                        value={business_partner_id}
                        onChange={handleOnChange}
                        required
                    />
                    <label htmlFor='business_partner_id'>Business Partner ID</label>
                    {errors.business_partner_id && (<span className='form-error'>{errors.business_partner_id}</span>)}
                  </div>
                  <div className='form-field'>
                    <input
                        type='text'
                        name='title'
                        className='form-input'
                        id='title'
                        value={title}
                        onChange={handleOnChange}
                        required
                    />
                    <label htmlFor='title'>Title</label>
                    {errors.title && (<span className='form-error'>{errors.title}</span>)}
                  </div>
                  <div className='form-field'>
                    <input
                        type='text'
                        name='discount'
                        className='form-input'
                        id='discount'
                        value={discount}
                        onChange={handleOnChange}
                        required
                    />
                    <label htmlFor='discount'>Discount ( % )</label>
                    {errors.discount && (<span className='form-error'>{errors.discount}</span>)}
                  </div>
                  
                  <input type="submit" className='btn-blue' disabled={disable} value="Set"/>
              </form>
              }
          />
          : 
          <div className="lds-ellipsis">
              <div className="dots"></div>
              <div className="dots"></div>
              <div className="dots"></div>
              <div className="dots"></div>
          </div>
          }
        
      </div>
    </main>
  );
}

export default withRouter(Login);
