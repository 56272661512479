// import { storeUser } from "../lib/api/users.js";
import { successNotif } from "../../lib/helpers/notifications.js";
import { url_path } from "../../constant";
import useForm from "../../lib/hooks/useForm.js";
import React, { useState, useEffect } from "react";
import Table from "../../components/Table";
import Card from "../../components/Card";
import Breadcrumbs from "../../components/Breadcrumbs";
import Sweetalert2 from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import ReactTooltip from "react-tooltip";
import axios from "axios";
// import Moment from "react-moment";
import Modal from "react-bootstrap/Modal";
// import 'react-phone-input-2/dist/style.css';
import "moment-timezone";
import { ReactComponent as IconDelete } from "../../assets/images/icon-delete.svg";
import { ReactComponent as IconEdit } from "../../assets/images/icon-edit.svg";
// import { ReactComponent as IconCaretDown } from "../assets/images/icon-caret-down.svg";
import { authenticateAdminPage } from "../../lib/helpers/validation";
const Swal = withReactContent(Sweetalert2);

const editInfo = {
  title: "Update User",
  hasClose: true,
  hasMessage: false,
  customClassName: "no-margin no-shadow",
};

function ManageDeal(props) {
  useEffect(() => {
    authenticateAdminPage(props);
  }, []);
  // const [data, setData] = useState([])
  const [DealData, setDealData] = useState([]);
  const [modalState, setModalState] = useState(false);
  const [currentEdit_ID, setCurrentEdit_ID] = useState("");
  // const [inputType, setInputType] = useState(true);
  // const iconShow = require("../assets/images/icon-show.png");
  // const iconHide = require("../assets/images/icon-hide.png");
  // const [isRoleDisabled, setRoleIsDisabled] = useState(false);

  const [loader, setLoader] = useState(false);

  /*
   * Regex Validations to Inputs
   */

  // var nameRegex = /^[a-zA-Z ]+$/;

  /*
   * Initialize input state schema
   */

  const stateSchema = {
    title: { value: "", error: "" },
    description: { value: "", error: "" },
    price_points: { value: "", error: "" },
  };

  /*
   * Create a schema for all inputs
   */

  const stateValidatorSchema = {
    title: {
      required: true,
    },
    description: {
      required: true,
    },
    price_points: {
      required: true,
    },
  };

  /*
   * On Submit Form
   */

  function onSubmitForm(state) {
    /*
     * Destructuring state Values
     */

    const { title, description, price_points } = state;

    /*
     * get data from state(Input) and set to variable
     */

    const deal = {
      title: title,
      description: description,
      price_points: price_points,
    };

    if (currentEdit_ID !== "") {
      // console.log("Edit");
      // storeUser(currentEdit_ID, deal).then(res => {
      //   let title = "Well Done!";
      //   let message = "User has been updated";
      //   successNotif(title, message);
      //   hideModal();
      //   getData();
      //   console.log(res.data);
      // });
      axios
        .post(`${url_path}/api/deals/update/${currentEdit_ID}`, deal)
        .then((res) => {
          let title = "Well Done!";
          let message = "Deal has been updated successfully.";
          successNotif(title, message);
          hideModal();
          getData();
          // console.log(res.data);
        });
    } else {
      // console.log("Add");
      axios.post(`${url_path}/api/deals/`, deal).then((res) => {
        // console.log(res);
        let title = "Well Done!";
        let message = "New deal has been created successfully.";
        successNotif(title, message);
        hideModal();
        getData();
        appendNewUser(res.data);
        // console.log(res.data.deal);
        // console.log(res.data);
      });
    }
  }

  // console.log("Running");
  // console.log(currentEdit_ID);

  /*
   * Get values, errors, handling events to custom useForm Hooks
   */

  const { values, errors, handleOnChange, handleOnSubmit, disable } = useForm(
    stateSchema,
    stateValidatorSchema,
    onSubmitForm
  );

  /*
   * Get values to custom useForm Hooks
   * Pass it to input values
   */

  const { title, description, price_points } = values;

  // var status = values.status.toString();

  /*
   * Toggle Input Password Type and Icon
   */

  // function togglePassword() {
  //   inputType ? setInputType(false) : setInputType(true);
  // }

  /*
   * Get User Data to Database
   * Set Data to State
   */

  function getData() {
    axios
      .get(`${url_path}/api/deals/`)
      .then((response) => {
        setDealData(response.data);
        setLoader(true);
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  /*
   * Callback funciton to Add
   * Append data to state[data]
   */

  function appendNewUser(data) {
    let deal = {
      _id: data._id,
      title: data.title,
      description: data.description,
      price_points: data.price_points,
    };

    setDealData([...DealData, deal]);
  }

  /*
   * Mount User Data
   * Clear and Set Initial Data to Inputs
   */

  useEffect(() => {
    getData();
    // setDealData(DealData)
  }, []);

  /*
   * Modal Actions Show/Hide
   */

  const hideModal = () => {
    setModalState(false);
    setCurrentEdit_ID("");
  };

  const showModal = () => {
    setModalState(true);
  };

  /*
   * Action Function Handlers
   */

  const handleShowCreate = () => {
    showModal();
    editInfo.title = "Create New Deal";

    values.title = "";
    values.description = "";
    values.price_points = "";

    errors.title = "";
    errors.description = "";
    errors.price_points = "";
  };

  const handleShowEdit = () => {
    showModal();
    editInfo.title = "Update Deal";
  };

  /*
   * Disallow non valid keys to input number
   */

  // const handleInputNumberAllowedCharacter = event => {
  //   var invalidChars = ["-", "e"];

  //   if (invalidChars.includes(event.key)) {
  //     event.preventDefault();
  //   }
  // };

  const heading = [
    {
      Header: "ID",
      accessor: "_id",
      width: 300,
      // filterMethod: (filter, row) =>
      //   row._original.title.startsWith(filter.value) ||
      //   row._original.description.startsWith(filter.value)
    },
    {
      Header: "Title",
      accessor: "title",

      // filterMethod: (filter, row) =>
      //   row._original.title.startsWith(filter.value) ||
      //   row._original.description.startsWith(filter.value)
    },
    {
      Header: "Description",
      accessor: "description",
    },
    {
      Header: "Price Points",
      accessor: "price_points",
      width: 150,
    },
    {
      Header: "Actions",
      sortable: false,
      // filterable: false,
      Cell: (row) => (
        <div className="button-wrapper">
          <div className="button-holder">
            <button
              className="btn-icon"
              onClick={() => {
                handleShowEdit();
                // getData()

                /*
                 * Get data from deal datas
                 */

                let datas = [...DealData];

                /*
                 * Initialize CurrentID
                 */

                setCurrentEdit_ID(datas[row.index]._id);

                /*
                 * Add shorthand notation value
                 */

                const { title, description, price_points } = datas[row.index];

                /*
                 * Set User Data value to Current Input Fields
                 */

                values.title = title;
                values.description = description;
                values.price_points = price_points;
              }}
            >
              <span className="icon-holder">
                <IconEdit className="icon-actions" data-tip data-for="edit" />
              </span>
            </button>
            <ReactTooltip id="edit" type="warning" effect="solid">
              <span>Edit</span>
            </ReactTooltip>
          </div>
          <div className="button-holder">
            <button
              className="btn-icon"
              onClick={() => {
                let datas = [...DealData];

                // console.log(datas[row.index]._id);
                const deleteID = datas[row.index]._id;

                Swal.fire({
                  title: "Are you sure?",
                  text: "You won't be able to revert this!",
                  icon: "warning",
                  showCancelButton: true,
                  confirmButtonColor: "#3085d6",
                  cancelButtonColor: "#d33",
                  confirmButtonText: "Yes, delete it!",
                }).then((result) => {
                  if (result.value) {
                    axios
                      .delete(`${url_path}/api/deals/${deleteID}`)
                      .then((response) => {
                        console.log(response);
                        getData();
                      });
                    let title = "Well Done!";
                    let message = "User has been deleted successfully.";
                    successNotif(title, message);
                    // Swal.fire(
                    //   'Deleted!',
                    //   'User has been deleted successfully!',
                    //   'success'
                    // )
                  }
                });
              }}
            >
              <span className="icon-holder">
                <IconDelete
                  className="icon-actions"
                  data-tip
                  data-for="delete"
                />
              </span>
            </button>
            <ReactTooltip id="delete" type="warning" effect="solid">
              <span>Delete</span>
            </ReactTooltip>
          </div>
        </div>
      ),
    },
  ];

  return (
    <main className="manage-users">
      <Breadcrumbs
        size="text-large"
        placeholder="Deals"
        targetLink="/admin-dashboard"
        hasButton="true"
        buttonTitle="Create New Deal"
        customClassName="m-3"
        propsFunction={handleShowCreate}
      />
      <div className="section-table">
        <div className="container">
          {loader ? (
            <Table tableHeader={heading} tableData={DealData} />
          ) : (
            <div className="lds-ellipsis">
              <div className="dots"></div>
              <div className="dots"></div>
              <div className="dots"></div>
              <div className="dots"></div>
            </div>
          )}
        </div>
      </div>
      <Modal
        show={modalState}
        onHide={hideModal}
        className="modal-dark- opacity"
      >
        <Card
          cardDetails={editInfo}
          form={
            <form className="form-wrapper" onSubmit={handleOnSubmit}>
              <div className="form-field">
                <input
                  id="title"
                  className="form-input"
                  type="text"
                  name="title"
                  value={title}
                  onChange={handleOnChange}
                  required
                />
                <label htmlFor="title">Title</label>
                {errors.title && (
                  <span className="form-error">{errors.title}</span>
                )}
              </div>
              <div className="form-field">
                <input
                  id="description"
                  className="form-input"
                  type="text"
                  name="description"
                  value={description}
                  onChange={handleOnChange}
                  required
                />
                <label htmlFor="description">Description</label>
                {errors.description && (
                  <span className="form-error">{errors.description}</span>
                )}
              </div>
              <div className="form-field">
                <input
                  id="price_points"
                  className="form-input"
                  type="text"
                  name="price_points"
                  value={price_points}
                  onChange={handleOnChange}
                  required
                />
                <label htmlFor="price_points">Price Points</label>
                {errors.price_points && (
                  <span className="form-error">{errors.price_points}</span>
                )}
              </div>

              <div className="form-btn-wrapper form-btn-dual-wrapper">
                <input
                  type="button"
                  className="btn-d btn-gray"
                  value="Cancel"
                  onClick={hideModal}
                ></input>
                <input
                  type="submit"
                  className="btn-d btn-blue"
                  value="Save Changes"
                  disabled={disable}
                />
              </div>
            </form>
          }
        />
      </Modal>
    </main>
  );
}

export default ManageDeal;
