import React, { useEffect, useState } from "react";
import { url_path } from "../../constant";
import Breadcrumbs from "../../components/Breadcrumbs";
import Sidebar from "../../components/Sidebar";
import { authenticateMemberPage } from "../../lib/helpers/validation";
import { getCookie } from "../../lib/helpers/cookies";
import axios from "axios";

function MemberAccountAbout({ history }) {
  // States
  const [loading, setLoading] = useState(true);
  const [qualification, setQualification] = useState({});

  // Functions
  const getQualificationData = async () => {
    setLoading(true);

    const token = getCookie("token");
    try {
      const { data: user_data } = await axios.get(`${url_path}/api/auth`, {
        headers: { "x-auth-token": token },
      });

      const req = {
        user_id: user_data.user._id,
        reference_number: user_data.user.reference_number,
      };

      const { data: qualification_data } = await axios.post(
        `${url_path}/api/member_request/accountGetData`,
        req
      );

      if (qualification_data.next_tier_category_name === "SILVER") {
        qualification_data.next_points = "10,000";
      } else if (qualification_data.next_tier_category_name === "GOLD") {
        qualification_data.next_points = "25,000";
      } else if (qualification_data.next_tier_category_name === "PLATINUM") {
        qualification_data.next_points = "50,000";
      }

      setQualification(qualification_data);

      setLoading(false);

      console.log(qualification_data);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    authenticateMemberPage(history);
    getQualificationData();
  }, []);

  return (
    <main className="qualification member-dashboard">
      <Sidebar history={history} />
      <div className="content-wrapper">
        <Breadcrumbs
          placeholder="My Account"
          size="text-large"
          targetLink="/my-infinity-account-account"
        />
        {loading ? (
          <div className="lds-ellipsis">
            <div className="dots"></div>
            <div className="dots"></div>
            <div className="dots"></div>
            <div className="dots"></div>
          </div>
        ) : (
          <div className="container">
            <div className="qualification-wrapper">
              <p className="heading title-centered">
                {qualification.next_tier_category_name} Qualification
              </p>
              <div className="qualification-holder">
                <p className="qualification-text">
                  Earn {qualification.next_points} points within 12 consecutive
                  months to achieve a {qualification.next_tier_category_name}{" "}
                  status membership. Your status will be valid for the next 12
                  months, but you will need to renew your card before the expiry
                  day.
                </p>
              </div>
              {qualification.current_tier_category_level > 1 && (
                <div className="qualification-holder">
                  <p className="text-blue">Requalification:</p>
                  <p className="qualification-text">
                    To requalify, earn <span>{qualification.next_points}</span>{" "}
                    points during your {qualification.next_tier_category_name}{" "}
                    Membership year.
                  </p>
                </div>
              )}
              <div className="qualification-holder">
                {qualification.current_tier_category_level < 4 && (
                  <p className="text-blue">Note:</p>
                )}
                {qualification.current_tier_category_level < 2 && (
                  <p className="qualification-text">
                    Earn <span>10,000</span> points to achieve Silver Status
                    Membership.
                  </p>
                )}
                {qualification.current_tier_category_level < 3 && (
                  <p className="qualification-text">
                    Earn <span>25,000</span> points to achieve Gold Status
                    Membership.
                  </p>
                )}
                {qualification.current_tier_category_level < 4 && (
                  <p className="qualification-text">
                    Earn <span>50,000</span> points to achieve Platinum Status
                    Membership.
                  </p>
                )}
              </div>
            </div>
          </div>
        )}
      </div>
    </main>
  );
}

export default MemberAccountAbout;
