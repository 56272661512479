import React, { useState, useEffect } from "react";
import { url_path } from "../../constant";

import Breadcrumbs from "../../components/Breadcrumbs";
import ConfirmModal from "../../components/ConfirmModal";
import Card from "../../components/Card";

import axios from "axios";

import Sweetalert2 from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { setCookie, getCookie } from "../../lib/helpers/cookies";
import { authenticateBusinessPage } from "../../lib/helpers/validation";

const Swal = withReactContent(Sweetalert2);

function PartnerSettings(props) {
  useEffect(() => {
    authenticateBusinessPage(props);
  }, []);
  const [showConfirmModal, setShowConfirmModal] = useState(false);

  const [loader, setLoader] = useState(true);
  const [referral, setReferral] = useState("");

  const cardInfo = {
    title: "My Referral",
    hasMessage: true,
    message: "Use this Referral Code",
  };

  const handleShowModal = (confirmSignOut) => {
    setShowConfirmModal(!showConfirmModal);

    if (confirmSignOut) {
      signOut();
    }
  };

  const signOut = () => {
    // Swal.fire({
    //   title: 'Are you sure?',
    //   text: `You want to sign out`,
    //   icon: 'warning',
    //   showCancelButton: true,
    //   confirmButtonColor: '#3085d6',
    //   cancelButtonColor: '#d33',
    //   confirmButtonText: 'Yes'
    // }).then((result) => {
    //   if (result.value) {
    //     setCookie("type", "", 0);
    //     setCookie("isLoggedIn", false, 0);
    //     setCookie("token", "", 0);
    //     props.history.push('/')
    //     console.log('confirmed');
    //   }else{
    //       console.log('not confirmed');
    //   }
    // })
    setCookie("type", "", 0);
    setCookie("isLoggedIn", false, 0);
    setCookie("token", "", 0);
    props.history.push('/')
    console.log('confirmed');
  }

  const getReferral = () => {
    // return console.log(getCookie("type"));
    const token = getCookie("token");
    axios
      .get(`${url_path}/api/auth`, { headers: { "x-auth-token": token } })
      .then((res) => {
        console.log(res.data);
        setReferral(res.data.user.reference_number);
        setLoader(!loader);
      });
    // axios.get(`${url_path}/api/users/${user_id}`).then(res => {
    //   setReferral(res.data.reference_number);
    //   setLoader(!loader);
    // });
  };

  useEffect(() => {
    getReferral();
  }, []);

  return (
    <main className="settings">
      <div className="container">
        <Breadcrumbs
          size="text-large"
          placeholder="Settings"
          targetLink="/partner-dashboard"
          buttonTitle="Sign out"
          hasButton="true"
          customClassName="m-3"
          propsFunction={() => signOut()}
        />
        <div className="card-body">
          {!loader ? (
            <Card
              cardDetails={cardInfo}
              form={
                <center>
                  <h4>{referral}</h4>
                </center>
              }
            />
          ) : (
            <div className="lds-ellipsis">
              <div className="dots"></div>
              <div className="dots"></div>
              <div className="dots"></div>
              <div className="dots"></div>
            </div>
          )}
        </div>
        <ConfirmModal
          title="Confirm Sign-out?"
          showConfirmModal={showConfirmModal}
          handleShowModal={handleShowModal}
        />
      </div>
    </main>
  );
}

export default PartnerSettings;
