import React from 'react'

function Points(props) {
  return (
    <div className="points-wrapper">
      <div className="points-info">
        <h2 className="title-blue">{props.available}</h2>
        <p className="text">Available Points</p>
      </div>
      <div className="points-info">
        <h2 className="title-blue">{props.redeemed}</h2>
        <p className="text">Points Redeemed</p>
      </div>
      <div className="points-info">
        <h2 className="title-blue">{props.earned}</h2>
        <p className="text">Points Earned</p>
      </div>
    </div>
  )
}

export default Points
