import React from 'react'
// import { useInput } from '../components/FormInput'
import Card from "../components/Card"
import '../stylesheets/main.scss'
// import Input from 'react-validation/build/input';
// import Form from 'react-validation/build/form';
// import Button from 'react-validation/build/button';
// import validator from 'validator'
import { withRouter } from 'react-router'
import { Link } from 'react-router-dom'


function SignupPhone(props) {
  // const { option, close, cta } = props;

  // const { value:phone, bind:bindPhone, reset:resetPhone } = useInput('');

  // const handleSubmit = (event) => {
  //   event.preventDefault();
  //   alert(`${phone}`);
  //   resetPhone();
  // }

  // const required = (value) => {
  //   if (!value.toString().trim().length) {
  //     // We can return string or jsx as the 'error' prop for the validated Component
  //     return <span className="form-error is-visible">Required*</span>;
  //   }
  // };

  
  // const validatePhoneNumber = (number) => {
  //   // const isValidPhoneNumber = validator.isMobilePhone(number)
  //   // return (isValidPhoneNumber)
  //   if (!validator.isMobilePhone(number)) {
  //     return <span className="form-error is-visible">Invalid phone number</span>
  //   }
  // }

  const cardInfo = { 
    title: 'Sign Up',
  }

  return (
    <div className="card-body">
      <Card cardDetails={cardInfo} divider={
        <span>
          {/* {option} */}
          <p className="signup-option">Sign up with <Link to="/signup"><span>email address</span></Link></p>
         
          <span className="divider"></span>
          <p>or</p>
        </span>
      } form={
        <form action="" className="form-wrapper">
          <div className="form-field phone">
            <label>+63</label>
            <input type="number" name="phone" className="form-input" />
            {/* <input type="number" className="form-input" name="phone" {...bindPhone}></input> */}
          </div>
          <button className="btn-blue">Sign up</button>
          {/* <input type="submit" className="btn-blue" value="Sign up"></input> */}
          <p className="cta">Already have an account? <Link to="/"><span>Log in</span></Link></p>
          {/* {cta} */}
          {/* {close} */}
        </form>
      }/>
    </div>
  )
}

export default withRouter(SignupPhone)





























// import React from 'react'
// // import { useInput } from '../components/FormInput'
// import Card from "../components/Card"
// import '../stylesheets/main.scss'
// import Input from 'react-validation/build/input';
// import Form from 'react-validation/build/form';
// import Button from 'react-validation/build/button';
// import validator from 'validator'
// import { withRouter } from 'react-router'
// import { Link } from 'react-router-dom'


// function SignupPhone(props) {
//   // const { option, close, cta } = props;

//   // const { value:phone, bind:bindPhone, reset:resetPhone } = useInput('');

//   // const handleSubmit = (event) => {
//   //   event.preventDefault();
//   //   alert(`${phone}`);
//   //   resetPhone();
//   // }

//   const required = (value) => {
//     if (!value.toString().trim().length) {
//       // We can return string or jsx as the 'error' prop for the validated Component
//       return <span className="form-error is-visible">Required*</span>;
//     }
//   };

  
//   const validatePhoneNumber = (number) => {
//     // const isValidPhoneNumber = validator.isMobilePhone(number)
//     // return (isValidPhoneNumber)
//     if (!validator.isMobilePhone(number)) {
//       return <span className="form-error is-visible">Invalid phone number</span>
//     }
//   }

//   const cardInfo = { 
//     title: 'Sign Up',
//   }

//   return (
//     <div className="card-body">
//       <Card cardDetails={cardInfo} divider={
//         <span>
//           {/* {option} */}
//           <p className="signup-option">Sign up with <Link to="/signup"><span>email address</span></Link></p>
         
//           <span className="divider"></span>
//           <p>or</p>
//         </span>
//       } form={
//         <Form action="" className="form-wrapper">
//           <div className="form-field phone">
//             <label>+63</label>
//             <Input type="number" name="phone" className="form-input" validations={[required, validatePhoneNumber]}/>
//             {/* <input type="number" className="form-input" name="phone" {...bindPhone}></input> */}
//           </div>
//           <Button className="btn-blue">Sign up</Button>
//           {/* <input type="submit" className="btn-blue" value="Sign up"></input> */}
//           <p className="cta">Already have an account? <Link to="/"><span>Log in</span></Link></p>
//           {/* {cta} */}
//           {/* {close} */}
//         </Form>
//       }/>
//     </div>
//   )
// }

// export default withRouter(SignupPhone)

