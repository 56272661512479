import { successNotif } from "../../lib/helpers/notifications.js";
import { url_path } from "../../constant";
import React, { useState, useEffect } from "react";
import axios from "axios";
import Card from "../../components/Card";
import "../../stylesheets/main.scss";
import useForm from "../../lib/hooks/useForm.js";

function BasicInformation({ data, businessPartnerData, partner_id }) {
  const [readOnly, setReadOnly] = useState(true);

  const [currentMainCategory, setCurrentMainCategory] = useState("");
  const [currentSubCategory, setCurrentSubCategory] = useState("");
  const [action, setAction] = useState(false);
  const [dataFound, setDataFound] = useState(false);

  // const [mainCategories, setMainCategories] = useState([]);
  // const [subCategories, setSubCategories] = useState([]);
  // const [selectedMain, setSelectedMain] = useState("");
  // const [selectedSub, setSelectedSub] = useState("");
  // const [selectedMainTitle, setSelectedMainTitle] = useState("");
  // const [selectedSubTitle, setSelectedSubTitle] = useState("");
  // const [disableSub, setDisableSub] = useState(true);
  const [loading, setLoading] = useState(false);

  /*
   * Regex Validations to Inputs
   */

  var nameRegex = /^[a-zA-Z ]+$/;
  var numRegex = /^[0-9]*$/;

  /*
   * Initialize input state schema
   */

  const stateSchema = {
    business_name: { value: businessPartnerData.business_name, error: "" },
    business_owner: { value: businessPartnerData.business_owner, error: "" },
    address: { value: businessPartnerData.address, error: "" },
    city: { value: businessPartnerData.city, error: "" },
    zip: { value: businessPartnerData.zip, error: "" },
    province: { value: businessPartnerData.province, error: "" },
    country_code: { value: businessPartnerData.country_code, error: "" },
    about: { value: businessPartnerData.about, error: "" },
    main_category: { value: currentMainCategory, error: "" },
    sub_category: { value: currentSubCategory, error: "" },
    thumbnail: { value: businessPartnerData.thumbnail, error: "" },
    map_link: { value: businessPartnerData.map_link, error: "" },
    wp_link: { value: businessPartnerData.wp_link, error: ""},
  };
  console.log(businessPartnerData.thumbnail)
  /*
   * Create a schema for all inputs
   */

  const stateValidatorSchema = {
    business_name: {
      required: true,
      validator: {
        func: (value) => nameRegex.test(value),
        error: "Invalid Name",
      },
    },
    business_owner: {
      required: true,
      validator: {
        func: (value) => nameRegex.test(value),
        error: "Invalid Name",
      },
    },
    address: {
      required: false,
    },
    city: {
      required: true,
      validator: {
        func: (value) => nameRegex.test(value),
        error: "Invalid City",
      },
    },
    province: {
      required: true,
      validator: {
        func: (value) => nameRegex.test(value),
        error: "Invalid Province",
      },
    },
    country_code: {
      required: true,
      validator: {
        func: (value) => nameRegex.test(value),
        error: "Invalid Country!",
      },
    },
    zip: {
      required: true,
      validator: {
        func: (value) => numRegex.test(value),
        error: "Invalid Zip Number!",
      },
    },
    about: {
      required: false,
    },
    main_category: {
      required: false,
    },
    sub_category: {
      required: false,
    },
    thumbnail:{
      required: false,
    },
    map_link:{
      required: false,
    },
    wp_link:{
      required: false,
    }
  };

  const { values, errors, handleOnChange, handleOnSubmit, disable } = useForm(
    stateSchema,
    stateValidatorSchema,
    onSubmitForm
  );

  function onSubmitForm(state) {
    setLoading(true);
    /*
     * Destructuring state Values
     */

    const {
      business_name,
      business_owner,
      address,
      city,
      zip,
      province,
      country_code,
      about,
    } = state;

    const basicInfo = {
      business_name: business_name,
      business_owner: business_owner,
      address: address,
      city: city,
      zip: zip,
      province: province,
      country_code: country_code,
      about: about,
      thumbnail: thumbnail,
      map_link: map_link,
      wp_link: wp_link
    };

    /*
     * Update Data
     */

    axios
      .put(`${url_path}/api/business_partners/update/${partner_id}`, basicInfo)
      .then((res) => {
        console.log(res);
        const partnerData = res.data;
        if (partnerData.data.status === "success") {
          let title = "Well Done!";
          let message = partnerData.data.msg;
          successNotif(title, message);
          setReadOnly(true);
        } else {
          let title = "Oops!";
          let message = partnerData.data.msg;
          successNotif(title, message);
        }
        setLoading(false);
        // console.log(res.data);
      })
      .catch((err) => {
        console.log(err.response);
      });

    // update category info
    const req = {
      category_main_type: main_category,
      category_sub_type: sub_category,
      
    };
    axios
      .post(`${url_path}/api/business_category/add/${partner_id}`, req)
      .then((res) => {
        // const business_category = res.data;

        // let title = "Well Done!";
        // let msg = business_category.data.msg;
        // successNotif(title, msg);
        setAction(false);
        getCategoryData();
        // getData();
      });
    // console.log(req);
  }

  /*
   * Get values to custom useForm Hooks
   * Pass it to input values
   */

  const {
    business_name,
    business_owner,
    address,
    city,
    zip,
    province,
    country_code,
    about,
    main_category,
    sub_category,
    map_link,
    thumbnail,
    wp_link
  } = values;

  const getCategoryData = async () => {
    // setLoading(true);

    try {
      axios
        .get(`${url_path}/api/business_category/input/${partner_id}`)
        .then((category) => {
          const mainCategory = category.data;
          console.log(mainCategory);
          if (mainCategory.data.status === "success") {
            setCurrentMainCategory(
              mainCategory.categoryData.category_main_type
            );
            setCurrentSubCategory(mainCategory.categoryData.category_sub_type);
            setDataFound(true);
          } else {
            setDataFound(false);
          }
        })
        .catch((err) => console.log(err));
    } catch (err) {
      console.log(err);
    }
  };

  const handleButtonEdit = () => {
    values.main_category = currentMainCategory;
    values.sub_category = currentSubCategory;

    setReadOnly(false);
    setAction(true);
    setDataFound(false);
  };

  const handleButtonCancel = () => {
    setReadOnly(true);
    setAction(false);
    getCategoryData();
  };

  // const handleMainCategoryChange = async (event) => {
  //   const main_id = event.target.value

  //   setSelectedMain(main_id)
  //   try {
  //     if(main_id === '') {
  //       setSelectedSub('')
  //       setSubCategories([])
  //     } else {
  //       const { data: sub_categories } = await axios.get(`${url_path}/api/subCategory/${ main_id }`)
  //       setSubCategories(sub_categories)
  //     }
  //   } catch(err) { console.log(err) }
  // }

  // const handleSubCategoryChange = (event) => {
  //   const sub_id = event.target.value

  //   setSelectedSub(sub_id)
  // }

  // const checkCategories = () => {
  //   return (selectedMain !== '' && selectedSub !== '' && disable)
  // }

  useEffect(() => {
    getCategoryData();
  }, []);

  return (
    <>
      {loading ? (
        <div className="lds-ellipsis">
          <div className="dots"></div>
          <div className="dots"></div>
          <div className="dots"></div>
          <div className="dots"></div>
        </div>
      ) : (
        <Card
          cardDetails={data}
          form={
            <form className="form-wrapper" onSubmit={handleOnSubmit}>
              <img 
                src={thumbnail} 
                style={{marginBottom:20, borderRadius: 25}}>
              </img>
              <div className="form-field">
                <input
                  id="business_name"
                  className="form-input"
                  type="text"
                  name="business_name"
                  value={business_name || ""}
                  onChange={handleOnChange}
                  required
                  readOnly={readOnly}
                  disabled={readOnly}
                />
                <label htmlFor="business_name">Business Name</label>
                {errors.business_name && (
                  <span className="form-error">{errors.business_name}</span>
                )}
              </div>
              <div className="form-field">
                <input
                  id="business_owner"
                  className="form-input"
                  type="text"
                  name="business_owner"
                  value={business_owner || ""}
                  onChange={handleOnChange}
                  required
                  readOnly={readOnly}
                  disabled={readOnly}
                />
                <label htmlFor="business_owner">Business Owner</label>
                {errors.business_owner && (
                  <span className="form-error">{errors.business_owner}</span>
                )}
              </div>
              <div className="form-field">
                <input
                  id="address"
                  className="form-input"
                  type="text"
                  name="address"
                  value={address || ""}
                  onChange={handleOnChange}
                  required
                  readOnly={readOnly}
                  disabled={readOnly}
                />
                <label htmlFor="address">Address</label>
                {errors.address && (
                  <span className="form-error">{errors.address}</span>
                )}
              </div>
              <div className="form-group">
                <div className="form-field select">
                  <input
                    id="city"
                    type="text"
                    className="form-input"
                    name="city"
                    value={city}
                    onChange={handleOnChange}
                    required
                    readOnly={readOnly}
                    disabled={readOnly}
                  />
                  <label htmlFor="city">City</label>
                </div>
                <div className="form-field">
                  <input
                    id="zip"
                    className="form-input"
                    type="text"
                    name="zip"
                    value={zip || ""}
                    onChange={handleOnChange}
                    readOnly={readOnly}
                    disabled={readOnly}
                  />
                  <label htmlFor="zip">Zip</label>
                  {errors.zip && (
                    <span className="form-error">{errors.zip}</span>
                  )}
                </div>
              </div>
              <div className="form-group">
                <div className="form-field select">
                  <input
                    id="province"
                    type="text"
                    className="form-input"
                    name="province"
                    value={province}
                    onChange={handleOnChange}
                    required
                    readOnly={readOnly}
                    disabled={readOnly}
                  />
                  <label htmlFor="province">Province</label>
                </div>
                <div className="form-field">
                  <input
                    id="country_code"
                    type="text"
                    className="form-input"
                    name="country_code"
                    value={country_code || ""}
                    onChange={handleOnChange}
                    required
                    readOnly={readOnly}
                    disabled={readOnly}
                  />
                  <label htmlFor="country_code">Country</label>
                  {errors.country_code && (
                    <span className="form-error">{errors.country_code}</span>
                  )}
                </div>
              </div>
              <div className="form-field form-textarea">
                <label htmlFor="about">About the business</label>
                <textarea
                  id="about"
                  name="about"
                  className="form-input"
                  value={about || ""}
                  onChange={handleOnChange}
                  readOnly={readOnly}
                  disabled={readOnly}
                ></textarea>
              </div>
              <h5>{"  "}Category Information</h5>
              {dataFound ? (
                <>
                  <div className="form-field">
                    <input
                      id="mainCategory"
                      className="form-input"
                      type="text"
                      name="mainCategory"
                      value={currentMainCategory}
                      readOnly
                      disabled
                    />
                    <label htmlFor="mainCategory">Main Category</label>
                  </div>
                  <div className="form-field">
                    <input
                      id="subCategory"
                      className="form-input"
                      type="text"
                      name="subCategory"
                      value={currentSubCategory}
                      readOnly
                      disabled
                    />
                    <label htmlFor="subCategory">Sub Category</label>
                  </div>
                  <div className="form-field">
                    <input
                      id="thumbnail"
                      className="form-input"
                      type="text"
                      name="thumbnail"
                      value={thumbnail}
                      readOnly
                      disabled
                    />
                    <label htmlFor="thumbnail">Thumbnail</label>
                  </div>
                  <div className="form-field">
                    <input
                      id="mapLink"
                      className="form-input"
                      type="text"
                      name="mapLink"
                      value={map_link}
                      readOnly
                      disabled
                    />
                    <label htmlFor="mapLink">Map Link</label>
                  </div>
                  <div className="form-field">
                    <input
                      id="wp_link"
                      className="form-input"
                      type="text"
                      name="wp_link"
                      value={wp_link}
                      readOnly
                      disabled
                    />
                    <label htmlFor="mapLink">Wordpress Link</label>
                  </div>
                  {/* <input
                    type="button"
                    className="btn-blue full-width"
                    value="Edit"
                    onClick={handleOnAction}
                  ></input> */}
                </>
              ) : action ? (
                <>
                  {/* <div className="form-field select">
                    <select
                      id="mainCategory"
                      className="form-input select"
                      name="mainCategory"
                      // value={currentMainCategory._id}
                      onChange={handleMainCategoryChange}
                      required
                    >
                      <option value="no selected">Select Main Category</option>
                      {mainCategoryData.map((category, index) => (
                        <option value={category._id} key={index}>
                          {category.main_type}
                        </option>
                      ))}
                    </select>
                    <label htmlFor="businessType">
                      Main Business Type
                      <IconCaretDown className="form-select-caret-down" />
                    </label>
                  </div>
                  <div className="form-field select">
                    <select
                      id="subCategory"
                      className="form-input select"
                      name="subCategory"
                      // value={currentMainCategory._id}
                      onChange={handleSubCategoryChange}
                      required
                    >
                      <option value="no selected">Select Main Category</option>
                      {subCategoryData.map((subCategory, index) => (
                        <option value={subCategory._id} key={index}>
                          {subCategory.sub_type}
                        </option>
                      ))}
                    </select>

                    <label htmlFor="category">
                      Type a category, eg. massage, photography
                      <IconCaretDown className="form-select-caret-down" />
                    </label>
                  </div> */}
                  <div className="form-field">
                    <input
                      id="main_category"
                      className="form-input"
                      type="text"
                      name="main_category"
                      value={main_category}
                      onChange={handleOnChange}
                      required
                    />
                    <label htmlFor="main_category">Main Category</label>
                    {errors.main_category && (
                      <span className="form-error">{errors.main_category}</span>
                    )}
                  </div>
                  <div className="form-field">
                    <input
                      id="sub_category"
                      className="form-input"
                      type="text"
                      name="sub_category"
                      value={sub_category}
                      onChange={handleOnChange}
                      required
                    />
                    <label htmlFor="sub_category">Sub Category</label>
                    {errors.sub_category && (
                      <span className="form-error">{errors.sub_category}</span>
                    )}
                  </div>
                  <div className="form-field">
                    <input
                      id="thumbnail"
                      className="form-input"
                      type="text"
                      name="thumbnail"
                      value={thumbnail}
                      onChange={handleOnChange}
                      required
                    />
                    <label htmlFor="thumbnail">Thumbnail</label>
                  </div>
                  <div className="form-field">
                    <input
                      id="map_link"
                      className="form-input"
                      type="text"
                      name="map_link"
                      value={map_link}
                      onChange={handleOnChange}
                      required
                    />
                    <label htmlFor="map_link">Map Link</label>
                  </div>
                  <div className="form-field">
                    <input
                      id="wp_link"
                      className="form-input"
                      type="text"
                      name="wp_link"
                      value={wp_link}
                      onChange={handleOnChange}
                      required
                    />
                    <label htmlFor="wp_link">Wordpress Link</label>
                  </div>
                  {/* <input
                    type="button"
                    className="btn-blue full-width addSpace"
                    value="Save"
                    onClick={() => onSubmitForm()}
                  ></input>
                  <input
                    type="button"
                    className="btn-blue-outline full-width"
                    value="Cancel"
                    onClick={handleOnCancel}
                  ></input> */}
                </>
              ) : (
                <>
                  <p>No Category Found, Please Set on Edit</p>
                  {/* <input
                    type="button"
                    className="btn-blue full-width"
                    value="Add"
                    onClick={handleOnAction}
                  ></input> */}
                </>
              )}

              {/* Category Info */}
              {/* <h2 className="title">Category Information</h2> */}
              {!readOnly ? (
                <>
                  {/* <div className="form-field select">
                <select
                  id="mainCategory"
                  className="form-input select"
                  name="mainCategory"
                  value={selectedMain}
                  onChange={handleMainCategoryChange}
                >
                  <option value="">Select main category</option>
                  {mainCategories.map((category, index) => (
                    <option value={category._id} key={index}>
                      {category.main_type}
                    </option>
                  ))}
                </select>
                <label htmlFor="businessType">
                  Main Category
                  <IconCaretDown className="form-select-caret-down" />
                </label>
              </div>
              <div className="form-field select">
                <select
                  id="subCategory"
                  className="form-input select"
                  name="subCategory"
                  value={selectedSub}
                  onChange={handleSubCategoryChange}
                  // readOnly={disableSub}
                  // disabled={disableSub}
                >
                  <option value="">Select sub category</option>
                  {subCategories.map((subCategory, index) => (
                    <option value={subCategory._id} key={index}>
                      {subCategory.sub_type}
                    </option>
                  ))}
                </select>
                <label htmlFor="category">
                  Sub Category
                  <IconCaretDown className="form-select-caret-down" />
                </label>
              </div> */}

                  <input
                    type="submit"
                    className="btn-blue full-width addSpace"
                    value="Save"
                    disabled={disable}
                  />
                  <input
                    type="button"
                    className="btn-blue-outline full-width"
                    value="Cancel"
                    onClick={handleButtonCancel}
                    // disabled={disable}
                  />
                </>
              ) : (
                <>
                  {/* <div className="form-field">
                  <input
                    id="main_type"
                    className="form-input"
                    type="text"
                    name="main_type"
                    defaultValue={selectedMainTitle}
                    required
                    readOnly
                    disabled
                  />
                  <label htmlFor="main_type">Main Category</label>
                </div>
                <div className="form-field">
                  <input
                    id="sub_type"
                    className="form-input"
                    type="text"
                    name="sub_type"
                    defaultValue={selectedSubTitle}
                    required
                    readOnly
                    disabled
                  />
                  <label htmlFor="sub_type">Sub Category</label>
                </div> */}
                  <button
                    className="btn-blue full-width"
                    onClick={handleButtonEdit}
                    // disabled={disable}
                  >
                    Edit
                  </button>
                </>
              )}
            </form>
          }
        />
      )}
    </>
  );
}

export default BasicInformation;
