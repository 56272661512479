import React from 'react'

function CardFavorite({store}) {
  return (
    <div className="section-favorites">
      <div className="container">
        <div className="items-wrapper">
          { store.map((item,i) => {
            return (
                <div className="item-holder" key={i}>
                  <div className="image-holder">
                    <img src={item.image} className="fav-img" alt={item.image}/>
                  </div>
                  <div className="info-holder">
                    <h4 className="title">{item.name}</h4>
                    <p className="location-text">{item.location}</p>
                    <div className="price-holder">
                      <p className="old-price"><span className="sign">P</span>{item.oldPrice}</p>
                      <p className="new-price"><span className="sign">P</span>{item.newPrice}</p>
                      <p className="discount">{item.discount}</p>
                    </div>
                    <p className="desc">{item.desc}</p>
                  </div>
                </div>
              )
            })
          }
        </div>
      </div>
    </div>
  )
}

export default CardFavorite
