import React, { useState, useEffect } from "react";
import { url_path } from "../../constant";
import Card from "../../components/Card";
import "../../stylesheets/main.scss";
import { withRouter } from "react-router";
import axios from "axios";

function Login(props) {
  const [loader] = useState(false);

  const [notificationError, setNotificationError] = useState("");

  useEffect(() => {
    setNotificationError("");
  }, []);

  const cardInfo = {
    title: "Paypal Tester",
    hasMessage: true,
    message: "This page is for testing only!",
  };

  const onSubmitForm = () => {
    // setLoader(true);
    // const {
    //   business_partner_id,
    //   title,
    //   discount
    // } = state;
    // // return console.log(title);
    // const req = {
    //   business_partner_id,
    //   title,
    //   discount: '.'+discount,
    //   status: true
    // };
    console.log("clicked");

    axios.post(`${url_path}/api/payment_method/pay`).then((res) => {
      const paymentData = res.data;
      window.location.href = paymentData;
      // <Redirect to={paymentData} />
      console.log(paymentData);
    });
  };

  //   const stateSchema = {
  //     business_partner_id: { value: "", error: "" },
  //     title: { value: "", error: "" },
  //     discount: { value: "", error: "" },
  //   }

  //   const stateValidatorSchema = {
  //     business_partner_id: {
  //       required: true,

  //     },
  //     title: {
  //       required: false,
  //     },
  //     discount: {
  //       required: false,
  //     }
  //   }

  //   const { values, errors, handleOnChange, handleOnSubmit, disable } = useForm(
  //     stateSchema,
  //     stateValidatorSchema,
  //     onSubmitForm
  //   )

  //   const {
  //     business_partner_id,
  //     title,
  //     discount

  //   } = values;

  return (
    <main>
      <div className="card-body">
        {!notificationError ? (
          ""
        ) : (
          <div className="section-notification">
            <div className="container">
              <div className="notification-holder">
                <p className="notification-text">{notificationError}</p>
              </div>
            </div>
          </div>
        )}
        {!loader ? (
          <Card
            cardDetails={cardInfo}
            form={
              <form className="form-wrapper">
                {/* <div className='form-field'>
                    <input
                        type='text'
                        name='business_partner_id'
                        className='form-input'
                        id='business_partner_id'
                        value={business_partner_id}
                        onChange={handleOnChange}
                        required
                    />
                    <label htmlFor='business_partner_id'>Business Partner ID</label>
                    {errors.business_partner_id && (<span className='form-error'>{errors.business_partner_id}</span>)}
                  </div>
                  <div className='form-field'>
                    <input
                        type='text'
                        name='title'
                        className='form-input'
                        id='title'
                        value={title}
                        onChange={handleOnChange}
                        required
                    />
                    <label htmlFor='title'>Title</label>
                    {errors.title && (<span className='form-error'>{errors.title}</span>)}
                  </div>
                  <div className='form-field'>
                    <input
                        type='text'
                        name='discount'
                        className='form-input'
                        id='discount'
                        value={discount}
                        onChange={handleOnChange}
                        required
                    />
                    <label htmlFor='discount'>Discount ( % )</label>
                    {errors.discount && (<span className='form-error'>{errors.discount}</span>)}
                  </div>
                   */}
                <input
                  type="button"
                  className="btn-blue"
                  value="Pay P500.00"
                  onClick={onSubmitForm}
                />
              </form>
            }
          />
        ) : (
          <div className="lds-ellipsis">
            <div className="dots"></div>
            <div className="dots"></div>
            <div className="dots"></div>
            <div className="dots"></div>
          </div>
        )}
      </div>
    </main>
  );
}

export default withRouter(Login);
