import React from 'react'

import classnames from 'classnames'

const ConfirmModal = ({ title, message, showConfirmModal, handleShowModal }) => {
  return (
    <div className={ classnames('confirm-overlay', { 'show-modal': showConfirmModal })}>
      <div className="confirm-modal">
        <div className="confirm-header">
          <h4 className="confirm-title">{ title }</h4>
        </div>
        <div className="confirm-footer">
          <button className="btn-blue btn-cancel" onClick={ () => handleShowModal(false) }>Cancel</button>
          <button className="btn-blue btn-confirm" onClick={ () => handleShowModal(true) }>Confirm</button>
        </div>
      </div>
    </div>
  )
}

export default ConfirmModal
