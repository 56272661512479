import React, { useState } from "react";
import { url_path } from "../constant";
import "../stylesheets/main.scss";
import Card from "../components/Card";
import useForm from "../lib/hooks/useForm.js";
import { successNotif, errorNotifIcon } from "../lib/helpers/notifications.js";
import axios from "axios";

function ResetPassword(props) {
  var passwordMinChar = /^.{8,}$/;

  const stateSchema = {
    password: { value: "", error: "" },
    confirm_password: { value: "", error: "" },
  };

  const stateValidatorSchema = {
    password: {
      required: true,
      validator: {
        func: (value) => passwordMinChar.test(value),
        error: "Minimum 8 characters.",
      },
    },
    confirm_password: {
      required: true,
      validator: {
        func: (value) => value === password,
        error: "Password do not match",
      },
    },
  };
  const { values, errors, handleOnChange, disable } = useForm(
    stateSchema,
    stateValidatorSchema
    // onSubmitForm
  );

  const { password, confirm_password } = values;

  // const isEqual = (value, props, components) => {
  //   const bothUsed = components.password[0].isUsed && components.confirm_password[0].isUsed;
  //   const bothChanged = components.password[0].isChanged && components.confirm_password[0].isChanged;

  //   if (bothChanged && bothUsed && components.password[0].value !== components.confirm_password[0].value) {
  //     return <span className="form-error is-visible">Passwords do not match</span>;
  //   }
  // };

  // const required = (value) => {
  //   if (!value.toString().trim().length) {
  //     return <span className="form-error is-visible">Required*</span>;
  //   }
  // };

  // const { submit } = props;

  const [inputPassword, setInputPassword] = useState(true);

  function togglePassword() {
    inputPassword ? setInputPassword(false) : setInputPassword(true);
  }

  // const [inputConfirmPassword, setInputConfirmPassword] = useState(true);

  // function toggleConfirmPassword() {
  //   inputConfirmPassword ? setInputConfirmPassword(false) : setInputConfirmPassword(true);
  // }

  function handleSubmit() {
    // props.history.push('/reset-success');
    const URL_ID = props.match.params.verification_code;

    const req = { new_password: password };
    console.log(req);
    // return console.log(req)
    axios
      .post(`${url_path}/api/verification_link/change_password/${URL_ID}`, req)
      .then((res) => {
        const verificationData = res.data;
        console.log(verificationData);
        if (verificationData.data.status === "success") {
          let title = "Well Done!";
          let message = verificationData.data.msg;
          successNotif(title, message);
          props.history.push("/reset-password-success");
        } else {
          let title = "Sorry!";
          let message = verificationData.data.msg;
          errorNotifIcon(title, message);
        }
      });
    console.log(URL_ID);
  }

  const iconShow = require("../assets/images/icon-show.png");
  const iconHide = require("../assets/images/icon-hide.png");

  const cardInfo = {
    title: "Reset Password",
    hasClose: true,
  };

  return (
    <main className="login">
      <div className="card-body">
        {/* <Modal show={show}> */}
        <Card
          cardDetails={cardInfo}
          form={
            <>
              <form className="form-wrapper" onSubmit={handleSubmit}>
                {/* <div className='form-field'>
              <input
                  type='password'
                  name='email'
                  className='form-input'
                  id='email'
                  value={email}
                  onChange={handleOnChange}
                  required
              />
              <label htmlFor='email'>Email</label>
              {errors.email && (<span className='form-error'>{errors.email}</span>)}
            </div> */}
                <div className="form-field">
                  <input
                    type={inputPassword ? "password" : "text"}
                    name="password"
                    className="form-input"
                    id="password"
                    value={password}
                    onChange={handleOnChange}
                    // validations={[required, isEqual]}
                    required
                  />
                  <span className="password__show" onClick={togglePassword}>
                    <img src={inputPassword ? iconHide : iconShow} alt="" />
                  </span>
                  <label htmlFor="password">Password</label>

                  {errors.password && (
                    <span className="form-error">{errors.password}</span>
                  )}
                </div>
                <div className="form-field">
                  <input
                    type={inputPassword ? "password" : "text"}
                    name="confirm_password"
                    className="form-input"
                    id="confirm_password"
                    value={confirm_password}
                    onChange={handleOnChange}
                    // validations={[required, isEqual]}
                    required
                  />
                  {/* <span className="password__show" onClick={togglePassword}>
                <img src={inputType ? iconHide : iconShow} alt="" />
              </span> */}
                  <label htmlFor="confirm_password">Confirm Password</label>

                  {errors.confirm_password && (
                    <span className="form-error">
                      {errors.confirm_password}
                    </span>
                  )}
                </div>
                {/* <div className="form-field">
              <label>New Password</label>
              <Input
                className="form-input"
                type={inputType ? 'password' : 'text'}
                name="password"
                validations={[required, isEqual]}

                id='password'
                value={password}
                onChange={handleOnChange}
                required
              />
              <span className="password__show" onClick={togglePassword}>
                <img src={inputType ? icon : icon} alt="" />
              </span>
            </div>
            <div className="form-field">
              <label>Confirm New Password</label>
              <Input
                className="form-input"
                type={inputType ? 'password' : 'text'}
                name="confirm"
                validations={[required, isEqual]}
              />
              <span className="password__show" onClick={togglePassword}>
                <img src={inputType ? icon : icon} alt="" />
              </span>
            </div> */}
                {/* <Button className="btn-blue">Reset password</Button> */}
              </form>
              {/* <Button className="btn-blue" onClick={handleSubmit}>Reset password</Button> */}
              <button
                className="btn-blue"
                disabled={disable}
                onClick={handleSubmit}
              >
                Submit
              </button>
            </>
          }
        />
        {/* <ResetSuccess/> */}
        {/* </Modal> */}
      </div>
    </main>
  );
}

export default ResetPassword;
