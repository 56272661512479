import React, { useState, useEffect } from "react";
import { url_path } from "../../constant";
import Breadcrumbs from "../../components/Breadcrumbs";
import axios from "axios";
import { successNotif, errorNotifIcon } from "../../lib/helpers/notifications";
import Sidebar from "../../components/Sidebar";
import { authenticateMemberPage } from "../../lib/helpers/validation";
const TransferVerify = ({ history, location }) => {
  useEffect(() => {
    authenticateMemberPage(history);
  }, []);
  // States
  const [loading, setLoading] = useState(true);
  const [confirmTransfer, setConfirmTransfer] = useState({});

  // Functions
  const handleOnSubmit = async (event) => {
    event.preventDefault();

    try {
      // Get Receiver Info
      const { data: receiver_info } = await axios.get(
        `${url_path}/api/users/cardNumber/${confirmTransfer.receiver_cardNo}`
      );

      // Get Sender Info
      const { data: sender_info } = await axios.get(
        `${url_path}/api/users/${confirmTransfer.user_id}`
      );

      // Transfer Points
      const req = {
        reciever_cardNo: confirmTransfer.receiver_cardNo,
        reciever_referenceNo: confirmTransfer.reciever_referenceNo,
        numberOfPoints: confirmTransfer.numberOfPoints,
        onVerify: false,
      };

      const { data: customer_bank } = await axios.post(
        `${url_path}/api/customerBank/transfer_points/${confirmTransfer.user_id}`,
        req
      );
      // console.log(customer_bank)
      if (customer_bank.data.status === "success") {
        let title = "Awesome!";
        let msg = "Points has been transferred successfully.";
        successNotif(title, msg);

        // Send Notification to the Receiver
        const req = {
          notification_user_id: receiver_info._id,
          title: sender_info.user_first_name + " " + sender_info.user_last_name,
          description:
            "has transferred " +
            confirmTransfer.totalPoints +
            " points to your account.",
          status: false,
        };

        const { data: notification } = await axios.post(
          `${url_path}/api/notification`,
          req
        );
        
        console.log(notification)

        history.push({
          pathname: "/my-infinity-account-transfer-points",
        });
      } else {
        let title = "Warning!";
        let msg = customer_bank.data.msg;
        errorNotifIcon(title, msg);
      }
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    if (location.state) {
      setConfirmTransfer(location.state.confirm_transfer);
      // console.log(location.state.confirm_transfer)
      setLoading(false);
    } else {
      history.push("/my-infinity-account-transfer-points");
    }
  }, []);

  return (
    <main className="transfer-points member-dashboard">
      <Sidebar history={history} />
      <div className="content-wrapper">
        <Breadcrumbs
          size="text-large"
          placeholder="Back"
          targetLink="/my-infinity-account-transfer-points"
          hasButton="false"
        />
        <div className="container">
          {loading ? (
            <div className="lds-ellipsis">
              <div className="dots"></div>
              <div className="dots"></div>
              <div className="dots"></div>
              <div className="dots"></div>
            </div>
          ) : (
            <div className="transfer-points-wrapper">
              <form className="form-wrapper" onSubmit={handleOnSubmit}>
                <div className="form-field">
                  <input
                    type="text"
                    name="cardNum"
                    className="form-input"
                    id="cardNum"
                    defaultValue={confirmTransfer.numberOfPoints}
                    readOnly
                    disabled
                  />
                  <label htmlFor="cardNum">Points to be transferred</label>
                </div>
                <div className="form-field">
                  <input
                    type="text"
                    name="referenceNum"
                    className="form-input"
                    id="referenceNum"
                    defaultValue={confirmTransfer.totalPoints}
                    readOnly
                    disabled
                  />
                  <label htmlFor="referenceNum">Points to be received</label>
                </div>
                <div className="form-field">
                  <input
                    type="number"
                    min="100"
                    max="5000"
                    name="transferPoints"
                    className="form-input"
                    id="transferPoints"
                    defaultValue={confirmTransfer.totalCharge}
                    readOnly
                    disabled
                  />
                  <label htmlFor="transferPoints">Transaction Fee</label>
                </div>
                <p className="note">
                  Infinity Privilege will charge 5% as service charge
                </p>
                <div className="btn-holder">
                  <button className="btn-blue">Transfer Points</button>
                </div>
              </form>
            </div>
          )}
        </div>
      </div>
    </main>
  );
};

export default TransferVerify;
