import React from 'react'
import 'react-table/react-table.css'
import '../stylesheets/components/_table.scss'
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from 'react-accessible-accordion';

function Filter(props) {
  const { filterDetails, content } = props

  return (
    <div className="section-filter">
      <Accordion
        allowZeroExpanded={true}
        preExpanded={[]}
      >
        <AccordionItem uuid={1}>
          <AccordionItemHeading>
            <AccordionItemButton>
              {filterDetails.title}
            </AccordionItemButton>
          </AccordionItemHeading>
          <AccordionItemPanel>
            {content}
          </AccordionItemPanel>
        </AccordionItem>
      </Accordion>
    </div>
  )
}

export default Filter