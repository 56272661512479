import React, { useEffect } from "react";

import Dashboard from "../../components/Dashboard";
import { authenticateAdminPage } from "../../lib/helpers/validation";
// import { authenticateAdminPage } from "../../lib/helpers/validation";

// import PrivateRoute from './PrivateRoute';
// import { AuthContext } from "./context/auth";

const linksList = [
  {
    title: "Manage Users",
    routeTo: "/manage-users",
    icon: require("../../assets/images/dashboard-img1.png")
  },
  {
    title: "Manage Classic Members",
    routeTo: "/manage-temp-users",
    icon: require("../../assets/images/dashboard-img10.png")
  },
  {
    title: "Manage Business Partners",
    routeTo: "/manage-business-partners",
    icon: require("../../assets/images/dashboard-img2.png")
  },
  // {
  //   title: 'Manage Business Deals',
  //   routeTo: '/admin-dashboard',
  //   icon: require('../assets/images/dashboard-img3.png')
  // },
  {
    title: "Manage Members",
    routeTo: "/manage-members",
    icon: require("../../assets/images/dashboard-img4.png")
  },
  {
    title: "Manage Pending Members",
    routeTo: "/pending-membership",
    icon: require("../../assets/images/dashboard-img4.png")
  },
  {
    title: "Transactions",
    routeTo: "/admin-transactions",
    icon: require("../../assets/images/dashboard-img9.png")
  },
  {
    title: "Promo Code",
    routeTo: "/promo-code",
    icon: require("../../assets/images/dashboard-img11.png")
  },
  {
    title: "Card Request",
    routeTo: "/card-request",
    icon: require("../../assets/images/dashboard-img4.png")
  },
  // {
  //   title: "Encash Request",
  //   routeTo: "/encash-request",
  //   icon: require("../../assets/images/dashboard-img9.png")
  // },
  {
    title: "Offers",
    routeTo: "/offers",
    icon: require("../../assets/images/dashboard-img11.png")
  },
  {
    title: "Manage Announcements",
    routeTo: "/announcements",
    icon: require("../../assets/images/dashboard-img6.png")
  },
  {
    title: "Settings",
    routeTo: "/settings",
    icon: require("../../assets/images/dashboard-img6.png")
  },
  {
    title: "Manage Voucher",
    routeTo: "/settings",
    icon: require("../../assets/images/dashboard-img11.png")
  },
  {
    title: "Archives",
    routeTo: "/archives",
    icon: require("../../assets/images/dashboard-img2.png")
  },
  {
    title: "OTP Requests",
    routeTo: "/otp-requests",
    icon: require("../../assets/images/dashboard-img11.png")
  }
// {
//   title: "Payment Request",
//   routeTo: "/payment-request",
//   icon: require("../../assets/images/dashboard-img9.png")
// },
  // {
  //   title: "Test Set Deal",
  //   routeTo: "/test-set-deal",
  //   icon: require("../../assets/images/dashboard-img6.png")
  // },
  // {
  //   title: "Test Set Offer",
  //   routeTo: "/test-set-offer",
  //   icon: require("../../assets/images/dashboard-img6.png")
  // }
];

function AdminDashboard(props) {
  useEffect(() => {
    authenticateAdminPage(props);
  }, []);
  return (
    // <AuthContext.Provider value={{ authTokens, setAuthTokens: setTokens }}></AuthContext.Provider>
    <main className="admin-dashboard">
      <div className="container custom-container">
        <Dashboard links={linksList} />
      </div>
    </main>
  );
}

export default AdminDashboard;
