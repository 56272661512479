import axios from "axios";
import { Button } from "react-bootstrap";
import "moment-timezone";
import React, { useEffect, useState } from "react";
import "react-accessible-accordion/dist/fancy-example.css";
import Modal from "react-bootstrap/Modal";
import Moment from "react-moment";
import Breadcrumbs from "../../components/Breadcrumbs";
// import Sweetalert2 from "sweetalert2";
import Card from "../../components/Card";
import Table from "../../components/Table";
import { url_path } from "../../constant";
// import withReactContent from "sweetalert2-react-content";
import { errorNotifIcon, successNotif } from "../../lib/helpers/notifications";
import { authenticateAdminPage } from "../../lib/helpers/validation";

// const Swal = withReactContent(Sweetalert2);

const OTPRequests = (props) => {
  useEffect(() => {
    authenticateAdminPage(props);
  }, []);
  // States
  const [modalState, setModalState] = useState(false);
  const [dataVal, setDataVal] = useState([]);
  const [loading, setLoading] = useState(true);
  const [isButtonLoading, setIsButtonLoading] = useState(false);
  const [pagerData, setPagerData] = useState([]);
  const [selectedData, setSelectedData] = useState();

  // Table Header
  const heading = [
    {
      Header: "No",
      id: "no",
      maxWidth: 50,
      filterable: false,
      Cell: (row) => {
        return <> {row.index + 1} </>;
      },
    },
    {
      Header: "User Card",
      accessor: "user_id.card_number",
    },
    {
      Header: "Business Name",
      accessor: "business_partner_id.business_name",
    },
    {
      Header: "Time Requested",
      id: "subscription_start",
      Cell: (row) => (
        <span>
          {<Moment format="ll hh:mm A" date={row.original.created_at} />}
        </span>
      ),
    },
    {
      Header: "Action",
      width: 250,
      maxWidth: 250,
      id: "_id",
      Cell: (row) => (
        <div className="featured-button-holder">
          <Button onClick={() => onSelectedApproval(row)}>Approve</Button>
        </div>
      ),
    },
  ];

  // Card info
  const cardInfo = {
    title: "Confirm Bypass?",
    customClassName: "no-margin no-shadow",
  };

  const getOTPData = (pageNumber = 1, search = "") => {
    setLoading(true);

    const req = { pageNumber, search };
    axios
      .get(`${url_path}/api/otp`, req)
      .then((response) => {
        setDataVal(response.data[0].data);
        setPagerData(response.data.paginate);
        setLoading(false);
        console.log(response.data[0].data);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  useEffect(() => {
    getOTPData(1, "");
  }, []);

  function onSelectedApproval(e){
    setSelectedData(e.original._id)
    handleShowModal();
  };

  const handleShowModal = (e) => {
    setModalState(true);
  };

  const handleHideModal = () => {
    setModalState(false);
  };

  const handleOnSubmit = (e) => {
    e.preventDefault();
    setIsButtonLoading(true);
    axios
      .post(`${url_path}/api/otp`, 
      {
        "id": selectedData,
        "action": "grant"
      })
      .then((response) => {
        setIsButtonLoading(false);
        response.status == 200 ? successNotif("Success!", "OTP Bypass is successful!") : errorNotifIcon("Error!", "OTP Bypass failed!");
        handleHideModal();
      })
      .catch(function (error) {
        console.log(error);
    });
  }

  return (
    <main className="manage-pages">
      <div className="container custom-container">
        <Breadcrumbs
          size="text-large"
          placeholder="OTP Requests"
          targetLink="/admin-dashboard"
          customClassName="m-3"
          propsFunction={handleShowModal}
        />
        <div className="section-table">
          {/* <div className="container-fluid"> */}
          {loading ? (
            <div className="lds-ellipsis">
              <div className="dots"></div>
              <div className="dots"></div>
              <div className="dots"></div>
              <div className="dots"></div>
            </div>
          ) : (
            <>
              <Table
                tableHeader={heading}
                tableData={dataVal}
                paginate={pagerData}
                pageFunction={getOTPData}
              />
            </>
          )}
          {/* </div> */}
        </div>
        <Modal
          show={modalState}
          onHide={handleHideModal}
          className="modal-dark- opacity"
        >
          <Card
            cardDetails={cardInfo}
            form={
              <form className="form-wrapper">
                  <>
                    <div className="form-btn-wrapper form-btn-dual-wrapper">
                      <input
                        type="button"
                        className="btn-d btn-gray"
                        value="No"
                        onClick={handleHideModal}
                      ></input>
                      {isButtonLoading ? (
                        <button class="btn-d-loading btn-blue-loading disabled">
                          <span class="load open"></span> Processing...
                        </button>
                      ) : (
                        <input
                          type="button"
                          className="btn-d btn-blue"
                          value="Yes"
                          onClick={handleOnSubmit}
                        />
                      )}
                    </div>
                  </>
              </form>
            }
          />
        </Modal>
      </div>
    </main>
  );
};

export default OTPRequests;
