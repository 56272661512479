import axios from "axios";
import "moment-timezone";
import React, { useEffect, useState } from "react";
import "react-accessible-accordion/dist/fancy-example.css";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
// import { ReactComponent as IconCalendar } from "../../assets/images/icon-calendar.svg";
import { FaCalendar } from "react-icons/fa";
import Moment from "react-moment";
import Breadcrumbs from "../../components/Breadcrumbs";
import Filter from "../../components/Filter";
import Table from "../../components/Table";
import { url_path } from "../../constant";
import { getCookie } from "../../lib/helpers/cookies";
import { authenticateBusinessPage } from "../../lib/helpers/validation";
import useForm from "../../lib/hooks/useForm";

function InfinityTransactions(props) {


  const [data, setData] = useState([]);
  const [userData, setUserData] = React.useState({});
  const [pagerData, setPagerData] = useState([]);
  const [filterStartDate, setFilterStartDate] = useState();
  const [filterEndDate, setFilterEndDate] = useState();
  const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
      authenticateBusinessPage(props);
      getData(1,false);
    }, []);

  const getData = async (pageNumber, filter) => {
    // get the user detail
    const token = getCookie("token");
    axios
      .get(`${url_path}/api/auth`, { headers: { "x-auth-token": token } })
      .then((res) => {
        const user_info = res.data;
        if (user_info.status === "success") {
          setUserData(user_info.user);
          // getTransaction(1, false, "", 0, 0, user_info.user);
          const req = {
            filter,
            start_date: filterStartDate,
            end_date: filterEndDate,
            by: "date",
            name:"",
            startAmount:0,
            endAmount:0,
            pageNumber,
            partner_staff_id: user_info.user._id,
          };
          axios
            .post(
              `${url_path}/api/transactions/infinity_transaction/${user_info.user.business_partner_id}`,
              req
            )
            .then((res) => {
              const data = res.data;
              // return console.log(transactions.pageOfItems)
              // this.setState({loading: false});
              // let table = [];
              console.log(data.transaction);
              setData(data.transaction);
              setPagerData(data.paginate);
              setIsLoading(false);
              // console.warn(this.tableData)
            });
        }
      });
  };
  // console.log(userData);

  // function getTransaction(
  //   pageNumber,
  //   filter,
  //   name,
  //   startAmount,
  //   endAmount,
  //   userInfo
  // ) {
  //   const req = {
  //     filter,
  //     start_date: filterStartDate,
  //     end_date: filterEndDate,
  //     by: "date",
  //     name,
  //     startAmount,
  //     endAmount,
  //     pageNumber,
  //     partner_staff_id: userInfo._id,
  //   };
  //   axios
  //     .post(
  //       `${url_path}/api/transactions/infinity_transaction/${userInfo.business_partner_id}`,
  //       req
  //     )
  //     .then((res) => {
  //       const data = res.data;
  //       // return console.log(transactions.pageOfItems)
  //       // this.setState({loading: false});
  //       // let table = [];
  //       console.log(data.transaction);
  //       setData(data.transaction);
  //       setPagerData(data.paginate);
  //       setIsLoading(false);
  //       // console.warn(this.tableData)
  //     });
  // }

  const filterContent = {
    title: "Filter",
  };

  const stateSchema = {
    name: { value: "", error: "" },
    startAmt: { value: "", error: "" },
    endAmt: { value: "", error: "" },
  };

  const stateValidatorSchema = {
    name: {
      required: false,
    },
    startAmt: {
      required: false,
    },
    endAmt: {
      required: false,
    },
  };

  const {
    // state,
    values,
    // errors, 
    handleOnChange,
    // disable,
  } = useForm(stateSchema, stateValidatorSchema);
  const { name, startAmt, endAmt } = values;

  const handleSubmit = (event) => {
    event.preventDefault();
    console.log(filterStartDate);
    console.log(filterEndDate);
    const { name, startAmt, endAmt } = values;
    getData(1, true, name, startAmt, endAmt, userData);
  };

  const handleDateChangeRaw = (e) => {
    e.preventDefault();
  };

  const heading = [
    {
      Header: "Name",
      accessor: "name",
      Cell: (row) =>
        row.original.user_id.user_first_name +
        " " +
        row.original.user_id.user_last_name,
      // width: 500
    },
    {
      Header: "Card Number",
      accessor: "cardNumber",
      // className: 'table-point-plus',
      Cell: (row) => row.original.user_id.card_number,
    },
    {
      Header: "Date",
      accessor: "date",
      // className: 'table-amount-peso',
      Cell: (row) => <Moment format="ll" date={row.original.created_at} />,
    },
    {
      Header: "Amount in Peso(s)",
      accessor: "amount",
      Cell: (row) =>
        row.original.description === "Managed Purchase"
          ? "-" + row.original.statement_amount
          : row.original.description === "Managed Redeem"
          ? "+" + row.original.redeemed_amount
          : row.original.amount,
    },
    {
      Header: "Points",
      accessor: "points",
      Cell: (row) =>
        row.original.description === "Redeem"
          ? row.original.redeemed_amount
          : row.original.description === "Managed Redeem"
          ? row.original.redeemed_amount
          : row.original.points,
    },
    {
      Header: "Bonus",
      accessor: "bonus",
      Cell: (row) => row.original.bonus,
    },
    {
      Header: "Total points",
      accessor: "total",
      Cell: (row) =>
        row.original.description === "Redeem"
          ? row.original.redeemed_amount
          : row.original.description === "Managed Redeem"
          ? row.original.redeemed_amount
          : row.original.total_points,
    },
  ];

  const validateNumberInput = (event) => {
    const { key } = event;

    const validKeys = ["Backspace", "Delete", "ArrowLeft", "ArrowRight"];

    if (!key.match(/\d/) && !validKeys.includes(key)) {
      event.preventDefault();
    }
  };
  return (
    <main className="transaction-statement">
      <div className="container custom-container">
        <div className="content-wrapper">
          <Breadcrumbs
            size="text-large"
            placeholder={"Infinity Transactions"}
            targetLink="/partner-dashboard"
            hasButton="false"
            customClassName="m-3"
          />
          <Filter
            filterDetails={filterContent}
            content={
              <div className="section-filter">
                <form
                  className="form-wrapper transactions"
                  onSubmit={handleSubmit}
                >
                  <div className="filter-wrapper">
                    <div className="group-wrapper">
                      <p className="filter-heading">Filter by date</p>
                      <div className="form-group">
                        <div className="form-field calendar">
                          <p className="input-label">From</p>
                          <label htmlFor="startDate">
                            <p className="label-calendar">
                              <FaCalendar />
                            </p>
                          </label>
                          <DatePicker
                            selected={filterStartDate}
                            onChange={(date) => setFilterStartDate(date)}
                            selectsEnd
                            dateFormat="MMM dd, yyyy"
                            endDate={filterStartDate}
                            minDate={filterStartDate}
                            onChangeRaw={handleDateChangeRaw}
                            className="form-input date"
                            name="filterStartDate"
                            placeholderText="Select a date between today and 5 days in the future"
                            // placeholderText={date}
                            autoComplete="off"
                            id="filterStartDate"
                            // required
                          />
                        </div>
                        <div className="form-field calendar">
                          <p className="input-label">To</p>
                          <label htmlFor="endDate">
                            <p className="label-calendar">
                              <FaCalendar />
                            </p>
                          </label>
                          <DatePicker
                            selected={filterEndDate}
                            onChange={(date) => setFilterEndDate(date)}
                            selectsEnd
                            dateFormat="MMM dd, yyyy"
                            endDate={filterEndDate}
                            minDate={filterStartDate}
                            onChangeRaw={handleDateChangeRaw}
                            className="form-input date"
                            name="filterEndDate"
                            // placeholderText={date}
                            autoComplete="off"
                            id="filterEndDate"
                            // required
                          />
                        </div>
                      </div>
                    </div>
                    <div className="group-wrapper">
                      <p className="filter-heading">Filter by name</p>
                      <div className="form-field">
                        <input
                          id="name"
                          className="form-input single-input"
                          type="text"
                          name="name"
                          value={name}
                          onChange={handleOnChange}
                          // required
                        />
                      </div>
                    </div>
                    <div className="group-wrapper">
                      <p className="filter-heading">Filter by amount</p>
                      <div className="form-col">
                        <div className="form-field">
                          <input
                            type="number"
                            name="startAmt"
                            className="form-input"
                            id="startAmt"
                            value={startAmt}
                            onChange={handleOnChange}
                            onKeyDown={validateNumberInput}
                          />
                          {/* <label htmlFor='startAmt'>House No.</label> */}
                        </div>
                        <div className="form-field">
                          <input
                            type="number"
                            name="endAmt"
                            className="form-input"
                            id="endAmt"
                            placeholderText="0.00"
                            value={endAmt}
                            onChange={handleOnChange}
                          />
                          {/* <label htmlFor='endAmt'>House No.</label> */}
                        </div>
                      </div>
                    </div>

                    <input
                      className="btn-blue form-deal-submit"
                      type="submit"
                      value="Apply"
                    />
                  </div>
                </form>
              </div>
            }
          />
          <div className="section-table">
            {isLoading ? (
              <div className="lds-ellipsis">
                <div className="dots"></div>
                <div className="dots"></div>
                <div className="dots"></div>
                <div className="dots"></div>
              </div>
            ) : (
              <Table
                tableHeader={heading}
                tableData={data}
                paginate={pagerData}
                pageFunction={getData}
              />
            )}
          </div>
        </div>
      </div>
    </main>
  );
}

export default InfinityTransactions;
