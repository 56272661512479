import React, { useEffect, useState } from "react";
import { url_path } from "../../constant";
import axios from "axios";
import Moment from "react-moment";

import Table from "../../components/Table";

const AccountTransactions = ({ user_id }) => {
  // States
  const [tableData, setTableData] = useState([]);
  const [loader, setLoader] = useState(false);
  const [pagerData, setPagerData] = useState({});

  const tableHeader = [
    {
      Header: "Date",
      width: 125,
      accessor: "created_at",
      Cell: (data) => (
        <Moment date={data.original[0]} format="MMMM DD YYYY HH:mm:ss" />
      ),
    },
    {
      Header: "Description",
      id: "description",
      width: 250,
      accessor: (data) => data[1],
    },
    {
      Header: "Purchase Amount in Peso(s)",
      id: "total_amount",
      width: 125,
      accessor: (data) => data[2],
    },
    {
      Header: "Points",
      id: "points",
      width: 125,
      accessor: (data) => data[3],
    },
    {
      Header: "Bonus Points",
      id: "bonus_points",
      width: 125,
      accessor: (data) => data[4],
    },
    {
      Header: "Total Points",
      id: "total_points",
      width: 125,
      accessor: (data) => data[5],
    },
    {
      Header: "Balance",
      id: "balance",
      width: 125,
      accessor: (data) => data[6],
    },
  ];

  useEffect(() => {
    getMemberTransaction();
  }, []);

  const getMemberTransaction = (pageNumber = 1) => {
    setLoader(false);

    const req = {
      start_date: new Date("01/01/2020"),
      end_date: new Date("12/12/2025"),
      by: "date name and amount",
      pageNumber,
      filter: false,
    };

    axios
      .post(`${url_path}/api/transactions/member/${user_id}`, req)
      .then((res) => {
        let data = [];
        const transData = res.data.transaction;
        console.log(transData);
        transData.map((item) => {
          if (item.description === "Received Points from Referral") {
            data.push([
              item.created_at,
              item.description,
              0,
              "+" + item.total_points_earned,
              0,
              item.total_points_earned,
              item.balance,
            ]);
          } else if (item.description === "Redeem") {
            data.push([
              item.created_at,
              item.description +
                " from " +
                item.business_partner_id.business_name,
              0,
              item.amount,
              item.bonus,
              item.statement_amount,
              item.balance,
            ]);
          } else if (item.description === "Received from Transfer Points") {
            data.push([
              item.created_at,
              item.description,
              item.amount,
              item.points,
              item.bonus,
              item.total_points,
              item.balance,
            ]);
          } else if (item.description === "Payment") {
            data.push([
              item.created_at,
              item.description,
              item.total_points_spent,
              0,
              0,
              0,
              0,
            ]);
          } else if (
            item.description === "Transfer Points" ||
            item.description === "Encash Points"
          ) {
            data.push([
              item.created_at,
              item.description,
              0,
              "-" +
                (parseInt(item.total_points) + parseInt(item.service_charge)),
              0,
              parseInt(item.total_points) + parseInt(item.service_charge),
              item.balance,
            ]);
          } else if (item.description === "Tier Bonus") {
            data.push([
              item.created_at,
              item.description,
              0,
              "+" + item.total_points_earned,
              0,
              0,
              item.balance,
            ]);
          } else if (item.description === "Deducted Points by Admin") {
            data.push([
              item.created_at,
              item.description,
              item.amount,
              "-" + item.points,
              item.bonus,
              item.total_points,
              item.balance,
            ]);
          } else if (item.description === "Purchase") {
            data.push([
              item.created_at,
              item.description +
                " from " +
                item.business_partner_id.business_name,
              item.statement_amount,
              "+" + item.points,
              item.bonus,
              item.total_points,
              item.balance,
            ]);
          } else if (item.description === "Managed Purchase") {
            data.push([
              item.created_at,
              item.description,
              item.statement_amount,
              "-" + item.points,
              item.bonus,
              item.total_points,
              item.balance,
            ]);
          } else if (item.description === "Managed Redeem") {
            data.push([
              item.created_at,
              item.description,
              0,
              "+" + item.total_points_spent,
              item.bonus,
              item.statement_amount,
              item.balance,
            ]);
          } else {
            data.push([
              item.created_at,
              item.description,
              item.statement_amount,
              item.points,
              item.bonus,
              item.total_points,
              item.balance,
            ]);
          }
        });

        setTableData(data);
        setPagerData(res.data.paginate);
        console.log(res.data.paginate);
        setLoader(true);
      });
  };

  return (
    <div className="member-transaction tab-member-account">
      <p className="heading">Account Transactions</p>
      {loader ? (
        <>
          <div className="section-table">
            {/* <Table tableHeader={tableHeader} tableData={tableData} /> */}
            <Table
              tableHeader={tableHeader}
              tableData={tableData}
              paginate={pagerData}
              pageFunction={getMemberTransaction}
            />
          </div>
          {/* <ul className="custom-pagination">
            <li
              className={classNames({
                active: pagerData.currentPage === pagerData.firstPage
              })}
            >
              {pagerData.prevPage ? (
                <Link
                  to="#"
                  onClick={() => getMemberTransaction(pagerData.firstPage)}
                >
                  First
                </Link>
              ) : (
                "First"
              )}
            </li>
            <li className={classNames({ active: !pagerData.prevPage })}>
              {pagerData.prevPage ? (
                <Link
                  to="#"
                  onClick={() => getMemberTransaction(pagerData.prevPage)}
                >
                  Previous
                </Link>
              ) : (
                "Previous"
              )}
            </li>

            {pagerData.midPages.map((page, index) => {
              return pagerData.currentPage === page ? (
                <li className="active" key={index}>
                  {page}
                </li>
              ) : (
                <li key={index}>
                  <Link to="#" onClick={() => getMemberTransaction(page)}>
                    {page}
                  </Link>
                </li>
              );
            })}

            <li className={classNames({ active: !pagerData.nextPage })}>
              {pagerData.nextPage ? (
                <Link
                  to="#"
                  onClick={() => getMemberTransaction(pagerData.nextPage)}
                >
                  Next
                </Link>
              ) : (
                "Next"
              )}
            </li>
            <li
              className={classNames({
                active:
                  pagerData.currentPage === pagerData.lastPage ||
                  pagerData.totalPages <= 1
              })}
            >
              {pagerData.nextPage ? (
                <Link
                  to="#"
                  onClick={() => getMemberTransaction(pagerData.lastPage)}
                >
                  Last
                </Link>
              ) : (
                "Last"
              )}
            </li>
          </ul> */}
        </>
      ) : (
        <div className="lds-ellipsis">
          <div className="dots"></div>
          <div className="dots"></div>
          <div className="dots"></div>
          <div className="dots"></div>
        </div>
      )}
    </div>
  );
};

export default AccountTransactions;
