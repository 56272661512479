import React, { useState, useEffect } from "react";
import Breadcrumbs from "../../components/Breadcrumbs";
import { authenticateMemberPage } from "../../lib/helpers/validation";
import { url_path } from "../../constant";
import axios from "axios";
import { getCookie } from "../../lib/helpers/cookies";
import Modal from "react-bootstrap/Modal";
import Card from "../../components/Card";
import Sidebar from "../../components/Sidebar";
import { MdClose } from "react-icons/md";
import { successNotif, errorNotifIcon } from "../../lib/helpers/notifications";
import "react-phone-input-2/lib/bootstrap.css";
import useForm from "../../lib/hooks/useForm.js";

import Sweetalert2 from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
const Swal = withReactContent(Sweetalert2);

function Request({ history }) {
  useEffect(() => {
    authenticateMemberPage(history);
    getContactDetails();
  }, []);

  const [showModal, setShowModal] = useState(false);
  const [isHome, setIsHome] = useState(false);
  const [isWork, setIsWork] = useState(false);
  const [isDefault, setIsDefault] = useState(true);
  const [isAlternative, setIsAlternative] = useState(false);
  const [contactDetails, setContactDetails] = useState({});
  const [userID, setUserID] = useState({});

  const stateSchema = {
    house_number: { value: "", error: "" },
    street: { value: "", error: "" },
    company_name: { value: "", error: "" },
    city: { value: "", error: "" },
    province: { value: "", error: "" },
    country: { value: "", error: "" },
    zipcode: { value: "", error: "" },
  };

  const stateValidatorSchema = {
    house_number: {
      required: true,
    },
    street: {
      required: false,
    },
    company_name: {
      required: false,
    },
    city: {
      required: true,
    },
    province: {
      required: false,
    },
    country: {
      required: false,
    },
    zipcode: {
      required: false,
    },
  };

  const { values, handleOnChange } = useForm(
    stateSchema,
    stateValidatorSchema,
    onSubmitForm
  );

  const {
    house_number,
    street,
    company_name,
    city,
    province,
    country,
    zipcode,
  } = values;

  const cardDetails = {
    title: "Request Infinity Privilege Plastic Card",
    customClassName: "",
  };
  const modalCardDetails = {
    title: "Please Confirm Address",
    customClassName: "no-margin no-shadow",
  };

  const handleShowModal = () => {
    // setEditDetails(contactDetails)
    setShowModal(true);
  };

  const handleHideModal = () => {
    setShowModal(false);
  };

  const getContactDetails = async () => {
    // setLoading(true)
    const token = getCookie("token");

    const { data: res_data } = await axios.get(`${url_path}/api/auth`, {
      headers: { "x-auth-token": token },
    });
    const { _id: user_id } = res_data.user;

    await axios
      .get(`${url_path}/api/member_additional_contact_details/${user_id}`)
      .then((res) => {
        const contactDetails = res.data;
        setContactDetails(contactDetails);
        setIsHome(contactDetails.isHome);
        setIsWork(contactDetails.isWork);
        setUserID(user_id);
        console.log(contactDetails);
      });

    // setIsHome(contact_details.isHome)
  };

  const onConfirm = () => {
    Swal.fire({
      title: "Are you sure?",
      text: "0 points will be deducted from your account",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Ok",
    }).then((result) => {
      if (result.value) {
        onSubmitForm();
        return true;
       
      } else {
        return false;
      }
    });

  };

  function onSubmitForm(state) {
    // return console.log(contactDetails);
    const req = {
      user_id: userID,
      email: contactDetails.user_email,
      mainPhone: contactDetails.user_phone,
      additionalPhone: contactDetails.additional_mobile_number,
      isHome: contactDetails.isHome,
      // isWork: this.state.isWork,
      companyName: contactDetails.company_name,
      houseNo: contactDetails.house_number,
      street: contactDetails.street,
      province: contactDetails.province,
      city: contactDetails.city,
      country: contactDetails.country,
      zip: contactDetails.zipcode,

      // other delivery address
      isDefault: isDefault,
      isAddAlternative: isAlternative,
      otherCompanyName: company_name,
      otherHouseNo: house_number,
      otherStreet: street,
      otherProvince: province,
      otherCity: city,
      otherCountry: country,
      otherZip: zipcode,
    };
    
    axios.post(`${url_path}/api/plastic_card_request/add`, req).then((res) => {
      const plasticCardData = res.data;
      if (plasticCardData.data.status === "success") {
        // then send an sms
        const reqBody = { to_email: contactDetails.user_email };
        axios
          .post(`${url_path}/api/send_email/plastic_card_request`, reqBody)
          .then((res) => {
            const plasticCardData = res.data;
            console.log(plasticCardData);
          });
        successNotif("Successful", "Request Sent");
        handleHideModal();
      } else {
        errorNotifIcon("Sorry!", plasticCardData.data.msg);
        // Alert.alert(
        //   'Sorry!',
        //   plasticCardData.data.msg,
        //   [{text: 'OK', onPress: () => console.log('Cancel Pressed')}],
        //   {cancelable: false},
        // );
      }
      console.log(plasticCardData);
      // if (plasticCardData.data.status == 'success') {
      //   this.setState({
      //     isRequested: true
      //   });
      // }
    });
  }
  return (
    <main className="member-data member-dashboard">
      <Sidebar history={history} />
      <div className="content-wrapper">
        <Breadcrumbs
          placeholder="Back"
          size="text-large"
          targetLink="/member-dashboard"
        />
        <Card
          cardDetails={cardDetails}
          form={
            <div className="container">
              {/* <div className="content-wrapper"> */}
              <p className="text-holder">
                Members are getting a virtual card which they can use for
                earning or redeeming points. If you would like to have a plastic
                card for your use of for your collections, you can apply today
                for a cost of 0 points.
              </p>
              <div className="btn-holder">
                <button className="btn-blue" onClick={handleShowModal}>
                  Apply
                </button>
              </div>
              {/* </div> */}
            </div>
          }
        />
      </div>
      <Modal
        show={showModal}
        onHide={handleHideModal}
        className="modal-dark- opacity edit-modal"
      >
        <Card
          cardDetails={modalCardDetails}
          form={
            <>
              <div className="heading-wrapper">
                <p className="heading">Please Confirm Address</p>
                <p className="heading-close" onClick={handleHideModal}>
                  <MdClose />{" "}
                </p>
              </div>
              <form className="form-wrapper">
                <p className="text-bold">Card delivery address</p>
                
                <div className="radio-wrapper">
                  <div className="form-field">
                    <label className="radio">
                      <input
                        type="radio"
                        checked={isAlternative}
                        onClick={(e) => {
                          setIsDefault(!isDefault);
                          setIsAlternative(!isAlternative);
                        }}
                      />
                      <span className="checkmark"></span>
                    </label>
                    <p className="radio-text">Use alternative address?</p>
                  </div>
                </div>
                {isAlternative ? (
                  <>
                  
                    <div className="form-col">
                      <div className="form-field">
                        <input
                          type="text"
                          name="house_number"
                          className="form-input"
                          id="house_number"
                          value={house_number}
                          onChange={handleOnChange}
                        />
                        <label htmlFor="house_number">House No.</label>
                      </div>
                      <div className="form-field">
                        <input
                          type="text"
                          name="street"
                          className="form-input"
                          id="street"
                          value={street}
                          onChange={handleOnChange}
                        />
                        <label htmlFor="street">Street</label>
                      </div>
                    </div>
                   

                    <div className="form-field">
                      <input
                        type="text"
                        name="city"
                        className="form-input"
                        id="city"
                        value={city}
                        onChange={handleOnChange}
                      />
                      <label htmlFor="city">City</label>
                    </div>
                    <div className="form-field">
                      <input
                        type="text"
                        name="province"
                        className="form-input"
                        id="province"
                        value={province}
                        onChange={handleOnChange}
                      />
                      <label htmlFor="province">Province</label>
                    </div>
                    <div className="form-field">
                      <input
                        type="text"
                        name="country"
                        className="form-input"
                        id="country"
                        value={country}
                        onChange={handleOnChange}
                      />
                      <label htmlFor="country">Country</label>
                    </div>
                    <div className="form-field">
                      <input
                        type="number"
                        name="zipCode"
                        className="form-input"
                        id="zipCode"
                        value={zipcode}
                        onChange={handleOnChange}
                      />
                      <label htmlFor="zipCode">Zip Code</label>
                    </div>
                  </>
                ) : (
                  <>
                    {isHome ? (
                      <>
                        <div className="form-col">
                          <div className="form-field">
                            <input
                              type="number"
                              name="houseNo"
                              className="form-input"
                              id="houseNo"
                              value={contactDetails.house_number}
                              disabled
                            />
                            <label htmlFor="houseNo">House No.</label>
                          </div>
                          <div className="form-field">
                            <input
                              type="text"
                              name="street"
                              className="form-input"
                              id="street"
                              value={contactDetails.street}
                              disabled
                            />
                            <label htmlFor="street">Street</label>
                          </div>
                        </div>
                      </>
                    ) : (
                      <>
                        <div className="form-field">
                          <input
                            type="text"
                            name="companyName"
                            className="form-input"
                            id="companyName"
                            value={contactDetails.company_name}
                            disabled
                          />
                          <label htmlFor="companyName">Company Name</label>
                        </div>
                        <div className="form-col">
                          <div className="form-field">
                            <input
                              type="number"
                              name="houseNo"
                              className="form-input"
                              id="houseNo"
                              value={contactDetails.house_number}
                              disabled
                            />
                            <label htmlFor="houseNo">Bldg No.</label>
                          </div>
                          <div className="form-field">
                            <input
                              type="text"
                              name="street"
                              className="form-input"
                              id="street"
                              value={contactDetails.street}
                              disabled
                            />
                            <label htmlFor="street">Street</label>
                          </div>
                        </div>
                      </>
                    )}
                    <div className="form-field">
                      <input
                        type="text"
                        name="city"
                        className="form-input"
                        id="city"
                        value={contactDetails.city}
                        disabled
                      />
                      <label htmlFor="city">City</label>
                    </div>
                    <div className="form-field">
                      <input
                        type="text"
                        name="province"
                        className="form-input"
                        id="province"
                        value={contactDetails.province}
                        disabled
                      />
                      <label htmlFor="province">Province</label>
                    </div>
                    <div className="form-field">
                      <input
                        type="text"
                        name="country"
                        className="form-input"
                        id="country"
                        value={contactDetails.country}
                        disabled
                      />
                      <label htmlFor="country">Country</label>
                    </div>
                    <div className="form-field">
                      <input
                        type="number"
                        name="zipCode"
                        className="form-input"
                        id="zipCode"
                        value={contactDetails.zipcode}
                        disabled
                      />
                      <label htmlFor="zipCode">Zip Code</label>
                    </div>
                  </>
                )}
              </form>
              <div className="btn-holder">
                <button className="btn-blue" onClick={() => onConfirm()}>
                  Save
                </button>
              </div>
            </>
          }
        />
      </Modal>
    </main>
  );
}

export default Request;
