import React from 'react'
import { Link } from 'react-router-dom'
import Breadcrumbs from '../components/Breadcrumbs'
import Card from "../components/Card"
import '../stylesheets/main.scss'


function ForgotSuccess(props) {
  // const { back, button } = props;

  const cardInfo = { 
    title: 'Forgot Password',
    // closeLink: '/forgot-password',
    // hasBack: true,
    hasMessage: true,
    message: 'We\'ve sent you an email or text to reset your password. We\'ll meet you back here.',
    customClassName: 'has-back-button'
  }
  
  return (
    <main className="login">
      <div className="card-body">
        <Card cardDetails={cardInfo} form={
          <span>
            <Breadcrumbs 
              size = 'text-large'
              // placeholder = 'Back'
              targetLink = '/forgot-password'
            />
            {/* {button} */}
            <Link to="/"><button className="btn-blue">Back to login</button></Link>
          </span>
        } />
      </div>
    </main>
  )
}

export default ForgotSuccess