import React, { useEffect, useState } from "react";
import { url_path } from "../../constant";
import Breadcrumbs from "../../components/Breadcrumbs";
import axios from "axios";
import { getCookie } from "../../lib/helpers/cookies";
import Card from "../../components/Card";
import Sidebar from "../../components/Sidebar";
import { successNotif, errorNotifIcon } from "../../lib/helpers/notifications";
import { authenticateMemberPage } from "../../lib/helpers/validation";
import "react-phone-input-2/lib/bootstrap.css";
import useForm from "../../lib/hooks/useForm.js";

const ContactDetails = ({ history }) => {
  useEffect(() => {
    authenticateMemberPage(history);
  }, []);
  // States
  // const [userData, setUserData] = useState({});
  const [userID, setUserID] = useState();
  // const [loader, setLoader] = useState(false);
  const [readOnly, setReadOnly] = useState(true);
  // const [inputType, setInputType] = useState(true);

  const iconShow = require("../../assets/images/icon-show.png");
  const iconHide = require("../../assets/images/icon-hide.png");

  /*
   * Regex Validations to Inputs
   */

  // var nameRegex = /^[a-zA-Z ]+$/;
  // var numRegex = /^[0-9]*$/;

  /*
   * Initialize input state schema
   */

  const stateSchema = {
    current_password: { value: "", error: "" },
    password: { value: "", error: "" },
    confirm_password: { value: "", error: "" },
  };

  /*
   * Create a schema for all inputs
   */

  const stateValidatorSchema = {
    current_password: {
      required: true,
      // validator: {
      //   func: value => nameRegex.test(value),
      //   error: "Invalid Name"
      // }
    },
    password: {
      required: true,
      // validator: {
      //   func: value => nameRegex.test(value),
      //   error: "Invalid Name"
      // }
    },
    confirm_password: {
      required: true,
      validator: {
        func: (value) => value === password,
        error: "Password Do not match",
      },
    },
  };

  function clearFields() {
    values.current_password = "";
    values.password = "";
    values.confirm_password = "";
  }

  const { values, errors, handleOnChange, handleOnSubmit, disable } = useForm(
    stateSchema,
    stateValidatorSchema,
    onSubmitForm,
    clearFields
  );

  const { current_password, password, confirm_password } = values;

  const [inputPassword, setInputPassword] = useState(true);

  function togglePassword() {
    inputPassword ? setInputPassword(false) : setInputPassword(true);
  }

  const [inputConfirmPassword, setInputConfirmPassword] = useState(true);

  function toggleConfirmPassword() {
    inputConfirmPassword
      ? setInputConfirmPassword(false)
      : setInputConfirmPassword(true);
  }
  const [inputCurrentPassword, setInputCurrentPassword] = useState(true);

  function toggleCurrentPassword() {
    inputCurrentPassword
      ? setInputCurrentPassword(false)
      : setInputCurrentPassword(true);
  }

  function onSubmitForm(state) {
    const { password } = state;
    const request = {
      device_type: "member_web",
      current_password,
      password,
    };
    // verify first if the two password is match
    if (password === confirm_password) {
      axios
        .patch(`${url_path}/api/member/reset_password/${userID}`, request)
        .then((res) => {
          console.log(res.data);
          if (res.data.data.status === "success") {
            const title = "Awesome!";
            const msg = res.data.data.msg;
            successNotif(title, msg);
            clearFields();
            setReadOnly(true);
            history.push("my-infinity-account");
          } else {
            const title = "Oops!";
            const msg = res.data.data.msg;
            errorNotifIcon(title, msg);
            //   clearFields()
            setReadOnly(true);
          }
        });
    } else {
      const title = "Oops!";
      const msg = "Password Do not Match";
      errorNotifIcon(title, msg);
      //   clearFields()
      setReadOnly(true);
    }
  }

  const cardDetails = {
    title: "Change Password",
    customClassName: "",
  };

  // Functions
  const getData = async () => {
    // setLoading(true)
    const token = getCookie("token");
    const { data: res_data } = await axios.get(`${url_path}/api/auth`, {
      headers: { "x-auth-token": token },
    });
    const { _id: user_id } = res_data.user;
    setUserID(user_id);
    // setLoading(false)
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <main className="member-data member-dashboard">
      <Sidebar history={history} />
      <div className="content-wrapper">
        <Breadcrumbs
          size="text-large"
          placeholder="Back"
          targetLink="/my-infinity-account"
          hasButton="false"
        />
        {false ? (
          <div className="lds-ellipsis">
            <div className="dots"></div>
            <div className="dots"></div>
            <div className="dots"></div>
            <div className="dots"></div>
          </div>
        ) : (
          <Card
            cardDetails={cardDetails}
            form={
              <form className="form-wrapper" onSubmit={handleOnSubmit}>
                <div className="form-field">
                  <input
                    type={inputCurrentPassword ? "password" : "text"}
                    name="current_password"
                    className="form-input"
                    id="current_password"
                    value={current_password}
                    onChange={handleOnChange}
                    // validations={[required, isEqual]}

                    required
                  />
                  <span
                    className="password__show"
                    onClick={toggleCurrentPassword}
                  >
                    <img
                      src={inputCurrentPassword ? iconHide : iconShow}
                      alt=""
                    />
                  </span>
                  <label htmlFor="current_password">Current Password</label>

                  {errors.current_password && (
                    <span className="form-error">
                      {errors.current_password}
                    </span>
                  )}
                </div>
                <div className="form-field">
                  <input
                    type={inputPassword ? "password" : "text"}
                    name="password"
                    className="form-input"
                    id="password"
                    value={password}
                    onChange={handleOnChange}
                    // validations={[required, isEqual]}

                    required
                  />
                  <span className="password__show" onClick={togglePassword}>
                    <img src={inputPassword ? iconHide : iconShow} alt="" />
                  </span>
                  <label htmlFor="password">Password</label>

                  {errors.password && (
                    <span className="form-error">{errors.password}</span>
                  )}
                </div>
                <div className="form-field">
                  <input
                    type={inputConfirmPassword ? "password" : "text"}
                    name="confirm_password"
                    className="form-input"
                    id="confirm_password"
                    value={confirm_password}
                    onChange={handleOnChange}
                    // validations={[required, isEqual]}
                    required
                  />
                  <span
                    className="password__show"
                    onClick={toggleConfirmPassword}
                  >
                    <img
                      src={inputConfirmPassword ? iconHide : iconShow}
                      alt=""
                    />
                  </span>
                  <label htmlFor="confirm_password">Confirm Password</label>

                  {errors.confirm_password && (
                    <span className="form-error">
                      {errors.confirm_password}
                    </span>
                  )}
                </div>
                <input
                  type="submit"
                  className="btn-blue right"
                  value="Reset"
                  disabled={disable}
                />
              </form>
            }
          />
        )}
      </div>
    </main>
  );
};

export default ContactDetails;
