import React, { useEffect, useState } from "react";
import Sidebar from "../../components/Sidebar";
import Breadcrumbs from "../../components/Breadcrumbs";
import { authenticateMemberPage } from "../../lib/helpers/validation";
const DealInfo = ({ history, location }) => {
  useEffect(() => {
    authenticateMemberPage(history);
  }, []);
  // States
  const [dealId, setDealId] = useState("");
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (location.state) {
      setDealId(location.state.deal_id);
      setLoading(false);
    } else {
      history.goBack();
    }
  }, []);

  return (
    <main className="member-deal-info member-dashboard">
      <Sidebar history={history} />
      <div className="content-wrapper">
        <Breadcrumbs
          placeholder="Back"
          size="text-large"
          targetLink="/member-dashboard"
        />
        {loading ? (
          <div className="lds-ellipsis">
            <div className="dots"></div>
            <div className="dots"></div>
            <div className="dots"></div>
            <div className="dots"></div>
          </div>
        ) : (
          <div className="">content</div>
        )}
      </div>
    </main>
  );
};

export default DealInfo;
