import React from 'react'
import { Link } from 'react-router-dom'

function Breadcrumbs({size, placeholder, targetLink, hasButton, buttonTitle, customClassName, propsFunction, hasSecondButton, secondPropsFunction, secondButtonTitle}) {
  return (
    <div className={`section-breadcrumbs ${customClassName}`}>
      {
        hasButton === 'true' ? 
        <div className="container">
          <div className="breadcrumbs-wrapper">
            <div className="breadcrumbs-link-holder">
              <Link to={targetLink} className={`breadcrumbs-link ${size}`}>
                <span className="back-btn"></span> {placeholder}
              </Link>
            </div>
            <div className="btn-holder">
              <div className="row">
                {
                  hasSecondButton === 'true' ?
                  <div className="col">
                    <button className="btn-blue" onClick={secondPropsFunction}>
                      <span style={{whiteSpace:"nowrap"}}>
                        {secondButtonTitle}
                      </span>
                      </button>  
                  </div>
                  : ""
                }

                <div className="col">
                  <button className="btn-blue" onClick={propsFunction}>{buttonTitle}</button>  
                </div>  
              </div> 
            </div>
          </div>
        </div>
        :
        <div className="container">
          <Link to={targetLink} className={`breadcrumbs-link ${size}`}>
            <span className="back-btn"></span> {placeholder}
          </Link>
        </div>
      }
      
    </div>
  )
}

export default Breadcrumbs
