import React, { useEffect, useState } from "react";
import { url_path } from "../../constant";
import Card from "../../components/Card";
import useForm from "../../lib/hooks/useForm";
import axios from "axios";

import Modal from "react-bootstrap/Modal";
import Table from "../../components/Table";
import ReactTooltip from "react-tooltip";
import { successNotif } from "../../lib/helpers/notifications.js";
import Sweetalert2 from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import PhoneInput from "react-phone-input-2";
import { ReactComponent as IconDelete } from "../../assets/images/icon-delete.svg";
import { ReactComponent as IconEdit } from "../../assets/images/icon-edit.svg";

const Swal = withReactContent(Sweetalert2);
const modalInfo = {
  title: "New Contact",
  customClassName: "no-margin no-shadow",
};

const ContactDetails = ({ data, user_id }) => {
  // States
  const [tableData, setTableData] = useState([]);
  const [loader, setLoader] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [editId, setEditId] = useState("");

  // Form Validations

  // Regular Expressions
  var nameRegex = /^[a-zA-Z ]+$/;
  var emailRegex = /\S+@\S+\.\S+/;
  // var numberRegex = /^(09|\+639|9)\d{9}$/;

  const stateSchema = {
    contact_person: { value: "", error: "" },
    email: { value: "", error: "" },
    contact_number: { value: "", error: "" },
    position: { value: "", error: "" },
  };

  const stateValidatorSchema = {
    contact_person: {
      required: true,
      validator: {
        func: (value) => nameRegex.test(value),
        error: "Invalid name format!",
      },
    },
    email: {
      required: true,
      validator: {
        func: (value) => emailRegex.test(value),
        error: "Invalid email format!",
      },
    },
    contact_number: {
      required: true,
      // validator: {
      //   func: value => numberRegex.test(value),
      //   error: "Invalid number format!"
      // }
    },
    position: {
      required: true,
    },
  };

  const onSubmitForm = (state) => {
    setLoader(false);

    const { contact_person, email, contact_number, position } = state;

    if (editId === "") {
      const req = {
        contact_person: contact_person,
        email: email,
        contact_number: contact_number,
        position: position,
      };

      axios
        .post(`${url_path}/api/business_contact/${user_id}`, req)
        .then((res) => {
          let title = "Well Done!";
          let msg = "New Contact has been added successfully.";
          successNotif(title, msg);
          getData();
          handleHideModal();
        })
        .catch((err) => console.log(err));
    } else {
      const req = {
        _id: editId,
        contact_person: contact_person,
        email: email,
        contact_number: contact_number,
        position: position,
      };

      axios
        .patch(`${url_path}/api/business_contact_list`, req)
        .then((res) => {
          let title = "Well Done!";
          let msg = "Contact has been updated successfully.";
          successNotif(title, msg);
          getData();
          handleHideModal();
          setEditId("");
        })
        .catch((err) => console.log(err));
    }
  };

  // Retrieve values, errors, handlers, etc... from useForm hooks
  const { values, errors, handleOnChange, handleOnSubmit, disable } = useForm(
    stateSchema,
    stateValidatorSchema,
    onSubmitForm
  );

  const { contact_person, email, contact_number, position } = values;

  const handleHideModal = () => {
    setShowModal(false);

    values.contact_person = "";
    values.email = "";
    values.contact_number = "";
    values.position = "";

    errors.contact_person = "";
    errors.email = "";
    errors.contact_number = "";
    errors.position = "";
    modalInfo.title = "";
  };

  const handleCreateModal = () => {
    setShowModal(true);

    modalInfo.title = "New Contact";
  };

  const handleEditModal = (index) => {
    setShowModal(true);

    modalInfo.title = "Edit Contact";

    setEditId(tableData[index]._id);

    values.contact_person = tableData[index].contact_person;
    values.email = tableData[index].email;
    values.contact_number = tableData[index].contact_number;
    values.position = tableData[index].position;
  };

  const handleDelete = (index) => {
    const deleteId = tableData[index]._id;

    Swal.fire({
      title: "Are you sure?",
      message: "This can't be undone!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#286df9",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((res) => {
      if (res.value) {
        setLoader(false);
        axios
          .delete(`${url_path}/api/business_contact_list/${deleteId}`)
          .then((res) => {
            let title = "Well Done!";
            let msg = "Contact has been deleted successfully.";
            successNotif(title, msg);
            getData();
            console.log(res);
          })
          .catch((err) => console.log(err));
      }
    });
  };

  // const handleInputChar = (evt) => {
  //   if (["-", "e"].includes(evt.key)) evt.preventDefault();
  // };

  const handlePhoneChange = (value) => {
    const event = {
      target: {
        name: "contact_number",
        value: value.replace(/ /g, ""),
      },
    };

    handleOnChange(event);
  };

  // Table Header
  const tableHeader = [
    {
      Header: "Contact Person",
      accessor: "contact_person",
      width: 250,
    },
    {
      Header: "Email",
      accessor: "email",
      width: 250,
      Cell: (row) => (
        <a href={"mailto:" + row.original.email}>{row.original.email}</a>
      ),
    },
    {
      Header: "Contact Number",
      accessor: "contact_number",
      width: 250,
    },
    {
      Header: "Position",
      accessor: "position",
      width: 250,
    },
    {
      Header: "Action",
      Cell: (row) => (
        <div className="button-wrapper">
          <div className="button-holder">
            <button
              className="btn-icon"
              onClick={() => handleEditModal(row.index)}
            >
              <span className="icon-holder">
                <IconEdit className="icon-actions" data-tip data-for="edit" />
              </span>
            </button>
            <ReactTooltip id="edit" type="warning" effect="solid">
              <span>Edit</span>
            </ReactTooltip>
          </div>
          <div className="button-holder">
            <button
              className="btn-icon"
              onClick={() => handleDelete(row.index)}
            >
              <span className="icon-holder">
                <IconDelete
                  className="icon-actions"
                  data-tip
                  data-for="delete"
                />
              </span>
            </button>
            <ReactTooltip id="delete" type="warning" effect="solid">
              <span>Delete</span>
            </ReactTooltip>
          </div>
        </div>
      ),
    },
  ];

  const getData = () => {
    axios
      .get(`${url_path}/api/business_contact/${user_id}`)
      .then((res) => {
        setTableData(res.data.contact_list);
        setLoader(true);
        // console.log(res.data);
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <div className="tab-contact-details">
      <p className="heading">Contact Details</p>
      {loader ? (
        <>
          <div className="section-table">
            <Table tableHeader={tableHeader} tableData={tableData}></Table>
          </div>

          <button className="btn-blue right" onClick={handleCreateModal}>
            New Contact
          </button>

          <Modal
            show={showModal}
            onHide={handleHideModal}
            className="modal-dark- opacity"
          >
            <Card
              cardDetails={modalInfo}
              form={
                <form className="form-wrapper" onSubmit={handleOnSubmit}>
                  <div className="form-field">
                    <input
                      id="contact_person"
                      className="form-input"
                      type="text"
                      name="contact_person"
                      value={contact_person}
                      onChange={handleOnChange}
                      required
                    />
                    <label htmlFor="contact_person">Contact Person</label>
                    {errors.contact_person && (
                      <span className="form-error">
                        {errors.contact_person}
                      </span>
                    )}
                  </div>
                  <div className="form-field">
                    <input
                      id="email"
                      className="form-input"
                      type="text"
                      name="email"
                      value={email}
                      onChange={handleOnChange}
                      required
                    />
                    <label htmlFor="email">Email</label>
                    {errors.email && (
                      <span className="form-error">{errors.email}</span>
                    )}
                  </div>
                  <div className="form-field">
                    {/* <input
                      id="contact_number"
                      className="form-input"
                      type="number"
                      name="contact_number"
                      value={contact_number}
                      onChange={handleOnChange}
                      onKeyDown={handleInputChar}
                      required
                    /> */}
                    <PhoneInput
                      inputClass={["form-input"]}
                      enableAreaCodes={true}
                      countryCodeEditable={false}
                      autoFormat={true}
                      country={"ph"}
                      value={contact_number}
                      enableSearch={true}
                      onChange={handlePhoneChange}
                      required
                    />
                    <label htmlFor="contact_number" className="label-fixed">
                      Contact Number
                    </label>
                    {errors.contact_number && (
                      <span className="form-error">
                        {errors.contact_number}
                      </span>
                    )}
                  </div>
                  <div className="form-field">
                    <input
                      id="position"
                      className="form-input"
                      type="text"
                      name="position"
                      value={position}
                      onChange={handleOnChange}
                      required
                    />
                    <label htmlFor="position">Position</label>
                    {errors.position && (
                      <span className="form-error">{errors.position}</span>
                    )}
                  </div>

                  <input
                    type="submit"
                    className="btn-blue full-width"
                    disabled={disable}
                    value="Submit"
                  />
                  <input
                    type="button"
                    className="btn-blue-outline full-width"
                    onClick={handleHideModal}
                    value="Cancel"
                  />
                </form>
              }
            ></Card>
          </Modal>
        </>
      ) : (
        <div className="lds-ellipsis">
          <div className="dots"></div>
          <div className="dots"></div>
          <div className="dots"></div>
          <div className="dots"></div>
        </div>
      )}
    </div>
  );
};

export default ContactDetails;
