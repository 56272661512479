import React, { useEffect, useState } from "react";
import {url_path} from '../../constant';
import axios from "axios";
import moment from 'moment'

import Table from "../../components/Table";

const Referrals = ({ referrence_no }) => {
  // States
  const [loading, setLoading] = useState(true);
  const [tableData, setTableData] = useState([]);
  const [pagerData, setPagerData] = useState({});

  const tableHeader = [
    {
      Header: "Date",
      Cell: row => moment(new Date(row.original.created_at)).format('ll'),
      width: 125
    },
    {
      Header: "Card Holder Name",
      Cell: row => 
        row.original.user_detail.user_first_name 
        + ' ' + 
        row.original.user_detail.user_last_name,
      width: 200
    },
    {
      Header: "Contact No.",
      Cell: row => row.original.user_detail.user_phone,
      width: 150
    },
    {
      Header: "Email",
      Cell: row => <a href={"mailto:" + row.original[2]}>{row.original[2]}</a>,
      width: 150
    },
    {
      Header: "Join Date",
      Cell: row => moment(new Date(row.original.membership.subscription_start)).format('ll'),
      width: 125
    },
    {
      Header: "Expiry Date",
      Cell: row => moment(new Date(row.original.membership.subscribed_expiration)).format('ll'),
      width: 125
    }
  ];

  useEffect(() => {
    getData();
  }, []);

  const getData = (pageNumber = 1) => {
    setLoading(true);

    const req = {
      type: "referrals",
      filter: false,
      start_date: new Date("01/01/2019"),
      end_date: new Date("12/12/2025"),
      by: "date",
      name: "",
      expiryStartDate: new Date("01/01/2019"),
      expiryEndDate: new Date("12/12/2025"),
      pageNumber
    };

    axios
      .post(`${url_path}/api/member/referrals-test/${referrence_no}`, req)
      .then(res => {
        setTableData(res.data.user_referrals);
        setPagerData(res.data.paginate);
        setLoading(false);
      })
      .catch(err => console.log(err));
  };

  return (
    <div className="tab-member-account member-transaction">
      <p className="heading">Referrals</p>
      { loading ? 
        <div className="lds-ellipsis">
          <div className="dots"></div>
          <div className="dots"></div>
          <div className="dots"></div>
          <div className="dots"></div>
        </div>
      :
        <div className="section-table">
          <Table 
            tableHeader={tableHeader} 
            tableData={tableData} 
            paginate={pagerData}
            pageFunction={getData}
          />
        </div>
      }
    </div>
  );
};

export default Referrals;
