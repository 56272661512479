// import { getData } from '../lib/api/users.js'
import React, { useState, useEffect } from "react";
import { url_path } from "../../constant";
import Table from "../../components/Table";
import Breadcrumbs from "../../components/Breadcrumbs";
// import ReactTooltip from 'react-tooltip'
import axios from "axios";
import "moment-timezone";
import { successNotif } from "../../lib/helpers/notifications.js";

import BootstrapSwitchButton from "bootstrap-switch-button-react";
import { authenticateAdminPage } from "../../lib/helpers/validation";

function PaymentRequest(props) {
  useEffect(() => {
    authenticateAdminPage(props);
  }, []);
  // States
  const [data, setData] = useState([]);
  const [pagerData, setPagerData] = useState([]);
  const [loader, setLoader] = useState(false);
  // const [checkbox, setCheckbox] = useState({
  //   items: [
  //     {
  //       id: 0,
  //       name: "name",
  //       label: "Name",
  //       isChecked: true
  //     },
  //     {
  //       id: 1,
  //       name: "partnerID",
  //       label: "Business Partner I.D",
  //       isChecked: true
  //     },
  //     {
  //       id: 2,
  //       name: "balance",
  //       label: "Balance",
  //       isChecked: true
  //     },
  //     {
  //       id: 3,
  //       name: "dateJoined",
  //       label: "Date of Joining",
  //       isChecked: true
  //     }
  //   ]
  // });
  const [searchInput, setSearchInput] = useState("");

  const heading = [
    {
      Header: "Date Request",
      // id: "no",
      maxWidth: 150,
      // filterable: false,
      accessor: "created_at",
    },
    {
      Header: "Name",
      accessor: "business_owner",
      Cell: (row) => {
        return (
          // <Link to={'/member-profile/' + row.original.user_id._id}>
          <>
            {row.original.user_id.user_first_name +
              " " +
              row.original.user_id.user_last_name}
          </>
          // </Link>
        );
      },
    },
    {
      Header: "Card Number",
      accessor: "user_id.card_number",
      // Cell: row => {
      //   return (
      //     // <Link to={'/member-profile/' + row.original.user_id._id}>
      //     <>
      //       {row.original.user_id.user_first_name +
      //         " " +
      //         row.original.user_id.user_last_name}
      //     </>
      //     // </Link>
      //   );
      // }
    },
    {
      Header: "Email",
      accessor: "user_id.user_email",
      // Cell: row => {
      //   return (
      //     // <Link to={'/member-profile/' + row.original.user_id._id}>
      //     <>
      //       {row.original.user_id.user_first_name +
      //         " " +
      //         row.original.user_id.user_last_name}
      //     </>
      //     // </Link>
      //   );
      // }
    },
    {
      Header: "Total Amount to Pay",
      accessor: "total_payment",
      width: 150,
    },
    {
      Header: "Payment Status",
      id: "status",
      Cell: (row) => (
        <div className="featured-button-holder">
          <span>Peding</span>
          <BootstrapSwitchButton
            checked={row.original.status}
            onlabel=" "
            offlabel=" "
            onChange={(checked) =>
              handleUserStatus(row.original.user_id._id, checked)
            }
          />
          <span>Paid</span>
        </div>
      ),
    },
    // }, {
    //   Header: "Account Status",
    //   id: 'actions',
    //   Cell: row => row.original.user_id.user_status ?
    //     <button
    //       className='btn-gray'
    //       onClick={() => handleUserStatus(row.original.user_id._id, false)}
    //     >Deactivate</button>
    //     :
    //     <button
    //       className='btn-blue'
    //       onClick={() => handleUserStatus(row.original.user_id._id, true)}
    //     >Activate</button>
    // }
  ];

  const handleUserStatus = (user_id, payment_status) => {
    const req = {
      user_id,
      payment_status,
    };

    axios.patch(`${url_path}/api/payment_request`, req).then((res) => {
      let title = "Well Done!";
      let msg = payment_status
        ? "Account has been Activated."
        : "Account has been Deactivated.";
      successNotif(title, msg);
      getPaymentRequestData();
    });

    // Swal.fire({
    //   title: 'Are you sure?',
    //   text: user_status ? 'Activate this Account?' : 'Deactivate this Account?',
    //   icon: 'question',
    //   showCancelButton: true,
    //   confirmButtonColor: "#286df9",
    //   cancelButtonColor: "#d33",
    //   confirmButtonText: 'Yes'
    // })
    // .then(res => {
    //   if(res.value) {
    //     setLoader(false);

    //   }
    // })
  };

  function getPaymentRequestData(pageNumber = 1, search = "") {
    setLoader(false);
    const req = {
      pageNumber,
      search,
    };
    axios
      .post(`${url_path}/api/payment_request/get`, req)
      .then((response) => {
        setData(response.data.payment_request);
        setPagerData(response.data.paginate);
        setLoader(true);
      })
      .catch(function (error) {
        console.log(error);
      });
  }
  console.log(data);
  useEffect(() => {
    getPaymentRequestData(1, searchInput);
  }, []);

  // const Checkbox = ({ type = "checkbox", name, value, checked = false, onChange, id }) => {
  //   return (
  //     <input type={type} id={id} name={name} value={value} checked={checked} onChange={onChange} className="input-checkbox" />
  //   );
  // };

  const handleChangeCheckbox = (event) => {
    const id = event.target.name;
    console.log(id)
  };

  // const handleChange = (event) => {
  //   setSearchInput(event.target.value);
  // };

  // const globalSearch = () => {
  //   let dataSearch = searchInput
  //   let filteredData = origData.filter(value => {
  //     return (
  //       value.business_owner.toLowerCase().includes(dataSearch.toLowerCase())
  //     );
  //   });
  //   setData(filteredData)
  // };

  const handleSearchInput = (event) => {
    setSearchInput(event.target.value);
  };

  const handleSearchSubmit = (event) => {
    event.preventDefault();

    getPaymentRequestData(1, searchInput);
  };

  return (
    <main className="manage-pages">
      <div className="container custom-container">
        <div className="section-table">
          {/* <div className="container"> */}
          <Breadcrumbs
            size="text-large"
            placeholder="Payment Request"
            targetLink="/admin-dashboard"
            // buttonTitle = 'Create New Business'
            customClassName="m-3"
          />
          <form onSubmit={handleSearchSubmit}>
            <div className="search-holder">
              <label htmlFor="searchInput">Search: </label>
              <input
                size="large"
                name="searchInput"
                label="Search"
                value={searchInput}
                onChange={handleSearchInput}
                className="search-input"
              />
              <input type="submit" className="btn-blue" value="Search" />
            </div>
          </form>
          {loader ? (
            <>
              <Table
                tableHeader={heading}
                tableData={data}
                show={handleChangeCheckbox}
                paginate={pagerData}
                pageFunction={getPaymentRequestData}
              />
            </>
          ) : (
            <div className="lds-ellipsis">
              <div className="dots"></div>
              <div className="dots"></div>
              <div className="dots"></div>
              <div className="dots"></div>
            </div>
          )}
          {/* </div> */}
        </div>
      </div>
    </main>
  );
}

export default PaymentRequest;
