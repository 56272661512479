import React, {useEffect} from 'react'

import Dashboard from '../../components/Dashboard'
import Breadcrumbs from '../../components/Breadcrumbs'
import {authenticateBusinessPage} from '../../lib/helpers/validation'
const linksList = [
  {
    title: 'Purchase',
    routeTo: '/manual-purchase',
    icon: require('../../assets/images/dashboard-earn.png')
  },
  {
    title: 'Redeem',
    routeTo: '/manual-redeem',
    icon: require('../../assets/images/dashboard-spend.png')
  }
]

function ManualInput(props) {
  useEffect(() => {
    authenticateBusinessPage(props)
  }, []);
  return (
    // <main className="partner-dashboard manual-input">
    //   <Breadcrumbs 
    //     size = 'text-large'
    //     placeholder = 'Back'
    //     targetLink = '/partner-dashboard'
    //   />
    //   <div className="container custom-container">
    //     <Dashboard links={ linksList } />
    //   </div>
    // </main>
    <main className="partner-dashboard manual-input">
      <div className="container custom-container">
        <div className="content-wrapper">
          <Breadcrumbs 
            size = 'text-large'
            placeholder = 'Back'
            targetLink = '/partner-dashboard'
            customClassName={'m-3'}
          />
          {/* <div className="container custom-container"> */}
            <Dashboard links={ linksList } />
          {/* </div> */}
        </div>
      </div>
    </main>
  )
}

export default ManualInput