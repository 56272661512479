import {
  Document, Font, Image, Page, PDFDownloadLink, StyleSheet, Text,
  View
} from "@react-pdf/renderer";
import axios from "axios";
import moment from "moment";
import "moment-timezone";
import React, { useEffect, useRef, useState } from "react";
import "react-accessible-accordion/dist/fancy-example.css";
import { DropdownButton } from "react-bootstrap";
import { CSVLink } from "react-csv";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { FaCalendar } from "react-icons/fa";
import Filter from "../../components/Filter";
import Table from "../../components/Table";
import { url_path } from "../../constant";
import useForm from "../../lib/hooks/useForm.js";


function InfinityStatement({ user_id, profileData }) {
  const [loading, setLoading] = useState(true);
  const [tableData, setTableData] = useState([]);
  const [pagerData, setPagerData] = useState({});
  const [filterStartDate, setFilterStartDate] = useState();
  const [filterName, setFilterName] = useState("");
  const [filterEndDate, setFilterEndDate] = useState();
  const [balance, setBalance] = useState();
  const [csvData, setCsvData] = useState([]);
  const [printData, setPrintData] = useState([]);
  const [currentMainCategory, setCurrentMainCategory] = useState("");
  const [currentSubCategory, setCurrentSubCategory] = useState("");


  const stateSchema = {
    business_name: { value: profileData.business_name, error: "" },
    business_owner: { value: profileData.business_owner, error: "" },
    address: { value: profileData.address, error: "" },
    city: { value: profileData.city, error: "" },
    zip: { value: profileData.zip, error: "" },
    province: { value: profileData.province, error: "" },
    country_code: { value: profileData.country_code, error: "" },
    about: { value: profileData.about, error: "" },
    main_category: { value: currentMainCategory, error: "" },
    sub_category: { value: currentSubCategory, error: "" },
  };


  const { values, 
    // errors, handleOnChange, handleOnSubmit, disable 
  } = useForm(
    stateSchema
  );

  /*
   * Get values to custom useForm Hooks
   * Pass it to input values
   */

  const {
    business_name,
    business_owner,
    address,
    city,
    zip,
    province,
    country_code
  } = values;

  const filterContent = {
    title: "Filter",
  };
  const csvHeader = [
    {
      label: "Date",
      key: "created_at",
    },
    {
      label: "First Name",
      key:  "user_id.user_first_name",
    },
    {
      label: "Last Name",
      key: "user_id.user_last_name",
    },
    {
      label: "Card Number",
      key: "user_id.card_number",
    },
    {
      label: "Amount",
      key: "amount",
    },
    {
      label: "Balance for DCJ",
      key: "discount_points",
    },
    {
      label: "Balance for Partner",
      key: "statement_amount",
    },
  ];
  const tableRef = useRef(null);
  // const ref = useRef();

  const handleSubmit = (event) => {
    console.log(filterStartDate);
    event.preventDefault();
    getInfinityTransaction(1, true);
  };

  const handleDateChangeRaw = (e) => {
    e.preventDefault();
    console.log(e.target.value);
  };

  const tableHeader = [
    {
      Header: "Date",
      Cell: (row) => (row.original[0] ? row.original[0] : ""),
    },
    {
      Header: "Name",
      Cell: (row) => (row.original[1] ? row.original[1] : ""),
    },
    {
      Header: "Card Number",
      Cell: (row) => (row.original[2] ? row.original[2] : "N/A"),
    },
    {
      Header: "Amount",
      Cell: (row) => (row.original[3] ? row.original[3] : ""),
    },
    {
      Header: "Balance for DCJ",
      Cell: (row) => (row.original[4] ? row.original[4] : ""),
    },
    {
      Header: "Balance for Business Partner",
      Cell: (row) => (row.original[5] ? row.original[5] : ""),
    },
  ];

  useEffect(() => {
    getInfinityTransaction(1, false);
  }, []);

  const getInfinityTransaction = (pageNumber, filter) => {
    setLoading(true);

    const req = {
      type: "web",
      filter,
      start_date: filterStartDate,
      end_date: filterEndDate,
      by: "date",
      name: filterName,
      startAmount: 0,
      endAmount: 0,
      pageNumber,
      isReport: 0
    };

    axios
      .post(`${url_path}/api/transactions/infinity_statement/${user_id}`, req)
      .then((res) => {
        const transactions = res.data.transaction;
        let businessBalance = res.data.balanceToDcj;
        let data = [];
        transactions.map((transaction) => {
          if (transaction.description === "Purchase") {
            data.push([
              moment(new Date(transaction.created_at)).format(
                "MMMM DD YYYY HH:mm:ss"
              ),
              transaction.user_id.user_first_name +
                " " +
                transaction.user_id.user_last_name,
              transaction.user_id.card_number,
              transaction.amount,
              transaction.discount_points,
              0,
            ]);
          } else if (transaction.description === "Managed Purchase") {
            data.push([
              moment(new Date(transaction.created_at)).format(
                "MMMM DD YYYY HH:mm:ss"
              ),
              transaction.user_id.user_first_name +
                " " +
                transaction.user_id.user_last_name,
              transaction.user_id.card_number,
              "-" + transaction.statement_amount,
              0,
              transaction.discount_points,
            ]);
          } else if (transaction.description === "Managed Redeem") {
            data.push([
              moment(new Date(transaction.created_at)).format(
                "MMMM DD YYYY HH:mm:ss"
              ),
              transaction.user_id.user_first_name +
                " " +
                transaction.user_id.user_last_name,
              transaction.user_id.card_number,
              "+" + transaction.statement_amount,
              transaction.statement_amount,
              0,
            ]);
          } else {
            data.push([
              moment(new Date(transaction.created_at)).format(
                "MMMM DD YYYY HH:mm:ss"
              ),
              transaction.user_id.user_first_name +
                " " +
                transaction.user_id.user_last_name,
              transaction.user_id.card_number,
              transaction.amount,
              transaction.discount_points,
              transaction.statement_amount,
            ]);
          }
        });

        setTableData(data);
        setPagerData(res.data.paginate);
        setPrintData(res.data.transac);
        setBalance(businessBalance);
        setLoading(false);
        console.log(res.data.transac);
      })
      .catch((err) => console.log(err));
  };

  Font.register({
    family: "OpenSansBold",
    fonts: [
      {
        src:
          "https://cdn.jsdelivr.net/npm/open-sans-all@0.1.3/fonts/open-sans-700.ttf",
        fontWeight: 700,
      },
    ],
  });

  const styles = StyleSheet.create({
    page: {
      // padding: "15px 25px 0px 0px",
    },
    table: {
      display: "table",
      marginTop: 15,
      width: "auto",
      borderStyle: "solid",
      borderWidth: 1,
      // borderRightWidth: 0,
    },
    table2: {
      display: "table",
      marginTop: 5,
      width: "auto",
      borderStyle: "solid",
      borderColor: "#e3e3e3",
      borderWidth: 1,
      backgroundColor: "#e3e3e3",
    },
    tableRow: {
      flexDirection: "row",
    },
    tableCol: {
      padding: "10px 5px",
      textAlign: "center",
      borderStyle: "solid",
      marginTop: -1,
      borderWidth: 1,
      borderLeftWidth: 0,
      borderTopWidth: 0,
      borderBottomWidth: 0,
    },
    tableCol2: {
      textAlign: "center",
    },
    tableColHeader: {
      display: "inline-flex",
      alignItems: "center",
      justifyContent: "center",
      height: "100%",
      padding: "5px",
      // textAlign: 'center',
      borderStyle: "solid",
      borderWidth: 1,
      borderLeftWidth: 0,
      borderTopWidth: 0,
      backgroundColor: "#e3e3e3",
    },
    tableCell: {
      fontSize: 10,
    },
    flex: {
      display: "flex",
      flexWrap: "wrap",
      flexDirection: "row",
    },
    header: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
    },
    heading: {
      fontSize: 10,
    },
    headerItem: {
      display: "flex",
      width: "40%",
    },
    logoImage: {
      float: "left",
      display: "inline-block",
      width: "auto",
      height: 45,
      objectFit: "contain",
    },
    logoItem: {
      display: "flex",
      width: "48%",
    },
    logoWrapper: {
      display: "flex",
      justifyContent: "space-between",
      flexDirection: "row",
      marginBottom: 30,
    },
    bgGray: {
      backgroundColor: "#77767d",
    },
    bgLightGray: {
      backgroundColor: "#e3e3e3",
    },
    bgBlue: {
      backgroundColor: "#a0cce8",
    },
    infoItem: {
      display: "flex",
      flexDirection: "row",
    },
    alignCenter: {
      display: "flex",
      alignItems: "center",
    },
    infoHolder: {
      width: "50%",
    },
    infoHeadingHolder: {
      width: "50%",
    },
    text: {
      fontSize: 8.5,
    },
    textLeft: {
      textAlign: "left",
    },
    textCenter: {
      textAlign: "center",
    },
    textRight: {
      textAlign: "right",
    },
    textUppercase: {
      textTransform: "uppercase",
    },
    bold: {
      fontSize: 8.4,
      fontFamily: "OpenSansBold",
    },
    padding: {
      padding: "0 3px",
    },
    footer: {
      display: "flex",
      padding: "5px 0",
      flexDirection: "row",
      // justifyContent: 'space-between',
      borderStyle: "solid",
      borderWidth: 3,
      borderRightWidth: 0,
      borderColor: "#456580",
      // borderRightWidth: 0,
      borderLeftWidth: 0,
      alignItems: "center",
    },
    footerCol: {
      width: "50%",
    },
    footerItem: {
      width: "50%",
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "start",
      padding: "0",
    },
    spaceBetween: {
      justifyContent: "space-between",
    },
    iconImage: {
      display: "inline-block",
      width: "auto",
      height: "20px",
      objectFit: "contain",
      marginRight: 2,
    },
  });

  const MyDoc = () => (
    <Document>
      <Page size="A4" style={{paddingLeft:"25px", paddingRight:"25px", paddingTop:"10px"}}>
        <View style={styles.header}>
          <View style={styles.headerItem}>
            <Text style={[styles.heading, styles.bold]}>
              DCJ Marketing Services Corporation
            </Text>
            <View style={styles.logoWrapper}>
              <View style={styles.logoItem}>
                <Image
                  style={styles.logoImage}
                  src={require("../../assets/images/dcj-logo.jpg")}
                />
              </View>
              <View style={styles.logoItem}>
                <Image
                  style={styles.logoImage}
                  src={require("../../assets/images/logo.png")}
                />
              </View>
            </View>

            <View style={styles.bill}>
              <Text
                style={[
                  styles.text,
                  styles.bold,
                  styles.bgGray,
                  styles.padding,
                ]}
              >
                Bill To:
              </Text>
              <View style={styles.infoList}>
                <View style={styles.infoItem}>
                  <View style={styles.infoHeadingHolder}>
                    <Text style={[styles.text, styles.bold]}>
                      Rewards Partner:
                    </Text>
                  </View>
                  <View style={styles.infoHolder}>
                    <Text style={[styles.text, styles.bold]}>
                      {business_name}
                    </Text>
                    <Text style={[styles.text, styles.bold]}>
                      {business_owner}
                    </Text>
                  </View>
                </View>

                <View style={styles.infoItem}>
                  <View style={styles.infoHeadingHolder}>
                    <Text style={[styles.text, styles.bold]}>Address:</Text>
                  </View>
                  <View style={styles.infoHolder}>
                    <Text style={styles.text}>
                      {address} {city} {province} {zip} {country_code}
                    </Text>
                  </View>
                </View>

                <View style={styles.infoItem}>
                  <View style={styles.infoHeadingHolder}>
                    <Text style={[styles.text, styles.bold]}>Mobile No.:</Text>
                  </View>
                  <View style={styles.infoHolder}>
                    <Text style={styles.text}>0926 502 1747</Text>
                  </View>
                </View>
              </View>
            </View>
          </View>

          <View style={styles.headerItem}>
            <View style={styles.bill}>
              <Text style={[styles.text, styles.bold]}>
                Statement of Transactions
              </Text>
              <View style={styles.infoList}>
                <View style={[styles.infoItem, styles.alignCenter]}>
                  <View style={styles.infoHeadingHolder}>
                    <Text style={[styles.text, styles.bold]}>Date:</Text>
                  </View>
                  <View style={styles.infoHolder}>
                    <Text style={[styles.text, styles.bold]}>
                      {moment().format("DD-MMM-YY").toString()}{" "}
                    </Text>
                  </View>
                </View>

                <View style={[styles.infoItem, styles.alignCenter]}>
                  <View style={styles.infoHeadingHolder}>
                    <Text style={[styles.text, styles.bold]}>Period:</Text>
                  </View>
                  <View style={styles.infoHolder}>
                    <Text style={styles.text}>
                      {moment(filterStartDate).format("DD MMM YY").toString()}{" - "}{moment(filterEndDate).format("DD MMM YY").toString()}</Text>

                  </View>
                </View>
              </View>
            </View>
          </View>
        </View>

        <View style={styles.table}>
          {/* TableHeader */}
          <View style={styles.tableRow}>
            <View style={[styles.tableColHeader, { width: "13%" }]}>
              <Text
                style={[
                  styles.tableCell,
                  styles.text,
                  styles.bold,
                  styles.textCenter,
                  { display: "block", width: "100%" },
                ]}
              >
                Date of Transaction
              </Text>
            </View>
            <View style={[styles.tableColHeader, { width: "19%" }]}>
              <Text
                style={[
                  styles.tableCell,
                  styles.text,
                  styles.bold,
                  styles.textCenter,
                  { display: "block", width: "100%" },
                ]}
              >
                Card Holder Name
              </Text>
            </View>
            <View style={[styles.tableColHeader, { width: "16%" }]}>
              <Text
                style={[
                  styles.tableCell,
                  styles.text,
                  styles.bold,
                  styles.textCenter,
                  { display: "block", width: "100%" },
                ]}
              >
                Card Number
              </Text>
            </View>
            <View style={[styles.tableColHeader, { width: "16%" }]}>
              <Text
                style={[
                  styles.tableCell,
                  styles.text,
                  styles.bold,
                  styles.textCenter,
                  { display: "block", width: "100%" },
                ]}
              >
                Purchased/Redeemed Amount
              </Text>
            </View>
            <View style={[styles.tableColHeader, { width: "18%" }]}>
              <Text
                style={[
                  styles.tableCell,
                  styles.text,
                  styles.bold,
                  styles.textCenter,
                  { display: "block", width: "100%" },
                ]}
              >
                Amount charged to DCJ
              </Text>
            </View>
            <View style={[styles.tableColHeader, { width: "18%" }]}>
              <Text
                style={[
                  styles.tableCell,
                  styles.text,
                  styles.bold,
                  styles.textCenter,
                  { display: "block", width: "100%" },
                ]}
              >
                Amount charged to {business_name}
              </Text>
            </View>
          </View>

          {/* TableContent */}
          {printData.map((value) => {
            const {
              created_at,
              points,
              total_points,
              redeemed_amount,
              user_id,
            } = value;
            
            const user_name = user_id.user_first_name + " " + user_id.user_last_name;
            return (
              <View style={styles.tableRow}>
                <View style={[styles.tableCol, { width: "13%" }]}>
                  <Text style={[styles.tableCell, styles.text]}>
                    {moment(created_at).format("DD-MMM-YY").toString()}
                  </Text>
                </View>
                <View style={[styles.tableCol, { width: "19%" }]}>
                  <Text
                    style={[
                      styles.tableCell,
                      styles.text,
                      styles.textUppercase,
                    ]}>

                    {user_name}
                  </Text>
                </View>
                <View style={[styles.tableCol, { width: "16%" }]}>
                  <Text style={[styles.tableCell, styles.text]}>
                    {user_id.card_number}
                  </Text>
                </View>
                <View style={[styles.tableCol, { width: "16%" }]}>
                  <Text style={[styles.tableCell, styles.text]}>{points}</Text>
                </View>
                <View style={[styles.tableCol, { width: "18%" }]}>
                  <Text style={[styles.tableCell, styles.text]}>{total_points}</Text>
                </View>
                <View style={[styles.tableCol, { width: "18%" }]}>
                  <Text style={[styles.tableCell, styles.text]}>
                    {redeemed_amount}
                  </Text>
                </View>
              </View>
            );
          })}
        </View>

        

        <View style={{ marginTop: 20, marginBottom: 40 }}>
          <Text
            style={[
              styles.text,
              styles.textCenter,
              styles.bold,
              { marginBottom: 7 },
            ]}
          >
            Thank you for your business!
          </Text>
          <Text style={[styles.text, styles.textCenter]}>
            Should you have any enquiries concerning this statement, please
            contact <Text style={[styles.bold, styles.text]}>Ms. Angela</Text>{" "}
            on mobile no.{" "}
            <Text style={[styles.bold, styles.text]}>0999 826 5929</Text>
          </Text>
        </View>

        <View style={styles.footer}>
          <View style={[styles.footerCol, { paddingLeft: 25 }]}>
            <Text style={[styles.text, styles.bold, styles.textLeft]}>
              DCJ Marketing Services Corportation
            </Text>
            <Text style={[styles.text, styles.textLeft]}>
              3rd Flr., 5G Building, Nepomuceno St., Sto. Rosario
            </Text>
            <Text style={[styles.text, styles.textLeft]}>
              Angeles City, Pampanga, Philippines 2009
            </Text>
          </View>

          <View style={[styles.footerCol, styles.flex]}>
            <View style={styles.footerItem}>
              <View style={styles.imageHolder}>
                <Image
                  style={styles.iconImage}
                  src={require("../../assets/images/icon-email.png")}
                />
              </View>
              <View style={styles.textHolder}>
                <Text style={styles.text}>info@infinityprivilege.com</Text>
              </View>
            </View>

            <View style={styles.footerItem}>
              <View style={styles.imageHolder}>
                <Image
                  style={styles.iconImage}
                  src={require("../../assets/images/icon-web.png")}
                />
              </View>
              <View style={styles.textHolder}>
                <Text style={styles.text}>infinityprivilege.com</Text>
              </View>
            </View>

            <View style={styles.footerItem}>
              <View style={styles.imageHolder}>
                <Image
                  style={styles.iconImage}
                  src={require("../../assets/images/icon-phone.png")}
                />
              </View>
              <View style={styles.textHolder}>
                <Text style={styles.text}>+63 45 8791363</Text>
              </View>
            </View>

            <View style={styles.footerItem}>
              <View style={styles.imageHolder}>
                <Image
                  style={styles.iconImage}
                  src={require("../../assets/images/icon-fb.jpg")}
                />
              </View>
              <View style={styles.textHolder}>
                <Text style={styles.text}>@infinityprivilege</Text>
              </View>
            </View>
          </View>
        </View>
      </Page>
    </Document>
  );

  return (
    <div className="tab-infinity-transaction transaction-statement">
      <p className="heading">Infinity Statement</p>

      <>
        <Filter
          filterDetails={filterContent}
          content={
            <div className="section-filter">
              <form className="form-wrapper statement" onSubmit={handleSubmit}>
                <div className="filter-wrapper">
                  <div className="group-wrapper">
                    <p className="filter-heading">Filter by date</p>
                    <div className="form-group">
                      <div className="form-field calendar">
                        <p className="input-label">From</p>
                        <label htmlFor="startDate">
                          <p className="label-calendar">
                            <FaCalendar />
                          </p>
                        </label>
                        <DatePicker
                          selected={filterStartDate}
                          onChange={(date) => setFilterStartDate(date)}
                          selectsEnd
                          dateFormat="MMM dd, yyyy"
                          // endDate={filterStartDate}
                          // minDate={filterStartDate}
                          onChangeRaw={handleDateChangeRaw}
                          className="form-input date"
                          name="filterStartDate"
                          placeholderText="Select a date between today and 5 days in the future"
                          // placeholderText={date}
                          autoComplete="off"
                          id="filterStartDate"
                        />
                      </div>
                      <div className="form-field calendar">
                        <p className="input-label">To</p>
                        <label htmlFor="endDate">
                          <p className="label-calendar">
                            <FaCalendar />
                          </p>
                        </label>
                        <DatePicker
                          selected={filterEndDate}
                          onChange={(date) => setFilterEndDate(date)}
                          selectsEnd
                          dateFormat="MMM dd, yyyy"
                          // endDate={filterEndDate}
                          minDate={filterStartDate}
                          onChangeRaw={handleDateChangeRaw}
                          className="form-input date"
                          name="filterEndDate"
                          // placeholderText={date}
                          autoComplete="off"
                          id="filterEndDate"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="group-wrapper name">
                    <p className="filter-heading">Filter by Name</p>
                    <div className="form-group">
                      <div className="form-field calendar name-field">
                        <p className="input-label">Name</p>
                        {/* <input
                          type="text"
                          className="form-input single-input"
                          onChange={(e) => setFilterName(e.target.value)}
                          name="name"
                          autoComplete="off"
                          id="name"
                        /> */}
                        <input
                        id="name"
                        className="form-input single-input"
                        type="text"
                        name="name"
                        // value={name}
                        // onChange={handleOnChange}
                        onChange={(e) => setFilterName(e.target.value)}
                        // required
                      />
                      </div>
                    </div>
                  </div>
                  <input
                    className="btn-blue form-deal-submit"
                    type="submit"
                    value="Apply"
                  />
                </div>
              </form>
            </div>
          }
        />
        <div className="section-table">
          {!loading ? (
            <>
              <div className="table-button-holder">
                <div className="export-dropdown">
                  <DropdownButton
                    id="dropdown-basic-button"
                    title="Export Data"
                    // onClick={(event, done) => handleExportData(event, done)}
                  >
                    <div className="export-option">
                      <CSVLink
                        className=""
                        filename={
                          profileData.business_name.replace(" ", "-") +
                          "-" +
                          moment().format("YYYY-MM-DD").toString() +
                          ".csv"
                        }
                        data={printData}
                        headers={csvHeader}
                        asyncOnClick={true}
                        target="_blank"
                      >
                        <span className="text-black hover-text-black">Export to Excel</span>
                      </CSVLink>
                    </div>
                    <div className="export-option">
                      <PDFDownloadLink
                        document={<MyDoc />}
                        fileName={
                          profileData.business_name.replace(" ", "-") +
                          "-" +
                          moment().format("YYYY-MM-DD").toString() +
                          ".pdf"
                        }
                        className="text-black"
                      >
                        {({ blob, url, loading, error }) =>
                          loading ? "Loading..." : "Export to PDF"
                        }
                      </PDFDownloadLink>
                    </div>
                  </DropdownButton>
                </div>
              </div>

              <Table
                tableRef={tableRef}
                tableHeader={tableHeader}
                tableData={tableData}
                paginate={pagerData}
                pageFunction={getInfinityTransaction}
              />
            </>
          ) : (
            <div className="lds-ellipsis">
              <div className="dots"></div>
              <div className="dots"></div>
              <div className="dots"></div>
              <div className="dots"></div>
            </div>
          )}
          <div className="statement-total">
            <p>Total balance of business partner:</p>
            <p>{balance}</p>
          </div>
        </div>
      </>
    </div>
  );
}

export default InfinityStatement;
