import React, { useEffect } from 'react'

import Dashboard from '../../components/Dashboard'
import {authenticateBusinessPage} from '../../lib/helpers/validation'
const linksList = [
  {
    title: 'Infinity Transactions',
    routeTo: '/infinity-transactions',
    icon: require('../../assets/images/dashboard-transactions.png')
  },
  {
    title: 'Infinity Statement',
    routeTo: '/infinity-statement',
    icon: require('../../assets/images/dashboard-statement.png')
  },
  {
    title: 'Manual Input',
    routeTo: '/manual-input',
    icon: require('../../assets/images/dashboard-manual.png')
  },
  // {
  //   title: 'Manage Deals',
  //   routeTo: '/manage-deals',
  //   icon: require('../../assets/images/dashboard-img3.png')
  // },
  // {
  //   title: 'Customers',
  //   routeTo: '/customers',
  //   icon: require('../../assets/images/dashboard-img8.png')
  // },
  // {
  //   title: 'Help',
  //   routeTo: '/partner-dashboard',
  //   icon: require('../../assets/images/dashboard-help.png')
  // },
  {
    title: "Settings",
    routeTo: "/partner-settings",
    icon: require("../../assets/images/dashboard-img6.png")
  },
]

function PartnersDashboard(props) {
  useEffect(() => {
    authenticateBusinessPage(props)
  }, []);
  return (
    <main className="partner-dashboard">
      <div className="container custom-container">
        <Dashboard links={ linksList } />
      </div>
    </main>
  )
}

export default PartnersDashboard