import React from 'react'
import { Link } from 'react-router-dom'

function list() {
  return (
    <div className="temporary">
      <div className="container">
        <h1>List of pages</h1>
        <hr />

        <br />
        <h4>Onboarding</h4>
        <hr />
        <Link to="/">Login</Link><br />
        <Link to="/">Sign Up</Link><br />
        <Link to="/">Forgot Password</Link><br />
        <Link to="/data-generator">Data Generator</Link><br />

        <br />
        <h4>Admin Dashboard</h4>
        <hr />
        <Link to="/admin-dashboard">Admin Dashboard</Link><br />
        <Link to="/manage-users">Manage Users</Link><br />
        <Link to="/manage-business-partners">Manage Business Partners</Link><br />
        <Link to="/business-profile">Business Profile</Link><br />

        <br />
        <h4>Business Partners Dashboard</h4>
        <hr />
        <Link to="/partner-dashboard">Business Partners Dashboard</Link><br />
        <Link to="/manage-deals">Manage deals</Link><br />
        <Link to="/deal-details">Deal Details</Link><br />
        <Link to="/customers">Customers</Link><br />
        <Link to="/transaction-history">Transaction</Link><br />

        <br />
        <h4>Members Dashboard</h4>
        <hr />
        <Link to="/member-dashboard">Member Dashboard</Link><br />
        <Link to="/my-infinity-card">My Infinity Card</Link><br />
        <Link to="/my-infinity-points">My Infinity Points</Link><br />
        <Link to="/favorites">Favorites</Link><br />
        <Link to="/help">Help</Link><br />
        <br />
      </div>      
    </div>
  )
}

export default list
