import React, { useState } from "react";
import HeaderLogo from "../assets/images/logo-white.png";
import { Dropdown } from "react-bootstrap";
// import HeaderModal from './HeaderModal'

function Header(isHidden) {
  const [isLoggedIn] = useState(false);

  return (
    <>
      {!isLoggedIn ? (
        <div className="header" hidden={window.location.href.substring(window.location.href.lastIndexOf('/') + 1) == "partners" ? true : false}>
          <div className="navbar" id="container">
            <a
              href="https://infinityprivilege.com"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={HeaderLogo} className="logo-header" alt="" />
            </a>
          </div>
        </div>
      ) : (
        <div className="header logged-in" hidden={window.location.href.substring(window.location.href.lastIndexOf('/') + 1) == "partners" ? true : false}>
          <div className="navbar" id="container">
            <div>
              <a
                href="https://infinityprivilege.com"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src={HeaderLogo} className="logo-header" alt="" />
              </a>
            </div>
            <div className="header-text-wrapper">
              <Dropdown>
                <Dropdown.Toggle id="dropdown-basic">
                  <span className="header-text">Welcome, Mr. Last Name</span>
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  <Dropdown.Item href="#/action-1">Action</Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default Header;
