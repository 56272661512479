import React, { useState } from 'react'
// import { Link } from 'react-router-dom'
import DatePicker from "react-datepicker";
import BootstrapSwitchButton from 'bootstrap-switch-button-react'
import "react-datepicker/dist/react-datepicker.css";
import Breadcrumbs from '../components/Breadcrumbs'
import { ReactComponent as IconCalendar } from '../assets/images/icon-calendar.svg'

function DealDetails() {

  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [activeState, setACtiveState] = useState(true);

  const handleSubmit = (event) => {
    event.preventDefault()
    console.log('submit')
  }

  const handleDateChangeRaw = (e) => {
    e.preventDefault();
  }

  return (
    <main className="deal-detail">
      <Breadcrumbs 
        size = 'text-small'
        placeholder = 'Back'
        targetLink = '/manage-deals'
      />
      <div className="section-deal">
        <div className="container">          
          <h2 className="title-centered">Deal Details</h2>
          <div className="deal-detail-wrapper">
            <div className="form-detail-holder">
              <form className="form-wrapper" onSubmit={handleSubmit}>
                <div className="form-field">
                  <input type="text" className="form-input" id="title" name="title" placeholder="Buy 3 Coffee get 1 for FREE" required />
                  <label htmlFor="title">Title</label>
                </div>

                <div className="form-field form-textarea">
                  <textarea className="form-input" id="description" name="description" required />
                  <label htmlFor="description">Description</label>
                </div>

                <div className="date-wrapper">
                  <div className="form-group">
                    <div className="form-field calendar">    
                      <label htmlFor="startDate">Start Date
                        <IconCalendar className="form-input-calendar"/>
                      </label>              
                      <DatePicker
                        selected={startDate}
                        onChange={date => setStartDate(date)}
                        selectsStart
                        dateFormat="MMM dd, yyyy"
                        startDate={startDate}
                        endDate={endDate}
                        maxDate={endDate}
                        onChangeRaw={handleDateChangeRaw} 
                        className="form-input date"
                        name="startDate"
                        placeholderText="Oct 29, 2019"
                        autoComplete="off"
                        id="startDate"
                        required
                      />                                      
                      {/* START DATE STATE AND END DATE STATE */}
                      {/* {console.log(startDate)}
                      {console.log(endDate)} */}
                    </div>
                    <div className="form-field calendar"> 
                      <label htmlFor="endDate">End Date
                        <IconCalendar className="form-input-calendar" htmlFor="endDate"/>
                      </label>                     
                      <DatePicker
                        selected={endDate}
                        onChange={date => setEndDate(date)}
                        selectsEnd
                        dateFormat="MMM dd, yyyy"
                        endDate={endDate}
                        minDate={startDate}
                        onChangeRaw={handleDateChangeRaw} 
                        className="form-input date"
                        name="endDate"
                        placeholderText="Oct 29, 2019"
                        autoComplete="off"
                        id="endDate"
                        required
                      />                      
                    </div>
                  </div>
                </div>

                <div className="upload-wrapper">
                  <div className="image-holder">
                    <img src={require('../assets/images/deals-image-2.png')} className="image-featured" alt="Featured"/>
                  </div>
                  <div className="file-upload-holder">
                    <h5 className="file-title">Upload Image</h5>
                    <div className="upload-holder">
                      <input type="file" name="file" id="file" className="upload-file"/>
                      <label htmlFor="file">Choose a file</label>
                      <span className="file-no-upload 321">No file chosen</span>
                    </div>
                    <button className="btn btn-remove">Remove image</button>
                  </div>
                </div>

                <div className="featured-holder">
                  <span className="featured-text text-bold">Featured</span>
                  <div className="featured-button-holder">
                    <span className="featured-text no">No</span>
                    <BootstrapSwitchButton
                      checked={false}
                      onlabel=' '
                      offlabel=' '
                      onChange={(checked) => {
                        setACtiveState({ activeState: checked })
                      }}
                    />
                    <span className="featured-text yes">Yes</span>
                  </div>
                  {/* FEATURED STATE BUTTON TRUE OR FALSE */}
                  {console.log(activeState.activeState)}

                  <span className="featured-text text-gray">This will be your lead deal.</span>
                </div>
                <div className="btn-wrapper">
                  <button className="btn-blue form-deal-submit" value="submit">Submit</button>
                </div>
              </form>
            </div>
            <div className="tip-detail-holder">
              <h4 className="tip-title">
                <span className="span-tip-title">
                  <img src={require('../assets/images/image-tip.png')} alt="Tip Icon"/>
                </span>
                TIP: Sample Deals
              </h4>
              <ul className="tip-list-title">
                <li className="tip-list">
                  <p className="tip-text">Buy 2 Cokes, Get 1 FREE</p>
                </li>
                <li className="tip-list">
                  <p className="tip-text">20% off Hawaiian Sunscreen</p>
                </li>
                <li className="tip-list">
                  <p className="tip-text">Purchase 1 body wash get 50% off hand soaps</p>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </main>
  )
}

export default DealDetails




























































// import React, { useState } from 'react'
// // import { Link } from 'react-router-dom'
// import DatePicker from "react-datepicker";
// import BootstrapSwitchButton from 'bootstrap-switch-button-react'
// import "react-datepicker/dist/react-datepicker.css";
// import Breadcrumbs from '../components/Breadcrumbs'
// import { ReactComponent as IconCalendar } from '../assets/images/icon-calendar.svg'

// function DealDetails() {

//   const [startDate, setStartDate] = useState();
//   const [endDate, setEndDate] = useState();
//   const [activeState, setACtiveState] = useState(true);



//   const handleDateChangeRaw = (e) => {
//     e.preventDefault();
//   }

//   return (
//     <main className="deal-detail">
//       <Breadcrumbs 
//         size = 'text-small'
//         placeholder = 'Back'
//         targetLink = '/manage-deals'
//       />
//       <div className="section-deal">
//         <div className="container">
//           <div className="deal-detail-wrapper">
//             <div className="form-detail-holder">
//               <form className="form-wrapper">
//                 <div className="form-field">
//                   <label htmlFor="title">Title</label>
//                   <input type="text" className="form-input" id="title" name="title" placeholder="Buy 3 Coffee get 1 for FREE" required />
//                 </div>

//                 <div className="form-field form-textarea">
//                   <label htmlFor="description">Description</label>
//                   <textarea className="form-input" id="description" name="description" required />
//                 </div>

//                 <div className="date-wrapper">
//                   <div className="form-group">
//                     <div className="form-field calendar">
//                       <label htmlFor="startDate">Start Date
//                         <IconCalendar className="form-input-calendar"/>
//                       </label>
                      
//                       <DatePicker
//                         selected={startDate}
//                         onChange={date => setStartDate(date)}
//                         selectsStart
//                         dateFormat="MMM dd, yyyy"
//                         startDate={startDate}
//                         endDate={endDate}
//                         maxDate={endDate}
//                         onChangeRaw={handleDateChangeRaw} 
//                         className="form-input date"
//                         name="startDate"
//                         placeholderText="Oct 29, 2019"
//                         autoComplete="off"
//                         id="startDate"
//                         required
//                       />
                      
//                       {/* START DATE STATE AND END DATE STATE */}
//                       {/* {console.log(startDate)}
//                       {console.log(endDate)} */}
//                     </div>
//                     <div className="form-field calendar">
//                       <label htmlFor="endDate">End Date
//                         <IconCalendar className="form-input-calendar" htmlFor="endDate"/>
//                       </label>
//                       <DatePicker
//                         selected={endDate}
//                         onChange={date => setEndDate(date)}
//                         selectsEnd
//                         dateFormat="MMM dd, yyyy"
//                         endDate={endDate}
//                         minDate={startDate}
//                         onChangeRaw={handleDateChangeRaw} 
//                         className="form-input date"
//                         name="endDate"
//                         placeholderText="Oct 29, 2019"
//                         autoComplete="off"
//                         id="endDate"
//                         required
//                       />
//                     </div>
//                   </div>
//                 </div>

//                 <div className="upload-wrapper">
//                   <div className="image-holder">
//                     <img src={require('../assets/images/deals-image-2.png')} className="image-featured" alt="Featured"/>
//                   </div>
//                   <div className="file-upload-holder">
//                     <h5 className="file-title">Upload Image</h5>
//                     <div className="upload-holder">
//                       <input type="file" name="file" id="file" className="upload-file"/>
//                       <label htmlFor="file">Choose a file</label>
//                       <span className="file-no-upload 321">No file chosen</span>
//                     </div>
//                     <button className="btn btn-remove">Remove image</button>
//                   </div>
//                 </div>

//                 <div className="featured-holder">
//                   <span className="featured-text text-bold">Featured</span>
//                   <div className="featured-button-holder">
//                     <span className="featured-text no">No</span>
//                     <BootstrapSwitchButton
//                       checked={false}
//                       onlabel=' '
//                       offlabel=' '
//                       onChange={(checked) => {
//                         setACtiveState({ activeState: checked })
//                       }}
//                     />
//                     <span className="featured-text yes">Yes</span>
//                   </div>
//                   {/* FEATURED STATE BUTTON TRUE OR FALSE */}
//                   {console.log(activeState.activeState)}

//                   <span className="featured-text text-gray">This will be your lead deal.</span>
//                 </div>
//                 <div className="btn-wrapper">
//                   <button className="btn-blue form-deal-submit" value="submit">Submit</button>
//                 </div>
//               </form>
//             </div>
//             <div className="tip-detail-holder">
//               <h4 className="tip-title">
//                 <span className="span-tip-title">
//                   <img src={require('../assets/images/image-tip.png')} alt="Tip Icon"/>
//                 </span>
//                 TIP: Sample Deals
//               </h4>
//               <ul className="tip-list-title">
//                 <li className="tip-list">
//                   <p className="tip-text">Buy 2 Cokes, Get 1 FREE</p>
//                 </li>
//                 <li className="tip-list">
//                   <p className="tip-text">20% off Hawaiian Sunscreen</p>
//                 </li>
//                 <li className="tip-list">
//                   <p className="tip-text">Purchase 1 body wash get 50% off hand soaps</p>
//                 </li>
//               </ul>
//             </div>
//           </div>
//         </div>
//       </div>
//     </main>
//   )
// }

// export default DealDetails
