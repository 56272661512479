import React, { useEffect, useState } from "react";
import { url_path } from "../../constant";
import Breadcrumbs from "../../components/Breadcrumbs";
import { Link } from "react-router-dom";
import Upgrade from "../../components/Upgrade";
import axios from "axios";
import moment from "moment";
import { getCookie } from "../../lib/helpers/cookies";
import Sidebar from "../../components/Sidebar";
import { authenticateMemberPage } from "../../lib/helpers/validation";

function MemberAccount({ history }) {
  // States
  const [loading, setLoading] = useState(true);
  const [userData, setUserData] = useState({});
  const [isQualified, setIsQualified] = useState(false);
  // Functions
  const getAccountData = async () => {
    setLoading(true);
    const token = getCookie("token");

    try {
      const res_user_info = await axios.get(`${url_path}/api/auth`, {
        headers: { "x-auth-token": token },
      });
      const user_info = res_user_info.data.user;

      const req = {
        user_id: user_info._id,
        reference_number: user_info.reference_number,
      };

      const res_user_data = await axios.post(
        `${url_path}/api/member_request/accountGetData`,
        req
      );
      const user_data = res_user_data.data;
      user_data.name = user_info.user_first_name + " " + user_info.user_last_name;
      user_data.card_number = user_info.card_number;
      user_data.reference_number = user_info.reference_number;

      setUserData(user_data);
      setLoading(false);
      if(user_data.points_available == 0) setIsQualified(true); 
    } catch (err) {
      console.log(err);
    }
  };

  const handleQualification = (next_category, points_needed) => {
    history.push({
      pathname: "/my-infinity-account-about",
      states: { next_category, points_needed },
    });
  };

  // if(userData.points_available == 0){
  //   setIsQualified(true)
  // }

  useEffect(() => {
    authenticateMemberPage(history);
    getAccountData();
  }, []);

  return (
    <main className="member-account member-dashboard">
      <Sidebar history={history} />
      <div className="content-wrapper">
        <Breadcrumbs
          placeholder="Back"
          size="text-large"
          targetLink="/my-infinity-account"
          customClassName={undefined}
        />
        <div className="container">
          <div className="account-holder">
            <p className="heading title-centered">My Account</p>
            {loading ? (
              <div className="lds-ellipsis">
                <div className="dots"></div>
                <div className="dots"></div>
                <div className="dots"></div>
                <div className="dots"></div>
              </div>
            ) : (
              <>
                <div className="infinity-card-img">
                  <img
                    src={require(`../../assets/images/cards/${userData.current_tier_category_name.toLowerCase()}/tier-${
                      userData.tier_level
                    }.jpg`)}
                    className="img-card"
                    alt=""
                  />
                  <img
                    src={require("../../assets/images/logo-white.png")}
                    className="card-logo"
                    alt="Infinity Privilege"
                  />
                  <div className="card-details">
                    <div className="member-card-info">
                      <p className="member-name">{userData.name}</p>
                      <p className="member-card-number">
                        {userData.card_number}
                      </p>
                      <div className="member-bottom-info">
                        <p className="card-bottom-text">
                          Expires: {moment(userData.expiration).format("l")}
                        </p>
                        <p className="card-bottom-text">
                          Reference: {userData.reference_number}
                        </p>
                      </div>
                    </div>
                    <div className="tier-wrapper">
                      <img
                        src={require("../../assets/images/card-tier.jpg")}
                        className="card-tier"
                        alt="Infinity Privilege"
                      />
                      <p className="tier-text">{userData.tier_level}</p>
                    </div>
                  </div>
                </div>
                {isQualified ? (
                  <div className="account-details-holder" style={{marginTop:"20px"}}>
                    <p className="congratulation-greeting" style={{fontFamily: "Gotham-Bold"}}>
                      Congratulations on reaching 250 points! you can now apply for a card<Upgrade></Upgrade>
                    </p>
                  </div>
                ) : ""}
                <div className="account-details">
                  <div className="account-details-holder">
                    <p className="label">Available points</p>
                    <p className="value">{userData.points_available}</p>
                  </div>
                  <div className="account-details-holder">
                    <p className="label">Number of referrals</p>
                    <p className="value">{userData.number_of_referals || 0}</p>
                  </div>
                  <div className="account-details-holder">
                    <p className="label">Card Expiration</p>
                    <p className="value">
                      {moment(new Date(userData.expiration)).from(moment())}
                    </p>
                  </div>
                  <div className="account-details-holder">
                    <p className="label">Total collected points</p>
                    <p className="value">{userData.total_collected_points}</p>
                  </div>
                  <div className="account-details-holder">
                    <p className="label">Member since</p>
                    <p className="value">
                      {moment(new Date(userData.subscription_start)).format(
                        "ll"
                      )}
                    </p>
                  </div>
                  <div className="account-details-holder">
                    <p className="text">
                      To qualify to next tier you need{" "}
                      <span className="text-blue">
                        {userData.referrals_needed}
                      </span>{" "}
                      more referral(s)
                    </p>
                  </div>
                  <div className="account-details-holder">
                    <p className="text">
                      To qualify to{" "}
                      <span className="text-blue">
                        {userData.next_tier_category_name}
                      </span>{" "}
                      status you will need to earn{" "}
                      <span className="text-blue">
                        {userData.points_raise_needed}
                      </span>{" "}
                      more points by{" "}
                      <span className="text-blue">
                        {moment(new Date(userData.expiration)).format("ll")}
                      </span>
                    </p>
                  </div>
                  <div
                    className="link-blue account-about"
                    onClick={() =>
                      handleQualification(
                        userData.next_tier_category_name,
                        userData.points_raise_needed
                      )
                    }
                  >
                    About {userData.next_tier_category_name} qualification
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </main>
  );
}

export default MemberAccount;
