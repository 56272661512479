import React, { useState, useEffect } from "react";
// import {Helmet} from "react-helmet";
import { url_path, captchakey } from "../constant";
import Card from "../components/Card";
import "../stylesheets/main.scss";
import { withRouter } from "react-router";
import { Link } from "react-router-dom";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import axios from "axios";
import {  errorNotifIcon } from "../lib/helpers/notifications.js";
import useForm from "../lib/hooks/useForm.js";
import { ReactComponent as IconCalendar } from "../assets/images/icon-calendar.svg";
import { ReactComponent as IconCaretDown } from "../assets/images/icon-caret-down.svg";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/bootstrap.css";
import { setCookie,} from "../lib/helpers/cookies";

import BankDeposit from "../signup-wizard/bank-deposit";
import Cebuana from "../signup-wizard/cebuana";
import OnlineBankTransfer from "../signup-wizard/online-bank-transfer";
import PayMongoPayment from "../signup-wizard/paymongo-payment";
import GCashPayment from "../signup-wizard/gcash-payment";
import ReCAPTCHA from "react-google-recaptcha";
const iconShow = require("../assets/images/icon-show.png");
const iconHide = require("../assets/images/icon-hide.png");

function Signup(props) {
  const [inputType, setInputType] = useState(true);
  const [inputConfirm, setInputConfirm] = useState(true);
  const [birthDate, setBirthDate] = useState();
  const [notificationError, setNotificationError] = useState("");
  const [wizardStep, setWizardStep] = useState(1);
  const [phone, setPhone] = useState();
  const [signupAvailability] = useState(true);
  const [countryCode, setCountryCode] = useState();
  const [deliverAddress, setDeliverAddress] = useState("home");
  const [totalPayment, setTotalPayment] = useState(250);
  const [promoCode, setPromoCode] = useState("");
  const [isButtonloading, setIsButtonLoading] = useState(false);
  const [paymentCode, setPaymentCode] = useState();
  const [termsAndCondition, setTermsAndCondition] = useState(false);
  const [privacyPolicy, setPrivacyPolicy] = useState(false);
  const [isFree, setIsFree] = useState(false);
  const [captcha, setCaptcha] = useState();
  

  const handleChangeAddress = (event) => {
    setDeliverAddress(event.target.value);
    console.log(event.target.value);
  };

  const handleDateChangeRaw = (e) => {
    e.preventDefault();
  };

  /*
   * Regex Validations to Inputs
   */

  var nameRegex = /^[a-zA-Z ]+$/;
  var emailRegex = /\S+@\S+\.\S+/;
  var passwordMinChar = /^.{8,}$/;
  var numRegex = /^[0-9]*$/;
  var paymentRegex = /^$/;

  /*
   * Initialize input state schema
   */

  const stateSchema = {
    title: { value: "Mr", error: "" },
    others: { value: "", error: "" },
    firstName: { value: "", error: "" },
    middleName: { value: "", error: "" },
    lastName: { value: "", error: "" },
    gender: { value: "Male", error: "" },
    tel: { value: "", error: "" },

    email: { value: "", error: "" },
    confirmEmail: { value: "", error: "" },
    password: { value: "", error: "" },
    confirmPassword: { value: "", error: "" },

    referral: { value: "", error: "" },
    promo_code: { value: promoCode, error: "" },
    cardNumber: { value: "", error: "" },
    expMonth: { value: "", error: "" },
    expYear: { value: "", error: "" },
    cvc: { value: "", error: "" },
    paymeny_mode: { value: "paypal", error: "" },

    address: { value: "", error: "" },
    city: { value: "", error: "" },
    zip: { value: "", error: "" },
    province: { value: "", error: "" },
    country_code: { value: "", error: "" },

    payment_method: { value: "", error: "" },
    deliverAddress: { value: "", error: "" },
    companyName: { value: "", error: "" },
    houseNo: { value: "", error: "" },
    street: { value: "", error: "" },
  };

  /*
   * Create a schema for all inputs
   */

  const stateValidatorSchema = {
    title: {
      required: true,
      validator: {
        func: (value) => nameRegex.test(value),
        error: "Invalid first name format.",
      },
    },
    others: {
      required: false,
    },
    firstName: {
      required: true,
      validator: {
        func: (value) => nameRegex.test(value),
        error: "Invalid first name format.",
      },
    },
    middleName: {
      required: false,
    },
    lastName: {
      required: true,
      validator: {
        func: (value) => nameRegex.test(value),
        error: "Invalid last name format.",
      },
    },
    gender: {
      required: true,
    },
    tel: {
      required: true,
      validator: {
        func: (value) => value,
        error: "Invalid Tel Number.",
      },
    },

    email: {
      required: true,
      validator: {
        func: (value) => emailRegex.test(value),
        error: "Invalid email",
      },
    },
    confirmEmail: {
      required: true,
      validator: {
        func: (value) => value === email,
        error: "Email addresses dont match",
      },
    },
    password: {
      required: true,
      validator: {
        func: (value) => passwordMinChar.test(value),
        error: "Minimum 8 characters.",
      },
    },
    confirmPassword: {
      required: true,
      validator: {
        func: (value) => value === password,
        error: "Password don't match",
      },
    },

    cardNumber: {
      required: true,
    },
    expMonth: {
      required: true,
    },
    expYear: {
      required: true,
    },
    cvc: {
      required: true,
    },
    totalPayment: {
      required: true,
      validator: {
        func: (value) => value,
        error: "Invalid",
      },
    },
    referral: {
      required: false,
    },
    promo_code: {
      required: false,
    },
    payment_mode: {
      required: false,
    },

    address: {
      required: false,
    },
    city: {
      required: true,
      validator: {
        func: (value) => nameRegex.test(value),
        error: "Invalid City",
      },
    },
    province: {
      required: true,
      validator: {
        func: (value) => nameRegex.test(value),
        error: "Invalid Province",
      },
    },
    country_code: {
      required: true,
      validator: {
        func: (value) => nameRegex.test(value),
        error: "Invalid Country!",
      },
    },
    zip: {
      required: false,
      validator: {
        func: (value) => numRegex.test(value),
        error: "Invalid zip format.",
      },
    },
    payment_method: {
      required: true,
      validator: {
        func: (value) => !paymentRegex.test(value),
        error: "Please select one of the Payment methods",
      },
    },

    deliverAddress: {
      required: true,
    },
    companyName: {
      required: false,
      validator: {
        func: (value) => nameRegex.test(value),
        error: "Invalid Name",
      },
    },
    houseNo: {
      required: false,
    },
    street: {
      required: false,
    },
  };

  /*
   * Get values, errors, handling events to custom useForm Hooks
   */

  const { values, errors, handleOnChange, handleOnSubmit, disable } = useForm(
    stateSchema,
    stateValidatorSchema,
    onSubmitForm
  );

  const {
    title,
    others,
    firstName,
    middleName,
    lastName,
    email,
    gender,
    tel,

    confirmEmail,
    password,
    confirmPassword,

    referral,
    promo_code,
    cardNumber,
    expMonth,
    expYear,
    cvc,
    // totalPayment,
    payment_mode,

    address,
    city,
    zip,
    province,
    country_code,

    payment_method,

    // deliverAddress,
    companyName,
    houseNo,
    street,
  } = values;

  /*
   * On Submit Form
   */
  function submitHere() {
    setIsButtonLoading(true);
    var userTitle;
    if (title === "Other") {
      userTitle = others;
    } else {
      userTitle = title;
    }
    var is_home;
    if (deliverAddress === "home") {
      is_home = true;
    } else {
      is_home = false;
    }

    if (email === confirmEmail && password === confirmPassword) {
      setNotificationError("");
      // add user data to pending user first
      if (
        ["PayPal", "Bank Deposit", "Online Bank Transfer", "Cebuana"].includes(
          payment_method
        )
      ) {
        const user = {
          title: userTitle,
          first_name: firstName,
          middle_name: middleName,
          last_name: lastName,
          birthdate: birthDate,
          gender: gender,
          contact_no: phone,
          email: confirmEmail,
          password: confirmPassword,
          role: "Member",
          is_home: is_home,
          company: companyName,
          house_no: houseNo,
          street: street,
          address: address,
          city: city,
          province: province,
          zip: zip,
          country: country_code,
          referral_code: referral.toUpperCase(),
          promo_code: promo_code.toUpperCase(),

          register_type: "self signup",
          dial_code: countryCode,

          form_of_payment: payment_method,
          receipt_no: "",
          payment_code: paymentCode,
        };

        axios
          .post(`${url_path}/api/users/add_pending_user`, user)
          .then((res) => {
            const userData = res.data;
            if (userData.data.status === "success") {
              // this is for paypal
              if (payment_method === "PayPal") {
                console.log(userData.memberData);
                // get link and proceed to paypal payment
                const bodyRequest = {
                  pending_user_id: userData.memberData._id,
                  promo_code: promo_code,
                };
                axios
                  .post(`${url_path}/api/payment_method/pay`, bodyRequest)
                  .then((res) => {
                    const paymentData = res.data;
                    window.location.href = paymentData;
                    setIsButtonLoading(false);
                  })
                  .catch((err) => {
                    setIsButtonLoading(false);
                  });
              } else if (payment_method === "Online Bank Transfer") {
                const req = {
                  to_email: confirmEmail,
                  name: firstName + " " + lastName,
                  paymentCode,
                  promoCode: promo_code,
                };

                axios
                  .post(`${url_path}/api/send_email/online_bank_transfer`, req)
                  .then((email_sent) => {
                    console.log(email_sent);
                    if (email_sent.data.data.status === "success") {
                      props.history.push({
                        pathname: "/signup-complete",
                        state: {
                          payment_code: paymentCode,
                        },
                      });
                      setIsButtonLoading(false);
                    }
                  })
                  .catch((err) => {
                    console.log(err);
                    setIsButtonLoading(false);
                  });
              } else if (payment_method === "Bank Deposit") {
                const req = {
                  to_email: confirmEmail,
                  name: firstName + " " + lastName,
                  paymentCode,
                  promoCode: promo_code,
                };

                axios
                  .post(`${url_path}/api/send_email/bank_deposit`, req)
                  .then((email_sent) => {
                    console.log(email_sent);
                    if (email_sent.data.data.status === "success") {
                      props.history.push({
                        pathname: "/signup-complete",
                        state: {
                          payment_code: paymentCode,
                        },
                      });
                      setIsButtonLoading(false);
                    }
                  })
                  .catch((err) => {
                    setIsButtonLoading(false);
                    console.log(err);
                  });
              } else if (payment_method === "Cebuana") {
                const req = {
                  to_email: confirmEmail,
                  name: firstName + " " + lastName,
                  paymentCode,
                  promoCode: promo_code,
                };

                axios
                  .post(`${url_path}/api/send_email/cebuana`, req)
                  .then((email_sent) => {
                    if (email_sent.data.data.status === "success") {
                      props.history.push({
                        pathname: "/signup-complete",
                        state: {
                          payment_code: paymentCode,
                        },
                      });
                      setIsButtonLoading(false);
                    }
                  })
                  .catch((err) => {
                    setIsButtonLoading(false);
                    console.log(err);
                  });
              }
            } else {
              setIsButtonLoading(false);
            }
          })
          .catch((error) => {
            // console.log(error.response.data.msg);
            setIsButtonLoading(false);
            setNotificationError(error.response.data.msg);
          });
      } else if (payment_method === "Credit/Debit Card" || isFree) {
        const user = {
          user_title: userTitle,
          user_first_name: firstName,
          user_middle_name: middleName,
          user_last_name: lastName,
          user_birthdate: birthDate,
          user_gender: gender,
          user_email: confirmEmail,
          user_password: confirmPassword,
          user_phone: phone,
          user_address: address,
          referal_code: referral.toUpperCase(),
          promo_code: promo_code.toUpperCase(),
          user_role: "Member",
          register_type: "manual",
          address: address,
          city: city,
          zip: zip,
          province: province,
          country_code: country_code,
          dial_code: countryCode,

          deliverAddress: deliverAddress,
          companyName: companyName,
          houseNo: houseNo,
          street: street,
          form_of_payment: isFree ? "Free Registration" : payment_method,
          receipt_no: "",
        };
        // sign up new user
        axios
          .post(`${url_path}/api/users/add`, user)
          .then((res) => {
            console.log(res);
            if (res.data.data.status === "success") {
              setCookie("register_id", res.data.user._id, 1);
              setIsButtonLoading(false);
              props.history.push("/");
            } else {
              let title = "Oops!";
              let message = res.data.data.msg;
              errorNotifIcon(title, message);
              setIsButtonLoading(false);
            }
          })
          .catch((error) => {
            console.log(error.response.data.msg);
            setNotificationError(error.response.data.msg);
          });
      }
      // else if(payment_method == "GCash"){
      //   const user = reqData();
      // }
    } else {
      setIsButtonLoading(false);
    }
  }

  function reqData() {
    var userTitle;
    if (title === "Other") {
      userTitle = others;
    } else {
      userTitle = title;
    }
    var is_home;
    if (deliverAddress === "home") {
      is_home = true;
    } else {
      is_home = false;
    }
    const user = {
      title: userTitle,
      first_name: firstName,
      middle_name: middleName,
      last_name: lastName,
      birthdate: birthDate,
      gender: gender,
      contact_no: phone,
      email: confirmEmail,
      password: confirmPassword,
      role: "Member",
      is_home: is_home,
      company: companyName,
      house_no: houseNo,
      street: street,
      address: address,
      city: city,
      province: province,
      zip: zip,
      country: country_code,
      referral_code: referral.toUpperCase(),
      promo_code: promo_code.toUpperCase(),

      register_type: "self signup",
      dial_code: countryCode,

      form_of_payment: payment_method,
      receipt_no: "",
      payment_code: paymentCode,
      source_id: "",
    };

    return user;
  }
  function onSubmitForm(state) {
    return console.log("clicked" + state.email);
  }

  useEffect(() => {
    getPromoCode();
    setNotificationError("");
    // console.log(phone)
  }, []);

  const getPromoCode = () => {
    const queryString = props.location.search;
    if (queryString !== "" && queryString.split("=")[0] === "?promo") {
      const [query] = props.location.search
        .replace(/\?/g, "")
        .split("=");
      setPromoCode(query);
    }
  };

  const togglePassword = () => {
    inputType ? setInputType(false) : setInputType(true);
  };

  const toggleConfirmPassword = () => {
    inputConfirm ? setInputConfirm(false) : setInputConfirm(true);
  };

  const cardInfo = {
    title: "Sign Up",
  };

  /*
   * Handle Wizard prev Function
   */

  const prev = () => {
    let currentStep = wizardStep;
    currentStep = currentStep > 1 && currentStep - 1;
    setWizardStep(currentStep);
  };

  /*
   * Handle Wizard Next Function
   */

  const getPromoCodeData = (currentStep) => {
    axios
      .get(`${url_path}/api/promo_code/get/${promo_code.toUpperCase()}`)
      .then((res) => {
        const promoData = res.data;
        if (promoData.data.status === "success") {
          // check if the promo is free registration
          if (promoData.registration === "Free") {
            setIsFree(true);
          }
          console.log(promoData.promo_code);
          setWizardStep(currentStep);
          setTotalPayment(promoData.promo_code.amount_to_pay);
        } else {
          console.log(promoData);
          window.scrollTo(0, 0);
          setNotificationError(promoData.data.msg);
        }
      })
      .catch((error) => {
        console.log(error.response.data.msg);
        // console.log("wew");
        setNotificationError(error.response.data.msg);
      });
  };

  const checkStep2 = (currentStep) => {
    axios
      .get(`${url_path}/api/users/check_sinup_data/${phone}/${confirmEmail}`,  {
        headers: {
          'GOOGLE_RECAPTCHA_KEY': captcha
        }})
      .then((res) => {
        const userData = res.data;
        if (userData.data.status === "success") {
          // console.log(userData.promo_code)
          setWizardStep(currentStep);
          // setTotalPayment(userData.promo_code.amount_to_pay)
        } else {
          console.log(userData);
          window.scrollTo(0, 0);
          setNotificationError(userData.data.msg);
        }
      })
      .catch((error) => {
        console.log(error);
        // console.log("wew");
        setNotificationError("Something Went Wrong.");
      });
  };

  const checkStep3 = (currentStep) => {
    // console.log(promo_code)

    axios
      .get(`${url_path}/api/users/check_sinup_data/${referral}`)
      .then((res) => {
        const userData = res.data;
        if (userData.data.status === "success") {
          // console.log(userData.promo_code)
          if (promo_code !== "") {
            getPromoCodeData(currentStep);
          } else {
            setTotalPayment(250);
            setWizardStep(currentStep);
          }

          // setTotalPayment(userData.promo_code.amount_to_pay)
        } else {
          console.log(userData);
          window.scrollTo(0, 0);
          setNotificationError(userData.data.msg);
        }
      })
      .catch((error) => {
        console.log(error.response.data.msg);
        // console.log("wew");
        setNotificationError(error.response.data.msg);
      });
  };

  const next = () => {
    let currentStep = wizardStep;
    currentStep = currentStep < 5 && currentStep + 1;

    console.log(currentStep);

    /*
     * Add statement validations before moving to the next step
     */

    if (currentStep === 2) {
      window.scrollTo(0, 0);
      console.log("------------Step 2----------------------");
      values.promo_code = promoCode;
      let inputFields =
        title !== "" &&
        firstName !== "" &&
        lastName !== "" &&
        gender !== "" &&
        birthDate !== undefined;
      let inputErrors = errors.firstName === "" && errors.lastName === "";

      if (inputFields && inputErrors) {
        setWizardStep(currentStep);
        setNotificationError("");
      } else {
        console.log("Step 1 incomplete");
        const textError = "Some fields are incorrect!";
        setNotificationError(textError);
        window.scrollTo(0, 0);
      }
    } else if (currentStep === 3) {
      console.log("------------Step 3----------------------");

      let inputFields =
        email !== "" &&
        confirmEmail !== "" &&
        password !== "" &&
        confirmPassword !== "" &&
        address !== "" &&
        country_code !== "";
      let inputErrors =
        errors.email === "" &&
        errors.confirmEmail === "" &&
        errors.password === "" &&
        errors.confirmPassword === "" &&
        errors.zip === "" &&
        errors.country_code === "";

      if (inputFields && inputErrors) {
        checkStep2(currentStep);
        // setWizardStep(currentStep);
        setNotificationError("");
        window.scrollTo(0, 0);
      } else {
        console.log("Step 2 incomplete");
        const textError = "Some fields are incorrect!";
        setNotificationError(textError);
        window.scrollTo(0, 0);
      }
    } else if (currentStep === 4) {
      window.scrollTo(0, 0);
      console.log("------------Step 4----------------------");
      setPaymentCode(Math.random().toString(36).substr(2, 7).toUpperCase());
      // const payment_code = Math.random().toString(36).substr(2, 7).toUpperCase();
      let inputFields = referral !== "" && payment_method !== "";
      let inputErrors = errors.referral === "" && errors.payment_method === "";

      if ((inputFields && inputErrors) || isFree) {
        checkStep3(currentStep);

        console.log("check total payment");
        setNotificationError("");
      } else {
        console.log("Step 2 incomplete");
        const textError = "Some fields are incorrect!";
        setNotificationError(textError);
        window.scrollTo(0, 0);
      }
    } else if (currentStep === 5) {
      window.scrollTo(0, 0);
      setWizardStep(currentStep);
      setNotificationError("");
    }
  };

  function prevButton() {
    let currentStep = wizardStep;
    if (currentStep !== 1) {
      return (
        <button
          type="button"
          className="btn-gray-outline-gray margin-top"
          onClick={prev}
        >
          Previous
        </button>
      );
    }
    return null;
  }

  function nextButton() {
    let currentStep = wizardStep;
    if (currentStep < 4) {
      return (
        <button type="button" className="btn-blue" onClick={next}>
          Next
        </button>
      );
    } else if (
      currentStep < 5 &&
      [
        "Bank Deposit",
        "Online Bank Transfer",
        "Credit/Debit Card",
        "Cebuana",
        "GCash",
      ].includes(payment_method)
    ) {
      return (
        <button
          type="button"
          className="btn-blue"
          onClick={next}
          readOnly={!termsAndCondition || !privacyPolicy}
          disabled={!termsAndCondition || !privacyPolicy}
        >
          Next
        </button>
      );
    }
    // else if (currentStep === 4) {
    //   return (
    //     <input
    //       type="submit"
    //       className="btn-blue"
    //       value="Sign up"
    //       disabled={disable}
    //     />
    //   )
    // }
    return null;
  }

  return !signupAvailability ? (
    <main className="login">
      <div>Signup Not available</div>
    </main>
  ) : (
    // signupAvailability == 'available' ? <div>Signup Not available</div> :
    <main className="login">
      {/* <Helmet>
        <meta name="viewport" content="width=device-width, initial-scale=1"/>
      </Helmet> */}
      <div className="card-body">
        {!notificationError ? (
          ""
        ) : (
          <div className="section-notification">
            <div className="container">
              <div className="notification-holder">
                <p className="notification-text">{notificationError}</p>
              </div>
            </div>
          </div>
        )}
        <Card
          cardDetails={cardInfo}
          form={
            <form className="form-wrapper" onSubmit={handleOnSubmit}>
              {/* Step 1 */}
              <Step1
                wizardStep={wizardStep}
                title={title}
                handleOnChange={handleOnChange}
                others={others}
                firstName={firstName}
                lastName={lastName}
                errors={errors}
                middleName={middleName}
                birthDate={birthDate}
                setBirthDate={setBirthDate}
                handleDateChangeRaw={handleDateChangeRaw}
                gender={gender}
                totalPayment={totalPayment}
              />

              <Step2
                wizardStep={wizardStep}
                tel={tel}
                phone={phone}
                setPhone={setPhone}
                countryCode={countryCode}
                deliverAddress={deliverAddress}
                handleChangeAddress={handleChangeAddress}
                setCountryCode={setCountryCode}
                handleOnChange={handleOnChange}
                errors={errors}
                confirmEmail={confirmEmail}
                email={email}
                password={password}
                confirmPassword={confirmPassword}
                inputType={inputType}
                togglePassword={togglePassword}
                inputConfirm={inputConfirm}
                toggleConfirmPassword={toggleConfirmPassword}
                address={address}
                city={city}
                zip={zip}
                province={province}
                country_code={country_code}
                companyName={companyName}
                houseNo={houseNo}
                street={street}
                setCaptcha={setCaptcha}
                captcha={captcha}
              />

              <Step3
                wizardStep={wizardStep}
                referral={referral}
                promo_code={promo_code}
                handleOnChange={handleOnChange}
                cardNumber={cardNumber}
                expMonth={expMonth}
                expYear={expYear}
                cvc={cvc}
                errors={errors}
                payment_mode={payment_mode}
                payment_method={payment_method}
                disable={disable}
                setIsFree={setIsFree}
                isFree={isFree}
                // deliverAddress={deliverAddress}
              />

              <Review
                wizardStep={wizardStep}
                title={title}
                others={others}
                firstName={firstName}
                lastName={lastName}
                middleName={middleName}
                birthDate={birthDate}
                gender={gender}
                tel={tel}
                phone={phone}
                email={email}
                address={address}
                city={city}
                zip={zip}
                province={province}
                paymentMethod={payment_method}
                companyName={companyName}
                houseNo={houseNo}
                street={street}
                totalPayment={totalPayment}
                submitHere={submitHere}
                isButtonloading={isButtonloading}
                termsAndCondition={termsAndCondition}
                setTermsAndCondition={setTermsAndCondition}
                privacyPolicy={privacyPolicy}
                setPrivacyPolicy={setPrivacyPolicy}
                isFree={isFree}
                // country_code={country_code}
              />

              <BankDeposit
                wizardStep={wizardStep}
                paymentMethod={payment_method}
                totalPayment={totalPayment}
                paymentCode={paymentCode}
                handleOnSubmit={submitHere}
                isButtonloading={isButtonloading}
              />

              <Cebuana
                wizardStep={wizardStep}
                paymentMethod={payment_method}
                totalPayment={totalPayment}
                paymentCode={paymentCode}
                handleOnSubmit={submitHere}
                isButtonloading={isButtonloading}
              />

              <OnlineBankTransfer
                wizardStep={wizardStep}
                paymentMethod={payment_method}
                totalPayment={totalPayment}
                paymentCode={paymentCode}
                handleOnSubmit={submitHere}
                isButtonloading={isButtonloading}
              />

              <PayMongoPayment
                wizardStep={wizardStep}
                paymentMethod={payment_method}
                totalPayment={totalPayment}
                handleOnSubmit={submitHere}
                history={props.history}
                promoCode={promo_code}
                isButtonloading={isButtonloading}
              />

              <GCashPayment
                wizardStep={wizardStep}
                paymentMethod={payment_method}
                totalPayment={totalPayment}
                handleOnSubmit={submitHere}
                history={props.history}
                promoCode={promo_code}
                isButtonloading={isButtonloading}
                reqData={reqData}
              />

              <div className="form-btn-group">
                {nextButton()}
                {prevButton()}
              </div>

              {/* {cta} */}
              <div>
                <p className="cta">
                  Already have an account?{" "}
                  <Link to="/">
                    <span>Log in</span>
                  </Link>
                </p>
              </div>
              {/* {close} */}
            </form>
          }
        />
      </div>
    </main>
  );
}

export default withRouter(Signup);

function Step1(props) {
  if (props.wizardStep !== 1) {
    return null;
  }

  const {
    title,
    others,
    firstName,
    handleOnChange,
    errors,
    middleName,
    birthDate,
    setBirthDate,
    handleDateChangeRaw,
    lastName,
    gender,
  } = props;

  return (
    <>
      <h5>Step 1</h5>
      <small className="form-note">
        Note*: (First name, last name and date of birth must be same as on your
        I.D or Passport)
      </small>

      <div className="form-field select">
        <select
          id="title"
          className="form-input select"
          name="title"
          value={title}
          onChange={handleOnChange}
          required
          // disabled={isRoleDisabled}
        >
          <option value=""></option>
          <option value="Mr">Mr.</option>
          <option value="Mrs">Mrs.</option>
          <option value="Ms">Ms</option>
          <option value="Miss">Miss</option>
          <option value="Other">Others</option>
        </select>
        <label htmlFor="title">
          Title
          <IconCaretDown className="form-select-caret-down" />
        </label>
      </div>
      {title === "Other" ? (
        <div className="form-field">
          <input
            id="others"
            className="form-input"
            type="text"
            name="others"
            value={others}
            onChange={handleOnChange}
            required
          />
          <label htmlFor="others">Others</label>
        </div>
      ) : (
        ""
      )}
      <div className="form-field">
        <input
          id="firstName"
          className="form-input"
          type="text"
          name="firstName"
          value={firstName}
          onChange={handleOnChange}
          required
        />
        <label htmlFor="firstName">
          First name<span className="text-required">*</span>
        </label>
        {errors.firstName && (
          <span className="form-error">{errors.firstName}</span>
        )}
      </div>
      <div className="form-field">
        <input
          id="middleName"
          className={`form-input ${!middleName ? "not-required" : ""}`}
          type="text"
          name="middleName"
          value={middleName}
          onChange={handleOnChange}
        />
        <label htmlFor="middleName">Middle name</label>
      </div>
      <div className="form-field">
        <input
          id="lastName"
          className="form-input"
          type="text"
          name="lastName"
          value={lastName}
          onChange={handleOnChange}
          required
        />
        <label htmlFor="lastName">
          Last name<span className="text-required">*</span>
        </label>
        {errors.lastName && (
          <span className="form-error">{errors.lastName}</span>
        )}
      </div>
      <div className="form-field calendar">
        <label htmlFor="startDate">
          Date of Birth<span className="text-required">*</span>
          <IconCalendar className="form-input-calendar" />
        </label>
        <DatePicker
          selected={birthDate}
          onChange={(date) => setBirthDate(date)}
          peekNextMonth
          showMonthDropdown
          showYearDropdown
          dropdownMode="select"
          // selectsStart
          // dateFormat="MMM dd, yyyy"
          // startDate={startDate}
          // endDate={endDate}
          // maxDate={endDate}
          onChangeRaw={handleDateChangeRaw}
          className="form-input date"
          name="startDate"
          placeholderText="Oct 29, 2019"
          autoComplete="off"
          id="startDate"
          required
        />
      </div>
      {/* <small className="form-note">(First name, Last name and date of birth must be same as on your I.D or passport)</small> */}
      <div className="form-field select">
        <select
          id="gender"
          className="form-input select"
          name="gender"
          value={gender}
          onChange={handleOnChange}
          required
          // disabled={isRoleDisabled}
        >
          <option value="Male">Male</option>
          <option value="Female">Female</option>
        </select>
        <label htmlFor="gender">
          Gender
          <IconCaretDown className="form-select-caret-down" />
        </label>
      </div>
    </>
  );
}

function Step2(props) {
  if (props.wizardStep !== 2) {
    return null;
  }

  const {
    phone,
    setPhone,
    handleOnChange,
    setCountryCode,
    // countryCode,
    errors,
    confirmEmail,
    email,
    password,
    confirmPassword,
    togglePassword,
    inputType,
    // toggleConfirmPassword,
    // inputConfirm,

    address,
    city,
    zip,
    province,
    country_code,
    handleChangeAddress,

    deliverAddress,
    companyName,
    street,
    houseNo,

    
    setCaptcha,
  } = props;
  const handlePhoneChanges = (value, data, event) => {
    // console.log(data.dialCode)
    // console.log(value);
    setPhone(value);
    setCountryCode(data.dialCode);
    // console.log(countryCode);
  };

  return (
    <>
      <h5>Step 2</h5>
      <small>&nbsp;</small>

      <div className="form-field">
        <PhoneInput
          inputClass={["form-input"]}
          enableAreaCodes={true}
          countryCodeEditable={false}
          autoFormat={true}
          country={"ph"}
          // regions={'asia'}
          value={phone}
          enableSearch={true}
          onChange={handlePhoneChanges}
          // onClick={() => {handlePhoneChange()}}
          required
        />
      </div>
      <div className="form-field">
        <input
          id="email"
          className="form-input"
          type="text"
          name="email"
          value={email}
          onChange={handleOnChange}
          required
        />
        <label htmlFor="email">
          Email<span className="text-required">*</span>
        </label>
        {errors.email && <span className="form-error">{errors.email}</span>}
      </div>
      <div className="form-field">
        <input
          id="confirmEmail"
          className="form-input"
          type="text"
          name="confirmEmail"
          value={confirmEmail}
          onChange={handleOnChange}
          required
        />
        <label htmlFor="confirmEmail">
          Confirm Email<span className="text-required">*</span>
        </label>
        {errors.confirmEmail && (
          <span className="form-error">{errors.confirmEmail}</span>
        )}
      </div>
      <div className="form-field">
        <input
          id="password"
          className="form-input"
          type={inputType ? "password" : "text"}
          name="password"
          value={password}
          onChange={handleOnChange}
          required
        />
        <label htmlFor="password">
          Password<span className="text-required">*</span>
        </label>
        {errors.password && (
          <span className="form-error">{errors.password}</span>
        )}
        <span className="password__show" onClick={togglePassword}>
          <img src={inputType ? iconShow : iconHide} alt="" />
        </span>
      </div>
      <div className="form-field">
        <input
          id="confirmPassword"
          className="form-input"
          type={inputType ? "password" : "text"}
          name="confirmPassword"
          value={confirmPassword}
          onChange={handleOnChange}
          required
        />
        <label htmlFor="confirmPassword">
          Confirm Password<span className="text-required">*</span>
        </label>
        {errors.confirmPassword && (
          <span className="form-error">{errors.confirmPassword}</span>
        )}
        {/* <span className="password__show" onClick={togglePassword}>
          <img src={inputType ? iconShow : iconHide} alt="" />
        </span> */}
      </div>

      <div className="radio-wrapper">
        <div className="form-field">
          <label className="radio">
            <input
              type="radio"
              value="home"
              checked={deliverAddress === "home"}
              onChange={handleChangeAddress}
            />
            <span className="checkmark"></span>
          </label>
          <p className="radio-text">Home</p>
        </div>
        <div className="form-field">
          <label className="radio">
            <input
              type="radio"
              value="work"
              checked={deliverAddress === "work"}
              onChange={handleChangeAddress}
            />
            <span className="checkmark"></span>
          </label>
          <p className="radio-text">Work</p>
        </div>
      </div>

      {deliverAddress !== "home" ? (
        <div className="form-field">
          <input
            type="text"
            name="companyName"
            className="form-input not-required"
            id="companyName"
            value={companyName}
            onChange={handleOnChange}
          />
          <label htmlFor="companyName">Company Name</label>
        </div>
      ) : (
        <></>
      )}

      <div className="form-col">
        {deliverAddress === "home" ? (
          <div className="form-field">
            <input
              type="number"
              name="houseNo"
              className="form-input"
              id="houseNo"
              value={houseNo}
              onChange={handleOnChange}
              required
            />
            <label htmlFor="houseNo">House No.</label>
          </div>
        ) : (
          <div className="form-field">
            <input
              type="number"
              name="bldgNo"
              className="form-input"
              id="bldgNo"
              value={houseNo}
              onChange={handleOnChange}
              required
            />
            <label htmlFor="houseNo">Bldg. No.</label>
          </div>
        )}
        <div className="form-field">
          <input
            type="text"
            name="street"
            className="form-input"
            id="street"
            value={street}
            onChange={handleOnChange}
            required
          />
          <label htmlFor="street">Street</label>
        </div>
      </div>

      <div className="form-field">
        <input
          id="address"
          className="form-input"
          type="text"
          name="address"
          value={address}
          onChange={handleOnChange}
          required
        />
        <label htmlFor="address">
          Address<span className="text-required">*</span>
        </label>
        {errors.address && <span className="form-error">{errors.address}</span>}
      </div>
      <div className="form-group">
        <div className="form-field">
          <input
            id="city"
            type="text"
            className="form-input"
            name="city"
            value={city}
            onChange={handleOnChange}
            required
          />
          <label htmlFor="city">
            City<span className="text-required">*</span>
          </label>
          {errors.city && <span className="form-error">{errors.city}</span>}
        </div>
        <div className="form-field">
          <input
            id="zip"
            type="text"
            className="form-input"
            name="zip"
            value={zip}
            onChange={handleOnChange}
            required
          />
          <label htmlFor="zip">Zip</label>
          {errors.zip && <span className="form-error">{errors.zip}</span>}
        </div>
      </div>
      <div className="form-group">
        <div className="form-field">
          <input
            id="province"
            type="text"
            className="form-input"
            name="province"
            value={province}
            onChange={handleOnChange}
            required
          />
          <label htmlFor="province">
            Province<span className="text-required">*</span>
          </label>
          {errors.province && (
            <span className="form-error">{errors.province}</span>
          )}
        </div>
        <div className="form-field">
          <input
            id="country_code"
            type="text"
            className="form-input"
            name="country_code"
            value={country_code}
            onChange={handleOnChange}
            required
          />
          <label htmlFor="country_code">
            Country<span className="text-required">*</span>
          </label>
          {errors.country_code && (
            <span className="form-error">{errors.country_code}</span>
          )}
        </div>
      </div>
      <ReCAPTCHA
        sitekey={captchakey}
        onChange={(e) => {
          console.log(e);
          setCaptcha(e)}}
        required
      />  
    </>
  );
}

function Step3(props) {
  if (props.wizardStep !== 3) {
    return null;
  }

  const {
    referral,
    promo_code,
    handleOnChange,
    // cardNumber,
    // expMonth,
    // expYear,
    // cvc,
    // totalPayment,
    // errors,
    // payment_mode,
    payment_method,
    // disable,
    setIsFree,
    isFree,
  } = props;
  // console.log(promo_code);
  const checkPromoCode = () => {
    // console.log(promo_code);
    if (promo_code.length === 6) {
      axios
        .get(`${url_path}/api/promo_code/get/${promo_code.toUpperCase()}`)
        .then((res) => {
          const promoData = res.data;
          // console.log(promoData);
          if (promoData.data.status === "success") {
            // check if the promo is free registration
            if (promoData.registration === "Free") {
              setIsFree(true);
            } else {
              setIsFree(false);
            }
            console.log(promoData);
          } else {
            setIsFree(false);
          }
        })
        .catch((error) => {
          setIsFree(false);
          console.log(error.response.data.msg);
        });
      // console.log("check promo code");
    } else {
      setIsFree(false);
    }
    return promo_code;
  };
  console.log(isFree);
  return (
    <>
      <h5>Step 3</h5>
      <small>&nbsp;</small>
      <div className="form-field">
        <input
          id="referral"
          className="form-input"
          type="text"
          name="referral"
          value={referral}
          onChange={handleOnChange}
          required
        />
        <label htmlFor="referral">
          Referral Code<span className="text-required">*</span>
        </label>
        <small className="text-muted b">
          (If you don't have a referral code, please email us at{" "}
          <a
            href="mailto:referrals@infinityprilege.com"
            className="text-primary"
          >
            referrals@infinityprivilege.com
          </a>{" "}
          or send a message to this #)
        </small>
      </div>
      <div className="form-field">
        <input
          id="promo_code"
          className="form-input"
          type="text"
          name="promo_code"
          value={checkPromoCode()}
          onChange={handleOnChange}
        />
        <label htmlFor="promo_code">Promo Code (Optional)</label>
        {/* <small className="text-muted b">
          (Note: If you have a promo code, your payment will be discounted)
        </small> */}
      </div>
      {isFree === true ? null : (
        <div className="form-field select">
          <select
            id="payment_method"
            className="form-input"
            name="payment_method"
            value={payment_method}
            onChange={handleOnChange}
            required
          >
            <option value="">Select payment method</option>
            <option value="Credit/Debit Card">Credit/Debit Card</option>
            {/* <option value="PayPal">PayPal</option> */}
            <option value="Online Bank Transfer">Online Banking</option>
            <option value="Bank Deposit">Bank Deposit</option>
            <option value="Cebuana">Cebuana</option>
            <option value="GCash">GCash</option>
          </select>
          <label htmlFor="payment_method">
            Payment Method
            <IconCaretDown className="form-select-caret-down" />
          </label>
        </div>
      )}

      {/* <input
        type="submit"
        className="btn-blue"
        value="Sign up"
        disabled={disable}
      /> */}
    </>
  );
}

function Review(props) {
  if (props.wizardStep !== 4) {
    return null;
  }

  const {
    title,
    firstName,
    middleName,
    lastName,
    // birthDate,
    gender,

    city,
    province,
    // country_code,
    zip,
    phone,
    email,
    disable,
    paymentMethod,
    companyName,
    houseNo,
    street,
    totalPayment,

    submitHere,
    isButtonloading,

    termsAndCondition,
    setTermsAndCondition,
    privacyPolicy,
    setPrivacyPolicy,
    isFree,
  } = props;
  // console.log(promo_code);
  return (
    <>
      <h5>Review</h5>
      <small>&nbsp;</small>
      <p className="note">
        Please verify the information you've given us below. Make sure that your
        first name, middle name, last name, and date of birth are accurate. Once
        sent, you can not change these details. Or you can call helpdesk for
        more information
      </p>
      <div className="review-wrapper">
        <div className="review-holder">
          <p>Title:</p>
          <p>{title}</p>
        </div>
        <div className="review-holder">
          <p>First name:</p>
          <p>{firstName}</p>
        </div>
        <div className="review-holder">
          <p>Middle name:</p>
          <p>{middleName}</p>
        </div>
        <div className="review-holder">
          <p>Last name:</p>
          <p>{lastName}</p>
        </div>
        {/* <div className="review-holder">
          <p>Date of birth:</p>
          <p>{birthDate}</p>
        </div> */}
        <div className="review-holder">
          <p>Gender:</p>
          <p>{gender}</p>
        </div>
        <div className="review-holder">
          <p>Company name:</p>
          <p>{companyName}</p>
        </div>
        <div className="review-holder">
          <p>House No.</p>
          <p>{houseNo}</p>
        </div>
        <div className="review-holder">
          <p>Street</p>
          <p>{street}</p>
        </div>
        <div className="review-holder">
          <p>City:</p>
          <p>{city}</p>
        </div>
        <div className="review-holder">
          <p>Province:</p>
          <p>{province}</p>
        </div>
        <div className="review-holder">
          <p>Zip code:</p>
          <p>{zip}</p>
        </div>
        <div className="review-holder">
          <p>Phone number:</p>
          <p>{phone}</p>
        </div>
        <div className="review-holder">
          <p>Email address:</p>
          <p>{email}</p>
        </div>
        {isFree ? (
          <div className="review-holder">
            <p>No Payment:</p>
            <p>Free Registration</p>
          </div>
        ) : (
          <>
            <div className="review-holder">
              <p>Payment Method:</p>
              <p>{paymentMethod}</p>
            </div>
            <div className="review-holder">
              <p>Total Payment:</p>
              <p>{totalPayment}</p>
            </div>
          </>
        )}
      </div>

      <div className="radio-wrapper terms-privacy">
        <div className="form-field">
          <label className="radio">
            <input
              type="checkbox"
              value="Terms and Condition"
              checked={termsAndCondition}
              onChange={() => setTermsAndCondition(!termsAndCondition)}
            />
            <span className="checkmark"></span>
          </label>
          <p className="radio-text">
            I have read{" "}
            <a
              className="link"
              href="https://infinityprivilege.com/terms-and-conditions/"
              target="_blank"
              rel="noopener noreferrer"
            >
              Terms and Conditions
            </a>
          </p>
        </div>
        <div className="form-field">
          <label className="radio">
            <input
              type="checkbox"
              value="Privacy Policy"
              checked={privacyPolicy}
              onChange={() => setPrivacyPolicy(!privacyPolicy)}
            />
            <span className="checkmark"></span>
          </label>
          <p className="radio-text">
            I have read{" "}
            <a
              className="link"
              href="https://infinityprivilege.com/privacy-policy/"
              target="_blank"
              rel="noopener noreferrer"
            >
              Privacy Policy
            </a>
          </p>
        </div>
      </div>

      {![
        "Bank Deposit",
        "Online Bank Transfer",
        "Credit/Debit Card",
        "Cebuana",
        "GCash",
      ].includes(paymentMethod) && paymentMethod === "PayPal" ? (
        isButtonloading ? (
          <button class="btn-blue-loading disabled">
            <span class="load open"></span> Please wait...
          </button>
        ) : (
          <input
            type="button"
            className="btn-blue"
            value="Proceed"
            disabled={!termsAndCondition || !privacyPolicy || disable}
            onClick={submitHere}
            readOnly={!termsAndCondition || !privacyPolicy}
          />
        )
      ) : null}
    </>
  );
}
