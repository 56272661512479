import React, { useEffect, useState } from "react";
import Breadcrumbs from "../../components/Breadcrumbs";
import Table from "../../components/Table";
import moment from "moment";
import axios from "axios";
import { url_path } from "../../constant";
import { successNotif, errorNotifIcon } from "../../lib/helpers/notifications";
import Sweetalert2 from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

const ManagePendingMembers = () => {
  // States
  const [loading, setLoading] = useState(false);
  const [searchInput, setSearchInput] = useState("");
  const [tableData, setTableData] = useState([]);
  const [pagination, setPagination] = useState({});

  // Variables
  const tableHeader = [
    {
      Header: "Date",
      id: "date",
      width: 200,
      Cell: (row) => moment(row.original.created_at).format("ll"),
    },
    {
      Header: "Name",
      id: "name",
      Cell: (row) => row.original.first_name + " " + row.original.last_name,
    },
    {
      Header: "Application Reference",
      accessor: "payment_code",
      width: 200,
    },
    {
      Header: "Email",
      accessor: "email",
    },
    {
      Header: "Contact No.",
      accessor: "contact_no",
    },
    {
      Header: "Activation",
      accessor: "action",
      width: 200,
      Cell: (row) => (
        <>
          <button
            className="btn-blue btn-sm rightSpace"
            type="button"
            onClick={() => activateUser(row.original._id)}
          >
            Activate
          </button>
          <button
            className="btn-red btn-sm"
            type="button"
            onClick={() => removeUser(row.original._id)}
          >
            Remove
          </button>
        </>
      ),
    },
  ];

  const Swal = withReactContent(Sweetalert2);

  // Functions
  const getData = async (pageNumber = 1, search = "") => {
    setLoading(true);

    try {
      const req = { pageNumber, search };

      const { data } = await axios.post(`${url_path}/api/pending_members`, req);
      setTableData(data.pending_members);
      setPagination(data.paginate);
      console.log(data);
      setLoading(false);
    } catch (err) {
      console.log(err);
    }
  };

  const activateUser = async (pending_id) => {
    const { value } = await Swal.fire({
      title: "Activate User?",
      icon: "question",
      showCancelButton: true,
      confirmButtonColor: "#106397",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes",
    });

    if (value) {
      setLoading(true);

      try {
        const req = { pending_id };
        const { data: user_data } = await axios.put(
          `${url_path}/api/pending_members/activate`,
          req
        );
        const user = {
          user_title: user_data.title,
          user_first_name: user_data.first_name,
          user_middle_name: user_data.middle_name,
          user_last_name: user_data.last_name,
          user_birthdate: user_data.birthdate,
          user_gender: user_data.gender,
          user_email: user_data.email,
          user_password: user_data.password,
          user_phone: user_data.contact_no,
          user_address: user_data.address,
          referal_code: user_data.referral_code,
          promo_code: user_data.promo_code,
          user_role: user_data.role,
          is_home: user_data.is_home,
          register_type: "manual",
          address: user_data.address,
          city: user_data.city,
          zip: user_data.zip,
          province: user_data.province,
          country_code: user_data.country,
          dial_code: user_data.dial_code,

          deliverAddress: user_data.is_home ? "home" : "work",
          companyName: user_data.company,
          bldg_no: user_data.bldg_no,
          houseNo: user_data.house_no,
          street: user_data.street,

          form_of_payment: user_data.form_of_payment,
          receipt_no: "",
        };
        const { data: result } = await axios.post(
          `${url_path}/api/users/add`,
          user
        );

        console.log(result);
        if (result.data.status !== "success") {
          errorNotifIcon("Oops!", result.data.msg);
        } else {
          successNotif("Awesome!", "User has been activated successfully.");
        }

        getData();
      } catch (err) {
        console.log(err);
      }
    }
  };

  const removeUser = async (pending_id) => {
    const { value } = await Swal.fire({
      title: "Remove User?",
      icon: "question",
      showCancelButton: true,
      confirmButtonColor: "#d33",
      cancelButtonColor: "#106397",
      confirmButtonText: "Yes",
    });

    if (value) {
      setLoading(true);

      try {
        const req = { pending_id };
        console.log(req);
        const { data: result } = await axios.put(
          `${url_path}/api/pending_members/remove`,
          req
        );

        if (result.data.status !== "success") {
          errorNotifIcon("Oops!", result.data.msg);
        } else {
          successNotif("Awesome!", "User has been removed successfully.");
        }

        getData();
      } catch (err) {
        console.log(err);
      }
    }
  };

  const handleSearchSubmit = (event) => {
    event.preventDefault();
    getData(1, searchInput);
  };

  const handleSearchInput = (event) => {
    setSearchInput(event.target.value);
  };

  const handleResetSearch = () => {
    setSearchInput("");
    getData();
  };

  // Life-cycles
  useEffect(() => {
    getData();
  }, []);

  return (
    <main className="pending-membership manage-pages">
      <div className="container custom-container">
        <Breadcrumbs
          size="text-large"
          placeholder="Manage Pending Members"
          targetLink="/admin-dashboard"
          customClassName="m-3"
        />

        <div className="section-table">
          <form onSubmit={handleSearchSubmit}>
            <div className="search-holder">
              <label htmlFor="searchInput">Search: </label>
              <input
                size="large"
                name="searchInput"
                label="Search"
                value={searchInput}
                onChange={handleSearchInput}
                className="search-input"
              />
              <button type="submit" className="btn-blue">
                Search
              </button>
              <button
                type="reset"
                className="btn-blue"
                onClick={handleResetSearch}
              >
                Clear
              </button>
            </div>
          </form>

          {loading ? (
            <div className="lds-ellipsis">
              <div className="dots"></div>
              <div className="dots"></div>
              <div className="dots"></div>
              <div className="dots"></div>
            </div>
          ) : (
            <Table
              tableHeader={tableHeader}
              tableData={tableData}
              paginate={pagination}
              pageFunction={getData}
            />
          )}
        </div>
      </div>
    </main>
  );
};

export default ManagePendingMembers;
