import React, { useEffect } from 'react'
import Breadcrumbs from '../../components/Breadcrumbs'
import {Link} from 'react-router-dom'
import Sidebar from '../../components/Sidebar'
import {authenticateMemberPage} from '../../lib/helpers/validation'
function InfinityCard({ history }) {
  useEffect(() => {
    authenticateMemberPage(history)
  }, []);
  return (
    <main className="infinity-account member-dashboard">
      <Sidebar history={history}/>
      <div className="content-wrapper">
        <Breadcrumbs placeholder='Back' size='text-large' targetLink='/member-dashboard'/>
        <div className="infinity-account-wrapper">
          <div className="container">
            <div className="items-wrapper">
              <p className="heading">My Infinity Account</p>
              <Link className="btn-blue" to="/my-infinity-account-account">
                Account
              </Link>
              <Link className="btn-blue" to="/my-infinity-account-transaction">
                Account Transaction
              </Link>
              <Link className="btn-blue" to="/my-infinity-account-transfer-points">
                Transfer Points
              </Link>
              {/* <Link className="btn-blue" to="/my-infinity-account-encash-points">
                Encash Points
              </Link> */}
              <Link className="btn-blue" to="/my-infinity-account-personal-data">
                Personal Data
              </Link>
              <Link className="btn-blue" to="/my-infinity-account-contact-details">
                Contact Details
              </Link>
              <Link className="btn-blue" to="/my-infinity-referrals">
                My Referrals
              </Link>
              <Link className="btn-blue" to="/my-infinity-account-change-password">
                Change Password
              </Link>
              {/* <Link className="btn-blue" to="/my-infinity-account-sms-info">
                SMS Info
              </Link> */}
            </div>
          </div>
        </div>
      </div>
    </main>
  );
}

export default InfinityCard
