import React, { useEffect, useState } from "react";
import { url_path } from "../../constant";
import {
  successNotif,
  errorNotifIcon,
} from "../../lib/helpers/notifications.js";
import { getCookie } from "../../lib/helpers/cookies";
import Card from "../../components/Card";
import axios from "axios";
import Table from "../../components/Table";
import ReactTooltip from "react-tooltip";
import Modal from "react-bootstrap/Modal";
import Sweetalert2 from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

// import { ReactComponent as IconCaretDown } from "../../assets/images/icon-caret-down.svg";
import { ReactComponent as IconDelete } from "../../assets/images/icon-delete.svg";
import { ReactComponent as IconEdit } from "../../assets/images/icon-edit.svg";

const Swal = withReactContent(Sweetalert2);

let cardDetails = {
  title: "Add Discount",
  hasClose: true,
  hasMessage: false,
  customClassName: "no-margin no-shadow",
};

const DiscountDeals = ({ data, user_id }) => {
  const [currentDiscount, setCurrentDiscount] = useState({
    id: "",
    title: "",
    discount: "",
  });
  const [loading, setLoading] = useState(true);
  // const [updateDiscount, setUpdateDiscount] = useState(false);
  // const [dataFound, setDataFound] = useState(false);
  const [action, setAction] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [discountList, setDiscountList] = useState([]);

  const heading = [
    {
      Header: "No",
      maxWidth: 50,
      filterable: false,
      Cell: (row) => row.index + 1,
    },
    {
      Header: "Title",
      id: "title",
      accessor: "title",
    },
    {
      Header: "Discount",
      id: "discount",
      accessor: "discount",
    },
    {
      Header: "Action",
      id: "action",
      Cell: (row) => (
        <div className="button-wrapper">
          <div className="button-holder">
            <button
              className="btn-icon"
              onClick={() => handleShowEditModal(row.index)}
            >
              <span className="icon-holder">
                <IconEdit className="icon-actions" data-tip data-for="edit" />
              </span>
            </button>
            <ReactTooltip id="edit" type="warning" effect="solid">
              <span>Edit</span>
            </ReactTooltip>

            <button
              className="btn-icon"
              onClick={() => handleRemoveDiscount(row.original._id)}
            >
              <span className="icon-holder">
                <IconDelete
                  className="icon-actions"
                  data-tip
                  data-for="delete"
                />
              </span>
            </button>
            <ReactTooltip id="delete" type="warning" effect="solid">
              <span>Delete</span>
            </ReactTooltip>
          </div>
        </div>
      ),
    },
  ];

  // var numberRegex = /^(09|\+639|9)\d{9}$/;
  // var numberRegex = /\d/;

  useEffect(() => {
    getDiscountData();
  }, []);

  // const handleEditButton = () => {
  //   setUpdateDiscount(true);
  //   setDataFound(false);
  //   setAction("update");
  //   values.title = currentDiscount.title;
  //   values.discount = currentDiscount.discount;
  //   console.log("update");
  // };
  // const handleSetButton = () => {
  //   setUpdateDiscount(true);
  //   setDataFound(false);
  //   setAction("add");
  //   console.log("add");
  // };

  // const handleCancelButton = () => {
  //   // setUpdateDiscount(true);
  //   setDataFound(true);
  //   // setAction('add');
  //   // console.log('add');
  // };
  // const handleUpdateDiscount = () => {
  //   // setUpdateDiscount(true);
  //   // const business_partner_id = currentDiscount.business_partner_id
  //   // const offer_id = event.target.value
  //   // console.log(offer_id);
  //   // const modifiedDiscount = discount.replace(/./g, "");
  //   const req = {
  //     business_partner_id: user_id,
  //     title: currentDiscount.title,
  //     discount: currentDiscount.discount,
  //     status: true
  //   };
  //   // return console.log(action);
  //   if (action == "add") {
  //     axios.post(`${url_path}/api/business_discount`, req).then(res => {
  //       const discountData = res.data;
  //       if (discountData.data.status === "success") {
  //         // setLoader(false);
  //         let title = "Well Done!";
  //         let message = discountData.data.msg;
  //         successNotif(title, message);
  //         getDiscountData();
  //       } else {
  //         // setLoader(false);
  //         let title = "Sorry!";
  //         let message = discountData.data.msg;
  //         errorNotifIcon(title, message);
  //       }
  //     });
  //   } else {
  //     axios
  //       .post(`${url_path}/api/business_discount/update/${user_id}`, req)
  //       .then(res => {
  //         const discountData = res.data;
  //         if (discountData.data.status === "success") {
  //           // setLoader(false);
  //           let title = "Well Done!";
  //           let message = discountData.data.msg;
  //           successNotif(title, message);
  //           getDiscountData();
  //         } else {
  //           // setLoader(false);
  //           let title = "Sorry!";
  //           let message = discountData.data.msg;
  //           errorNotifIcon(title, message);
  //         }
  //       });
  //   }

  //   // axios.post(`${url_path}/api/business_discount/update/${ user_id }`, req)
  //   //   .then(res => {
  //   //     getDiscountData()
  //   //   })
  // };

  // Load offers
  const getDiscountData = async () => {
    setLoading(true);

    try {
      const { data: discount_list } = await axios.get(
        `${url_path}/api/business_discount/${user_id}`
      );

      if (discount_list.status === "success") {
        setDiscountList(discount_list.business_discount);
        setLoading(false);
      }
    } catch (err) {
      console.error(err);
    }

    // axios
    //   .get(`${url_path}/api/business_discount/${user_id}`)
    //   .then(business_discount => {
    //     if (business_discount.data.data.status == "success") {
    //       setDataFound(true);
    //       setCurrentDiscount(business_discount.data.business_discount);
    //       setLoading(false);
    //       console.log("if");
    //       // console.log(business_discount.data);
    //     } else {
    //       // let title = "Oops!";
    //       // let message = business_discount.data.data.msg;
    //       // errorNotifIcon(title, message);
    //       setDataFound(false);
    //       setLoading(false);
    //       console.log("else");
    //       // console.log(business_discount.data);
    //     }
    //   })
    //   .catch(err => console.log(err));
  };

  // Handlers
  const handleShowAddModal = () => {
    setAction("Add");
    setShowModal(true);
  };

  const handleShowEditModal = (index) => {
    setAction("Edit");
    setCurrentDiscount({
      id: discountList[index]._id,
      title: discountList[index].title,
      discount: discountList[index].discount,
    });
    setShowModal(true);
  };

  const handleHideModal = () => {
    setCurrentDiscount({
      id: "",
      title: "",
      discount: "",
    });
    setShowModal(false);
  };

  const handleRemoveDiscount = async (id) => {
    const bp_discount_id = id;

    try {
      const { value } = await Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
      });

      if (value) {
        const token = getCookie("token");

        const { data: response} = await axios.delete(
          `${url_path}/api/business_discount/${bp_discount_id}`,
          { headers: { "x-auth-token": token } }
        );

        console.log(response)

        successNotif("Well done!", "Discount has been removed successfully.");

        getDiscountData();
      }
    } catch (err) {
      console.error(err);
    }
  };

  const handleOnSubmit = async (event) => {
    event.preventDefault();

    try {
      if (action === "Add") {
        const req = {
          ...currentDiscount,
          business_partner_id: user_id,
          status: true,
        };

        const { data: response } = await axios.post(
          `${url_path}/api/business_discount/`,
          req
        );

        if (response.data.status === "success") {
          successNotif("Well done!", "Discount has been added successfully.");
        } else {
          errorNotifIcon("Oops!", "Something went wrong :( please try again.");
        }
      } else if (action === "Edit") {
        const req = {
          ...currentDiscount,
          status: true,
        };

        const { data: response } = await axios.post(
          `${url_path}/api/business_discount/update`,
          req
        );

        if (response.data.status === "success") {
          successNotif("Well done!", "Discount has been updated successfully.");
        } else {
          errorNotifIcon("Oops!", "Something went wrong :( please try again.");
        }
      }
      setCurrentDiscount({
        id: "",
        title: "",
        discount: "",
      });
      setShowModal(false);
      getDiscountData();
    } catch (err) {
      console.error(err);
    }
  };

  const handleDiscountChange = (event) => {
    const { value, name } = event.target;

    setCurrentDiscount({
      ...currentDiscount,
      [name]: value,
    });
  };

  return (
    <div className="tab-point-offer">
      <p className="heading">Discount Deals</p>
      {loading ? (
        <div className="lds-ellipsis">
          <div className="dots"></div>
          <div className="dots"></div>
          <div className="dots"></div>
          <div className="dots"></div>
        </div>
      ) : (
        <>
          <div className="section-table">
            <Table tableHeader={heading} tableData={discountList} />
          </div>
          <button className="btn-blue right" onClick={handleShowAddModal}>
            Add Discount
          </button>
        </>
      )}

      <Modal
        show={showModal}
        onHide={handleHideModal}
        className="modal-dark- opacity"
      >
        <Card
          cardDetails={cardDetails}
          form={
            <form className="form-wrapper" onSubmit={handleOnSubmit}>
              <div className="form-field">
                <input
                  id="title"
                  className="form-input"
                  type="text"
                  name="title"
                  value={currentDiscount.title}
                  onChange={handleDiscountChange}
                />
                <label htmlFor="title">Title</label>
              </div>
              <div className="form-field">
                <input
                  id="discount"
                  className="form-input"
                  type="number"
                  name="discount"
                  value={currentDiscount.discount}
                  onChange={handleDiscountChange}
                />
                <label htmlFor="discount">Discount(%)</label>
              </div>

              <div className="form-btn-wrapper form-btn-dual-wrapper">
                <input
                  type="button"
                  className="btn-d btn-gray"
                  value="Cancel"
                  onClick={handleHideModal}
                ></input>
                <input
                  type="submit"
                  className="btn-d btn-blue"
                  value="Save Changes"
                  disabled={!currentDiscount.title || !currentDiscount.discount}
                />
              </div>
            </form>
          }
        />
      </Modal>
    </div>
  );
};

export default DiscountDeals;
