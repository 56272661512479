import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import Card from "../../components/Card";
import "../../stylesheets/main.scss";

function ForgotSuccess(props) {
  // const { back, button } = props;

  const cardInfo = {
    title: "Payment has been Cancelled",
    // closeLink: '/forgot-password',
    // hasBack: true,
    hasMessage: true,
    message: "Thank you for choosing IPC.",
    customClassName: "has-back-button",
  };

  useEffect(() => {
    // getData();
  }, []);

  return (
    <main className="login">
      <div className="card-body">
        <Card
          cardDetails={cardInfo}
          form={
            <span>
              {/* <Breadcrumbs 
              size = 'text-large'
              // placeholder = 'Back'
              targetLink = '/forgot-password'
            /> */}
              {/* {button} */}
              <Link to="/signup">
                <button className="btn-blue">Back to Signup</button>
              </Link>
            </span>
          }
        />
      </div>
    </main>
  );
}

export default ForgotSuccess;
