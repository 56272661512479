import React,{ useState, useEffect } from 'react'
import Modal from 'react-bootstrap/Modal'
import "../stylesheets/main.scss";
import { getCookie } from "../lib/helpers/cookies";

import {
    successNotif,
    errorNotifIcon,
    submitConfimation,
  } from "../lib/helpers/notifications.js";
import useForm from "../lib/hooks/useForm.js";
import { ReactComponent as IconCaretDown } from "../assets/images/icon-caret-down.svg";
import Button from 'react-bootstrap/Button'
import axios from "axios";
import { url_path } from "../constant";

const Upgrade = ({data, user_id}) => {


    const [firstName, setFirstName ] = useState();
    const [lastName, setLastName ] = useState();
    const [gender, setGender ] = useState();
    const [title, setTitle ] = useState();
    const [show, setShow] = useState(false);
    const [card_array, setCards] = useState([]);
    const [email, setEmail] = useState();
    const [password, setPassword] = useState();
    const [contactNo, setContactNo] = useState();
    const [isDisabled, setIsDisabled] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    
    const fetchData = async () => {
        try {
        const { data } = await axios.get(`${url_path}/api/card-imgur`)
        
        setCards(data);
        } catch (error) {
        // handleErrors(error);
        }
    }

    useEffect(() => {
        fetchData();
    }, []);


    const stateSchema = {
        firstName: { value: "", error: "" },
        lastName: { value: "", error: "" },
        gender: { value: "", error: "" },
        title: { value: "", error: "" },
        email: { value: "", error: "" },
        password: { value: "", error: "" }
      };
    
    const stateValidatorSchema = {
        firstName: {
            required: true,
        },    
        lastName: {
            required: true,
        },
        gender: {
            required: true,
        },
        title: {
            required: true,
        },
        email: {
            required: true,
        },
        password: {
            required: true
        },
        contactNo: {
            required: true
        }
    };

    const { handleOnChange } = useForm(
        stateSchema,
        stateValidatorSchema,
    )

    const formOnSubmit = async () => {
        setIsDisabled(true);
        if(!(firstName)){return errorNotifIcon("Oops!", "First Name is required")};
        if(!(lastName)){return errorNotifIcon("Oops!", "Last Name is required")};
        if(!(email)){return errorNotifIcon("Oops!", "Email is required")};
        if(!(password)){return errorNotifIcon("Oops!", "Password is required")};
        if(!(contactNo)){return errorNotifIcon("Oops!", "Contact No. is required")};

        //axios request
        const req = {
            firstName,
            lastName,
            gender,
            title,
            email,
            password,
            contactNo
        }

        const token = getCookie("token");
        const { data: res } = await axios.get(`${url_path}/api/auth`,{
            headers: { "x-auth-token": token },
          });
        axios.post(`${url_path}/api/classic-members/upgrade/${res.user._id}`, req, {
            headers: { "x-auth-token": token },
            })
        .then(function (post_res) {
            console.log(post_res);
            if(post_res.status == 200){
                successNotif("Congratulation!", "You are now a premium user. Page will refresh shortly!")
                setTimeout(function(){window.location.reload()}, 3000);
                setIsDisabled(false);
            }
            else{
                console.log("err")
            }  
        });
      
    }

    return (
        <>
        <a className="link" onClick={handleShow}> here!</a>
        <Modal show={show} onHide={handleClose}>
            {/* <Modal.Header>
                <Modal.Title>Upgrade Form</Modal.Title>
            </Modal.Header> */}
            <Modal.Body>
                <div className="container" style={{marginTop: "20px"}}>
                    <form onchange={handleOnChange} className="form-wrapper" style={{marginLeft:"20px", marginRight:"20px"}}>
                    <h2 className="title text-center">Upgrade Request</h2>
                    <div className="mb-3 mt-3">Fill up the form below to complete your upgrade request</div>
                        <div className="form-field">
                            <input
                            type="text"
                            name="firstName"
                            className="form-input"
                            id="firstName"
                            value={firstName}
                            onChange={(e) =>  setFirstName(e.target.value)}
                            required
                            />
                            <label htmlFor="cardNo">FIRST NAME</label>
                        </div>
                        <div className="form-field">
                            <input
                            type="text"
                            name="lastName"
                            className="form-input"
                            id="lastName"
                            value={lastName}
                            onChange={(e) => setLastName(e.target.value)}
                            required
                            />
                            <label htmlFor="cardNo">LAST NAME</label>
                        </div>
                        <div className="form-group">
                            <div className="form-field select">
                            <select
                                id="gender"
                                value={gender}
                                onChange={(e) => setGender(e.target.value)}
                                className="form-input select"
                                name="gender"
                                required
                                // disabled={isRoleDisabled}
                                >
                                <option value=""></option>
                                <option value="Male">Male</option>
                                <option value="Female">Female</option>
                            </select>
                            <label htmlFor="cardNo">GENDER</label>
                            </div>
                            <div className="form-field select">
                                <select
                                id="title"
                                value={title}
                                onChange={(e) => setTitle(e.target.value)}
                                className="form-input select"
                                name="title"
                                required
                                // disabled={isRoleDisabled}
                                >
                                <option value=""></option>
                                <option value="Mr">Mr.</option>
                                <option value="Mrs">Mrs.</option>
                                <option value="Ms">Ms</option>
                                <option value="Miss">Miss</option>
                                <option value="Other">Others</option>
                                </select>
                            <label htmlFor="cardNo">TITLE</label>
                            </div>
                        </div>
                        <div className="form-field">
                            <input
                            type="text"
                            name="email"
                            className="form-input"
                            id="email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            required
                            />
                            <label htmlFor="cardNo">Email</label>
                        </div>
                        <div className="form-field">
                            <input
                            type="password"
                            name="password"
                            className="form-input"
                            id="password"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            required
                            />
                            <label htmlFor="cardNo">Password</label>
                        </div>
                        <div className="form-field">
                            <input
                            type="email"
                            name="contactNo"
                            className="form-input"
                            id="contactNo"
                            value={contactNo}
                            onChange={(e) => setContactNo(e.target.value)}
                            required
                            />
                            <label htmlFor="cardNo">Contact No.</label>
                        </div>
                    </form>
                </div>


            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                    Cancel
                </Button>
                <Button 
                    variant="primary"
                    onClick={formOnSubmit}
                    disabled={isDisabled}
                >
                    Submit
                </Button>
            </Modal.Footer>
        </Modal>
        </>
    )
}

export default Upgrade
