import React, { useState, useEffect, Component } from "react";
import { url_path, captchakey } from "../constant";
import Card from "../components/Card";
import "../stylesheets/main.scss";
import useForm from "../lib/hooks/useForm.js";
import { withRouter } from "react-router";
import { Link, Redirect } from "react-router-dom";
import axios from "axios";
import { PublicAuth, userData } from "../lib/helpers/validation";
import { setCookie } from "../lib/helpers/cookies";
import { errorNotifIcon } from "../lib/helpers/notifications";
import ReCAPTCHA from "react-google-recaptcha";

function Login(props) {

  const [notificationError, setNotificationError] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isAccountLocked, setIsAccountLocked] = useState(false);
  const [dateEnd, setDateEnd] = useState(false);
  const [card_number, setCardNumber] = useState("");
  const [reference_no, setReferenceNo] = useState("");
  const [captchaVal, setCapchaVal] = useState(false);
  const [isClassic, setIsClassic] = useState(0); 
  useEffect(() => {
    setNotificationError("");
    PublicAuth(props);
  }, []);

  const cardInfo = {
    title: "Classic Card Log in",
  };


  const stateSchema = {
    card_number: { value: "", error: "" },
    reference_no: { value: "", error: "" },
  };

  const stateValidatorSchema = {
    card_number: {
      required: true,
    },
    reference_no: {
      required: true,
    },
  };


  function FormOnSubmit(e) {
    e.preventDefault();
    if(captchaVal){
      //insert login script
      setIsLoading(true);
      setIsClassic(1);
      const body_request = {
        email:card_number,
        password:reference_no,
        captchaVal:captchaVal,
        isClassic:1
      };
      axios.post(`${url_path}/api/auth/`, body_request).then((res) => {
      
          const data = res.data.data;
          if (data.status === "success") {
              setCookie("token", res.data.token, "donotremember");
              props.history.push("/member-dashboard");
              setIsLoading(false);
          } else if(data.status === "404") {
            errorNotifIcon("Oops!", data.msg);
            setIsLoading(false);
          }
      });
    }
  }

  function onChange(value) {
    setCapchaVal(value);
  }


  const { values, errors, handleOnChange, handleOnSubmit, disable } = useForm(
    stateSchema,
    stateValidatorSchema,
  );
  // const { email, password } = values;

  // return (
  //   <main className="login">
  //     <div className="card-body">
  //       {!notificationError ? (
  //         ""
  //       ) : (
  //         <div className="section-notification">
  //           <div className="container">
  //             <div className="notification-holder">
  //               <p className="notification-text">{notificationError}</p>
  //             </div>
  //           </div>
  //         </div>
  //       )}
  //       <Card
  //         cardDetails={cardInfo}
  //         form={
  //           <form className="form-wrapper" onSubmit={FormOnSubmit}>
  //             <div className="form-field">
  //               <input
  //                 title="Card Number"
  //                 type="text"
  //                 name="card_number"
  //                 className="form-input"
  //                 id="card_number"
                  
  //                 value={card_number}
  //                 onChange={(e) => setCardNumber(e.target.value.toUpperCase())}
  //                 disabled={isAccountLocked}
  //                 required
  //               />
  //               <label htmlFor="email">Card Number</label>
  //               {errors.email && (
  //                 <span className="form-error">{errors.email}</span>
  //               )}
  //             </div>
  //             <div className="form-field">
  //               <input
  //                 type={"text"}
  //                 name="ref_no"
  //                 className="form-input"
  //                 id="ref_no"
  //                 value={reference_no}
  //                 onChange={(e) => setReferenceNo(e.target.value.toUpperCase())}
  //                 disabled={isAccountLocked}
  //                 required
  //               />
  //               <label htmlFor="password">Reference No.</label>
  //             </div>
  //             {/* <div className="remember-password">
  //               <div className="checkbox-wrapper">

  //               </div>
  //             </div> */}
  //             <div className="mx-auto">
  //             <ReCAPTCHA
  //               sitekey={captchakey}
  //               onChange={onChange}
  //               required
  //             />      
  //             </div>
  //             {isLoading ? (
  //               <button className="btn-blue-loading disabled">
  //                 <span className="load open"></span> Please wait...
  //               </button>
  //             ) : (
  //               <>
  //                 <input
  //                   type="submit"
  //                   className="btn-blue"
  //                   disabled={isAccountLocked}
  //                   value="Login"
  //                 />

  //                 <div className="form-error" id="demo"></div>
  //               </>
  //             )}

  //             {/* {cta} */}
  //             <div>
  //               <p className="cta">
  //                 Don't have an account yet?{" "}
  //                 <Link to="/signup">
  //                   <span>Sign up now</span>
  //                 </Link>
  //               </p>
  //             </div>
  //             <div>
  //               <p className="cta">
  //                 Already have an account?{" "}
  //                 <Link to="/">
  //                   <span>Click here</span>
  //                 </Link>
  //               </p>
  //             </div>
  //             {/* {close} */}

  //           </form>
            
  //         }
  //       />
  //     </div>
  //   </main>
  // );
}

export default withRouter(Login);
