import React, { useState } from "react";
import Swiper from "react-id-swiper";

import "swiper/css/swiper.css";

function DashboardSwiper({ deals, goToDeal }) {
  const [swiper, updateSwiper] = useState(null);

  const params = {
    spaceBetween: 12,
    slidesPerView: "3",
    centeredSlides: true,
    loop: true,
    breakpoints: {
      1199: {
        slidesPerView: 3,
      },
    },
    pagination: {
      el: ".swiper-pagination",
      type: "bullets",
      clickable: true,
    },
  };

  const goNext = () => {
    if (swiper !== null) {
      swiper.slideNext();
    }
  };

  const goPrev = () => {
    if (swiper !== null) {
      swiper.slidePrev();
    }
  };
  const swipe_deals = deals;

  // const items = swipe_deals.map((item, i) => <li key={i}>{item.image_url}</li>);
  return (
    <div className="dashboard-swiper">
      {/* {items} */}
      <Swiper {...params} getSwiper={updateSwiper} noSwiping={true}>
        {swipe_deals.map((item, i) => {
          return (
            <div key={i} className="swiper-slide-wrapper">
              <img src={item.image_url} className="deal-image" alt=""/>
            </div>
          );
        })}
      </Swiper>
      <div className="navigations-wrapper">
        <span className="back-btn" onClick={goPrev}></span>
        <span className="back-btn next-btn" onClick={goNext}></span>
      </div>

      {/* { deals.map((item, i) => {
          return (
            <div
              key={i}
              className="swiper-slide-wrapper"
            >
              <img src={item.image_url} className="deal-image" />
              <div className="top-wrapper">
                <img src={require('../assets/images/logo-icon.png')} className="top-logo" />
                <div className="top-buttons">
                  <p className="top-icons"><MdShare/></p>
                  <p className="top-icons"><TiStarOutline/></p>
                </div>
              </div>
              <div className="swiper-content">
                <p className="deal-title">{item.title}</p>
                <p className="deal-description">{item.description}</p>
                <p className="deal-link" onClick={() => goToDeal(item._id)}>View offer</p>
              </div>
            </div>
          )
        })
      } */}
      {/* </div> */}
    </div>
  );
}

export default DashboardSwiper;
