import Sweetalert2 from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
const Swal = withReactContent(Sweetalert2);

export function successNotif(title, message) {
  Swal.fire(title, message, "success");
}
export function errorNotif(title, message) {
  Swal.fire(title, message, "error");
}
export function errorNotifIcon(title, message) {
  Swal.fire({
    icon: "error",
    title: title,
    text: message,
  });
}

export function warningNotifIcon(title, message) {
  Swal.fire({
    icon: "warning",
    title: title,
    text: message,
  });
}

export function deleteConfirmation(message) {
  const { value: text } = Swal.fire({
    title: "Are you sure?",
    text: message,
    icon: "warning",
    showCancelButton: true,
    confirmButtonColor: "#3085d6",
    cancelButtonColor: "#d33",
    confirmButtonText: "Confirm",

    input: "textarea",
    inputPlaceholder: "Type your message here...",
    inputAttributes: {
      "aria-label": "Type your message here",
    },
  });

  if (text) {
    Swal.fire(text);
  }
}

export function cancelConfirmation(meessage) {
  Swal.fire({
    title: "Are you sure?",
    text: meessage,
    icon: "warning",
    showCancelButton: true,
    confirmButtonColor: "#3085d6",
    cancelButtonColor: "#d33",
    confirmButtonText: "Yes, delete it!",
  }).then((result) => {
    if (result.value) {
      // Swal.fire(
      //   'Deleted!',
      //   'Your file has been deleted.',
      //   'success'
      // )
      return true;
    } else {
      return false;
    }
  });
}

export function paymentSuccessNotif(meessage) {
  Swal.fire({
    title: "Payment Success!",
    text: meessage,
    icon: "success",
    showCancelButton: false,
    confirmButtonColor: "#3085d6",
    cancelButtonColor: "#d33",
    confirmButtonText: "Login",
  }).then((result) => {
    if (result.value) {
      // handleSubmit();
      window.location.assign("/");
      return true;
    } else {
      return false;
    }
  });
}

export function submitConfimation(title, meessage, onSubmit, offerID) {
  Swal.fire({
    title: title,
    text: meessage,
    icon: "warning",
    showCancelButton: true,
    confirmButtonColor: "#3085d6",
    cancelButtonColor: "#d33",
    confirmButtonText: "Yes",
  }).then((result) => {
    if (result.value) {
      onSubmit(offerID);
      // handleSubmit();
      // window.location.assign("/");
      return true;
    } else {
      return false;
    }
  });
}
