import React, { useState, useEffect } from "react";
import { url_path } from "../../constant";
import axios from "axios";
import Card from "../../components/Card";
import useForm from "../../lib/hooks/useForm";
import Breadcrumbs from "../../components/Breadcrumbs";
import { authenticateBusinessPage } from "../../lib/helpers/validation";
import { errorNotifIcon } from "../../lib/helpers/notifications";

function ManualRedeem(props) {
  useEffect(() => {
    authenticateBusinessPage(props);
  }, []);

  var numRegex = /^[0-9]*$/;

  const [pointsBalance, setPointsBalance] = useState("0");
  const [isButtonLoading, setIsButtonLoading] = useState(false);



  const cardInfo = {
    title: "Redeem",
  };

  const stateSchema = {
    cardNo: { value: "", error: "" },
    amount: { value: "", error: "" },
    confirmAmount: { value: "", error: "" },
    invoiceNo: { value: "", error: "" },
    notes: { value: "", error: "" },
  };

  const stateValidatorSchema = {
    cardNo: {
      required: true,
    },

    referralCode: {
      required: true,
    },
    amount: {
      required: true,
      validator: {
        func: (value) => numRegex.test(value),
        error: "Amount Only Accept Numbers",
      },
    },
    confirmAmount: {
      required: true,
      validator: {
        func: (value) => value === amount && numRegex.test(value),
        error: "Amount don't match / Only Accept Number",
      },
    },
    invoiceNo: {
      required: false,
    },
    notes: {
      required: false,
    },
  };

  const onCardnumberChange = () => {
    console.log(cardNo);
    // this.setState({cardNo})
    if (cardNo.length === 12) {
      axios
        .post(`${url_path}/api/customerBank/getBank/${cardNo}`)
        .then((res) => {
          const data = res.data;
          // this.setState({points_balance: data.points_balance});
          setPointsBalance(data.points_balance);
          // console.log(res.data);
        });
    } else {
      setPointsBalance("0");
    }
  };

  const { values, errors, handleOnChange, disable } = useForm(
    stateSchema,
    stateValidatorSchema,
    onCardnumberChange
  );

  const {
    cardNo,
    referralCode,
    amount,
    confirmAmount,
    invoiceNo,
    notes,
  } = values;

  const handleSubmit = () => {
    // console.log(notes)
    if (cardNo === "" || amount === "") {
      errorNotifIcon("Oops!", "Card No. & Amount is required");
    }
    if (amount !== confirmAmount) {
      errorNotifIcon("Oops!", "Amount do not match!");
    }
    setIsButtonLoading(true);
    axios.get(`${url_path}/api/scanner/${cardNo}`).then((res) => {
      const scanner = res.data;
      // return console.warn(scanner.data.msg);
      if (scanner.data.status === "error") {
        errorNotifIcon("Oops!", scanner.data.msg);
        setIsButtonLoading(false);
      } else {
        // console.log(this.state.referenceNo.toUpperCase() + ' ' + scanner.scanner.reference_number);
        if (referralCode.toUpperCase() === scanner.scanner.reference_number) {
          if (parseInt(pointsBalance) >= parseInt(amount)) {
            setIsButtonLoading(false);

            props.history.push(
              `/manual-redeem-otp/${scanner.scanner.card_number}/${scanner.scanner._id}/${amount}/${
                invoiceNo ? invoiceNo : "no invoice"
              }/${notes ? notes : "no notes"}`
            );

          } else {
            setIsButtonLoading(false);
            errorNotifIcon("Oops!", "Not enough points");
          }
        } else {
          setIsButtonLoading(false);
          errorNotifIcon("Sorry!", "User Not Found!");
        }
      }
    });
  };

  const formatCardNumber = (value) => {
    let cardNumber = value.replace(/ /g, "");

    const formatedCardNumber =
      cardNumber.substring(0, 4).replace(/\d{3}(?=.)/, "$& ") +
      cardNumber.substring(4, 8).replace(/\d{2}(?=.)/, "$& ") +
      cardNumber.substring(8, 10);

    return formatedCardNumber;
  };

  const validateNumberInput = (event) => {
    const { key } = event;
    console.log(key);

    const validKeys = ["Backspace", "Delete", "ArrowLeft", "ArrowRight"];

    if (!key.match(/\d/) && !validKeys.includes(key)) {
      event.preventDefault();
    }
  };

  return (
    <main className="partner-dashboard manual-redeem">
      <div className="container custom-container">
        <div className="content-wrapper">
          <Breadcrumbs
            size="text-large"
            placeholder="Back"
            targetLink="/manual-input"
            customClassName={"m-3"}
          />
          <div className="card-body">
            <Card
              cardDetails={cardInfo}
              form={
                <>
                  <form className="form-wrapper" onSubmit={handleSubmit}>
                    <div className="points-wrapper">
                      <p className="points-text">{pointsBalance}</p>
                      <p className="points-subtext">Available points</p>
                    </div>
                    <div className="form-field">
                      <input
                        type="text"
                        name="cardNo"
                        className="form-input"
                        id="cardNo"
                        value={formatCardNumber(cardNo)}
                        onChange={handleOnChange}
                        onKeyUp={onCardnumberChange}
                        onKeyDown={validateNumberInput}
                        onKeyPress={validateNumberInput}
                        required
                      />
                      <label htmlFor="cardNo">Enter card no.</label>
                    </div>
                    <div className="form-field">
                      <input
                        type={"text"}
                        name="referralCode"
                        className="form-input"
                        id="referralCode"
                        value={referralCode}
                        onChange={handleOnChange}
                        required
                      />
                      <label htmlFor="referralCode">Referral Code</label>
                    </div>
                    <div className="form-field">
                      <input
                        type="number"
                        name="amount"
                        className="form-input"
                        id="amount"
                        value={amount}
                        onChange={handleOnChange}
                        onKeyDown={validateNumberInput}
                        required
                      />
                      <label htmlFor="amount">Amount</label>
                      {errors.amount && (
                        <span className="form-error">{errors.amount}</span>
                      )}
                    </div>
                    <div className="form-field">
                      <input
                        type="number"
                        name="confirmAmount"
                        className={"form-input"}
                        id="confirmAmount"
                        value={confirmAmount}
                        onChange={handleOnChange}
                        onKeyDown={validateNumberInput}
                        required
                      />
                      <label htmlFor="confirmAmount">Confirm amount</label>
                      {errors.confirmAmount && (
                        <span className="form-error">
                          {errors.confirmAmount}
                        </span>
                      )}
                    </div>
                    <div className="form-field">
                      <input
                        type={"text"}
                        name="invoiceNo"
                        // className={'form-input not-required'}
                        className={`form-input ${
                          !invoiceNo ? "not-required" : ""
                        }`}
                        id="invoiceNo"
                        value={invoiceNo}
                        onChange={handleOnChange}
                      />
                      <label htmlFor="invoiceNo">Invoice no.</label>
                    </div>
                    <div className="form-field form-textarea">
                      <textarea
                        type={"text"}
                        name="notes"
                        className="form-input not-required"
                        id="notes"
                        value={notes}
                        onChange={handleOnChange}
                      />
                      <label htmlFor="notes">Notes (optional)</label>
                    </div>
                    {/* <input type="submit" className='btn-blue' disabled={disable} value="Submit"/> */}
                  </form>
                  {isButtonLoading ? (
                    <button class="btn-blue-loading disabled">
                      <span class="load open"></span> Please wait...
                    </button>
                  ) : (
                    <button
                      className="btn-blue"
                      onClick={handleSubmit}
                      disabled={disable}
                    >
                      Submit
                    </button>
                  )}
                </>
              }
            />
          </div>
        </div>
      </div>
    </main>
  );
}

export default ManualRedeem;
