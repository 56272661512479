import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import axios from 'axios';
import { Provider } from "react-redux";
import { createStore } from "redux";
import allReducer from "./reducers";
import HttpsRedirect from "react-https-redirect";
import { getCookie } from "./lib/helpers/cookies";

const store = createStore(
  allReducer,
  window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
);

const url_hostname = window.location.hostname;
const url_path = url_hostname.replace(/www./g, "");

axios.defaults.headers.common['x-auth-token'] =  getCookie("token");
if (window.location.host.startsWith("www")) {
  window.location.href = url_path;
}
// console.log(window.location.host);

// if (window.location.host.startsWith("www")) {
//   window.location =
//     window.location.protocol +
//     "//" +
//     window.location.host +
//     window.location.pathname;
// }

ReactDOM.render(
  <Provider store={store}>
    <HttpsRedirect>
      <App />
    </HttpsRedirect>
  </Provider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register();
