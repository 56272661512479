import React, { useEffect, useState } from "react";
import { url_path } from "../../constant";
// import useForm from '../lib/hooks/useForm.js';
import Breadcrumbs from "../../components/Breadcrumbs";
import axios from "axios";
import { getCookie } from "../../lib/helpers/cookies";
import { errorNotifIcon } from "../../lib/helpers/notifications";
import Sidebar from "../../components/Sidebar";
import { authenticateMemberPage } from "../../lib/helpers/validation";
import CardNumberInput from "../../components/CardNumberInput";

function TransferPoints({ history }) {
  useEffect(() => {
    authenticateMemberPage(history);
  }, []);
  // States
  const [loading, setLoading] = useState(true);
  const [userId, setUserId] = useState("");
  const [points, setPoints] = useState(0);
  const [cardNo, setCardNo] = useState("");
  const [referenceNo, setReferenceNo] = useState("");
  const [transferPoints, setTransferPoints] = useState("");

  // Functions
  const getData = async () => {
    setLoading(true);
    const token = getCookie("token");

    try {
      const { data: res } = await axios.get(`${url_path}/api/auth`, {
        headers: { "x-auth-token": token },
      });

      const { data: customer_bank } = await axios.get(
        `${url_path}/api/customerBank/${res.user._id}`
      );

      setUserId(res.user._id);
      setPoints(customer_bank.points_balance);
      setLoading(false);
    } catch (err) {
      console.log(err);
    }
  };

  const handleOnSubmit = (event) => {
    event.preventDefault();

    pointsTransfer();
  };

  // const formatCardNumber = (value) => {
  //   let cardNumber = value.replace(/ /g, '')

  //   const formatedCardNumber =
  //     cardNumber.substring(0, 4).replace(/\d{3}(?=.)/, '$& ') +
  //     cardNumber.substring(4, 8).replace(/\d{2}(?=.)/, '$& ') +
  //     cardNumber.substring(8, 10)

  //   return formatedCardNumber
  // }

  // const handleCardNumber = event => {
  //   let cardNumber = event.target.value
  //   cardNumber.replace('', '$& ')

  //   setCardNo(cardNumber)
  // }

  const pointsTransfer = async () => {
    if (
      cardNo &&
      referenceNo &&
      transferPoints >= 100 &&
      transferPoints <= 5000
    ) {
      const req = {
        reciever_cardNo: cardNo,
        reciever_referenceNo: referenceNo,
        numberOfPoints: transferPoints,
        onVerify: true,
      };

      try {
        const { data: customer_bank } = await axios.post(
          `${url_path}/api/customerBank/transfer_points/${userId}`,
          req
        );
        if (customer_bank.data.status === "success") {
          const confirm_transfer = {
            user_id: userId,
            receiver_cardNo: cardNo,
            reciever_referenceNo: referenceNo,
            numberOfPoints: transferPoints,
            totalCharge: customer_bank.data.totalChargeRound,
            totalPoints: customer_bank.data.totalPoints,
          };
          history.push({
            pathname: "/my-infinity-account-transfer-verify",
            state: { confirm_transfer },
          });
          // handleShowModal()
        } else {
          let title = "Warning!";
          let msg = customer_bank.data.msg;
          errorNotifIcon(title, msg);
        }
      } catch (err) {
        console.log(err);
      }
    } else {
      let title = "Warning!";
      let msg = "Please provide all the fields.";
      errorNotifIcon(title, msg);
    }
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <main className="transfer-points member-dashboard">
      <Sidebar history={history} />
      <div className="content-wrapper">
        <Breadcrumbs
          size="text-large"
          placeholder="Back"
          targetLink="/my-infinity-account"
          hasButton="false"
        />
        <div className="container">
          {loading ? (
            <div className="lds-ellipsis">
              <div className="dots"></div>
              <div className="dots"></div>
              <div className="dots"></div>
              <div className="dots"></div>
            </div>
          ) : (
            <div className="transfer-points-wrapper">
              <form className="form-wrapper" onSubmit={handleOnSubmit}>
                <div className="heading-wrapper">
                  <p className="heading">
                    <span className="text-blue">{points}</span>
                  </p>
                  <p className="subtitle">Available points</p>
                </div>
                <div className="form-field">
                  <CardNumberInput
                    cardNumber={cardNo}
                    onStateChange={setCardNo}
                  />
                  <label htmlFor="cardNum">
                    Receiver's Infinity Card Number
                  </label>
                </div>
                <div className="form-field">
                  <input
                    type="text"
                    name="referenceNum"
                    className="form-input"
                    id="referenceNum"
                    value={referenceNo}
                    onChange={(e) => setReferenceNo(e.target.value)}
                    required
                  />
                  <label htmlFor="referenceNum">
                    Receiver's Infinity Referral Code
                  </label>
                </div>
                <div className="form-field">
                  <input
                    type="number"
                    min="100"
                    max="5000"
                    name="transferPoints"
                    className="form-input"
                    id="transferPoints"
                    value={transferPoints}
                    onChange={(e) => setTransferPoints(e.target.value)}
                    required
                  />
                  <label htmlFor="transferPoints">
                    Number of points to transfer
                  </label>
                </div>
                <p className="note">
                  Infinity Privilege will charge 5% as service charge
                </p>
                <div className="btn-holder">
                  <button className="btn-blue">Submit</button>
                </div>
              </form>
            </div>
          )}
        </div>
      </div>
    </main>
  );
}

export default TransferPoints;
