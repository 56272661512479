import React, { useEffect, useState } from "react";
import { url_path } from "../../constant";
import {
  successNotif,
  errorNotifIcon,
} from "../../lib/helpers/notifications.js";
import { getCookie } from "../../lib/helpers/cookies";
import Modal from "react-bootstrap/Modal";
import Card from "../../components/Card";
import Table from "../../components/Table";
import ReactTooltip from "react-tooltip";
import axios from "axios";
import Sweetalert2 from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

// import { ReactComponent as IconCaretDown } from "../../assets/images/icon-caret-down.svg";
import { ReactComponent as IconDelete } from "../../assets/images/icon-delete.svg";
// import { ReactComponent as IconEdit } from "../../assets/images/icon-edit.svg";

const Swal = withReactContent(Sweetalert2);

const cardDetails = {
  title: "Add Offer",
  hasClose: true,
  hasMessage: false,
  customClassName: "no-margin no-shadow",
};

const PointOffer = ({ data, user_id }) => {
  const [currentOffer, setCurrentOffer] = useState("");
  // const [selectedOfferAmount, setSelectedOfferAmount] = useState("");
  // const [selectedOfferPoints, setSelectedOfferPoints] = useState("");
  const [dataFound, setDataFound] = useState(false);
  // const [offerUpdate, setOfferUpdate] = useState(false);
  const [offerData, setOfferData] = useState([]);
  const [offerList, setOfferList] = useState([]);
  // const [selectedOffer] = useState("no selected offer");
  const [loading, setLoading] = useState(true);
  const [showModal, setShowModal] = useState(false);

  const heading = [
    {
      Header: "No",
      maxWidth: 50,
      filterable: false,
      Cell: (row) => row.index + 1,
    },
    {
      Header: "Title",
      id: "title",
      accessor: "title",
    },
    {
      Header: "Amount",
      id: "amount",
      accessor: "amount",
    },
    {
      Header: "Points",
      id: "points",
      accessor: "points",
    },
    {
      Header: "Action",
      id: "action",
      Cell: (row) => (
        <div className="button-wrapper">
          <div className="button-holder">
            <button
              className="btn-icon"
              onClick={() => handleRemoveOffer(row.original.bp_offer_id)}
            >
              <span className="icon-holder">
                <IconDelete
                  className="icon-actions"
                  data-tip
                  data-for="delete"
                />
              </span>
            </button>
            <ReactTooltip id="delete" type="warning" effect="solid">
              <span>Delete</span>
            </ReactTooltip>
          </div>
        </div>
      ),
    },
  ];

  useEffect(() => {
    getOfferData();
  }, []);

  // Change offer
  // const handleOfferChange = event => {
  //   // const business_partner_id = currentOffer.business_partner_id
  //   const offer_id = event.target.value;
  //   setSelectedOffer(offer_id);
  //   console.log(offer_id);
  //   if (offer_id != "no selected offer") {
  //     axios.get(`${url_path}/api/offer/${offer_id}`).then(res => {
  //       // getOfferData()
  //       // console.log(res.data);
  //       setSelectedOfferAmount(res.data.amount);
  //       setSelectedOfferPoints(res.data.points);
  //     });
  //   } else {
  //     setSelectedOfferAmount("");
  //     setSelectedOfferPoints("");
  //   }
  // };

  // const handleUpdateOffer = (event) => {
  //   const req = {
  //     business_partner_id: user_id,
  //     offer_id: selectedOffer,
  //   };
  //   console.log(req);
  //   axios
  //     .post(`${url_path}/api/business_offer/update/${user_id}`, req)
  //     .then((res) => {
  //       if (res.data.data.status === "success") {
  //         getOfferData();
  //       } else {
  //         console.log(res.data.data.msg);
  //         let title = "Oops!";
  //         let message = "Please Select Offer";
  //         errorNotifIcon(title, message);
  //       }
  //     });
  // };

  // const handleCancelUpdate = () => {
  //   getOfferData();
  // };

  // const handleSetOffer = () => {
  //   setOfferUpdate(true);
  // };

  // const handleShowEditOffer = () => {
  //   setDataFound(false);
  //   setOfferUpdate(true);
  // };

  // Load offers
  const getOfferData = async () => {
    setLoading(true);

    try {
      const { data: offer_list } = await axios.get(`${url_path}/api/offer/`);
      setOfferList(offer_list);

      const { data: bp_offers_data } = await axios.get(
        `${url_path}/api/point_offer/${user_id}`
      );

      if (bp_offers_data.status === "success") {
        // Map through offers
        const bp_offers = bp_offers_data.offer_data.map((offer) => {
          return {
            ...offer.offer_id,
            bp_offer_id: offer._id,
          };
        });
        setOfferData(bp_offers);
      }

      setDataFound(true);
      setLoading(false);
    } catch (err) {
      console.error(err);
      setDataFound(false);
      setLoading(false);
    }

    // .then(currentOffer => {
    //   if (currentOffer.data.data.status == "success") {
    //     setDataFound(true);
    //     console.log(currentOffer.data.data.offer_data);
    //     setCurrentOffer(currentOffer.data.data.offer_data.offer_id);
    //     setSelectedOffer(currentOffer.data.data.offer_data.offer_id._id);
    //     setSelectedOfferAmount(
    //       currentOffer.data.data.offer_data.offer_id.amount
    //     );
    //     setSelectedOfferPoints(
    //       currentOffer.data.data.offer_data.offer_id.points
    //     );
    //   } else {
    //     setDataFound(false);
    //     setLoading(false);
    //   }
    // });
    // axios.get(`${url_path}/api/offer/`).then(offers => {
    //   setOfferData(offers.data);
    //   setLoading(false);
    // });
  };

  const handleRemoveOffer = async (id) => {
    const bp_offer_id = id;

    try {
      const { value } = await Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
      });

      if (value) {
        const token = getCookie("token");

        const {
          data: response,
        } = await axios.delete(
          `${url_path}/api/business_offer/${bp_offer_id}`,
          { headers: { "x-auth-token": token } }
        );

        successNotif("Well done!", "Offer has been removed successfully.");
        console.log(response)
        getOfferData();
      }
    } catch (err) {
      console.error(err);
    }
  };

  // Modal state handler
  const handleShowModal = () => {
    setShowModal(true);
  };

  const handleHideModal = () => {
    setShowModal(false);
  };

  const handleOfferChange = (event) => {
    const { value } = event.target;

    setCurrentOffer(value);
  };

  const handleOnSubmit = async (event) => {
    event.preventDefault();

    try {
      const req = {
        business_partner_id: user_id,
        offer_id: currentOffer,
        status: false,
      };

      const { data: response } = await axios.post(
        `${url_path}/api/business_offer`,
        req
      );

      if (response.status === "success") {
        successNotif("Well done!", "Offer has been added successfully.");

        getOfferData();
      } else {
        errorNotifIcon("Oops!", "Offer already exists.");
      }

      handleHideModal();
      setCurrentOffer("");
    } catch (err) {
      console.error(err);
    }
  };
  // console.log(currentOffer);
  return (
    <div className="tab-point-offer">
      <p className="heading">Points Offer</p>
      {loading ? (
        <div className="lds-ellipsis">
          <div className="dots"></div>
          <div className="dots"></div>
          <div className="dots"></div>
          <div className="dots"></div>
        </div>
      ) : (
        <>
          <div className="section-table">
            <Table tableHeader={heading} tableData={offerData} />
          </div>
          <button className="btn-blue right" onClick={handleShowModal}>
            Add Offer
          </button>
        </>
      )}
      <Modal
        show={showModal}
        onHide={handleHideModal}
        className="modal-dark- opacity"
      >
        <Card
          cardDetails={cardDetails}
          form={
            <form className="form-wrapper" onSubmit={handleOnSubmit}>
              <div className="form-field select">
                <select
                  id="title"
                  className="form-input select"
                  name="title"
                  value={currentOffer}
                  onChange={handleOfferChange}
                  required
                >
                  <option value="">Select offer</option>
                  {offerList.map((offer, index) => (
                    <option value={offer._id} key={index}>
                      {offer.title}
                    </option>
                  ))}
                </select>
                <label htmlFor="title">Point Offer</label>
              </div>

              <div className="form-btn-wrapper form-btn-dual-wrapper">
                <input
                  type="button"
                  className="btn-d btn-gray"
                  value="Cancel"
                  onClick={handleHideModal}
                ></input>
                <input
                  type="submit"
                  className="btn-d btn-blue"
                  value="Save Changes"
                  disabled={!currentOffer}
                />
              </div>
            </form>
          }
        />
      </Modal>
    </div>
  );
};

export default PointOffer;
