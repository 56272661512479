import React from 'react'
import { Link } from 'react-router-dom'

function Dashboard({links}) {
  return (
    <>
      {/* <div className="container"> */}
        <div className="dashboard-wrapper">
          { links.map((item, i) => {
            return (
              <Link key={i} to={item.routeTo} className="dashboard-item" >
                <div className="icon-holder">
                  <img src={item.icon} className="icon-img" alt="Dashboard Icon"/>
                </div>
                <div className="title-holder">
                  <p className="title-item">{item.title}</p>
                </div>
              </Link>
            )
            })
          }
        </div>
      {/* </div> */}
    </>
  )
}

export default Dashboard