import React, { useEffect, useState, useRef } from "react";
import { url_path } from "../../constant";
import axios from "axios";
import moment from "moment";
import Filter from "../../components/Filter";
import DatePicker from "react-datepicker";
import { FaCalendar } from "react-icons/fa";
import useForm from "../../lib/hooks/useForm";
// import { CSVLink } from "react-csv";
import { CSVLink } from "react-csv";
import { DropdownButton } from "react-bootstrap";
import { jsPDF } from "jspdf";
import "jspdf-autotable";

import Table from "../../components/Table";

const InfinityTransaction = ({ user_id, profileData }) => {
  const [loading, setLoading] = useState(true);
  const [tableData, setTableData] = useState([]);
  const [pagerData, setPagerData] = useState({});
  const [filterStartDate, setFilterStartDate] = useState();
  const [filterEndDate, setFilterEndDate] = useState();
  const [printData, setPrintData] = useState([]);
  console.log(printData);
  // const [startAmt, setStartAmt] = useState("");
  // const [endAmt, setEndAmt] = useState("");
  const [filterName, setFilterName] = useState("");

  // const [csvData, setCsvData] = useState([]);

  const filterContent = {
    title: "Filter",
  };

  const csvHeader = [
    {
      label: "Date",
      key: "created_at",
    },
    {
      label: "First Name",
      key:  "user_id.user_first_name",
    },
    {
      label: "Last Name",
      key: "user_id.user_last_name",
    },
    {
      label: "Card Number",
      key: "user_id.card_number",
    },
    {
      label: "Amount in Peso",
      key: "amount",
    },
    {
      label: "Points",
      key: "points",
    },
    {
      label: "Bonus",
      key: "bonus",
    },
    {
      label: "Total Points",
      key: "total_points",
    },
  ];

  const stateSchema = {
    name: { value: "", error: "" },
    startAmt: { value: "", error: "" },
    endAmt: { value: "", error: "" },
  };

  const stateValidatorSchema = {
    name: {
      required: false,
    },
    startAmt: {
      required: false,
    },
    endAmt: {
      required: false,
    },
  };

  const {
    // state,
    values,
    // errors,
    handleOnChange,
    // disable,
  } = useForm(stateSchema, stateValidatorSchema);

  const { startAmt, endAmt } = values;

  const tableRef = useRef(null);

  const handleSubmit = (event) => {
    event.preventDefault();
    getTransaction(1, true, startAmt, endAmt);
    // getTransaction(1, false, 0, 0);

  };

  const handleDateChangeRaw = (e) => {
    e.preventDefault();
  };

  // const handleExportDataCSV = async (event, done) => {
  //   const tableData = printData;
    
  //   tableData.map((value) => {
  //     const [
  //       date,
  //       name,
  //       card_number,
  //       amount,
  //       bal_dcj,
  //       bal_partner,
  //     ] = value._original;

  //     csvData.push({ date, name, card_number, amount, bal_dcj, bal_partner });

  //   });

  //   console.log(csvData);
  // };

  const handleExportData = async (event, done) => {
    const tblData = printData;
    let body = [];

    tblData.map((value) => {
      const {
        created_at,
        amount,
        points,
        total_points,
        redeemed_amount,
        bonus,
        user_id: { user_first_name, user_last_name, card_number },
        description,
      } = value;

      if (description === "Redeem") {
        body.push([
          moment(new Date(created_at)).format("MMMM DD, YYYY HH:mm:ss"),
          user_first_name + " " + user_last_name,
          card_number,
          amount,
          redeemed_amount,
          bonus,
          redeemed_amount,
        ]);
      } else {
        body.push([
          moment(new Date(created_at)).format("MMMM DD, YYYY hh:mm A"),
          user_first_name + " " + user_last_name,
          card_number,
          amount,
          points,
          bonus,
          total_points,
        ]);
      }
    });

    const doc = new jsPDF("portrait", "pt", "A4");
    const title = "Infinity Transaction - " + profileData.business_name;
    const headers = [
      [
        "Date",
        "Name",
        "Card Number",
        "Amount in Peso",
        "Points",
        "Bonus",
        "Total Points",
      ],
    ];
    doc.text("DCJ Marketing Services Corporation", 40, 40);
    doc.text(title, 40, 80);
    doc.autoTable({
      headerStyles: { fillColor: "#106397" },
      startY: 90,
      head: headers,
      body: body,
    });
    doc.save(
      profileData.business_name.replace(" ", "-") +
        "-" +
        moment().format("YYYY-MM-DD").toString() +
        ".pdf"
    );
  };

  const tableHeader = [
    {
      Header: "Date",
      accessor: "created_at",
      Cell: (row) =>
        moment(new Date(row.original.created_at)).format(
          "MMMM DD YYYY HH:mm:ss"
        ),
    },
    {
      Header: "Name",
      accessor: "name",
      Cell: (row) =>
        row.original.user_id.user_first_name +
        " " +
        row.original.user_id.user_last_name,
    },
    {
      Header: "Card Number",
      accessor: "ch_number",
      Cell: (row) => row.original.user_id.card_number,
    },
    {
      Header: "Amount in Peso(s)",
      accessor: "amount",
      Cell: (row) =>
        row.original.description === "Managed Purchase"
          ? "-" + row.original.statement_amount
          : row.original.description === "Managed Redeem"
          ? "+" + row.original.redeemed_amount
          : row.original.amount,
    },
    {
      Header: "Points",
      accessor: "points",
      Cell: (row) =>
        row.original.description === "Redeem"
          ? row.original.redeemed_amount
          : row.original.description === "Managed Redeem"
          ? row.original.redeemed_amount
          : row.original.points,
    },
    {
      Header: "Bonus",
      accessor: "bonus",
    },
    {
      Header: "Total Points",
      accessor: "total_points",
      Cell: (row) =>
        row.original.description === "Redeem"
          ? row.original.redeemed_amount
          : row.original.description === "Managed Redeem"
          ? row.original.redeemed_amount
          : row.original.total_points,
    },
  ];

  useEffect(() => {
    getTransaction(1, false, 0, 0);
  }, []);

  function getTransaction(
    pageNumber,
    filter,
    // name = filterName,
    // startAmount = startAmt,
    // endAmount = endAmt
  ) {
    setLoading(true);
    const req = {
      type: "web",
      filter,
      start_date: filterStartDate,
      end_date: filterEndDate,
      by: "date",
      // filterName,
      name: filterName,
      startAmount: startAmt,
      endAmount: endAmt,
      pageNumber,
    };
    axios
      .post(`${url_path}/api/transactions/infinity_transaction/${user_id}`, req)
      .then((res) => {
        const data = res.data;
        setTableData(data.transaction);
        setPrintData(data.transac);
        setPagerData(data.paginate);
        setLoading(false);
      })
      .catch((err) => console.log(err));
  }
  const validateNumberInput = (event) => {
    const { key } = event;

    const validKeys = ["Backspace", "Delete", "ArrowLeft", "ArrowRight"];

    if (!key.match(/\d/) && !validKeys.includes(key)) {
      event.preventDefault();
    }
  };

  return (
    <div className="tab-infinity-transaction">
      <p className="heading">Infinity Transactions</p>
      <Filter
        filterDetails={filterContent}
        content={
          <div className="section-filter">
            <form className="form-wrapper statement" onSubmit={handleSubmit}>
              <div className="form-wrapper transactions">
                <div className="filter-wrapper">
                  <div className="group-wrapper date">
                    <p className="filter-heading">Filter by date</p>
                    <div className="form-group">
                      <div className="form-field calendar">
                        <p className="input-label">From</p>
                        <label htmlFor="startDate">
                          <p className="label-calendar">
                            <FaCalendar />
                          </p>
                        </label>
                        <DatePicker
                          selected={filterStartDate}
                          onChange={(date) => setFilterStartDate(date)}
                          selectsEnd
                          dateFormat="MMM dd, yyyy"
                          // endDate={filterStartDate}
                          // minDate={filterStartDate}
                          onChangeRaw={handleDateChangeRaw}
                          className="form-input date"
                          name="filterStartDate"
                          autoComplete="off"
                          id="filterStartDate"
                          // required
                        />
                      </div>
                      <div className="form-field calendar">
                        <p className="input-label">To</p>
                        <label htmlFor="endDate">
                          <p className="label-calendar">
                            <FaCalendar />
                          </p>
                        </label>
                        <DatePicker
                          selected={filterEndDate}
                          onChange={(date) => setFilterEndDate(date)}
                          selectsEnd
                          dateFormat="MMM dd, yyyy"
                          // endDate={filterEndDate}
                          minDate={filterStartDate}
                          onChangeRaw={handleDateChangeRaw}
                          className="form-input date"
                          name="filterEndDate"
                          // placeholderText={date}
                          autoComplete="off"
                          id="filterEndDate"
                          // required
                        />
                      </div>
                    </div>
                  </div>
                  <div className="group-wrapper name">
                    <p className="filter-heading">Filter by name</p>
                    <div className="form-field">
                      <input
                        id="name"
                        className="form-input single-input"
                        type="text"
                        name="name"
                        // value={name}
                        // onChange={handleOnChange}
                        onChange={(e) => setFilterName(e.target.value)}
                        // required
                      />
                    </div>
                  </div>
                  <div className="group-wrapper amount">
                    <p className="filter-heading">Filter by amount</p>
                    <div className="form-col">
                      {/* <div className="form-group"> */}
                      <div className="form-field calendar">
                        <p className="input-label">From</p>
                        <input
                          type="number"
                          name="startAmt"
                          className="form-input"
                          id="startAmt"
                          value={startAmt}
                          onChange={handleOnChange}
                          // onChange={(text) => setStartAmt(text)}
                          onKeyDown={validateNumberInput}
                        />
                        {/* <label htmlFor='startAmt'>House No.</label> */}
                      </div>
                      <div className="form-field calendar">
                        <p className="input-label">To</p>
                        <input
                          type="number"
                          name="endAmt"
                          className="form-input"
                          id="endAmt"
                          value={endAmt}
                          onChange={handleOnChange}
                          // onChange={(endAmount) => setEndAmt(endAmount)}
                        />
                        {/* <label htmlFor='endAmt'>House No.</label> */}
                      </div>
                      {/* </div> */}
                    </div>
                  </div>
                </div>
                <button className="btn-blue form-deal-submit" type="submit">
                  Apply
                </button>
              </div>
            </form>
          </div>
        }
      />

      <div className="section-table">
        {!loading ? (
          <>
            {/* <div className="table-button-holder">
              <button
                className="btn-blue-outline btn-csv-export"
                onClick={() => handleExportData()}
              >
                Export Data
              </button>
            </div> */
            <div className="table-button-holder">
            <div className="export-dropdown">
              <DropdownButton
                id="dropdown-basic-button"
                title="Export Data"
                // onClick={(event, done) => handleExportData(event, done)}
              >
                <div className="export-option">
                  <CSVLink
                    className=""
                    filename={
                      profileData.business_name.replace(" ", "-") +
                      "-" +
                      moment().format("YYYY-MM-DD").toString() +
                      ".csv"
                    }
                    data={printData}
                    headers={csvHeader}
                    asyncOnClick={true}
                    target="_blank"
                  >
                    <span className="text-black hover-text-black">Export to Excel</span>
                  </CSVLink>
                </div>
                <div className="export-option">
                {<span 
                  onClick={() => handleExportData()}
                  className={"text-black hover-text-black"}
                  >Export to PDF</span>}
                </div>
              </DropdownButton>
            </div>
          </div>
            }
            <Table
              tableRef={tableRef}
              tableHeader={tableHeader}
              tableData={tableData}
              paginate={pagerData}
              pageFunction={getTransaction}
            />
          </>
        ) : (
          <div className="lds-ellipsis">
            <div className="dots"></div>
            <div className="dots"></div>
            <div className="dots"></div>
            <div className="dots"></div>
          </div>
        )}
      </div>
    </div>
  );
};

export default InfinityTransaction;
