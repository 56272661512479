import React, { useState, useEffect } from "react";
import { url_path } from "../../constant";
import { Link } from "react-router-dom";
import Table from "../../components/Table";
import Breadcrumbs from "../../components/Breadcrumbs";
import axios from "axios";
import Moment from "react-moment";
import "moment-timezone";
import {
  successNotif,
  errorNotifIcon,
} from "../../lib/helpers/notifications.js";
import Sweetalert2 from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { authenticateAdminPage } from "../../lib/helpers/validation";

const Swal = withReactContent(Sweetalert2);
function ManageArchiveBusinessPartners(props) {
  useEffect(() => {
    authenticateAdminPage(props);
  }, []);
  // States
  const [data, setData] = useState([]);
  const [pagerData, setPagerData] = useState([]);
  const [loader, setLoader] = useState(false);
  const [searchInput, setSearchInput] = useState("");
  const heading = [
    {
      Header: "No",
      id: "no",
      maxWidth: 50,
      filterable: false,
      Cell: (row) => {
        return <>{row.index + 1}</>;
      },
    },
    {
      Header: "Business Name",
      accessor: "business_name",
      // maxWidth: 200,
      Cell: (row) => {
        return (
          <div>
            <Link to={"/business-profile/" + row.original._id}>
              {row.original.business_name}
            </Link>
          </div>
        );
      },
    },
    {
      Header: "Business Owner",
      accessor: "business_owner",
      // maxWidth: 350
    },
    {
      Header: "Province",
      accessor: "province",
      // maxWidth: 190
    },
    {
      Header: "City",
      accessor: "city",
      // maxWidth: 190
    },
    {
      Header: "Date of joining",
      id: "created_at",
      maxWidth: 180,
      Cell: (row) => (
        <span>{<Moment format="L" date={row.original.created_at} />}</span>
      ),
    },
    {
      Header: "",
      accessor: "action",
      width: 150,
      Cell: (row) => (
        <>
          <button
            className="btn-blue btn-sm"
            type="button"
            onClick={() => returnPartner(row.original._id)}
          >
            Re-activate
          </button>
        </>
      ),
    },
  ];
  const returnPartner = async (partner_id) => {
    const { value } = await Swal.fire({
      title: "Return Partner?",
      icon: "question",
      showCancelButton: true,
      confirmButtonColor: "#106397",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes",
    });

    if (value) {
      try {
        const req = { partner_id, reverse: true };
        const { data: result } = await axios.put(
          `${url_path}/api/business_partners/remove_partner`,
          req
        );
        if (result.data.status !== "success") {
          errorNotifIcon("Oops!", result.data.msg);
        } else {
          successNotif("Awesome!", "Partner has been reactivated successfully.");
        }

        getPartnerData(1, "");
      } catch (err) {
        console.log(err);
      }
    }
  };
  function getPartnerData(pageNumber = 1, search = "") {
    setLoader(false);
    const req = {
      pageNumber,
      search,
      reverse: true
    };
    axios
      .post(`${url_path}/api/business_partners`, req)
      .then((response) => {
        setData(response.data.business_partners);
        setPagerData(response.data.paginate);
        setLoader(true);
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  useEffect(() => {
    getPartnerData(1, searchInput);
  }, []);

  const handleChangeCheckbox = (event) => {
    const id = event.target.name;
    console.log(id)
  };

  const handleSearchInput = (event) => {
    setSearchInput(event.target.value);
  };

  const handleSearchSubmit = (event) => {
    event.preventDefault();

    getPartnerData(1, searchInput);
  };

  return (
    <main className="manage-pages">
      <div className="container custom-container">
        <Breadcrumbs
          size="text-large"
          placeholder="Manage Archived Business Partners"
          targetLink="/archives"
          customClassName="m-3"
        />

        <div className="section-table">
          {/* <div className="container-fluid"> */}
          <form onSubmit={handleSearchSubmit}>
            <div className="search-holder">
              <label htmlFor="searchInput">Search: </label>
              <input
                size="large"
                name="searchInput"
                label="Search"
                value={searchInput}
                onChange={handleSearchInput}
                className="search-input"
              />
              <input type="submit" className="btn-blue" value="Search" />
            </div>
          </form>
          {loader ? (
            <>
              <Table
                tableHeader={heading}
                tableData={data}
                show={handleChangeCheckbox}
                paginate={pagerData}
                pageFunction={getPartnerData}
              />
            </>
          ) : (
            <div className="lds-ellipsis">
              <div className="dots"></div>
              <div className="dots"></div>
              <div className="dots"></div>
              <div className="dots"></div>
            </div>
          )}
          {/* </div> */}
        </div>
        
      </div>
    </main>
  );
}

export default ManageArchiveBusinessPartners;
