import React, { useState, useEffect } from 'react'
import Table from "../../components/Table"
import Breadcrumbs from "../../components/Breadcrumbs"
import { Link } from 'react-router-dom'
import ReactTooltip from 'react-tooltip'

import { ReactComponent as IconDelete } from '../../assets/images/icon-delete.svg'
import { ReactComponent as IconEdit } from '../../assets/images/icon-edit.svg'
import {authenticateBusinessPage} from '../../lib/helpers/validation'

function ManageDeals(props) {
  useEffect(() => {
    authenticateBusinessPage(props)
  }, []);
  const [data, setData] = useState([])

  const dataVal = [
    { 
      id: 1, 
      details: "Buy 3 coffees and get 1 Free", 
      type: "A loyalty discount",
      dateCreated: '10/20/2019' 
    }, { 
      id: 2, 
      details: "Receive $10 off 20 oz smoothie cup", 
      type: "Flash Sale",
      dateCreated: '11/23/2019' 
    }, { 
      id: 3, 
      details: "Referral Bonus", 
      type: "+ 100",
      dateCreated: '11/25/2019' 
    }, { 
      id: 4, 
      details: "Referral Bonus", 
      type: "+ 100",
      dateCreated: '11/26/2019' 
    }
  ]

  const heading = [
    {
      Header: "Details",
      accessor: 'details',
      width: 350
    }, {
      Header: "Detail Type",
      accessor: 'type'
    }, {
      Header: "Created On",
      accessor: 'dateCreated'
    }, {
      Header: 'Actions',
      sortable: false,
      filterable: false,
      Cell: row => ( 
        <div className="button-wrapper">
          <div className="button-holder">
            <Link to="/deal-details" className="icon-link">
              <span className="icon-holder">
                <IconEdit className="icon-actions" data-tip data-for="edit"/>
              </span>
            </Link>
            <ReactTooltip id="edit" type="warning" effect="solid">
              <span>Edit</span>
            </ReactTooltip>
          </div>
          <div className="button-holder">
            <button className="btn-icon" 
              onClick= {() => {
                let datas = [...data];
                var index = datas.map(id => {
                  return id.id;
                }).indexOf(datas[row.index].id)
                datas.splice(index, 1)

                var delConfirmation = window.confirm('Do you want to delete tihs item?')
                
                if (delConfirmation === true) {
                  setData(datas)
                } else {
                  return;
                }
              }}
            >
              <span className="icon-holder">
                <IconDelete className="icon-actions" data-tip data-for="delete"/>
              </span>
            </button>
            <ReactTooltip id="delete" type="warning" effect="solid">
              <span>Delete</span>
            </ReactTooltip>
          </div>
        </div>
       ) 
    }
  ]

  const handleNewOffer = () => {
    props.history.push('/deal-details')
  }
  

  useEffect(() => {
    setData(dataVal)
  }, [])

  return (
    <main className="manage-deals">
      <Breadcrumbs 
        size = 'text-large'
        placeholder = 'Manage Deals'
        targetLink = '/partner-dashboard'
        hasButton = 'true'
        buttonTitle = 'Create New Offer'
        customClassName = 'm-3'
        propsFunction = {handleNewOffer}
      />
      <div className="section-table">
        <div className="container">
          <Table 
            tableHeader={ heading }
            tableData={ data }
          />
        </div>
      </div>
    </main>
  )
}

export default ManageDeals
