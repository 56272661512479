import React, { useState, useEffect } from "react";
import {url_path} from '../../constant';
// import { Link } from 'react-router-dom'
import Tab from "react-bootstrap/Tab";
import Nav from "react-bootstrap/Nav";
import Breadcrumbs from "../../components/Breadcrumbs";
// import '../../stylesheets/main.scss';
import axios from "axios";

import Account from "../../tabs/member/Account";
import ContactDetails from "../../tabs/member/ContactDetails";
import AccountTransactions from "../../tabs/member/AccountTransactions";
import PersonalData from "../../tabs/member/PersonalData";
import Referrals from "../../tabs/member/Referrals";
import ResetPassword from "../../tabs/member/ResetPassword";
import SendNotifications from "../../tabs/member/SendNotifications";
import ManualPromotion from "../../tabs/member/ManualPromotion";
import {authenticateAdminPage} from '../../lib/helpers/validation'

const MemberProfile = props => {
  useEffect(() => {
    authenticateAdminPage(props)
  }, []);
  // States
  const [userData, setUserData] = useState({});
  const [loader, setLoader] = useState(false);

  // Variables
  const URL_ID = props.match.params.id;

  const content = [
    {
      name: "Account",
      eventKey: "account"
    },
    {
      name: "Account Transactions",
      eventKey: "account-transactions"
    },
    {
      name: "Personal Data",
      eventKey: "personal-data"
    },
    {
      name: "Contact Details",
      eventKey: "contact-details"
    },
    {
      name: "Referrals",
      eventKey: "referrals"
    },
    {
      name: "Reset Password",
      eventKey: "reset-password"
    },
    {
      name: "Send Notification",
      eventKey: "send-notifications"
    },
    {
      name: "Manual Promotion",
      eventKey: "manual-promotion"
    },
  ];

  const tabPane = {
    account: {
      title: "Account"
    },
    accountTransactions: {
      title: "Account Transaction"
    },
    personalData: {
      title: "Personal Data"
    },
    contactDetails: {
      title: "Contact Details"
    },
    photosInfo: {
      title: "Photos of Business",
      hasMessage: true,
      message: "You can add up to 5 Photos that suits your business"
    },
    dealsInfo: {
      title: "Deals"
    },
    resetPassword: {
      title: "Reset Password"
    },
    sendNotification: {
      title: "Send Notification"
    },
    manualPromotion: {
      title: "Manual Promotion"
    }
  };

  useEffect(() => {
    axios
      .get(`${url_path}/api/userSubscription/${URL_ID}`)
      .then(response => {
        setUserData(response.data);
        setLoader(true);
        console.log(response.data);
      })
      .catch(err => console.log(err));
  }, []);

  return (
    <main className="member-profile">
      <div className="container custom-container">
        <Breadcrumbs
          customClassName="m-3"
          size="text-large"
          placeholder="Back"
          targetLink="/manage-members"
        />
        {loader ? (
          <div className="section-member-profile">
            {/* <div className="container-fluid"> */}
              <Tab.Container
                id="member-profile-tabs"
                defaultActiveKey="account"
                mountOnEnter={true}
              >
                <div className="section-tab">
                  <div className="tab-nav">
                    <Nav className="flex-column">
                      {content.map(function(item, i) {
                        return (
                          <Nav.Item key={i}>
                            <Nav.Link eventKey={item.eventKey}>
                              {item.name}
                            </Nav.Link>
                          </Nav.Item>
                        );
                      })}
                    </Nav>
                  </div>
                  <div className="tab-content-holder">
                    <Tab.Content>
                      <Tab.Pane eventKey="account">
                        <Account data={tabPane.account} user_id={URL_ID} />
                      </Tab.Pane>
                      <Tab.Pane eventKey="account-transactions">
                        <AccountTransactions
                          data={tabPane.accountTransactions}
                          user_id={URL_ID}
                        />
                      </Tab.Pane>
                      <Tab.Pane eventKey="personal-data">
                        <PersonalData
                          data={tabPane.personalData}
                          user_id={URL_ID}
                        />
                      </Tab.Pane>
                      <Tab.Pane eventKey="contact-details">
                        <ContactDetails
                          data={tabPane.contactDetails}
                          user_id={URL_ID}
                        />
                      </Tab.Pane>
                      <Tab.Pane eventKey="referrals">
                        <Referrals
                          data={tabPane.contactDetails}
                          referrence_no={userData.referrence_number}
                        />
                      </Tab.Pane>
                      <Tab.Pane eventKey="reset-password">
                        <ResetPassword
                          data={tabPane.resetPassword}
                          user_id={URL_ID}
                        />
                      </Tab.Pane>
                      <Tab.Pane eventKey="send-notifications">
                        <SendNotifications
                          data={tabPane.sendNotification}
                          user_id={URL_ID}
                        />
                      </Tab.Pane>
                      <Tab.Pane eventKey="manual-promotion">
                        <ManualPromotion
                          data={tabPane.manualPromotion}
                          user_id={URL_ID}
                          user_category={userData.user_category}
                        />
                      </Tab.Pane>
                    </Tab.Content>
                  </div>
                </div>
              </Tab.Container>
            {/* </div> */}
          </div>
        ) : (
          <div className="lds-ellipsis">
            <div className="dots"></div>
            <div className="dots"></div>
            <div className="dots"></div>
            <div className="dots"></div>
          </div>
        )}
      </div>
    </main>
  );
};

export default MemberProfile;
