import React, { useEffect, useState } from "react";
import { url_path } from "../../constant";
import Breadcrumbs from "../../components/Breadcrumbs";
import axios from "axios";
import { getCookie } from "../../lib/helpers/cookies";
import Modal from "react-bootstrap/Modal";
import Card from "../../components/Card";
import Sidebar from "../../components/Sidebar";
import { MdClose } from "react-icons/md";
import { successNotif, errorNotifIcon } from "../../lib/helpers/notifications";
import { authenticateMemberPage } from "../../lib/helpers/validation";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/bootstrap.css";
const ContactDetails = ({ history }) => {
  useEffect(() => {
    authenticateMemberPage(history);
  }, []);
  // States
  const [loading, setLoading] = useState(true);
  const [contactDetails, setContactDetails] = useState({});
  const [showModal, setShowModal] = useState(false);
  const [editDetails, setEditDetails] = useState({});
  const [isHome, setIsHome] = useState(true);
  // const [phone, setPhone] = useState()
  // const [countryCode, setCountryCode] = useState()

  // Variables
  const editCardDetails = {
    title: "Edit Contact Details",
    customClassName: "no-margin no-shadow",
  };

  const cardDetails = {
    title: "Contact Details",
    customClassName: "",
  };

  // const handleClose = () => setShow(false);
  // const handleShow = () => setShow(true);

  useEffect(() => {
    getContactDetails();
  }, []);

  // Functions
  const getContactDetails = async () => {
    setLoading(true);
    const token = getCookie("token");

    const { data: res_data } = await axios.get(`${url_path}/api/auth`, {
      headers: { "x-auth-token": token },
    });
    const { _id: user_id } = res_data.user;

    const { data: contact_details } = await axios.get(
      `${url_path}/api/member_additional_contact_details/${user_id}`
    );
    contact_details.user_id = user_id;

    setContactDetails(contact_details);
    setIsHome(contact_details.isHome);
    setLoading(false);
  };

  const handleShowModal = () => {
    setEditDetails(contactDetails);
    setShowModal(true);
  };

  const handleHideModal = () => {
    setShowModal(false);
  };

  const handleOnSubmit = async (event) => {
    event.preventDefault();

    const req = editDetails;

    try {
      const { data: update_res } = await axios.put(
        `${url_path}/api/member_additional_contact_details/${contactDetails.user_id}`,
        req
      );

      if (update_res.data.status === "success") {
        let title = "Awesome!";
        let msg = update_res.data.msg;
        getContactDetails();
        successNotif(title, msg);
        handleHideModal();
      } else {
        let title = "Oops!";
        let msg = "There's an error updating your data.";
        errorNotifIcon(title, msg);
      }
    } catch (err) {
      console.log(err);
    }
  };

  // const validatePhoneInput = event => {
  //   const validKeys = ['Backspace', 'Delete', 'ArrowLeft', 'ArrowRight', 'Home', 'End', '+']
  //   if(!event.key.match(/[0-9]/) && !validKeys.includes(event.key)) {
  //     event.preventDefault()
  //   }
  // }

  useEffect(() => {
    getContactDetails();
  }, []);

  return (
    <main className="member-data member-dashboard">
      <Sidebar history={history} />
      <div className="content-wrapper">
        <Breadcrumbs
          size="text-large"
          placeholder="Back"
          targetLink="/my-infinity-account"
          hasButton="false"
        />
        {loading ? (
          <div className="lds-ellipsis">
            <div className="dots"></div>
            <div className="dots"></div>
            <div className="dots"></div>
            <div className="dots"></div>
          </div>
        ) : (
          <Card
            cardDetails={cardDetails}
            form={
              <div className="account-form">
                <div className="form-wrapper">
                  <div className="form-field">
                    <input
                      type="email"
                      name="email"
                      className="form-input"
                      id="email"
                      defaultValue={contactDetails.user_email}
                      readOnly
                      disabled
                    />
                    <label htmlFor="email">Email</label>
                  </div>
                  <div className="form-field">
                    <input
                      type="text"
                      name="mobileNum"
                      className="form-input"
                      id="mobileNum"
                      defaultValue={contactDetails.user_phone}
                      readOnly
                      disabled
                    />
                    <label htmlFor="mobileNum">Main mobile number</label>
                  </div>
                  <div className="form-field">
                    <input
                      type="text"
                      name="mobileNumAdd"
                      className="form-input"
                      id="mobileNumAdd"
                      defaultValue={contactDetails.additional_mobile_number}
                      readOnly
                      disabled
                    />
                    <label htmlFor="mobileNumAdd">
                      Additional mobile number
                    </label>
                  </div>

                  <p className="text-bold">Card delivery address</p>

                  <div className="radio-wrapper">
                    <div className="form-field">
                      <label className="radio">
                        <input
                          type="radio"
                          defaultValue="home"
                          defaultChecked={isHome}
                        />
                        <span className="checkmark"></span>
                      </label>
                      <p className="radio-text">Home</p>
                    </div>
                    <div className="form-field">
                      <label className="radio">
                        <input
                          type="radio"
                          defaultValue="work"
                          defaultChecked={!isHome}
                        />
                        <span className="checkmark"></span>
                      </label>
                      <p className="radio-text">Work</p>
                    </div>
                  </div>
                  {/* <div className="form-col">
                <div className="form-field">
                  <div className="radio form-input">
                      <input type="radio" defaultValue="home" defaultChecked={ isHome } />
                    <label>
                      Home
                    </label>
                  </div>
                </div>
                <div className="form-field">
                  <div className="radio form-input">
                      <input type="radio" defaultValue="work" defaultChecked={ !isHome } />
                    <label>
                      Work
                    </label>
                  </div>
                </div>
              </div> */}

                  {contactDetails.isHome ? (
                    <>
                      <div className="form-col">
                        <div className="form-field">
                          <input
                            type="number"
                            name="houseNo"
                            className="form-input"
                            id="houseNo"
                            defaultValue={contactDetails.house_number}
                            readOnly
                            disabled
                          />
                          <label htmlFor="houseNo">House No.</label>
                        </div>
                        <div className="form-field">
                          <input
                            type="text"
                            name="street"
                            className="form-input"
                            id="street"
                            defaultValue={contactDetails.street}
                            readOnly
                            disabled
                          />
                          <label htmlFor="street">Street</label>
                        </div>
                      </div>
                    </>
                  ) : (
                    <>
                      <div className="form-field">
                        <input
                          type="text"
                          name="companyName"
                          className="form-input"
                          id="companyName"
                          defaultValue={contactDetails.company_name}
                          readOnly
                          disabled
                        />
                        <label htmlFor="companyName">Company Name</label>
                      </div>
                      <div className="form-col">
                        <div className="form-field">
                          <input
                            type="number"
                            name="houseNo"
                            className="form-input"
                            id="houseNo"
                            defaultValue={contactDetails.house_number}
                            readOnly
                            disabled
                          />
                          <label htmlFor="houseNo">Bldg No.</label>
                        </div>
                        <div className="form-field">
                          <input
                            type="text"
                            name="street"
                            className="form-input"
                            id="street"
                            defaultValue={contactDetails.street}
                            readOnly
                            disabled
                          />
                          <label htmlFor="street">Street</label>
                        </div>
                      </div>
                    </>
                  )}

                  <div className="form-field">
                    <input
                      type="text"
                      name="city"
                      className="form-input"
                      id="city"
                      defaultValue={contactDetails.city}
                      readOnly
                      disabled
                    />
                    <label htmlFor="city">City</label>
                  </div>
                  <div className="form-field">
                    <input
                      type="text"
                      name="province"
                      className="form-input"
                      id="province"
                      defaultValue={contactDetails.province}
                      readOnly
                      disabled
                    />
                    <label htmlFor="province">Province</label>
                  </div>
                  <div className="form-field">
                    <input
                      type="text"
                      name="country"
                      className="form-input"
                      id="country"
                      defaultValue={contactDetails.country}
                      readOnly
                      disabled
                    />
                    <label htmlFor="country">Country</label>
                  </div>
                  <div className="form-field">
                    <input
                      type="number"
                      name="zipCode"
                      className="form-input"
                      id="zipCode"
                      defaultValue={contactDetails.zipcode}
                      readOnly
                      disabled
                    />
                    <label htmlFor="zipCode">Zip Code</label>
                  </div>

                  <div className="btn-holder">
                    <button className="btn-blue" onClick={handleShowModal}>
                      Edit
                    </button>
                  </div>
                </div>
              </div>
            }
          />
        )}
      </div>

      <Modal
        show={showModal}
        onHide={handleHideModal}
        className="modal-dark- opacity edit-modal"
      >
        <Card
          cardDetails={editCardDetails}
          form={
            <>
              <div className="heading-wrapper">
                <p className="heading">Edit additional personal details</p>
                <p className="heading-close" onClick={handleHideModal}>
                  <MdClose />{" "}
                </p>
              </div>
              <form className="form-wrapper" onSubmit={handleOnSubmit}>
                <div className="form-field">
                  <input
                    type="email"
                    name="email"
                    className="form-input"
                    id="email"
                    value={editDetails.user_email}
                    onChange={(e) =>
                      setEditDetails({
                        ...editDetails,
                        user_email: e.target.value,
                      })
                    }
                  />
                  <label htmlFor="email">Email</label>
                </div>
                <div className="form-field">
                  <PhoneInput
                    inputClass={["form-input not-required"]}
                    enableAreaCodes={true}
                    countryCodeEditable={false}
                    autoFormat={true}
                    country={"ph"}
                    // regions={'asia'}
                    // value={phone}
                    enableSearch={true}
                    // onChange={handlePhoneChanges}
                    name="mobileNum"
                    id="mobileNum"
                    value={editDetails.user_phone}
                    onChange={(value) =>
                      setEditDetails({
                        ...editDetails,
                        user_phone: value.replace(/ /g, ""),
                      })
                    }
                    required
                  />
                  {/* <input 
                  type='text'
                  name='mobileNum'
                  className='form-input'
                  id='mobileNum'
                  value={ editDetails.user_phone }
                  onKeyDown={ validatePhoneInput }
                  onChange={e => setEditDetails({
                    ...editDetails,
                    user_phone: e.target.value
                  })} 
                /> */}
                  <label htmlFor="mobileNum" className="label-fixed">
                    Main mobile number
                  </label>
                </div>
                <div className="form-field">
                  <PhoneInput
                    inputClass={["form-input"]}
                    enableAreaCodes={true}
                    countryCodeEditable={false}
                    autoFormat={true}
                    country={"ph"}
                    // regions={'asia'}
                    // value={phone}
                    enableSearch={true}
                    // onChange={handlePhoneChanges}
                    name="mobileNumAdd"
                    id="mobileNumAdd"
                    value={editDetails.additional_mobile_number}
                    onChange={(value) =>
                      setEditDetails({
                        ...editDetails,
                        additional_mobile_number: value.replace(/ /g, ""),
                      })
                    }
                    required
                  />
                  <label htmlFor="mobileNumAdd" className="label-fixed">
                    Additional mobile number
                  </label>
                </div>
                {/* <div className="form-field">
                <input 
                  type='text'
                  name='mobileNumAdd'
                  className='form-input'
                  id='mobileNumAdd'
                  value={ editDetails.additional_mobile_number }
                  onKeyDown={ validatePhoneInput }
                  onChange={e => setEditDetails({
                    ...editDetails,
                    additional_mobile_number: e.target.value
                  })}
                />
                <label htmlFor='mobileNumAdd'>Additional mobile number</label>
              </div> */}
                <p className="text-bold">Card delivery address</p>
                <div className="radio-wrapper">
                  <div className="form-field">
                    <label className="radio">
                      <input
                        type="radio"
                        value="home"
                        onChange={(e) =>
                          setEditDetails({
                            ...editDetails,
                            isHome: true,
                          })
                        }
                        checked={editDetails.isHome}
                      />
                      <span className="checkmark"></span>
                    </label>
                    <p className="radio-text">Home</p>
                  </div>
                  <div className="form-field">
                    <label className="radio">
                      <input
                        type="radio"
                        value="work"
                        onChange={(e) =>
                          setEditDetails({
                            ...editDetails,
                            isHome: false,
                          })
                        }
                        checked={!editDetails.isHome}
                      />
                      <span className="checkmark"></span>
                    </label>
                    <p className="radio-text">Work</p>
                  </div>
                </div>

                {/* <div className="form-col">
                <div className="form-field">
                  <div className="radio form-input">
                    <label>
                      <input
                        type="radio"
                        value="home"
                        onChange={e => setEditDetails({
                          ...editDetails,
                          isHome: true
                        })}
                    checked={ editDetails.isHome } />
                      Home
                    </label>
                  </div>
                </div>
                <div className="form-field">
                  <div className="radio form-input">
                    <label>
                      <input
                        type="radio" value="work"
                        onChange={e => setEditDetails({
                          ...editDetails,
                          isHome: false
                        })}
                    checked={ !editDetails.isHome } />
                      Work
                    </label>
                  </div>
                </div>
              </div> */}

                {editDetails.isHome ? (
                  <>
                    <div className="form-col">
                      <div className="form-field">
                        <input
                          type="number"
                          name="houseNo"
                          className="form-input"
                          id="houseNo"
                          value={editDetails.house_number}
                          onChange={(e) =>
                            setEditDetails({
                              ...editDetails,
                              house_number: e.target.value,
                            })
                          }
                        />
                        <label htmlFor="houseNo">House No.</label>
                      </div>
                      <div className="form-field">
                        <input
                          type="text"
                          name="street"
                          className="form-input"
                          id="street"
                          value={editDetails.street}
                          onChange={(e) =>
                            setEditDetails({
                              ...editDetails,
                              street: e.target.value,
                            })
                          }
                        />
                        <label htmlFor="street">Street</label>
                      </div>
                    </div>
                  </>
                ) : (
                  <>
                    <div className="form-field">
                      <input
                        type="text"
                        name="companyName"
                        className="form-input"
                        id="companyName"
                        value={editDetails.company_name}
                        onChange={(e) =>
                          setEditDetails({
                            ...editDetails,
                            company_name: e.target.value,
                          })
                        }
                      />
                      <label htmlFor="companyName">Company Name</label>
                    </div>
                    <div className="form-col">
                      <div className="form-field">
                        <input
                          type="number"
                          name="houseNo"
                          className="form-input"
                          id="houseNo"
                          value={editDetails.house_number}
                          onChange={(e) =>
                            setEditDetails({
                              ...editDetails,
                              house_number: e.target.value,
                            })
                          }
                        />
                        <label htmlFor="houseNo">Bldg No.</label>
                      </div>
                      <div className="form-field">
                        <input
                          type="text"
                          name="street"
                          className="form-input"
                          id="street"
                          value={editDetails.street}
                          onChange={(e) =>
                            setEditDetails({
                              ...editDetails,
                              street: e.target.value,
                            })
                          }
                        />
                        <label htmlFor="street">Street</label>
                      </div>
                    </div>
                  </>
                )}

                <div className="form-field">
                  <input
                    type="text"
                    name="city"
                    className="form-input"
                    id="city"
                    value={editDetails.city}
                    onChange={(e) =>
                      setEditDetails({
                        ...editDetails,
                        city: e.target.value,
                      })
                    }
                  />
                  <label htmlFor="city">City</label>
                </div>
                <div className="form-field">
                  <input
                    type="text"
                    name="province"
                    className="form-input"
                    id="province"
                    value={editDetails.province}
                    onChange={(e) =>
                      setEditDetails({
                        ...editDetails,
                        province: e.target.value,
                      })
                    }
                  />
                  <label htmlFor="province">Province</label>
                </div>
                <div className="form-field">
                  <input
                    type="text"
                    name="country"
                    className="form-input"
                    id="country"
                    value={editDetails.country}
                    onChange={(e) =>
                      setEditDetails({
                        ...editDetails,
                        country: e.target.value,
                      })
                    }
                  />
                  <label htmlFor="country">Country</label>
                </div>
                <div className="form-field">
                  <input
                    type="number"
                    name="zipCode"
                    className="form-input"
                    id="zipCode"
                    value={editDetails.zipcode}
                    onChange={(e) =>
                      setEditDetails({
                        ...editDetails,
                        zipcode: e.target.value,
                      })
                    }
                  />
                  <label htmlFor="zipCode">Zip Code</label>
                </div>
                <div className="btn-holder">
                  <button type="submit" className="btn-blue">
                    Save
                  </button>
                </div>
              </form>
            </>
          }
        />
      </Modal>
    </main>
  );
};

export default ContactDetails;
