import React, { useEffect, useState } from "react";
import { url_path } from "../../constant";
import {
  successNotif,
  errorNotifIcon,
  submitConfimation,
} from "../../lib/helpers/notifications.js";
import useForm from "../../lib/hooks/useForm.js";
import Card from "../../components/Card";
import { ReactComponent as IconCaretDown } from "../../assets/images/icon-caret-down.svg";
import axios from "axios";

const ManagePurchase = ({ data, business_partner_id }) => {
  // Life-cycles
  useEffect(() => {
    getStaffData();
    getOffersAndDiscountsData();
  }, []);
  // const [isLoading, setIsLoading] = useState(false);

  const [points_earned, set_points_earned] = useState();
  const [business_name, set_business_name] = useState();
  const [ch_id, set_ch_id] = useState();
  const [staffData, setStaffData] = useState([]);
  const [staff, setStaff] = useState("default");
  const [offer, setOffer] = useState("default");
  const [discount, setDiscount] = useState("default");
  const [offer_id, set_offer_id] = useState();
  // const [discount_id, set_discount_id] = useState();
  const [isButtonLoading, setIsButtonLoading] = useState(false);
  const [discountList, setDiscountList] = useState([]);
  const [offerList, setOfferList] = useState([]);

  var numRegex = /^[0-9]*$/;
  const stateSchema = {
    cardNo: { value: "", error: "" },
    amount: { value: "", error: "" },
    confirmAmount: { value: "", error: "" },
    invoiceNo: { value: "", error: "" },
    notes: { value: "", error: "" },
  };

  const stateValidatorSchema = {
    cardNo: {
      required: true,
      validator: {
        func: (value) =>
          parseInt(value.length) === 4 ? (values.cardNo = "asdasdsad") : null,
      },
    },
    amount: {
      required: true,
      validator: {
        func: (value) => numRegex.test(value),
        error: "Amount Only Accept Numbers",
      },
    },
    confirmAmount: {
      required: true,
      validator: {
        func: (value) => value === amount && numRegex.test(value),
        error: "Amounts don't match / Amount Only Accept Numbers",
      },
    },
    invoiceNo: {
      required: false,
    },
    notes: {
      required: false,
    },
  };


  const onSubmitForm = async () => {
    // const req = {cardNo, amount, confirmAmount, invoiceNo, notes};

    console.log(amount);
    if (staff != "default" && amount != ""){
      if (staff == "default") {
        return errorNotifIcon("Oops!", "Please Select Staff!");
      }
      if (offer == "default"){
        return errorNotifIcon("Oops!", "Please Select Offer");
      }
      setIsButtonLoading(true);
      axios.get(`${url_path}/api/scanner/${cardNo}`).then((res) => {
        const scannerData = res.data;

        console.warn(scannerData.data);
        if (scannerData.data.status === "error") {
          console.log(scannerData.data.msg);
          const title = "Oops!";
          const msg = scannerData.data.msg;
          errorNotifIcon(title, msg);
          setIsButtonLoading(false);
          // Alert.alert(
          //   'Oops!',
          //   scannerData.data.msg,
          //   [{text: 'OK', onPress: () => console.warn('exit')}],
          //   {cancelable: false},
          // );
          // this.setState({spinner: false});
        } else {
          if (scannerData.scanner.user_role != "Member") {
            const title = "Oops!";
            const msg = "Card Number is not Valid";
            errorNotifIcon(title, msg);
            setIsButtonLoading(false);
            // return console.log('Card is not Valid');
          } else {
            checkDiscounts();
          }

          // this.props.navigation.navigate('Purchase', {
          //   cardNo: this.state.cardNo,
          //   amount: this.state.amount,
          //   invoice: this.state.invoice,
          // });
          // this.setState({spinner: false});
        }
      });

    }
    else{
      return errorNotifIcon("Oops!", "Please check the following! (Staff, Point, Discount and Amount)");
    }
  };

  const {
    // state,
    values,
    errors,
    handleOnChange,
    handleSubmit,
    disable,
  } = useForm(stateSchema, stateValidatorSchema, onSubmitForm);
  const { cardNo, amount, confirmAmount, invoiceNo, notes } = values;
  
  function checkDiscounts() {
    // get the user detail

    const req = {
      business_partner_id: business_partner_id,
      card_number: cardNo,
    };
    axios
      .post(`${url_path}/api/business_request/purchaseGetData`, req)
      .then((res) => {
        const business_request = res.data;
        if (business_request.status !== "success") {
          setIsButtonLoading(false);
          const title = "Oops!";
          const msg = business_request.msg;
          return errorNotifIcon(title, msg);
        }

        const pointsEarnedReq = {
          offer_id: business_request.data.offer_id,
          payment: amount,
        };
        // get points in total
        axios
          .post(
            `${url_path}/api/purchase/points_earned/${business_request.data.ch_id}`,
            pointsEarnedReq
          )
          .then((res) => {
            const pointsData = res.data;

            const discount_req = {
              payment: amount,
            };
            // console.log(points);
            // get discount in total
            axios
              .post(
                `${url_path}/api/purchase/points_discount/${business_request.data.business_partner_id}`,
                discount_req
              )
              .then((res) => {
                console.warn("purchase");
                const discount = res.data;
                // console.log(business_request)
                if (discount.status === "success") {
                  set_points_earned(pointsData.points);
                  set_business_name(business_request.data.business_name);
                  set_ch_id(business_request.data.ch_id);
                  set_offer_id(business_request.data.offer_id);
                  //   console.log(business_request.data.offer_id)
                  // setIsButtonLoading(false);
                  submitConfimation(
                    "Are you sure!",
                    "Cardholder points will be deducted",
                    onApply,
                    business_request.data.offer_id
                  );
                } else {
                  setIsButtonLoading(false);
                  const title = "Oops!";
                  const msg = "Please set Points Discount first.";
                  errorNotifIcon(title, msg);
                  // props.history.push(
                  //   `/manual-purchase
                  //   }`
                  // );
                }
              });
          });
        // console.log(tier_level);
      });
  }

  function getStaffData() {
    let req = {
      business_partner_id,
    };
    try {
      axios.put(`${url_path}/api/business_partner_staff`, req).then((res) => {
        const result = res.data;
        if (result.data.status === "success") {
          setStaffData(result.data.userData);
        }
      });
    } catch (error) {}
  }

  const getOffersAndDiscountsData = async () => {
    try {
      const { data: offer_list } = await axios.get(
        `${url_path}/api/point_offer/${business_partner_id}`
      );
      if (offer_list.status === "success") {
        setOfferList(offer_list.offer_data);
      }

      const { data: discount_list } = await axios.get(
        `${url_path}/api/business_discount/${business_partner_id}`
      );
      if (discount_list.status === "success") {
        setDiscountList(discount_list.business_discount);
      }
    } catch (err) {
      console.error(err);
    }
  };

  const onApply = (offerId) => {
    if (offer === "default") {
      return errorNotifIcon("Oops!", "Please Select Offer!");
    }
    if (discount === "default") {
      return errorNotifIcon("Oops!", "Please Select Discount!");
    }
    const req = {
      offer_id: offer,
      discount_id: discount,
      payment: amount,
      purchase_detail: notes,
      status: "reverse",
    };
    // return console.log(req);
    const partner_staff_id = staff;
    if(business_partner_id == null || business_partner_id == "" || partner_staff_id == null || partner_staff_id == ""){
      return errorNotifIcon("Oops!", "Something went wrong please Refresh!")
    }
    else{
      axios
        .post(
          `${url_path}/api/scanner/purchase/${business_partner_id}/${partner_staff_id}/${cardNo}`,
          req
        )
        .then((res) => {
          const purchase_detail = res.data;

          // send notification
          const notifReq = {
            notification_user_id: ch_id,
            title: business_name + " (Purchase)",
            description:
              "you purchased " +
              amount +
              " and you earned " +
              points_earned +
              " points",
            status: false,
          };
          axios.post(`${url_path}/api/notification`, notifReq).then((res) => {
            const notification = res.data;
            console.log(notification);
          });
          const title = "Successful";
          const msg = purchase_detail.msg;
          values.cardNo = "";
          values.amount = "";
          values.confirmAmount = "";
          values.invoiceNo = "";
          values.notes = "";
          setStaff("default");
          successNotif(title, msg);
          setIsButtonLoading(false);
      });
    }

  };

  const formatCardNumber = (value) => {
    let cardNumber = value.replace(/ /g, "");

    const formatedCardNumber =
      cardNumber.substring(0, 4).replace(/\d{3}(?=.)/, "$& ") +
      cardNumber.substring(4, 8).replace(/\d{2}(?=.)/, "$& ") +
      cardNumber.substring(8, 10);

    return formatedCardNumber;
  };

  const validateNumberInput = (event) => {
    const { key } = event;

    const validKeys = ["Backspace", "Delete", "ArrowLeft", "ArrowRight"];

    if (!key.match(/\d/) && !validKeys.includes(key)) {
      event.preventDefault();
    }
  };

  const selectOnChange = (e) => {
    const value = e.target.value;
    if (e.target.name === "staff") {
      setStaff(value);
    } else if (e.target.name === "offer") {
      setOffer(value);
    } else if (e.target.name === "discount") {
      setDiscount(value);
    }

    console.log(value);
  };

  return (
    <div className="tab-point-offer">
      <Card
        cardDetails={data}
        form={
          <>
            <p>Note: This is a Reverse Transaction of Purchase.</p>
            <form className="form-wrapper" onSubmit={handleSubmit}>
              <div className="form-field select">
                <select
                  id="staff"
                  className="form-input select"
                  name="staff"
                  value={staff}
                  onChange={(e) => selectOnChange(e)}
                  required
                  // disabled={isRoleDisabled}
                >
                  <option value="default">Select Staff</option>
                  {staffData.length >= 1 ? (
                    staffData.map((item, index) => {
                      return (
                        <option value={item._id} key={index}>
                          {item.user_first_name + " " + item.user_last_name}
                        </option>
                      );
                    })
                  ) : (
                    <option value="default">No Staff</option>
                  )}
                </select>
                <label htmlFor="staff">
                  Select Staff
                  <IconCaretDown className="form-select-caret-down" />
                </label>
              </div>
              <div className="form-field select">
                <select
                  id="offer"
                  className="form-input select"
                  name="offer"
                  value={offer}
                  onChange={(e) => selectOnChange(e)}
                  required
                  // disabled={isRoleDisabled}
                >
                  <option value="default">Select Point</option>
                  {offerList.length >= 1 ? (
                    offerList.map((item, index) => {
                      console.log(item.offer_id);
                      return (
                        <option value={item.offer_id._id} key={index}>
                          {item.offer_id.title}
                        </option>
                      );
                    })
                  ) : (
                    <option value="default">No Offer</option>
                  )}
                </select>
                <label htmlFor="offer">
                  Point Offer
                  <IconCaretDown className="form-select-caret-down" />
                </label>
              </div>
              <div className="form-field select">
                <select
                  id="discount"
                  className="form-input select"
                  name="discount"
                  value={discount}
                  onChange={(e) => selectOnChange(e)}
                  required
                  // disabled={isRoleDisabled}
                >
                  <option value="default">Select Discount</option>
                  {discountList.length >= 1 ? (
                    discountList.map((item, index) => {
                      return (
                        <option value={item._id} key={index}>
                          {item.title}
                        </option>
                      );
                    })
                  ) : (
                    <option value="default">No Discount</option>
                  )}
                </select>
                <label htmlFor="discount">
                  Discount Deal (%)
                  <IconCaretDown className="form-select-caret-down" />
                </label>
              </div>
              <div className="form-field">
                <input
                  type="text"
                  name="cardNo"
                  className="form-input"
                  id="cardNo"
                  value={formatCardNumber(cardNo)}
                  onChange={handleOnChange}
                  onKeyDown={validateNumberInput}
                  required
                />
                <label htmlFor="cardNo">Enter Card No.</label>
              </div>
              <div className="form-field">
                <input
                  type="number"
                  name="amount"
                  className="form-input"
                  id="amount"
                  value={amount}
                  onChange={handleOnChange}
                  onKeyDown={validateNumberInput}
                  required
                />
                <label htmlFor="amount">Amount</label>
                {errors.amount && (
                  <span className="form-error">{errors.amount}</span>
                )}
              </div>
              <div className="form-field">
                <input
                  type="number"
                  name="confirmAmount"
                  className="form-input"
                  id="confirmAmount"
                  value={confirmAmount}
                  onChange={handleOnChange}
                  onKeyDown={validateNumberInput}
                  required
                />
                <label htmlFor="confirmAmount">Confirm amount</label>
                {errors.confirmAmount && (
                  <span className="form-error">{errors.confirmAmount}</span>
                )}
              </div>
              <div className="form-field">
                <input
                  type={"text"}
                  name="invoiceNo"
                  className={`form-input ${!invoiceNo ? "not-required" : ""}`}
                  id="invoiceNo"
                  value={invoiceNo}
                  onChange={handleOnChange}
                  // required
                />
                <label htmlFor="invoiceNo">Invoice no. (optional)</label>
              </div>
              <div className="form-field">
                <textarea
                  type={"text"}
                  name="notes"
                  className="form-input not-required"
                  id="notes"
                  value={notes}
                  onChange={handleOnChange}
                />
                <label htmlFor="notes">Notes (optional)</label>
              </div>
              {/* <input type="submit" className='btn-blue' disabled={disable} value="Submit"/> */}
            </form>
            {isButtonLoading ? (
              <button class="btn-blue-loading disabled">
                <span class="load open"></span> Please wait...
              </button>
            ) : (
              <button
                className="btn-blue"
                disabled={disable}
                onClick={onSubmitForm}
              >
                Submit
              </button>
            )}
          </>
        }
      />
    </div>
  );
};

export default ManagePurchase;
