import axios from "axios";
import "moment-timezone";
import moment from "moment-timezone";
import React, { useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import { FaCalendar } from "react-icons/fa";
import Moment from "react-moment";
import { Link } from "react-router-dom";
import Breadcrumbs from "../../components/Breadcrumbs";
import Filter from "../../components/Filter";
import Table from "../../components/Table";
import Modal from "react-bootstrap/Modal";
import Card from "../../components/Card";
import { url_path } from "../../constant";
import { authenticateAdminPage } from "../../lib/helpers/validation";
import { CSVLink, CSVDownload } from "react-csv";

const GeneralTransactions = (props) => {
  useEffect(() => {
    authenticateAdminPage(props);
  }, []);
  // States
  const [transactionData, setTransactionData] = useState([]);
  const [pagerData, setPagerData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [searchInput, setSearchInput] = useState("");
  const [filterStartDate, setFilterStartDate] = useState();
  const [filterEndDate, setFilterEndDate] = useState();
  const [filterName, setFilterName] = useState("");
  const [printData, setPrintData] = useState([]);
  const [tempPrintData, setTempPrintData] = useState([]);
  const [csvLoaded, setCsvLoaded] = useState(false);
  const [modalState, setModalState] = useState(false);
  const filterContent = {
    title: "Filter",
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    getData(1, searchInput ,filterStartDate, filterEndDate);
    handleCsvProcessing(tempPrintData);
    // getTransaction(1, false, 0, 0);

  };
  const hideModal = () => {
    setModalState(false);
  };
  const showModal = () => {
    setModalState(true);
  };
  // Table Header
  const heading = [
    {
      Header: "No",
      id: "no",
      width: 75,
      Cell: (row) => row.index + 1 + (pagerData.currentPage - 1) * 10,
    },
    {
      Header: "Date",
      accessor: "created_at",
      sortable: true,
      Cell: (row) => (
        <Moment format="MMMM DD YYYY HH:mm:ss" date={row.original.created_at} />
      ),
    },
    {
      Header: "Card Holder Name",
      accessor: "name",
      sortType: "basic",
      id: "name",
      Cell: (row) => {
        return (
          (row.original.user_id._id) ? 
          (<Link to={"/member-profile/" + row.original.user_id._id}>
            {row.original.user_id.user_first_name +
              " " +
              row.original.user_id.user_last_name}
          </Link>) : (row.original.user_id.user_first_name +
              " " +
              row.original.user_id.user_last_name)
        );
      },
    },
    {
      Header: "Running Balance",
      accessor: "balance",
    },
    {
      Header: "Card Number",
      accessor: "user_id.card_number",
    },
    {
      Header: "Transaction Details",
      accessor: "description",
      Cell: (row) =>
        row.original.description === "Redeem"
          ? row.original.description +
            " from " +
            row.original.business_partner_id.business_name
          : row.original.description === "Purchase"
          ? row.original.description +
            " from " +
            row.original.business_partner_id.business_name
          : row.original.description,
    },
    {
      Header: "Spend Amount",
      Cell: (row) =>
        row.original.description === "Transfer Points"
          ? "0"
          : row.original.description === "Managed Purchase"
          ? "-" + row.original.statement_amount
          : row.original.description === "Managed Redeem"
          ? "+" + row.original.statement_amount
          : row.original.spend_amount,
      // accessor: 'spend_amount'
    },
    {
      Header: "Total Points Earned",
      Cell: (row) =>
        row.original.description === "Transfer Points"
          ? "0"
          : row.original.total_points_earned,
      // accessor: 'total_points_earned'
    },
    {
      Header: "Redeemed Amount",
      accessor: "redeemed_amount",
    },{
      Header: "Notes",
      accessor: "notes",
    },
  ];

  const csvHeader = [
    {
      label: "#",
      key: "no",
    },
    {
      label: "Date",
      key: "created_at",
    },
    {
      label: "Card Holder Name",
      key:  "card_holder_name",
    },
    {
      label: "Running Balance",
      key: "running_balance",
    },
    {
      label: "Card Number",
      key: "card_number",
    },
    {
      label: "Transaction Details",
      key: "transaction_details",
    },
    {
      label: "Spend Amount",
      key: "spend_amount",
    },
    {
      label: "Total Points Earned",
      key: "total_points_earned",
    },
    {
      label: "Redeemed Amount",
      key: "redeemed_amount",
    },
  ];

  const getData = (pageNumber = 1, search = searchInput, filterStartDate_ = filterStartDate, filterEndDate_ = filterEndDate) => {
    setLoading(true);
    filterStartDate_ = moment(filterStartDate).format("MM/DD/YYYY").toString();
    filterEndDate_ = moment(filterEndDate).format("MM/DD/YYYY").toString();

    const req = { pageNumber: pageNumber, search:search , start_date: filterStartDate_,
      end_date: filterEndDate_ };
    axios
      .post(`${url_path}/api/admin_request/general_transactions`, req)
      .then((res) => {
        setTransactionData(res.data.transactions);
        setPagerData(res.data.paginate);
        setTempPrintData(res.data.totalDocuments);
        handleCsvProcessing(res.data.totalDocuments);
        setLoading(false);

      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleDateChangeRaw = (e) => {
    e.preventDefault();
  };

  useEffect(() => {
    getData(1, "");
  }, []);

  const handleSearchInput = (event) => {
    setSearchInput(event.target.value);
  };

  const handleSearchSubmit = (event) => {
    event.preventDefault();

    getData(1, searchInput, filterStartDate, filterEndDate);
  };

  const handleResetSearch = () => {
    setSearchInput("");

    getData(1, "");
  };

  const handleCsvProcessing = (tempPrintData) => {
    let tempCsv = [];
      tempPrintData.map(function(item, i){
      let tempArray = [];
      tempArray['no'] = i+1;
      tempArray['created_at'] = item.created_at;
      tempArray['card_holder_name'] =item.user_id.user_first_name +
      " " +
      item.user_id.user_last_name;
      tempArray['running_balance'] = item.balance;
      tempArray['card_number'] = item.user_id.card_number;
      tempArray['transaction_details'] = item.description === "Redeem"
      ? item.description +
        " from " +
        item.business_partner_id.business_name
      : item.description === "Purchase"
      ? item.description +
        " from " +
        item.business_partner_id.business_name
      : item.description;
      tempArray['spend_amount'] = item.description === "Transfer Points"
      ? "0"
      : item.description === "Managed Purchase"
      ? "-" + item.statement_amount
      : item.description === "Managed Redeem"
      ? "+" + item.statement_amount
      : item.spend_amount;
      tempArray['total_points_earned'] =  item.description === "Transfer Points"
      ? "0"
      : item.total_points_earned;
      tempArray['redeemed_amount'] = item.redeemed_amount;
      
      tempCsv.push(tempArray)
    })
    

    const csvDataAssort = tempCsv.map((item) => ({
      no: item.no,
      created_at: item.created_at,
      card_holder_name: item.card_holder_name,
      running_balance: item.running_balance,
      card_number: item.card_number,
      transaction_details: item.transaction_details,
      spend_amount: item.spend_amount,
      total_points_earned: item.total_points_earned,
      redeemed_amount : item.redeemed_amount
    }))
    setPrintData(csvDataAssort);
  }
  // console.log(transactionData);

  // if (!pagerData.pages || pagerData.pages.length <= 1) {
  //     // don't display pager if there is only 1 page
  //     return null;
  // }
  return (
    <main className="manage-pages">
      <div className="container custom-container">
        <Breadcrumbs
          size="text-large"
          placeholder="General Transactions"
          targetLink="/admin-transactions"
          hasButton="false"
          customClassName="m-3"
        />
        <Filter
        filterDetails={filterContent}
        content={
          <div className="section-filter">
            <form className="form-wrapper statement" onSubmit={handleSubmit}>
              <div className="form-wrapper transactions">
                <div className="filter-wrapper">
                  <div className="group-wrapper date">
                    <p className="filter-heading">Filter by date</p>
                    <div className="form-group">
                      <div className="form-field calendar">
                        <p className="input-label">From</p>
                        <label htmlFor="startDate">
                          <p className="label-calendar">
                            <FaCalendar />
                          </p>
                        </label>
                        <DatePicker
                          selected={filterStartDate}
                          onChange={(date) => setFilterStartDate(date)}
                          selectsEnd
                          dateFormat="MMM dd, yyyy"
                          // endDate={filterStartDate}
                          // minDate={filterStartDate}
                          onChangeRaw={handleDateChangeRaw}
                          className="form-input date"
                          name="filterStartDate"
                          autoComplete="off"
                          id="filterStartDate"
                          // required
                        />
                      </div>
                      <div className="form-field calendar">
                        <p className="input-label">To</p>
                        <label htmlFor="endDate">
                          <p className="label-calendar">
                            <FaCalendar />
                          </p>
                        </label>
                        <DatePicker
                          selected={filterEndDate}
                          onChange={(date) => setFilterEndDate(date)}
                          selectsEnd
                          dateFormat="MMM dd, yyyy"
                          // endDate={filterEndDate}
                          minDate={filterStartDate}
                          onChangeRaw={handleDateChangeRaw}
                          className="form-input date"
                          name="filterEndDate"
                          // placeholderText={date}
                          autoComplete="off"
                          id="filterEndDate"
                          // required
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <button className="btn-blue form-deal-submit" type="submit">
                  Apply
                </button>
              </div>
            </form>
          </div>
        }
      />  
        <div className="section-table">
          <div className="container-fluid">
            <form onSubmit={handleSearchSubmit}>
              <div className="search-holder">
                <label htmlFor="searchInput">Search: </label>
                <input
                  size="large"
                  name="searchInput"
                  label="Search"
                  value={searchInput}
                  onChange={handleSearchInput}
                  className="search-input"
                />
                <button type="submit" className="btn-blue">
                  Search
                </button>
                <button
                  type="reset"
                  className="btn-blue"
                  onClick={handleResetSearch}
                >
                  Clear
                </button>
                  <CSVLink
                    type="button"
                    className="btn-blue"
                    filename={
                      "General-Transaction" +
                      "-" +
                      moment().format("YYYY-MM-DD").toString() +
                      ".csv"
                    }
                    data={printData}
                    headers={csvHeader}
                    asyncOnClick={true}
                    target="_blank"
                  >
                    <span>Export to Excel</span>
                  </CSVLink>
              </div>
            </form>
            {loading ? (
              <div className="lds-ellipsis">
                <div className="dots"></div>
                <div className="dots"></div>
                <div className="dots"></div>
                <div className="dots"></div>
              </div>
            ) : (
              <Table
                tableHeader={heading}
                tableData={transactionData}
                paginate={pagerData}
                pageFunction={getData}
              />
            )}
          </div>
        </div>
      </div>
    </main>
  );
};

export default GeneralTransactions;
