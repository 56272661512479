import React, { useEffect } from "react";
import Breadcrumbs from "../../components/Breadcrumbs";
import Card from "../../components/Card";
import { authenticateBusinessPage } from "../../lib/helpers/validation";
const cardInfo = {
  title: "",
};

// const dataVal = [
//   {
//     id: 1,
//     details: "Buy 2 Cokes, Get 1 FREE",
//     points: "500",
//     amount: "1999",
//     date: "10/20/2019",
//   },
//   {
//     id: 2,
//     details: "Purchase 1 body wash get 50% off hand soaps",
//     points: "500",
//     amount: "1999",
//     date: "10/20/2019",
//   },
// ];

// const heading = [
//   {
//     Header: "Details",
//     accessor: "details",
//     width: 500,
//   },
//   {
//     Header: "Points",
//     accessor: "points",
//     className: "table-point-plus",
//   },
//   {
//     Header: "Amount",
//     accessor: "amount",
//     className: "table-amount-peso",
//   },
//   {
//     Header: "Date",
//     accessor: "date",
//   },
//   {
//     Header: "Actions",
//     sortable: false,
//     Cell: (row) => (
//       <div className="button-wrapper">
//         <div className="button-holder">
//           <button
//             className="btn-icon"
//             onClick={() => {
//               let errorMessage = "You won't be able to revert this!";
//               deleteConfirmation(errorMessage);
//             }}
//           >
//             <span className="icon-holder">
//               <IconDelete className="icon-actions" data-tip data-for="delete" />
//             </span>
//           </button>
//           <ReactTooltip id="delete" type="warning" effect="solid">
//             <span>Delete</span>
//           </ReactTooltip>
//         </div>
//       </div>
//     ),
//   },
// ];

function Scanner(props) {
  useEffect(() => {
    authenticateBusinessPage(props);
  }, []);
  // const [data, setData] = useState([]);

  // const [currentUser, setCurrentUser] = useState("");

  // var currentTransactionUser = currentUser + "’s";

  // useEffect(() => {
  //   setData(dataVal);
  // }, []);

  return (
    <main className="scanner">
      <Breadcrumbs
        size="text-small"
        placeholder="Back"
        targetLink="/partner-dashboard"
      />
      <div className="section-scanner">
        <div className="container">
          <h2 className="title-centered">Scanner</h2>
          <Card
            cardDetails={cardInfo}
            form={
              <form className="form-wrapper">
                <div className="form-group form-group-checkbox">
                  <div className="checkbox-wrapper">
                    <input
                      type="radio"
                      id="checkboxInput"
                      name="gender"
                      value="male"
                    />
                    <label htmlFor="checkboxInput"></label>
                    <p>Redeem Points</p>
                  </div>
                  <div className="checkbox-wrapper">
                    <input
                      type="radio"
                      id="checkboxInput1"
                      name="gender"
                      value="female"
                    />
                    <label htmlFor="checkboxInput1"></label>
                    <p>Use Points</p>
                  </div>
                </div>

                <div className="form-field">
                  <input
                    id="lastName"
                    className="form-input"
                    type="text"
                    name="lastName"
                    required
                  />
                  <label htmlFor="lastName">Enter card no.</label>
                </div>
                <p>
                  Total Points: <span></span>
                </p>
                <hr />
                {/* <div className="form-field">
                <input 
                  id="lastName"  
                  className="form-input" 
                  type="text" 
                  name="lastName" 
                  required
                />
                <label htmlFor="lastName">Amount</label>
              </div> */}
                {/* <div className="form-field form-textarea">
                <textarea className="form-input" id="description" name="notes" required/>
                <label htmlFor="notes">Notes(optional)</label>
              </div> */}
                <button className="btn-blue">Submit</button>
              </form>
            }
          />
        </div>
      </div>

      {/* <div className="section-table">
        <div className="container">          
          <h5 className="title-centered">{currentTransactionUser + ' Transaction History'}</h5>
          <Table 
            tableHeader={ heading }
            tableData={ data }
          />
        </div>
      </div> */}
    </main>
  );
}

export default Scanner;
