// import { getData } from '../lib/api/users.js'
import React, { useState, useEffect } from "react";
import { url_path } from "../../constant";
import Table from "../../components/Table";
import Breadcrumbs from "../../components/Breadcrumbs";
// import ReactTooltip from 'react-tooltip'
import axios from "axios";
// import Moment from 'react-moment'
import "moment-timezone";
import moment from "moment";
// import classNames from "classnames";
import { getCookie } from "../../lib/helpers/cookies";
import Sidebar from "../../components/Sidebar";
// import Filter from "../../components/Filter";
// import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
// import { FaCalendar } from "react-icons/fa";

import { authenticateMemberPage } from "../../lib/helpers/validation";
const MyInfinityReferrals = ({ history }) => {
  useEffect(() => {
    authenticateMemberPage(history);
  }, []);
  // States
  const [tableData, setTableData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [startDate] = useState("01/01/2019");
  const [endDate] = useState("12/12/2025");
  // const [expiryStartDate, setExpiryStartDate] = useState("01/01/2019");
  // const [expiryEndDate, setExpiryEndDate] = useState("12/12/2025");
  const [filter] = useState(false);
  const [pages, setPages] = useState({});
  // const [filterStartDate, setFilterStartDate] = useState();
  // const [filterEndDate, setFilterEndDate] = useState();

  // const filterContent = {
  //   title: "Filter",
  // };

  // const handleSubmit = (event) => {
  //   event.preventDefault();
  //   console.log(filterStartDate);
  //   console.log(filterEndDate);
  // };

  // const handleDateChangeRaw = (e) => {
  //   e.preventDefault();
  // };

  const tableHeader = [
    {
      Header: "Referral Name",
      id: "name",
      Cell: (name) =>
        name.original.user_detail.user_first_name +
        " " +
        name.original.user_detail.user_last_name,
    },
    {
      Header: "Contact No",
      id: "contact_no",
      Cell: (data) => data.original.user_detail.user_phone,
    },
    {
      Header: "Email",
      id: "email",
      Cell: (data) => data.original.user_detail.user_email,
    },
    {
      Header: "Join Date",
      id: "join_date",
      Cell: (data) =>
        moment(new Date(data.original.membership.subscription_start)).format(
          "ll"
        ),
    },
    {
      Header: "Expiry Date",
      id: "expiry_date",
      Cell: (data) =>
        moment(new Date(data.original.membership.subscribed_expiration)).format(
          "ll"
        ),
    },
  ];

  // console.log(tab)

  useEffect(() => {
    getReferrals(1, filter, startDate, endDate);
  }, []);

  const getReferrals = async (
    pageNumber = 1,
    filter = filter,
    start_date = startDate,
    end_date = endDate,
    expiryStartDate = expiryStartDate,
    expiryEndDate = expiryEndDate,
    by = "date",
    name = ""
  ) => {
    setLoading(true);
    const token = getCookie("token");

    const res_user_info = await axios.get(`${url_path}/api/auth`, {
      headers: { "x-auth-token": token },
    });
    const reference_number = res_user_info.data.user.reference_number;

    const req = {
      name,
      filter,
      start_date,
      end_date,
      expiryStartDate,
      expiryEndDate,
      by,
      pageNumber,
    };

    try {
      const res_referrals = await axios.post(
        `${url_path}/api/member/referrals-test/${reference_number}`,
        req
      );
      // console.log(res_referrals)
      const referrals = res_referrals.data.user_referrals;

      setTableData(referrals);
      setPages(res_referrals.data.paginate);
      setLoading(false);
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <main className="member-referrals member-dashboard">
      <Sidebar history={history} />
      <div className="content-wrapper">
        <Breadcrumbs
          size="text-large"
          placeholder="Back"
          targetLink="/my-infinity-account"
          hasButton="false"
        />
        <div className="container custom-container">
          {/* <Filter
            filterDetails={filterContent}
            content={
              <form className="form-wrapper transactions" onSubmit={handleSubmit}>
                <div className="filter-wrapper">
                  <div className="group-wrapper">
                    <p className="filter-heading">Filter by date</p>
                    <div className="form-group">
                      <div className="form-field calendar">
                        <p className="input-label">From</p>    
                        <label htmlFor="startDate">
                          <p className="label-calendar"><FaCalendar/></p>
                        </label>              
                        <DatePicker
                          selected={filterStartDate}
                          onChange={date => setFilterStartDate(date)}
                          selectsEnd
                          dateFormat="MMM dd, yyyy"
                          endDate={filterStartDate}
                          minDate={filterStartDate}
                          onChangeRaw={handleDateChangeRaw} 
                          className="form-input date"
                          name="filterStartDate"
                          placeholderText="Select a date between today and 5 days in the future"
                          autoComplete="off"
                          id="filterStartDate"
                        />                                                  
                      </div>
                      <div className="form-field calendar">
                        <p className="input-label">To</p>
                        <label htmlFor="endDate">
                          <p className="label-calendar"><FaCalendar/></p>
                        </label>                     
                        <DatePicker
                          selected={filterEndDate}
                          onChange={date => setFilterEndDate(date)}
                          selectsEnd
                          dateFormat="MMM dd, yyyy"
                          endDate={filterEndDate}
                          minDate={filterStartDate}
                          onChangeRaw={handleDateChangeRaw} 
                          className="form-input date"
                          name="filterEndDate"
                          autoComplete="off"
                          id="filterEndDate"
                        />                      
                      </div>
                    </div>
                  </div>
                  <div className="group-wrapper">
                    <p className="filter-heading">Filter by name</p>
                    <div className="form-field">
                      <input
                        id="name"
                        className="form-input single-input"
                        type="text"
                        name="name"
                      />
                    </div>
                  </div>
                  <div className="group-wrapper">
                    <p className="filter-heading">Filter by amount</p>
                    <div className="form-col">
                      <div className="form-field">
                        <input 
                          type='number'
                          name='startAmt'
                          className='form-input'
                          id='startAmt'
                          // placeholderText='0.00'
                          // value={startAmt}
                          // onChange={handleOnChange}
                        />
                      </div>
                      <div className="form-field">
                        <input 
                          type='number'
                          name='endAmt'
                          className='form-input'
                          id='endAmt'
                          // placeholderText='0.00'
                          // value={endAmt}
                          // onChange={handleOnChange}
                        />
                      </div>
                    </div>
                  </div>
                    <input className="btn-blue form-deal-submit" type="submit" value="Apply"/>
                </div>
              </form>
            }
          /> */}
          <div className="section-table">
            {loading ? (
              <div className="lds-ellipsis">
                <div className="dots"></div>
                <div className="dots"></div>
                <div className="dots"></div>
                <div className="dots"></div>
              </div>
            ) : (
              <Table
                tableHeader={tableHeader}
                tableData={tableData}
                paginate={pages}
                pageFunction={getReferrals}
              />
            )}
          </div>
        </div>
      </div>
    </main>
  );
};

export default MyInfinityReferrals;
