import React, { useState, useEffect } from "react";
import { url_path } from "../../constant";
import axios from "axios";
import Breadcrumbs from "../../components/Breadcrumbs";
import { authenticateBusinessPage } from "../../lib/helpers/validation";
import { getCookie } from "../../lib/helpers/cookies";
import moment from "moment";
import { successNotif, errorNotifIcon } from "../../lib/helpers/notifications";
function RedeemDetails(props) {
  useEffect(() => {
    authenticateBusinessPage(props);
    getInfo();
  }, []);

  const [customer_id, set_customer_id] = useState();
  const [name, set_name] = useState();
  const [reference_number, set_reference_number] = useState();
  const [business_partner_name, set_business_partner_name] = useState();
  const [availablePoints, set_availablePoints] = useState();
  const [expiration, set_expiration] = useState();
  const [tier_level, set_tier_level] = useState();
  const [tier_category_name, set_tier_category_name] = useState();
  const [business_partner_id, set_business_partner_id] = useState();
  const [partner_staff_id, set_partner_staff_id] = useState();
  const [business_name, set_business_name] = useState();
  const [loading, set_loading] = useState(true);
  const [isButtonLoading, setIsButtonLoading] = useState(false);

  const { cardNo, amount, invoice, notes } = props.match.params;

  function getInfo() {
    const token = getCookie("token");
    // get the user detail
    axios
      .get(`${url_path}/api/auth`, { headers: { "x-auth-token": token } })
      .then((res) => {
        // console.warn('auth');
        const user_info = res.data;
        const req = {
          business_partner_id: user_info.user.business_partner_id,
          card_number: cardNo,
        };

        axios
          .post(`${url_path}/api/business_request/redeemGetData`, req)
          .then((res) => {
            const business_request = res.data;
            console.log(business_request);
            set_customer_id(business_request.ch_id);
            set_name(business_request.ch_name);
            set_reference_number(business_request.ch_reference_number);
            set_business_partner_name(
              user_info.user.user_first_name +
                " " +
                user_info.user.user_last_name
            );
            set_availablePoints(business_request.availablePoints);
            set_expiration(business_request.ch_expiration);
            set_tier_level(business_request.tier_level);
            set_tier_category_name(business_request.tier_category_name);
            set_business_partner_id(business_request.business_partner_id);
            // console.log(user_info.user._id)
            set_partner_staff_id(user_info.user._id);
            set_business_name(business_request.business_name);
            set_loading(false);
          });
      });
  }

  // If the submit button clicked
  const onApply = () => {
    // this.setState({spinner: true});
    // return console.warn(this.state.offer_id);
    // Make Transaction
    setIsButtonLoading(true);
    // return console.log(partner_staff_id)
    const req = {
      amount: amount,
      notes: notes,
    };
    axios
      .post(
        `${url_path}/api/scanner/redeem/${business_partner_id}/${partner_staff_id}/${cardNo}`,
        req
      )
      .then((res) => {
        const redeem_detail = res.data;
        // console.warn(redeem_detail.data.msg);
        if (redeem_detail.data.status === "success") {
          // send notification
          const notifReq = {
            notification_user_id: customer_id,
            title:
              business_name !== ""
                ? business_name + " (Redeem)"
                : "Business Name not Set",
            description: "you redeemed " + amount + " from " + business_name,
            status: false,
          };
          axios.post(`${url_path}/api/notification`, notifReq).then((res) => {
            const notification = res.data;
            console.log(notification)
          });
          setIsButtonLoading(false);
          successNotif("Well Done!", redeem_detail.data.msg);
          props.history.push(`/manual-redeem`);
        } else {
          setIsButtonLoading(false);
          errorNotifIcon("Oops!", redeem_detail.data.msg);
        }
      });
  };

  const cards = () => {
    console.log(loading);
    if (!loading) {
      return (
        <img
          src={require(`../../assets/images/cards/${tier_category_name.toLowerCase()}/tier-${tier_level}.jpg`)}
          className="img-card"
          alt=""
        />
      );
    }
  };

  return (
    <main className="purchase-redeem-details partner-dashboard">
      <div className="container custom-container">
        <div className="content-wrapper">
          <Breadcrumbs
            size="text-large"
            placeholder="Back"
            targetLink="/manual-redeem"
          />
          <div className="purchase-content">
            <p className="heading">Redeem Details</p>
            <form className="details-wrapper">
              <div className="infinity-card-img">
                {cards()}
                <img
                  src={require("../../assets/images/logo-white.png")}
                  className="card-logo"
                  alt="Infinity Privilege"
                />
                <div className="card-details">
                  <div className="member-card-info">
                    <p className="member-name">{name}</p>
                    <p className="member-card-number">{cardNo}</p>
                    <div className="member-bottom-info">
                      <p className="card-bottom-text">
                        Expires {moment(new Date(expiration)).format("ll")}
                      </p>
                      <p className="card-bottom-text">
                        Reference {reference_number}
                      </p>
                    </div>
                  </div>
                  <div className="tier-wrapper">
                    <img
                      src={require("../../assets/images/card-tier.jpg")}
                      className="card-tier"
                      alt="Infinity Privilege"
                    />
                    <p className="tier-text">{tier_level}</p>
                  </div>
                </div>
              </div>
              <div className="points-wrapper">
                <p className="points-text">{availablePoints}</p>
                <p className="points-subtext">Available points</p>
              </div>
              <div className="form-wrapper">
                <div className="form-field">
                  <input
                    className="form-input"
                    value={amount}
                    disabled={true}
                  />
                  <label htmlFor="email">Redeem Amount</label>
                </div>
                <div className="form-field">
                  <input
                    className="form-input"
                    value={invoice === "no invoice" ? "" : invoice}
                    disabled={true}
                  />
                  <label htmlFor="email">Invoice No. (optional)</label>
                </div>
                <div className="form-field">
                  <input
                    className="form-input"
                    value={notes === "no notes" ? "" : notes}
                    disabled={true}
                  />
                  <label htmlFor="email">Notes (optional)</label>
                </div>
              </div>
              {isButtonLoading ? (
                <button class="btn-blue-loading disabled">
                  <span class="load open"></span> Please wait...
                </button>
              ) : (
                <input
                  type="button"
                  className="btn-blue"
                  onClick={onApply}
                  value="Apply"
                />
              )}
            </form>
          </div>
        </div>
      </div>
    </main>
  );
}

export default RedeemDetails;
