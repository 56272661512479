import React, { useState, useEffect } from "react";
import { url_path } from "../../constant";
// import { Link } from 'react-router-dom'
import DatePicker from "react-datepicker";
import BootstrapSwitchButton from "bootstrap-switch-button-react";
import "react-datepicker/dist/react-datepicker.css";
import Breadcrumbs from "../../components/Breadcrumbs";
import { ReactComponent as IconCalendar } from "../../assets/images/icon-calendar.svg";
import Progress from "../../components/Progress";
import axios from "axios";
function DealDetail(props) {
  // const [selectedDealID, setSelectedDealID] = useState('');
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [activeState, setACtiveState] = useState(true);
  const [formData, setFormData] = useState({});
  const [imageData, setImageData] = useState("");
  const [uploadPercentage, setUploadPercentage] = useState(0);
  const [fileSelected, setFileSelected] = useState("");
  const [file, setFile] = useState("");
  const [filename, setFilename] = useState("Choose File");
  const [notificationError, setNotificationError] = useState("");

  const SelectedDealID = localStorage.getItem("selectedDealID");
  function getData() {
    // const req = {pageNumber: pageNumber};
    // console.log(selectedDealID);
    axios
      .get(`${url_path}/api/member_deal/${SelectedDealID}`)
      .then((res) => {
        // console.log(res.data.list_of_transactions)
        setFormData(res.data);
        setStartDate(new Date(res.data.start_date));
        setEndDate(new Date(res.data.end_date));
        setACtiveState({ activeState: res.data.featured });
        setImageData(res.data.image);

        // setTransactionData(res.data.data)
        // setLoader(true)
      })
      .catch((err) => {
        console.log(err);
      });
  }
  useEffect(() => {
    // setData(dataVal)
    getData();
    setNotificationError("");
  }, []);

  const { title, description, deal_type, image } = formData;

  const onFormChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const formData = new FormData();

    file ? formData.append("file", file) : formData.append("file", image);

    formData.append("title", title);
    formData.append("description", description);
    formData.append("deal_type", deal_type);
    formData.append("featured", activeState.activeState);

    try {
      await axios
        .put(`${url_path}/api/member_deal/update/${SelectedDealID}`, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
          onUploadProgress: (progressEvent) => {
            setUploadPercentage(
              parseInt(
                Math.round((progressEvent.loaded * 100) / progressEvent.total)
              )
            );

            // Clear percentage
            setTimeout(() => setUploadPercentage(0), 10000);
          },
        })
        .then((res) => {
          props.history.push("/secret");
        });
      // setNotificationError(res.data.msg);
    } catch (err) {
      if (err.response.status === 500) {
        const textError = "There was a problem with the server";
        setNotificationError(textError);
      } else {
        console.log("err");

        setNotificationError(err.response.data.msg);
      }
    }
    console.log("submit");
  };

  const handleDateChangeRaw = (e) => {
    e.preventDefault();
  };

  const onChange = (e) => {
    console.log(e.target.files);
    if (e.target.files[0]) {
      setFileSelected(URL.createObjectURL(e.target.files[0]));
      setFile(e.target.files[0]);
      setFilename(e.target.files[0].name);
    }
  };

  // console.log(file)

  return (
    <main className="deal-detail manage-pages">
      <div className="container">
        <Breadcrumbs
          size="text-large"
          placeholder="Back"
          targetLink="/secret"
          customClassName="m-3"
        />
        <div className="section-deal">
          {/* <div className="container">           */}
          <h2 className="title-centered">Deal Details</h2>
          <div className="deal-detail-wrapper">
            <div className="form-detail-holder">
              <form className="form-wrapper" onSubmit={handleSubmit}>
                <div className="form-field">
                  <input
                    type="text"
                    className="form-input"
                    id="title"
                    name="title"
                    placeholder="Buy 3 Coffee get 1 for FREE"
                    value={title}
                    onChange={(e) => onFormChange(e)}
                    required
                  />
                  <label htmlFor="title">Title</label>
                </div>
                <div className="form-field">
                  <input
                    type="text"
                    className="form-input"
                    id="deal_type"
                    name="deal_type"
                    placeholder="Flash Sale"
                    value={deal_type}
                    onChange={(e) => onFormChange(e)}
                    required
                  />
                  <label htmlFor="deal_type">Deal Type</label>
                </div>

                <div className="form-field form-textarea">
                  <textarea
                    className="form-input"
                    id="description"
                    name="description"
                    value={description}
                    required
                  />
                  <label htmlFor="description">Description</label>
                </div>

                <div className="date-wrapper">
                  <div className="form-group">
                    <div className="form-field calendar">
                      <label htmlFor="startDate">
                        Start Date
                        <IconCalendar className="form-input-calendar" />
                      </label>
                      <DatePicker
                        selected={startDate}
                        onChange={(date) => setStartDate(date)}
                        selectsStart
                        dateFormat="MMM dd, yyyy"
                        startDate={startDate}
                        endDate={endDate}
                        maxDate={endDate}
                        onChangeRaw={handleDateChangeRaw}
                        className="form-input date"
                        name="startDate"
                        placeholderText="Oct 29, 2019"
                        autoComplete="off"
                        id="startDate"
                        required
                      />
                      {/* START DATE STATE AND END DATE STATE */}
                      {/* {console.log(startDate)}
                        {console.log(endDate)} */}
                    </div>
                    <div className="form-field calendar">
                      <label htmlFor="endDate">
                        End Date
                        <IconCalendar
                          className="form-input-calendar"
                          htmlFor="endDate"
                        />
                      </label>
                      <DatePicker
                        selected={endDate}
                        onChange={(date) => setEndDate(date)}
                        selectsEnd
                        dateFormat="MMM dd, yyyy"
                        endDate={endDate}
                        minDate={startDate}
                        onChangeRaw={handleDateChangeRaw}
                        className="form-input date"
                        name="endDate"
                        placeholderText="Oct 29, 2019"
                        autoComplete="off"
                        id="endDate"
                        required
                      />
                    </div>
                  </div>
                </div>

                <div className="upload-wrapper">
                  <div className="image-holder">
                    {/* <img src={require('../../assets/uploads/sample.jpg')} className="image-featured" alt="Featured"/> */}

                    {fileSelected ? (
                      <img className="image-featured" src={fileSelected} alt=""/>
                    ) : (
                      <img
                        src={require("../../assets/uploads/sample.jpg")}
                        className="image-featured"
                        alt="Featured"
                      />
                    )}
                  </div>
                  <div className="file-upload-holder">
                    <h5 className="file-title">Upload Image</h5>
                    <div className="upload-holder">
                      <input
                        type="file"
                        name="file"
                        id="customFile"
                        className="upload-file"
                        onChange={onChange}
                      />
                      <label htmlFor="customFile">Choose a file</label>
                      <span className="file-no-upload 321" htmlFor="customFile">
                        {filename}
                      </span>
                    </div>
                    <button className="btn btn-remove">Remove image</button>
                  </div>
                </div>
                <Progress percentage={uploadPercentage} />
                <div className="featured-holder">
                  <span className="featured-text text-bold">Featured</span>
                  <div className="featured-button-holder">
                    <span className="featured-text no">No</span>
                    <BootstrapSwitchButton
                      checked={activeState.activeState}
                      onlabel=" "
                      offlabel=" "
                      onChange={(checked) => {
                        setACtiveState({ activeState: checked });
                      }}
                    />
                    <span className="featured-text yes">Yes</span>
                  </div>
                  {/* FEATURED STATE BUTTON TRUE OR FALSE */}
                  {console.log(activeState.activeState)}

                  <span className="featured-text text-gray">
                    This will be your lead deal.
                  </span>
                </div>
                <div className="btn-wrapper">
                  <button className="btn-blue form-deal-submit" value="submit">
                    Submit
                  </button>
                </div>
              </form>
            </div>
            <div className="tip-detail-holder">
              <h4 className="tip-title">
                <span className="span-tip-title">
                  <img
                    src={require("../../assets/images/image-tip.png")}
                    alt="Tip Icon"
                  />
                </span>
                TIP: Sample Deals
              </h4>
              <ul className="tip-list-title">
                <li className="tip-list">
                  <p className="tip-text">Buy 2 Cokes, Get 1 FREE</p>
                </li>
                <li className="tip-list">
                  <p className="tip-text">20% off Hawaiian Sunscreen</p>
                </li>
                <li className="tip-list">
                  <p className="tip-text">
                    Purchase 1 body wash get 50% off hand soaps
                  </p>
                </li>
              </ul>
            </div>
          </div>
          {/* </div> */}
        </div>
      </div>
    </main>
  );
}

export default DealDetail;
