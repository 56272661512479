import React, { useState, useEffect, Button } from "react";
import { successNotif } from "../../lib/helpers/notifications.js";
import { errorNotif } from "../../lib/helpers/notifications.js";
import { url_path } from "../../constant";
import Modal from "react-bootstrap/Modal";
import Card from "../../components/Card";
import axios from "axios";
import Sweetalert2 from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { setCookie, getCookie } from "../../lib/helpers/cookies";
import { authenticateAdminPage } from "../../lib/helpers/validation";
const Swal = withReactContent(Sweetalert2);

function ManualPromotion({user_category, user_id},props) {
  //State
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [loader, setLoader] = useState(true);
  const [referral, setReferral] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [notification, setNotification] = useState({
    title: "",
    description: "",
  });
  const [userID, setUserID] = useState(user_id);
  const [userCategory, setUserCategory] = useState(user_category);
  const [isDisabled, setIsDisabled] = useState(false);
  const preview_tier = () => {
    switch(userCategory) {

      case "BASIC":   return "SILVER";
      case "SILVER":   return "GOLD";
      case "GOLD":   return "PLATINUM";
      default:      return ""
    }
  }

  const current_tier = () => {
    switch(userCategory) {

      case "BASIC":   return "BASIC";
      case "SILVER":   return "SILVER";
      case "GOLD":   return "GOLD";
      case "PLATINUM":   return "PLATINUM";
      default:      return ""
    }
  }

  const cardInfo = {
    title: "Manual Promotion",
    hasMessage: true,
    message: `Current Account Tier: ${current_tier()}`,
  };

  const modalInfo = (() => {
    if (userCategory != "PLATINUM")
      return {    title: "Confirm Promotion",
      hasMessage: true,
      message: `Are you sure you want to upgrade to ${preview_tier()}`,}
    else
      return {    title: "Promotion Exceeded",
      hasMessage: true,
      message: `User is at the Maximum Tier Category, Cannot be upgraded`,}
  })();


  useEffect(() => {
    authenticateAdminPage(props);
  }, []);


  const handleManualPromotion = () => {
    console.log('1');
    // modalInfo.title = "Confirm Promotion";
    // setNotificationUserID(user_id);
    handleShowModal();
  };
  const handleShowModal = () => {
    setShowModal(true);
  };

  const handleHideModal = () => {
    setShowModal(false);
  };

  const handleOnChange = (event) => {
    const { name, value } = event.target;

    // setNotification({
    //   ...notification,
    //   [name]: value,
    // });
  };

  const handleOnSubmit = async (event) => {
    event.preventDefault();
    setIsDisabled(true);
    const req = {
      user_id: userID,
    };
    console.log(req);
    
    try {

      const { data } = await axios.post(
        `${url_path}/api/users/promote`,
        req
      );
      console.log(data);
      setUserCategory(preview_tier());
      const title = "Awesome!";
      const msg = "Promotion process has been completed";
      successNotif(title, msg);
      handleHideModal();
      setIsDisabled(false);
    }
     catch (error) {
      const title = "Warning!";
      const msg = error;
      errorNotif(title, msg);
      console.log(error);
    }
  };

  const getReferral = () => {
    // return console.log(getCookie("type"));
    const token = getCookie("token");
    axios
      .get(`${url_path}/api/auth`, { headers: { "x-auth-token": token } })
      .then((res) => {
        console.log(res.data);
        setReferral(res.data.user.reference_number);
        setLoader(!loader);
      });

  };

  // const { values, handleOnChange, handleOnSubmit } = useForm(
  //   stateSchema,
  //   stateValidatorSchema,
  //   onSubmitForm
  // );

  // const {

  //   card_selected = userData.card_selected,

  // } = values;
  useEffect(() => {
    getReferral();
  }, []);

  return (
    <main className="settings manage-pages">
      <div className="container">
        <div className="card-body">
            <Modal
              show={showModal}
              onHide={handleHideModal}
              className="modal-dark- opacity"
            >
              <form className="form-wrapper" onSubmit={handleOnSubmit}>
              <Card
                cardDetails={modalInfo}
                form={
                    <div className="row">

                      {user_category != "PLATINUM" ? (
                        <div>
                          <input
                            className="btn-blue col-5 "
                            value="Cancel"
                            onClick={() => handleHideModal()}
                            style={{background: "red", borderColor: "red", marginRight: "20px", textAlign: "center"}}
                          />
                          <input
                            type="submit"
                            className="btn-blue col-6"
                            value="Proceed Promotion"
                            disabled={isDisabled}
                          />
                        </div>
                      ):(
                        <input
                        className="btn-blue col-12 "
                        value="Cancel"
                        onClick={() => handleHideModal()}
                        style={{background: "red", borderColor: "red", marginRight: "20px", textAlign: "center"}}
                        />
                      )}

                    </div>
                }
              >
              </Card>
              </form>
            </Modal>
        </div>
      </div>
      <div className="container">
        <div className="card-body">
          {!loader ? (
            <Card
              cardDetails={cardInfo}
              form={
                <center>
                    <input
                    type="submit"
                    className="btn-blue right"
                    value="Promote Account"
                    onClick={() => handleManualPromotion()}
                    />
                </center>
                // <CardPromotion>
                //   card_selected={card_selected}
                //   onChangeCard={handleOnChange}
                // </CardPromotion>
              }
            >
            </Card>
          ) : (
            <div className="lds-ellipsis">
              <div className="dots"></div>
              <div className="dots"></div>
              <div className="dots"></div>
              <div className="dots"></div>
            </div>
          )}
        </div>
      </div>
    </main>
  );
}

export default ManualPromotion;
