import React, { useState } from "react";
import { url_path } from "../constant";
import axios from "axios";
import PhoneInput from "react-phone-input-2";

import { errorNotifIcon } from "../lib/helpers/notifications";

import { ReactComponent as IconCaretDown } from "../assets/images/icon-caret-down.svg";

const GCashPayment = (props) => {
  // States
  const [line1, setLine1] = useState("");
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [country, setCountry] = useState("PH");
  const [postalCode, setPostalCode] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [name, setName] = useState("");
  const [isButtonLoading, setIsButtonLoading] = useState(false);
  // const [amount, setAmount] = useState(500);

  // Variables
  const { wizardStep, paymentMethod, totalPayment, promoCode, reqData } = props;
  // Functions
  const paymentSubmit = (e) => {
    setIsButtonLoading(true);
    if (
      line1 !== "" &&
      city !== "" &&
      state !== "" &&
      country !== "" &&
      postalCode !== "" &&
      email !== "" &&
      phone !== ""
    ) {
      const req = {
        promo_code: promoCode,
        line1,
        city,
        state,
        country,
        postal_code: postalCode,
        email,
        phone,
        name,
        amount: totalPayment,
      };

      // setIframeLink('https://www.google.com/');
      // window.location.href = 'https://www.google.com/';
      // return console.log(req);

      axios
        .post(`${url_path}/api/payment_method/gcash`, req)
        .then((res) => {
          // console.log(res);
          const sourceData = res.data;
          console.log(sourceData);
          if (sourceData.data.status === "success") {
            let userData = reqData();
            userData.source_id = sourceData.data.data.data.id; // source id
            axios
              .post(`${url_path}/api/users/add_gcash_pending_user`, userData)
              .then((res) => {
                const userData = res.data;
                if (userData.data.status === "success") {
                  const redirectLink =
                    sourceData.data.data.data.attributes.redirect.checkout_url;
                  window.location.href = redirectLink;
                }
              });
            // successNotif('Awesome!','You may now login with your Account.');

            // setIframeLink(redirectLink);
            // setShowModal(true);

            // history.push("/");
          } else {
            // show message for an error
            errorNotifIcon("Oops!", "There is an error found!");
          }
          setIsButtonLoading(false);
        })
        .catch(() => {
          setIsButtonLoading(false);
        });

      //
    } else {
      setIsButtonLoading(false);
      errorNotifIcon("Please fill up all fields.");
    }
  };
  const handlePhoneChanges = (value) => {
    setPhone(value);
  };
  return (
    <>
      {wizardStep === 5 && paymentMethod === "GCash" && (
        <>
          <h3>GCash</h3>
          <h5>Payment Details</h5>
          <div className="form-wrapper">
            <div className="form-field">
              <input
                id="name"
                className="form-input"
                type="text"
                name="name"
                value={name}
                onChange={(e) => setName(e.target.value)}
                required
              />
              <label htmlFor="name">
                Name
                <span className="text-required">*</span>
              </label>
            </div>
            <div className="form-field">
              <input
                id="line1"
                className="form-input"
                type="text"
                name="line1"
                value={line1}
                onChange={(e) => setLine1(e.target.value)}
                required
              />
              <label htmlFor="line1">
                Street address / Company name
                <span className="text-required">*</span>
              </label>
            </div>
            <div className="form-group">
              <div className="form-field">
                <input
                  id="state"
                  className="form-input"
                  type="text"
                  name="state"
                  value={state}
                  onChange={(e) => setState(e.target.value)}
                  required
                />
                <label htmlFor="state">State</label>
              </div>
              <div className="form-field">
                <input
                  id="postalCode"
                  className="form-input"
                  type="number"
                  name="postalCode"
                  value={postalCode}
                  onChange={(e) => setPostalCode(e.target.value)}
                  required
                />
                <label htmlFor="postalCode">ZIP / Postal Code</label>
              </div>
            </div>
            <div className="form-field">
              <input
                id="city"
                className="form-input"
                type="text"
                name="city"
                value={city}
                onChange={(e) => setCity(e.target.value)}
                required
              />
              <label htmlFor="city">City</label>
            </div>
            <div className="form-field select">
              <select
                id="country"
                className="form-input select"
                name="country"
                value={country}
                onChange={(e) => setCountry(e.target.value)}
                required
                // disabled={isRoleDisabled}
              >
                <option value="PH">Philippines</option>
                <option value="US">United States</option>
              </select>
              <label htmlFor="country">
                Country
                <IconCaretDown className="form-select-caret-down" />
              </label>
            </div>
            <div className="form-field">
              <input
                id="email"
                className="form-input"
                type="text"
                name="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
              />
              <label htmlFor="email">
                Email
                <span className="text-required">*</span>
              </label>
            </div>
            <div className="form-field">
              <PhoneInput
                inputClass={["form-input"]}
                enableAreaCodes={true}
                countryCodeEditable={false}
                autoFormat={true}
                country={"ph"}
                value={phone}
                enableSearch={true}
                onChange={handlePhoneChanges}
                required
              />
            </div>
            <div className="form-field">
              <input
                id="amount"
                className="form-input"
                type="number"
                name="amount"
                defaultValue={totalPayment}
                readOnly
                disabled
              />
              <label htmlFor="amount">Amount</label>
            </div>
            {isButtonLoading ? (
              <button className="btn-blue-loading disabled">
                <span className="load open"></span> Please wait...
              </button>
            ) : (
              <button
                type="button"
                className="btn-blue"
                onClick={(e) => paymentSubmit(e)}
              >
                Pay
              </button>
            )}
          </div>
        </>
      )}
    </>
  );
};

export default GCashPayment;
