// import { getData } from '../lib/api/users.js'
import React, { useState, useEffect } from "react";
import { url_path } from "../../constant";
import useForm from "../../lib/hooks/useForm.js";
import { Link } from "react-router-dom";
import Table from "../../components/Table";
import Breadcrumbs from "../../components/Breadcrumbs";
// import ReactTooltip from 'react-tooltip'
import axios from "axios";
import Moment from "react-moment";
import "moment-timezone";
import Sweetalert2 from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import {
  successNotif,
  errorNotifIcon,
} from "../../lib/helpers/notifications.js";
// import classNames from "classnames";

import BootstrapSwitchButton from "bootstrap-switch-button-react";
import Modal from "react-bootstrap/Modal";
import Card from "../../components/Card";
// import { ReactComponent as IconCaretDown } from "../../assets/images/icon-caret-down.svg";
import { authenticateAdminPage } from "../../lib/helpers/validation";

const Swal = withReactContent(Sweetalert2);

const editInfo = {
  title: "Update Partner",
  hasClose: true,
  hasMessage: false,
  customClassName: "no-margin no-shadow",
};

function ManageBusinessPartners(props) {
  useEffect(() => {
    authenticateAdminPage(props);
  }, []);
  // States
  const [data, setData] = useState([]);
  const [pagerData, setPagerData] = useState([]);
  const [loader, setLoader] = useState(false);
  const [searchInput, setSearchInput] = useState("");

  // const [inputType, setInputType] = useState(true);
  const [partnerData, setPartnerData] = useState([]);
  const [modalState, setModalState] = useState(false);
  const [currentEdit_ID, setCurrentEdit_ID] = useState("");
  const [roleIsDisabled, setRoleIsDisabled] = useState(false);

  /*
   * Regex Validations to Inputs
   */

  // var nameRegex = /^[a-zA-Z ]+$/;
  // var emailRegex = /\S+@\S+\.\S+/;
  // var numberRegex = /^(09|\+639|9)\d{9}$/;
  // var passwordMinChar = /^.{8,}$/;

  /*
   * Initialize input state schema
   */

  const stateSchema = {
    business_name: { value: "", error: "" },
    business_owner: { value: "", error: "" },
    address: { value: "", error: "" },
    city: { value: "", error: "" },
    zip: { value: "", error: "" },
    province: { value: "", error: "" },
    country_code: { value: "", error: "" },
    about: { value: "", error: "" },
  };

  /*
   * Create a schema for all inputs
   */

  const stateValidatorSchema = {
    business_name: {
      required: true,
      // validator: {
      //   func: (value) => nameRegex.test(value),
      //   error: "Invalid business_name format.",
      // },
    },
    business_owner: {
      required: true,
      // validator: {
      //   func: (value) => nameRegex.test(value),
      //   error: "Invalid business_owner format.",
      // },
    },
    address: {
      required: true,
    },
    city: {
      required: true,
    },
    zip: {
      required: true,
    },
    province: {
      required: true,
    },
    country_code: {
      required: true,
    },
    about: {
      required: true,
    },
  };
  const { values, errors, handleOnChange, handleOnSubmit, disable } = useForm(
    stateSchema,
    stateValidatorSchema,
    onSubmitForm
  );

  function onSubmitForm(state) {
    /*
     * Destructuring state Values
     */

    const {
      business_name,
      business_owner,
      address,
      city,
      zip,
      province,
      country_code,
      about,
    } = state;

    /*
     * get data from state(Input) and set to variable
     */

    const partner = {
      business_name: business_name,
      business_owner: business_owner,
      address: address,
      city: city,
      zip: zip,
      province: province,
      country_code: country_code,
      about: about,
    };

    // return console.log(partner);
    axios.post(`${url_path}/api/business_partners/add`, partner).then((res) => {
      if (res.data.data.status === "success") {
        console.log(res);
        let title = "Well Done!";
        let message = res.data.data.msg;
        successNotif(title, message);
        hideModal();
        getPartnerData(1, searchInput);
        appendNewPartner(res.data);
        // console.log(res.data.user);
        console.log(res.data);
      } else {
        console.log(res);
        let title = "Well Done!";
        let message = res.data.data.msg;
        errorNotifIcon(title, message);
        // hideModal();
        getPartnerData(1, searchInput);
        appendNewPartner(res.data);
        // console.log(res.data.user);
        console.log(res.data);
      }
    });
  }

  const {
    business_name,
    business_owner,
    address,
    city,
    zip,
    province,
    country_code,
    about,
  } = values;

  // var status = values.status.toString();

  /*
   * Callback funciton to Add
   * Append data to state[data]
   */

  function appendNewPartner(data) {
    let partner = {
      _id: data._id,
      business_name: data.business_name,
      business_owner: data.business_owner,
      address: data.address,
      city: data.city,
      zip: data.zip,
      province: data.province,
      country_code: data.country_code,
      about: data.about,
    };

    setPartnerData([...partnerData, partner]);
  }

  /*
   * Modal Actions Show/Hide
   */

  const hideModal = () => {
    setModalState(false);
    setCurrentEdit_ID("");
  };

  const showModal = () => {
    setModalState(true);
  };

  /*
   * Action Function Handlers
   */

  const handleShowCreate = () => {
    showModal();
    editInfo.title = "Create New Partner";
    // console.log(values)
    // console.log(errors)
    setRoleIsDisabled(false);

    values.business_name = "";
    values.business_owner = "";
    values.address = "";
    values.city = "";
    values.zip = "";
    values.province = "";
    values.country_code = "";
    values.about = "";

    errors.business_name = "";
    errors.business_owner = "";
    errors.address = "";
    errors.city = "";
    errors.zip = "";
    errors.province = "";
    errors.country_code = "";
    errors.about = "";
  };

  const heading = [
    {
      Header: "No",
      id: "no",
      maxWidth: 50,
      filterable: false,
      Cell: (row) => {
        return <Link to="/">{row.index + 1}</Link>;
      },
    },
    {
      Header: "Business Name",
      accessor: "business_name",
      // maxWidth: 200,
      Cell: (row) => {
        return (
          <div>
            <Link to={"/business-profile/" + row.original._id}>
              {row.original.business_name}
            </Link>
          </div>
        );
      },
    },
    {
      Header: "Business Owner",
      accessor: "business_owner",
      // maxWidth: 350
    },
    {
      Header: "Province",
      accessor: "province",
      // maxWidth: 190
    },
    {
      Header: "City",
      accessor: "city",
      // maxWidth: 190
    },
    {
      Header: "Date of joining",
      id: "created_at",
      maxWidth: 180,
      Cell: (row) => (
        <span>{<Moment format="L" date={row.original.created_at} />}</span>
      ),
    },
    {
      Header: "Status",
      width: 280,
      maxWidth: 280,
      id: "_id",
      Cell: (row) => (
        <div className="featured-button-holder">
          <span>Deactivated</span>
          <BootstrapSwitchButton
            checked={row.original.status}
            onlabel=" "
            offlabel=" "
            onChange={(checked) =>
              handlePartnerStatus(row.original._id, checked)
            }
          />
          <span>Activated</span>
        </div>
      ),
    },
    {
      Header: "Activation",
      accessor: "action",
      width: 150,
      Cell: (row) => (
        <>
          <button
            className="btn-red btn-sm"
            type="button"
            onClick={() => removePartner(row.original._id)}
          >
            Archive
          </button>
        </>
      ),
    },
  ];

  const handlePartnerStatus = (partner_id, partner_status) => {
    const req = {
      partner_id,
      partner_status,
    };

    axios
      .put(`${url_path}/api/business_partners/update_status`, req)
      .then((res) => {
        let title = "Well Done!";
        let msg = partner_status
          ? "Partner has been Activated."
          : "Partner has been Deactivated.";
        successNotif(title, msg);
        getPartnerData(1, "");
      });
  };

  const removePartner = async (partner_id) => {
    const { value } = await Swal.fire({
      title: "Remove Partner?",
      icon: "question",
      showCancelButton: true,
      confirmButtonColor: "#d33",
      cancelButtonColor: "#106397",
      confirmButtonText: "Yes",
    });

    if (value) {
      try {
        const req = { partner_id };
        const { data: result } = await axios.put(
          `${url_path}/api/business_partners/remove_partner`,
          req
        );
        if (result.data.status !== "success") {
          errorNotifIcon("Oops!", result.data.msg);
        } else {
          successNotif("Awesome!", "Partner has been removed successfully.");
        }

        getPartnerData(1, "");
      } catch (err) {
        console.log(err);
      }
    }
  };

  function getPartnerData(pageNumber = 1, search = "") {
    setLoader(false);
    const req = {
      pageNumber,
      search,
      reverse: false
    };
    axios
      .post(`${url_path}/api/business_partners`, req)
      .then((response) => {
        setData(response.data.business_partners);
        setPagerData(response.data.paginate);
        setLoader(true);
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  useEffect(() => {
    getPartnerData(1, searchInput);
  }, []);

  // const Checkbox = ({ type = "checkbox", name, value, checked = false, onChange, id }) => {
  //   return (
  //     <input type={type} id={id} name={name} value={value} checked={checked} onChange={onChange} className="input-checkbox" />
  //   );
  // };

  const handleChangeCheckbox = (event) => {
    const id = event.target.name;
    console.log(id)
  };

  // const globalSearch = () => {
  //   let dataSearch = searchInput
  //   let filteredData = origData.filter(value => {
  //     return (
  //       value.business_owner.toLowerCase().includes(dataSearch.toLowerCase())
  //     );
  //   });
  //   setData(filteredData)
  // };

  const handleSearchInput = (event) => {
    setSearchInput(event.target.value);
  };

  const handleSearchSubmit = (event) => {
    event.preventDefault();

    getPartnerData(1, searchInput);
  };

  return (
    <main className="manage-pages">
      <div className="container custom-container">
        <Breadcrumbs
          size="text-large"
          placeholder="Manage Business Partners"
          targetLink="/admin-dashboard"
          hasButton="true"
          buttonTitle="Create New Partner"
          customClassName="m-3"
          propsFunction={handleShowCreate}
        />

        <div className="section-table">
          {/* <div className="container-fluid"> */}
          <form onSubmit={handleSearchSubmit}>
            <div className="search-holder">
              <label htmlFor="searchInput">Search: </label>
              <input
                size="large"
                name="searchInput"
                label="Search"
                value={searchInput}
                onChange={handleSearchInput}
                className="search-input"
              />
              <input type="submit" className="btn-blue" value="Search" />
            </div>
          </form>
          {loader ? (
            <>
              <Table
                tableHeader={heading}
                tableData={data}
                show={handleChangeCheckbox}
                paginate={pagerData}
                pageFunction={getPartnerData}
              />
            </>
          ) : (
            <div className="lds-ellipsis">
              <div className="dots"></div>
              <div className="dots"></div>
              <div className="dots"></div>
              <div className="dots"></div>
            </div>
          )}
          {/* </div> */}
        </div>
        <Modal
          show={modalState}
          onHide={hideModal}
          className="modal-dark- opacity"
        >
          <Card
            cardDetails={editInfo}
            form={
              <form className="form-wrapper" onSubmit={handleOnSubmit}>
                {/* business_name
                business_owner
                address
                city
                zip
                province
                country_code
                about */}
                <div className="form-field">
                  <input
                    id="business_name"
                    className="form-input"
                    type="text"
                    name="business_name"
                    value={business_name}
                    onChange={handleOnChange}
                    required
                  />
                  <label htmlFor="business_name">
                    Business Name<span className="text-required">*</span>
                  </label>
                  {errors.business_name && (
                    <span className="form-error">{errors.business_name}</span>
                  )}
                </div>
                <div className="form-field">
                  <input
                    id="business_owner"
                    className="form-input"
                    type="text"
                    name="business_owner"
                    value={business_owner}
                    onChange={handleOnChange}
                    required
                  />
                  <label htmlFor="business_owner">
                    Business Owner<span className="text-required">*</span>
                  </label>
                  {errors.business_owner && (
                    <span className="form-error">{errors.business_owner}</span>
                  )}
                </div>
                <div className="form-field">
                  <input
                    id="address"
                    className="form-input"
                    type="text"
                    name="address"
                    value={address}
                    onChange={handleOnChange}
                    required
                  />
                  <label htmlFor="address">
                    Address<span className="text-required">*</span>
                  </label>
                  {errors.address && (
                    <span className="form-error">{errors.address}</span>
                  )}
                </div>
                <div className="form-group">
                  <div className="form-field select">
                    <input
                      id="city"
                      type="text"
                      className="form-input"
                      name="city"
                      value={city}
                      onChange={handleOnChange}
                      required
                    />
                    <label htmlFor="city">
                      City<span className="text-required">*</span>
                    </label>
                  </div>
                  <div className="form-field">
                    <input
                      id="zip"
                      className="form-input"
                      type="text"
                      name="zip"
                      value={zip}
                      onChange={handleOnChange}
                    />
                    <label htmlFor="zip">Zip</label>
                    {errors.zip && (
                      <span className="form-error">{errors.zip}</span>
                    )}
                  </div>
                </div>
                <div className="form-group">
                  <div className="form-field select">
                    <input
                      id="province"
                      type="text"
                      className="form-input"
                      name="province"
                      value={province}
                      onChange={handleOnChange}
                      required
                    />
                    <label htmlFor="province">
                      Province<span className="text-required">*</span>
                    </label>
                  </div>
                  <div className="form-field">
                    <input
                      id="country_code"
                      type="text"
                      className="form-input"
                      name="country_code"
                      value={country_code}
                      onChange={handleOnChange}
                      required
                    />
                    <label htmlFor="country_code">
                      Country<span className="text-required">*</span>
                    </label>
                    {errors.country_code && (
                      <span className="form-error">{errors.country_code}</span>
                    )}
                  </div>
                </div>
                <div className="form-field">
                  <input
                    id="about"
                    className="form-input"
                    type="text"
                    name="about"
                    value={about}
                    onChange={handleOnChange}
                    required
                  />
                  <label htmlFor="about">About</label>
                  {errors.about && (
                    <span className="form-error">{errors.about}</span>
                  )}
                </div>
                <div className="form-btn-wrapper form-btn-dual-wrapper">
                  <input
                    type="button"
                    className="btn-d btn-gray"
                    value="Cancel"
                    onClick={hideModal}
                  ></input>
                  <input
                    type="submit"
                    className="btn-d btn-blue"
                    value="Save Changes"
                    disabled={disable}
                  />
                </div>
              </form>
            }
          />
        </Modal>
      </div>
    </main>
  );
}

export default ManageBusinessPartners;
