import React, { useState, useEffect } from "react";
import { url_path } from "../../constant";
import Breadcrumbs from "../../components/Breadcrumbs";
import Table from "../../components/Table";
import Filter from "../../components/Filter";
import DateFilter from "../../components/DateFilter";
import { Link } from "react-router-dom";
import Moment from "react-moment";
import "moment-timezone";
import axios from "axios";
import { authenticateAdminPage } from "../../lib/helpers/validation";

const filterTitle = {
  title: "Filter",
};

const newMemberHeading = [
  {
    Header: "Date",
    accessor: "created_at",
    Cell: (row) => (
      <Moment format="MMMM DD YYYY HH:mm:ss" date={row.original.created_at} />
    ),
  },
  {
    Header: "Card Holder Name",
    Cell: (row) => {
      return (
        <Link to={"/member-profile/" + row.original.user_id._id}>
          {row.original.user_id.user_first_name +
            " " +
            row.original.user_id.user_last_name}
        </Link>
      );
    },
  },
  {
    Header: "Card Number",
    Cell: (row) => row.original.user_id.card_number,
  },
  {
    Header: "Province",
    Cell: (row) => row.original.additional_contact_detail.province,
  },
  {
    Header: "Receipt No",
    accessor: "reciept",
  },
  {
    Header: "Form of Payment",
    accessor: "form_of_payment",
  },
  {
    Header: "Net Amount Received",
    accessor: "net_amount",
  },
];

const transferPointsHeading = [
  {
    Header: "Date",
    accessor: "date",
    Cell: (row) => (
      <Moment format="MMMM DD YYYY HH:mm:ss" date={row.original.created_at} />
    ),
  },
  {
    Header: "Card Holder Name",
    accessor: "card_holder",
    Cell: (row) => {
      return (
        <Link to={"/member-profile/" + row.original.user_id._id}>
          {row.original.user_id.user_first_name +
            " " +
            row.original.user_id.user_last_name}
        </Link>
      );
    },
  },
  {
    Header: "Card Number",
    accessor: "card_number",
    Cell: (row) => row.original.user_id.card_number,
  },
  {
    Header: "Details",
    accessor: "description",
  },
  {
    Header: "Points",
    Cell: (row) =>
      row.original.description === "Transfer Points"
        ? row.original.service_charge
        : row.original.total_points_spent,
    // accessor: "total_spent_points"
  },
];

const partnerRevenueHeading = [
  {
    Header: "Date",
    accessor: "date",
    Cell: (row) => (
      <Moment format="MMMM DD YYYY HH:mm:ss" date={row.original.created_at} />
    ),
  },
  {
    Header: "Business Partner",
    Cell: (row) => row.original.business_partner_id.business_name,
  },
  {
    Header: "Card Holder Name",
    Cell: (row) => {
      return (
        <Link to={"/member-profile/" + row.original.user_id._id}>
          {row.original.user_id.user_first_name +
            " " +
            row.original.user_id.user_last_name}
        </Link>
      );
    },
  },
  {
    Header: "Card Number",
    Cell: (row) => row.original.user_id.card_number,
  },
  {
    Header: "Amount Revenue",
    Cell: (row) =>
      parseInt(row.original.discount_points) - parseInt(row.original.points),
  },
];

const IncomeTransactions = (props) => {
  // States
  const [newMember, setNewMember] = useState([]);
  const [transferPoints, setTransferPoints] = useState([]);
  const [partnerRevenue, setPartnerRevenue] = useState([]);
  const [loader, setLoader] = useState(false);
  const [newMemberPagerData, setPagerNewMember] = useState({});
  const [newTransferPointsPagerData, setPagerTransferpoints] = useState({});
  const [newPartnerRevenuePagerData, setPagerPartnerRevenue] = useState({});
  const [paymentStartDate, setPaymentStartDate] = useState();
  const [paymentEndDate, setPaymentEndDate] = useState();
  const [totalPayment, setTotalPayment] = useState(0);
  const [transferStartDate, setTransferStartDate] = useState();
  const [transferEndDate, setTransferEndDate] = useState();
  const [totalPoints, setTotalPoints] = useState(0);
  const [revenueStartDate, setRevenueStartDate] = useState();
  const [revenueEndDate, setRevenueEndDate] = useState();
  const [totalRevenue, setTotalRevenue] = useState(0);

  // Handlers
  const getNewMemberData = async (pageNumber = 1) => {
    // for new member payment

    const req = {
      pageNumber,
      startDate: paymentStartDate,
      endDate: paymentEndDate,
    };

    try {
      const { data } = await axios.post(
        `${url_path}/api/admin_request/income_transaction/new_member`,
        req
      );

      setNewMember(data.transactions);
      setPagerNewMember(data.paginate);
      setTotalPayment(data.net_amount);
      setLoader(true);
    } catch (err) {
      console.error(err);
    }
  };

  const getTransferPointsData = async (pageNumber = 1) => {
    // for transfer points
    const req = {
      pageNumber,
      startDate: transferStartDate,
      endDate: transferEndDate,
    };

    try {
      const { data } = await axios.post(
        `${url_path}/api/admin_request/income_transaction/transfer_points`,
        req
      );

      setTransferPoints(data.transactions);
      setPagerTransferpoints(data.paginate);
      setTotalPoints(data.service_charge);
      setLoader(true);
    } catch (err) {
      console.error(err);
    }
  };

  const getPartnerRevenueData = async (pageNumber = 1) => {
    // for partner revenue
    const req = {
      pageNumber,
      startDate: revenueStartDate,
      endDate: revenueEndDate,
    };

    try {
      const { data } = await axios.post(
        `${url_path}/api/admin_request/income_transaction/partner_revenue`,
        req
      );

      setTotalRevenue(data.revenue);
      setPartnerRevenue(data.transactions);
      setPagerPartnerRevenue(data.paginate);
      setLoader(true);
    } catch (err) {
      console.error(err);
    }
  };

  // Life-cycles
  useEffect(() => {
    authenticateAdminPage(props);
    getNewMemberData();
    getTransferPointsData();
    getPartnerRevenueData();
  }, []);

  return (
    <main className="manage-pages">
      <div className="container custom-container">
        <Breadcrumbs
          size="text-large"
          placeholder="Income Transactions"
          targetLink="/admin-transactions"
          hasButton="false"
          customClassName="m-3"
        />
        <div className="section-table">
          {/* <div className="container-fluid"> */}
          {loader ? (
            <>
              {/* <Table tableHeader={newMemberHeading} tableData={newMember} /> */}

              <h3>New Member/Registration Revenue</h3>
              <Filter
                filterDetails={filterTitle}
                content={
                  <DateFilter
                    startDate={paymentStartDate}
                    endDate={paymentEndDate}
                    onChangeStartDate={setPaymentStartDate}
                    onChangeEndDate={setPaymentEndDate}
                    onApply={getNewMemberData}
                  />
                }
              />
              <Table
                tableHeader={newMemberHeading}
                tableData={newMember}
                paginate={newMemberPagerData}
                pageFunction={getNewMemberData}
              />
              <div className="card-total">
                <p>Total Net Amount: {totalPayment.toLocaleString()}</p>
              </div>

              <h3>Service Charge Revenue</h3>
              {/* <Table
                  tableHeader={transferPointsHeading}
                  tableData={transferPoints}
                /> */}
              <Filter
                filterDetails={filterTitle}
                content={
                  <DateFilter
                    startDate={transferStartDate}
                    endDate={transferEndDate}
                    onChangeStartDate={setTransferStartDate}
                    onChangeEndDate={setTransferEndDate}
                    onApply={getTransferPointsData}
                  />
                }
              />
              <Table
                tableHeader={transferPointsHeading}
                tableData={transferPoints}
                paginate={newTransferPointsPagerData}
                pageFunction={getTransferPointsData}
              />
              <div className="card-total">
                <p>Total Service Charge: {totalPoints.toLocaleString()}</p>
              </div>
              <h3>Business Partner Revenue</h3>
              {/* <Table
                  tableHeader={partnerRevenueHeading}
                  tableData={partnerRevenue}
                /> */}
              <Filter
                filterDetails={filterTitle}
                content={
                  <DateFilter
                    startDate={revenueStartDate}
                    endDate={revenueEndDate}
                    onChangeStartDate={setRevenueStartDate}
                    onChangeEndDate={setRevenueEndDate}
                    onApply={getPartnerRevenueData}
                  />
                }
              />
              <Table
                tableHeader={partnerRevenueHeading}
                tableData={partnerRevenue}
                paginate={newPartnerRevenuePagerData}
                pageFunction={getPartnerRevenueData}
              />
              <div className="card-total">
                <p>Total Revenue: {totalRevenue.toLocaleString()}</p>
              </div>
            </>
          ) : (
            <div className="lds-ellipsis">
              <div className="dots"></div>
              <div className="dots"></div>
              <div className="dots"></div>
              <div className="dots"></div>
            </div>
          )}
          {/* </div> */}
        </div>
      </div>
    </main>
  );
};

export default IncomeTransactions;
