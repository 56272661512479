import React, { useState } from "react";
import { url_path, paymongo_key } from "../constant";
import axios from "axios";
import PhoneInput from "react-phone-input-2";
import { ReactComponent as IconCaretDown } from "../assets/images/icon-caret-down.svg";

import {
  errorNotifIcon,
  paymentSuccessNotif,
  warningNotifIcon,
} from "../lib/helpers/notifications";

const PayMongoPayment = (props) => {
  // States
  const [cardNumber, setCardNumber] = useState("");
  const [expMonth, setExpMonth] = useState("");
  const [expYear, setExpYear] = useState("");
  const [CVCode, setCVCode] = useState("");
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [country, setCountry] = useState("PH");
  const [iframeLink, setIframeLink] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [isButtonLoading, setIsButtonLoading] = useState(false);

  // Variables
  const { wizardStep, paymentMethod, totalPayment, handleOnSubmit } = props;
  const nameRegex = /^[a-zA-Z ]+$/;
  const emailRegex = /\S+@\S+\.\S+/;

  // Functions
  const paymentSubmit = () => {
    // return console.log(expYear);
    console.log(emailRegex.test(email));
    setIsButtonLoading(true);
    if (
      cardNumber !== "" &&
      expMonth !== "" &&
      expYear !== "" &&
      CVCode !== "" &&
      // line1 !== "" &&
      phone !== "" &&
      // city !== "" &&
      country !== "" &&
      name !== "" &&
      email !== ""
    ) {
      if(nameRegex.test(name) == false){
        setIsButtonLoading(false);
        return warningNotifIcon("Oops!", "Name format is invalid");
      }
      if(emailRegex.test(email) == false){
        setIsButtonLoading(false);
        return warningNotifIcon("Oops!", "Email format is invalid");
      }
      // return console.log('all good');
      var auth = btoa(paymongo_key);
      var dcml = "00";

      //execute payment method attach to payment intent trhough backend
      axios
        .post(
          "https://api.paymongo.com/v1/payment_methods",
          {
            data: {
              attributes: {
                type: "card", // The only available type for now is 'card'.
                details: {
                  card_number: cardNumber,
                  exp_month: parseInt(expMonth),
                  exp_year: parseInt(expYear),
                  cvc: CVCode,
                },
                billing: {
                  address: {
                    // line1,
                    // city,
                    // state,
                    country,
                    // postal_code,
                  },
                  name,
                  email,
                  phone: phone.replace(/ /g, ""),
                },
              },
            },
          },
          {
            headers: {
              "content-type": "application/json",
              // Base64 encoded public PayMongo API key.
              authorization: "Basic " + auth,
            },
          }
        )
        .then(function (response) {
          var pmID = response.data.data.id;

          var bodyRequest = {
            pmID,
            amount: totalPayment,
            decimal: dcml,
          };

          axios
            .post(
              `${url_path}/api/payment_method/debit-credit-payment`,
              bodyRequest
            )
            .then(function (res) {
              var data = res.data.data;
              if (data.status === "success") {
                console.log('data.status === "success"');
                setShowModal(false);
                setIsButtonLoading(false);
                // return successNotif("Congrats", "it work");
                handleOnSubmit();
                return paymentSuccessNotif(
                  "Thank you for choosing Infinity Privilege"
                );
              } else if (data.status === "warning") {
                console.log('data.status === "warning"');
                setIframeLink(
                  data.payment_intent_attach_data.attributes.next_action
                    .redirect.url
                );
                setShowModal(true);
                setIsButtonLoading(false);
                window.addEventListener(
                  "message",
                  (ev) => {
                    if (ev.data === "3DS-authentication-complete") {
                      console.log('ev.data == "3DS-authentication-complete"');
                      // 3D Secure authentication is complete. You can requery the payment intent again to check the status.

                      // PaymentIntent client_key example
                      var paymentIntentId = data.payment_intent_data.id;
                      var clientKey =
                        data.payment_intent_data.attributes.client_key;

                      axios
                        .get(
                          "https://api.paymongo.com/v1/payment_intents/" +
                            paymentIntentId +
                            "?client_key=" +
                            clientKey,
                          {
                            headers: {
                              // Base64 encoded public PayMongo API key.
                              Authorization: `Basic ${auth}`,
                            },
                          }
                        )
                        .then(function (response) {
                          var paymentIntent = response.data.data;
                          var paymentIntentStatus =
                            paymentIntent.attributes.status;
                          console.log(paymentIntent);
                          if (paymentIntentStatus === "succeeded") {
                            console.log("paymentIntentStatus == succeeded");
                            // You already received your customer's payment. You can show a success message from this condition.
                            setShowModal(false);
                            handleOnSubmit();
                            return paymentSuccessNotif(
                              "Thank you for choosing Infinity Privilege"
                            );
                          } else if (
                            paymentIntentStatus === "awaiting_payment_method"
                          ) {
                            console.log(
                              "paymentIntentStatus == awaiting_payment_method"
                            );
                            setShowModal(false);
                            if (
                              paymentIntent.attributes.last_payment_error !=
                              null
                            ) {
                              return errorNotifIcon(
                                "Oops!",
                                paymentIntent.attributes.last_payment_error
                                  .failed_message
                              );
                            }
                            // The PaymentIntent encountered a processing error. You can refer to paymentIntent.attributes.last_payment_error to check the error and render the appropriate error message.
                          }
                        });
                    }
                  },
                  false
                );
              } else if (data.status === "error") {
                setShowModal(false);
                setIsButtonLoading(false);
                return errorNotifIcon(
                  "Oops!",
                  "Please check your card details and try again"
                );
              } else if (data.status === "error_response") {
                var message = null;
                var error_message = data.data.errors[0].detail;
                var error_code = data.data.errors[0].sub_code;

                if (error_code === "card_expired") {
                  message =
                    error_message +
                    " Please use a different card or other form of payment.";
                } else if (error_code === "cvc_invalid") {
                  message =
                    error_message +
                    " Correct the CVC/CVN before re-submitting or use a different card or other form of payment.";
                } else if (error_code === "generic_decline") {
                  message = error_message;
                } else if (
                  error_code === "fraudulent" ||
                  error_code === "processor_blocked" ||
                  error_code === "lost_card" ||
                  error_code === "stolen_card" ||
                  error_code === "blocked"
                ) {
                  message =
                    "Unkown Error with your card, Please contact your card issuing bank for further details.";
                } else if (error_code === "insufficient_funds") {
                  message =
                    "Insufficient Funds, Please use a different card or other form of payment.";
                } else if (error_code === "processor_unavailable") {
                  message = error_message;
                }
                setIsButtonLoading(false);
                return warningNotifIcon("Oops!", message);
              }
            });
        })
        .catch(function (error) {
          var err = error.response;
          var error_message = err.data.errors[0].detail;
          setIsButtonLoading(false);
          warningNotifIcon("Oops!", error_message);
        });
    }
    else {
      setIsButtonLoading(false);
      warningNotifIcon("Oops!", "Please fill up all required fields!");
    }
  };

  // const handleCountryChange = (value, data, event) => {};

  const formatCardNumber = (value) => {
    if (value.length <= 16) {
      return value.replace(/\d{4}(?=.)/g, "$& ");
    }

    return cardNumber.substring(0, 16).replace(/\d{4}(?=.)/g, "$& ");
  };

  const formatExpMonth = (value) => {
    if (value.length <= 2) {
      return value;
    }

    return expMonth.substring(0, 2);
  };

  const formatExpYear = (value) => {
    setExpYear(value);
    if (value.length <= 2 && value.match(/[0-9]/)) {
      return value;
    }
    const updatedExpYear = expYear.substring(0, 2);
    setExpYear(updatedExpYear);
    return updatedExpYear;
  };

  const formatCvc = (value) => {
    if (value.length <= 4) {
      return value;
    }

    return CVCode.substring(0, 4);
  };

  // const formatCountry = (value) => {
  //   if (value.length <= 2) {
  //     return value.toUpperCase();
  //   }
  //   const updatedCountry = country.substring(0, 2).toUpperCase();
  //   setCountry(updatedCountry);
  //   return updatedCountry;
  // };

  const validateNumberInput = (event) => {
    const validKeys = [
      "Backspace",
      "Delete",
      "ArrowLeft",
      "ArrowRight",
      "Home",
      "End",
    ];
    if (!event.key.match(/[0-9]/) && !validKeys.includes(event.key)) {
      event.preventDefault();
    }
  };

  // const validatePhoneInput = (event) => {
  //   const validKeys = [
  //     "Backspace",
  //     "Delete",
  //     "ArrowLeft",
  //     "ArrowRight",
  //     "Home",
  //     "End",
  //     "+",
  //   ];
  //   if (!event.key.match(/[0-9]/) && !validKeys.includes(event.key)) {
  //     event.preventDefault();
  //   }
  // };

  const handlePhoneChanges = (value) => {
    setPhone(value);
  };

  return (
    <>
      {wizardStep === 5 && paymentMethod === "Credit/Debit Card" && (
        <>
          <h5>Name on Card</h5>
          <div className="form-wrapper">
            <div className="form-field">
              <input
                id="card_number"
                className="form-input"
                type="text"
                name="card_number"
                value={formatCardNumber(cardNumber)}
                onKeyDown={validateNumberInput}
                onChange={(e) =>
                  setCardNumber(e.target.value.replace(/\s+/g, ""))
                }
                required
              />
              <label htmlFor="card_number">
                Card Number
                <span className="text-required">*</span>
              </label>
            </div>
            <div className="form-group">
              <div className="form-field">
                <input
                  id="exp_month"
                  className="form-input"
                  type="number"
                  name="exp_month"
                  value={formatExpMonth(expMonth)}
                  onKeyDown={validateNumberInput}
                  onChange={(e) => setExpMonth(e.target.value)}
                  required
                />
                <label htmlFor="exp_month">
                  MM
                  <span className="text-required">*</span>
                </label>
              </div>
              <div className="form-field">
                <input
                  id="exp_year"
                  className="form-input"
                  type="number"
                  name="exp_year"
                  value={expYear}
                  onKeyDown={validateNumberInput}
                  onChange={(e) => formatExpYear(e.target.value)}
                  required
                />
                <label htmlFor="exp_year">
                  YY
                  <span className="text-required">*</span>
                </label>
              </div>
            </div>
            <div className="form-field">
              <input
                id="cv_code"
                className="form-input"
                type="number"
                name="cv_code"
                value={formatCvc(CVCode)}
                onKeyDown={validateNumberInput}
                onChange={(e) => setCVCode(e.target.value)}
                required
              />
              <label htmlFor="cv_code">
                CV Code
                <span className="text-required">*</span>
              </label>
            </div>
            <div className="form-field">
              <input
                id="amount"
                className="form-input"
                type="number"
                name="amount"
                defaultValue={totalPayment}
                readOnly
                disabled
              />
              <label htmlFor="amount">Amount</label>
            </div>
          </div>

          {/* <h5>Billing Address</h5>
          <div className="form-wrapper">
            <div className="form-field">
              <input
                id="line1"
                className="form-input"
                type="text"
                name="line1"
                value={line1}
                onChange={(e) => setLine1(e.target.value)}
                required
              />
              <label htmlFor="lie1">
                Street address / Company name
                <span className="text-required">*</span>
              </label>
            </div>
            <div className="form-field">
              <input
                id="line2"
                className="form-input"
                type="text"
                name="line2"
                value={line2}
                onChange={(e) => setLine2(e.target.value)}
              />
              <label htmlFor="line2">Apartment / Building</label>
            </div>
            <div className="form-group">
              <div className="form-field">
                <input
                  id="city"
                  className="form-input"
                  type="text"
                  name="city"
                  value={city}
                  onChange={(e) => setCity(e.target.value)}
                  required
                />
                <label htmlFor="city">
                  City / Municipality
                  <span className="text-required">*</span>
                </label>
              </div>
              <div className="form-field">
                <input
                  id="state"
                  className="form-input"
                  type="text"
                  name="state"
                  value={state}
                  onChange={(e) => setState(e.target.value)}
                  required
                />
                <label htmlFor="state">State / Province</label>
              </div>
            </div>
            <div className="form-group">
              <div className="form-field select">
                <select
                  id="country"
                  className="form-input select"
                  name="country"
                  value={country}
                  onChange={(e) => setCountry(e.target.value)}
                  required
                  // disabled={isRoleDisabled}
                >
                  <option value="PH">Philippines</option>
                  <option value="US">United States</option>
                </select>
                <label htmlFor="country">
                  Country
                  <IconCaretDown className="form-select-caret-down" />
                </label>
              </div>
              <div className="form-field">
                <input
                  id="postal_code"
                  className="form-input"
                  type="number"
                  name="postal_code"
                  value={postal_code}
                  onChange={(e) => setPostalCode(e.target.value)}
                  required
                />
                <label htmlFor="postal_code">ZIP / Postal Code</label>
              </div>
            </div>
          </div> */}

          <h5>Contact Information</h5>
          <div className="form-wrapper">
            <div className="form-field">
              <input
                id="name"
                className="form-input"
                type="text"
                name="name"
                value={name}
                onChange={(e) => setName(e.target.value)}
                required
              />
              <label htmlFor="name">
                Name
                <span className="text-required">*</span>
              </label>
            </div>
            <div className="form-field">
              <input
                id="email"
                className="form-input"
                type="text"
                name="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
              />
              <label htmlFor="email">
                Email
                <span className="text-required">*</span>
              </label>
            </div>
            <div className="form-field">
              <PhoneInput
                inputClass={["form-input"]}
                enableAreaCodes={true}
                countryCodeEditable={false}
                autoFormat={true}
                country={"ph"}
                value={phone}
                enableSearch={true}
                onChange={handlePhoneChanges}
                required
              />
            </div>
            <div className="form-field select">
              <select
                id="country"
                className="form-input select"
                name="country"
                value={country}
                onChange={(e) => setCountry(e.target.value)}
                required
                // disabled={isRoleDisabled}
              >
                <option value="PH">Philippines</option>
                <option value="US">United States</option>
              </select>
              <label htmlFor="country">
                Country
                <IconCaretDown className="form-select-caret-down" />
              </label>
            </div>
          </div>
          {isButtonLoading ? (
            <button class="btn-blue-loading disabled">
              <span class="load open"></span> Please wait...
            </button>
          ) : (
            <button type="button" className="btn-blue" onClick={paymentSubmit}>
              Pay
            </button>
          )}
        </>
      )}

      <div className={showModal ? "modal-3ds show" : "modal-3ds"}>
        <iframe title="payment" src={iframeLink} frameBorder="0"></iframe>
      </div>

      {showModal && <div className="modal-3ds-overlay"></div>}
    </>
  );
};

export default PayMongoPayment;
