import React, { useState, useEffect } from 'react'
import Table from '../../components/Table'
import Breadcrumbs from '../../components/Breadcrumbs'
import { Link } from 'react-router-dom'
import {authenticateBusinessPage} from '../../lib/helpers/validation'

function Customers(props) {
  useEffect(() => {
    authenticateBusinessPage(props)
  }, []);
  
  const [data, setData] = useState([])

  const dataVal = [
    { 
      id: 1, 
      name: "John Doe", 
      firstVisit: 'Oct 10 2019 at 9:04 AM',
      lastVisit: 'Oct 10 2019 at 9:04 AM',
      numOfVisit: '2',
      redeemedPoints: '200',
    }, { 
      id: 1, 
      name: "Cecille Sicat", 
      firstVisit: 'Oct 10 2019 at 9:04 AM',
      lastVisit: 'Oct 10 2019 at 9:04 AM',
      numOfVisit: '2',
      redeemedPoints: '200',
    }, { 
      id: 1, 
      name: "Janette David", 
      firstVisit: 'Oct 10 2019 at 9:04 AM',
      lastVisit: 'Oct 10 2019 at 9:04 AM',
      numOfVisit: '2',
      redeemedPoints: '200',
    }, 
  ]

  const heading = [
    {
      Header: "Customer Name",
      accessor: 'name',
    }, {
      Header: "First Visit",
      accessor: 'firstVisit'
    }, {
      Header: "Last Visit",
      accessor: 'lastVisit'
    }, {
      Header: "# Of Visit",
      accessor: 'numOfVisit'
    }, {
      Header: "Redeemed Points",
      accessor: 'redeemedPoints'
    }, {
      Header: 'Actions',
      sortable: false,
      // filterable: false,
      Cell: row => ( 
        <div className="link-wrapper">
          <Link to="/transaction-history">Transactions</Link>
        </div>
       ) 
    }
  ]

  useEffect(() => {
    setData(dataVal)
  }, [])

  return (
    <main className="customers">
      <Breadcrumbs 
        size = 'text-large'
        placeholder = 'Customers'
        targetLink = '/partner-dashboard'
        hasButton = 'false'
      />
      <div className="section-table">
        <div className="container">
          <Table 
            tableHeader={ heading }
            tableData={ data }
          />
        </div>
      </div>
    </main>
  )
}

export default Customers
