import React, { useEffect, useState } from "react";
import Breadcrumbs from "../../components/Breadcrumbs";
import Table from "../../components/Table";
import axios from "axios";
import { url_path } from "../../constant";
import { successNotif, errorNotifIcon } from "../../lib/helpers/notifications";
import Moment from "react-moment";
import "moment-timezone";
import Modal from "react-bootstrap/Modal";
import Card from "../../components/Card";
import "../../stylesheets/main.scss";
import { Link } from "react-router-dom";

const deliveryInfo = {
  title: "Delivery Address Info",
  hasClose: true,
  hasMessage: false,
  customClassName: "no-margin no-shadow",
};

const CardRequest = () => {
  // States
  const [loading] = useState(false);
  const [searchInput, setSearchInput] = useState("");
  const [tableData, setTableData] = useState([]);
  const [pagination, setPagination] = useState({});
  // const [deliveryAddress, setDeliveryAddress] = useState({});
  const [modalState, setModalState] = useState(false);

  const handleOnChange = (e, request_id) => {
    console.log(e.target.value + " : " + request_id);
    const selectedValue = e.target.value;
    const req = {
      status: selectedValue,
      request_id,
    };
    axios
      .post(`${url_path}/api/plastic_card_request/update_request_status`, req)
      .then((response) => {
        console.log(response.data);
        if (response.data.data.status === "success") {
          successNotif("Success", "Status Change to " + selectedValue);
        } else {
          errorNotifIcon("Oops!", `Can't found Request`);
        }
        getData(1, searchInput);
        // setTableData(response.data.alternativeDeliveryAddress);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  // Variables
  const tableHeader = [
    {
      Header: "Category",
      id: "category",
      accessor: (row) => `${row.plastic_card_request_id.category}`,
      width: 200,
    },
    {
      Header: "Tier",
      id: "tier",
      accessor: (row) => `${row.plastic_card_request_id.tier}`,
    },
    {
      Header: "Name",
      // accessor: "name"
      id: "fullName",
      // accessor: row =>
      //   `${row.user_id.user_first_name} ${row.user_id.user_last_name}`
      accessor: (row) => {
        return (
          <Link to={"/member-profile/" + row.user_id._id}>
            {row.user_id.user_first_name + " " + row.user_id.user_last_name}
          </Link>
        );
      },
    },
    {
      Header: "Delivery Address",
      id: "defaultAddress",
      accessor: (row) =>
        `${row.otherHouseNo} ${row.otherStreet} ${row.otherCity} ${row.otherProvince}`,
    },
    {
      Header: "Date Requested",
      id: "createdOn",
      accessor: (c) => <Moment format="ll" date={c.created_at} />,
    },
    {
      Header: "Status",
      id: "status",
      Cell: (row) => (
        <div className="form-field select">
          <select
            id="payment_method"
            className="form-input"
            name="statusData"
            value={row.original.plastic_card_request_id.status}
            onChange={(e) =>
              handleOnChange(e, row.original.plastic_card_request_id._id)
            }
            // required
          >
            {/* <option value="none">Select payment method</option> */}
            <option value="In Progress">In Progress</option>
            <option value="Printing">Printing</option>
            <option value="Delivered">Delivered</option>
          </select>
          {/* <label htmlFor="payment_method">
            Payment Method
            <IconCaretDown className="form-select-caret-down" />
          </label> */}
        </div>
      ),
    },
    // {
    //   Header: "Action",
    //   Cell: row => (
    //     <div className="button-wrapper">
    //       <div className="button-holder">
    //         <button
    //           className="btn-icon"
    //           onClick={() => {
    //             showModal();
    //             let datas = [...tableData];
    //             // console.log({ USERS: datas[row.index] });
    //             const details = {
    //               email: datas[row.index].user_id.user_email,
    //               phone_number: datas[row.index].user_id.user_phone,
    //               additional_number:
    //                 datas[row.index].contact_detail_id.additional_mobile_number,
    //               company_name: datas[row.index].contact_detail_id.company_name,
    //               house_number: datas[row.index].contact_detail_id.house_number,
    //               street: datas[row.index].contact_detail_id.street,
    //               city: datas[row.index].contact_detail_id.city,
    //               province: datas[row.index].contact_detail_id.province,
    //               country: datas[row.index].contact_detail_id.country,
    //               zipcode: datas[row.index].contact_detail_id.zipcode,

    //               other_company_name: datas[row.index].otherCompanyName,
    //               otherHouseNo: datas[row.index].otherHouseNo,
    //               otherProvince: datas[row.index].otherProvince,
    //               otherCity: datas[row.index].otherCity,
    //               otherCountry: datas[row.index].otherCountry,
    //               otherZip: datas[row.index].otherZip
    //             };
    //             console.log(details);

    //             // let status = datas[row.index].user_status.toString();
    //             // let number = datas[row.index].user_phone.toString();
    //           }}
    //         >
    //           <span className="icon-holder">
    //             <IconEdit className="icon-actions" data-tip data-for="show" />
    //           </span>
    //         </button>
    //         <ReactTooltip id="show" type="warning" effect="solid">
    //           <span>Show Detail</span>
    //         </ReactTooltip>
    //       </div>
    //     </div>
    //   )
    // }
  ];

  // const Swal = withReactContent(Sweetalert2);

  // Functions
  const getData = async (pageNumber = 1, search = "") => {
    const req = { pageNumber, search };
    axios
      .post(
        `${url_path}/api/plastic_card_request/get_list_of_card_request`,
        req
      )
      .then((response) => {
        console.log(response.data);
        setTableData(response.data.alternativeDeliveryAddress);
        setPagination(response.data.paginate);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const handleSearchSubmit = (event) => {
    event.preventDefault();
    getData(1, searchInput);
  };

  const handleSearchInput = (event) => {
    setSearchInput(event.target.value);
  };

  const handleResetSearch = () => {
    setSearchInput("");
    getData();
  };

  const hideModal = () => {
    setModalState(false);
  };

  // const showModal = () => {
  //   setModalState(true);
  //   // getAccessControl();
  // };

  // Life-cycles
  useEffect(() => {
    getData();
  }, []);

  return (
    <main className="pending-membership manage-pages">
      <div className="container custom-container">
        <Breadcrumbs
          size="text-large"
          placeholder="Card Request"
          targetLink="/admin-dashboard"
          customClassName="m-3"
        />

        <div className="section-table">
          <form onSubmit={handleSearchSubmit}>
            <div className="search-holder">
              <label htmlFor="searchInput">Search: </label>
              <input
                size="large"
                name="searchInput"
                label="Search"
                value={searchInput}
                onChange={handleSearchInput}
                className="search-input"
              />
              <button type="submit" className="btn-blue">
                Search
              </button>
              <button
                type="reset"
                className="btn-blue"
                onClick={handleResetSearch}
              >
                Clear
              </button>
            </div>
          </form>

          {loading ? (
            <div className="lds-ellipsis">
              <div className="dots"></div>
              <div className="dots"></div>
              <div className="dots"></div>
              <div className="dots"></div>
            </div>
          ) : (
            <Table
              tableHeader={tableHeader}
              tableData={tableData}
              paginate={pagination}
              pageFunction={getData}
            />
          )}
        </div>
        <Modal
          show={modalState}
          onHide={hideModal}
          className="modal-dark-opacity"
        >
          <Card
            cardDetails={deliveryInfo}
            form={
              <form className="form-wrapper">
                <p className="note">
                  Contact Detail Address and Alternative Delivery Address
                </p>
                <div className="review-wrapper">
                  <p>
                    <b>Contact Details</b>
                  </p>
                  <div className="review-holder">
                    <p>Title:</p>
                    <p>Sample</p>
                  </div>
                </div>
                <div className="form-btn-wrapper">
                  <input
                    type="button"
                    className="btn-d btn-gray"
                    value="Close"
                    onClick={hideModal}
                  ></input>
                </div>
              </form>
            }
          />
        </Modal>
      </div>
    </main>
  );
};

export default CardRequest;
