import React, { useEffect, useState } from "react";
import { url_path } from "../../constant";
import Breadcrumbs from "../../components/Breadcrumbs";
import DashboardSwiper from "../../components/DashboardSwiper";
import Sidebar from "../../components/Sidebar";
import axios from "axios";
import { getCookie } from "../../lib/helpers/cookies";
import { authenticateMemberPage } from "../../lib/helpers/validation";
import FeaturedDeals from "../../components/FeaturedDeals";

function MemberEarnPoints({ history }) {
  useEffect(() => {
    authenticateMemberPage(history);
  }, []);
  // States
  const [featuredDeals, setFeaturedDeals] = useState([]);
  const [deals, setDeals] = useState([]);
  const [loading, setLoading] = useState(true);

  // Functions
  const getData = async () => {
    setLoading(true);

    const token = getCookie("token");

    try {
      const { data: user_data } = await axios.get(`${url_path}/api/auth`, {
        headers: { "x-auth-token": token },
      });

      const req = { deal_type: "Earn", user_id: user_data.user._id };
      const { data: featured_deals } = await axios.post(
        `${url_path}/api/member_deal/featured`,
        req
      );
      setFeaturedDeals(featured_deals);

      const { data: deals } = await axios.post(
        `${url_path}/api/member_deal/get_all`,
        req
      );
      setDeals(deals);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <main className="member-earn-points member-dashboard">
      <Sidebar history={history} />
      <div className="content-wrapper">
        <Breadcrumbs
          placeholder="Back"
          size="text-large"
          targetLink="/member-dashboard"
        />
        <DashboardSwiper deals={featuredDeals} />
        <FeaturedDeals dealsList={deals} />
      </div>
    </main>
  );
}

export default MemberEarnPoints;
