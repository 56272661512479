import React from 'react'

const CardNumberInput = ({ cardNumber, onStateChange }) => {

  // Validate Keys
  const validateNumberInput = event => {
    const validKeys = ['Backspace', 'Delete', 'ArrowLeft', 'ArrowRight', 'Home', 'End']
    if(!event.key.match(/[0-9]/) && !validKeys.includes(event.key)) {
      event.preventDefault()
    }
  }

  // Format Card Number
  const formatCardNumber = (value) => {
    let cardNumber = value.replace(/ /g, '')
    
    const formatedCardNumber =
      cardNumber.substring(0, 4).replace(/\d{3}(?=.)/, '$& ') +
      cardNumber.substring(4, 8).replace(/\d{2}(?=.)/, '$& ') +
      cardNumber.substring(8, 10)

    return formatedCardNumber
  }

  const handleCardNumberChange = event => {
    const value = event.target.value
    onStateChange(value)
  }

  return (
    <>
      <input 
        id='card_number'
        className='form-input'
        type='text'
        name='card_number'
        value={ formatCardNumber(cardNumber) }
        onKeyDown={ validateNumberInput }
        onChange={ handleCardNumberChange }
        required
      />
    </>
  )
}

export default CardNumberInput
