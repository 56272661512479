import { successNotif } from "../../lib/helpers/notifications.js";
import { errorNotif } from "../../lib/helpers/notifications.js";
import { url_path } from "../../constant";
import React, { useState, useEffect } from "react";
import axios from "axios";
import Card from "../../components/Card";
import "../../stylesheets/main.scss";
import useForm from "../../lib/hooks/useForm.js";

import { ReactComponent as IconCaretDown } from "../../assets/images/icon-caret-down.svg";
// import Moment from "react-moment";
import moment from "moment";

import CardSelection from "../../components/CardSelection.js";
const PersonalData = ({ data, user_id }) => {
  const [userData, setUserData] = useState({});
  const [loader, setLoader] = useState(false);
  const [readOnly, setReadOnly] = useState(true);
  /*
   * Regex Validations to Inputs
   */

  var nameRegex = /^[a-zA-Z ]+$/;
  // var numRegex = /^[0-9]*$/;
  // var dateRegex = /^\d{4}\/\d{1,2}\/\d{1,2}$/;
  /*
   * Initialize input state schema
   */

  const stateSchema = {
    card_number: { value: userData.card_number, error: "" },
    first_name: { value: userData.first_name, error: "" },
    last_name: { value: userData.last_name, error: "" },
    gender: { value: userData.gender, error: "" },
    birthdate: { value: userData.birthdate, error: "" },
    title: { value: userData.title, error: "" },
    company: { value: userData.company, error: "" },
    occupation: { value: userData.occupation, error: "" },
    position: { value: userData.position, error: "" },
    subscribed_expiration: {
      value: userData.subscription_expiration,
      error: "",
    },
    card_selected: { value: userData.card_selected, error: "" },
    user_type: { value: userData.user_type, error: "" },
  };

  /*
   * Create a schema for all inputs
   */

  const stateValidatorSchema = {
    card_number: {
      required: true,
      // validator: {
      //   func: value => nameRegex.test(value),
      //   error: "Invalid Name"
      // }
    },
    first_name: {
      required: true,
      validator: {
        func: (value) => nameRegex.test(value),
        error: "Invalid Name",
      },
    },
    last_name: {
      required: true,
      validator: {
        func: (value) => nameRegex.test(value),
        error: "Invalid Name",
      },
    },
    user_type: {
      required: false,
    },
    gender: {
      required: true,
      validator: {
        func: (value) => nameRegex.test(value),
        error: "Invalid Gender",
      },
    },
    title: {
      required: true,
      validator: {
        func: (value) => nameRegex.test(value),
        error: "Invalid Title",
      },
    },
    company: {
      required: false,
      validator: {
        func: (value) => nameRegex.test(value),
        error: "Invalid Company",
      },
    },
    occupation: {
      required: false,
      validator: {
        func: (value) => nameRegex.test(value),
        error: "Invalid Occupation",
      },
    },
    position: {
      required: false,
      validator: {
        func: (value) => nameRegex.test(value),
        error: "Invalid Position",
      },
    },
    subscribed_expiration: {
      required: false,
    },
    card_selected: {
      required: false,
    },
    birthdate: {
      required: false,
    },
  };

  function onSubmitForm(state) {
    console.log(userData.user_type);
    console.log("Submit Triggered");
    const {
      // card_number = userData.card_number,
      first_name = userData.first_name,
      last_name = userData.last_name,
      gender = userData.gender,
      title = userData.title,
      company = userData.company,
      occupation = userData.occupation,
      position = userData.position,
      subscribed_expiration = userData.subscription_expiration,
      card_selected = userData.card_selected,
      birthdate = userData.user_birthdate,
      user_type = userData.user_type,
    } = state;
    // const subscribed_expiration = '2021-04-20';
    const request = {
      // card_number,
      first_name,
      last_name,
      gender,
      title,
      company,
      occupation,
      position,
      subscribed_expiration,
      card_selected,
      birthdate,
      user_type,
    };
    axios
      .put(
        `${url_path}/api/admin_request/member_profile_personal_data/${user_id}`,
        request
      )
      .then((res) => {
        console.log(res.data);
        if (res.data.data.status === "success") {
          const title = "Awesome!";
          const msg = res.data.data.msg;
          successNotif(title, msg);
          setReadOnly(true);
          getData();
        } else if (res.data.data.status === "error") {
          const title = "Something went wrong";
          const msg = res.data.data.msg;
          errorNotif(title, msg);
        } else {
          console.log(res.data);
        }
      });
    console.log(request);
  }

  const { values, handleOnChange, handleOnSubmit } = useForm(
    stateSchema,
    stateValidatorSchema,
    onSubmitForm
  );

  const {
    card_number = userData.card_number,
    first_name = userData.first_name,
    last_name = userData.last_name,
    gender = userData.gender,
    title = userData.title,
    company = userData.company,
    occupation = userData.occupation,
    position = userData.position,
    subscribed_expiration = userData.subscription_expiration,
    card_selected = userData.card_selected,
    birthdate = userData.birthdate,
    user_type = userData.user_type,
  } = values;

  const handleButtonEdit = () => {
    setReadOnly(false);
  };
  const handleButtonCancel = () => {
    values.card_number = userData.card_number;
    values.first_name = userData.first_name;
    values.last_name = userData.last_name;
    values.gender = userData.gender;
    values.title = userData.title;
    values.company = userData.company;
    values.occupation = userData.occupation;
    values.position = userData.position;
    values.subscribed_expiration = userData.subscription_expiration;
    values.card_selected = userData.card_selected;
    values.birthdate = userData.birthdate;
    values.user_type = userData.user_type;
    setReadOnly(true);
  };

  function getData() {
    axios.get(`${url_path}/api/userSubscription/${user_id}`).then((res) => {
      setUserData(res.data);
      setLoader(true);
    });
  }

  useEffect(() => {
    getData();
  }, []);

  return (
    // </div>
    <Card
      cardDetails={data}
      form={
        <form className="form-wrapper" onSubmit={handleOnSubmit}>
          {!readOnly ? <p>Update Info</p> : null}

          <div className="form-field">
            <input
              id="card_number"
              className="form-input"
              type="text"
              name="card_number"
              value={card_number}
              onChange={handleOnChange}
              required
              readOnly={true}
              disabled={true}
            />
            <label htmlFor="card_number">Infinity Privilege Card Number</label>
            {/* {errors.card_number && (
              <span className="form-error">{errors.card_number}</span>
            )} */}
          </div>
          <div className="form-field select">
            <select
              id="user_type"
              className="form-input"
              name="user_type"
              value={user_type}
              onChange={handleOnChange}
              readOnly={readOnly}
              disabled={readOnly}
            >
              <option value="">Basic</option>
              <option value="classic">Classic</option>
            </select>
            <label htmlFor="user_type">
              User Type
              <IconCaretDown className="form-select-caret-down" />
            </label>
          </div>
          <div className="form-field">
            <input
              id="first_name"
              className="form-input"
              type="text"
              name="first_name"
              value={first_name}
              onChange={handleOnChange}
              required
              readOnly={readOnly}
              disabled={readOnly}
            />
            <label htmlFor="first_name">First Name</label>
            {/* {errors.first_name && (
              <span className="form-error">{errors.first_name}</span>
            )} */}
          </div>
          <div className="form-field">
            <input
              id="last_name"
              className="form-input"
              type="text"
              name="last_name"
              value={last_name}
              onChange={handleOnChange}
              required
              readOnly={readOnly}
              disabled={readOnly}
            />
            <label htmlFor="last_name">Last Name</label>
            {/* {errors.last_name && (
              <span className="form-error">{errors.last_name}</span>
            )} */}
          </div>
          <div className="form-group">
            <div className="form-field select">
              <select
                id="gender"
                className="form-input"
                name="gender"
                value={gender}
                onChange={handleOnChange}
                readOnly={readOnly}
                disabled={readOnly}
              >
                <option value="Male">Male</option>
                <option value="Female">Female</option>
              </select>
              <label htmlFor="gender">
                Gender
                <IconCaretDown className="form-select-caret-down" />
              </label>
            </div>

            <div className="form-field select">
              <select
                id="title"
                className="form-input"
                name="title"
                value={title}
                onChange={handleOnChange}
                readOnly={readOnly}
                disabled={readOnly}
              >
                <option value="Mr">Mr</option>
                <option value="Mrs">Mrs</option>
                <option value="Miss">Miss</option>
              </select>
              <label htmlFor="title">
                Title
                <IconCaretDown className="form-select-caret-down" />
              </label>
            </div>
          </div>

          <div className="form-field">
            <input
              id="birthdate"
              className="form-input"
              type="date"
              name="birthdate"
              value={moment(new Date(birthdate)).format("YYYY-MM-DD")}
              onChange={handleOnChange}
              readOnly={readOnly}
              disabled={readOnly}
            />
            <label htmlFor="birthdate">Birthdate</label>
          </div>
          <h5>{"  "}Additional Personal Details</h5>
          <div className="form-field">
            <input
              id="company"
              className="form-input"
              type="text"
              name="company"
              value={company}
              onChange={handleOnChange}
              readOnly={readOnly}
              disabled={readOnly}
            />
            <label htmlFor="company">Company</label>
            {/* {errors.company && (
              <span className="form-error">{errors.company}</span>
            )} */}
          </div>
          <div className="form-field">
            <input
              id="occupation"
              className="form-input"
              type="text"
              name="occupation"
              value={occupation}
              onChange={handleOnChange}
              readOnly={readOnly}
              disabled={readOnly}
            />
            <label htmlFor="occupation">Occupation</label>
            {/* {errors.occupation && (
              <span className="form-error">{errors.occupation}</span>
            )} */}
          </div>
          <div className="form-field">
            <input
              id="position"
              className="form-input"
              type="text"
              name="position"
              value={position}
              onChange={handleOnChange}
              readOnly={readOnly}
              disabled={readOnly}
            />
            <label htmlFor="position">Position</label>
            {/* {errors.position && (
              <span className="form-error">{errors.position}</span>
            )} */}
          </div>
          <hr></hr>

          <h5>{"  "}Subscription Details</h5>
          <div className="form-field">
            <input
              id="subscribed_expiration"
              className="form-input"
              type="date"
              name="subscribed_expiration"
              value={moment(new Date(subscribed_expiration)).format(
                "YYYY-MM-DD"
              )}
              onChange={handleOnChange}
              readOnly={readOnly}
              disabled={readOnly}
            />
            <label htmlFor="subscribed_expiration">Expiration Date</label>
          </div>
          <CardSelection
           
            card_selected={card_selected}
            onChangeCard={handleOnChange}
          ></CardSelection>
          {!readOnly ? (
            <>
              <input
                type="submit"
                className="btn-blue full-width addSpace"
                value="Save"
                // disabled={disable}
              />
              <input
                type="button"
                className="btn-blue-outline full-width"
                value="Cancel"
                onClick={handleButtonCancel}
                // disabled={disable}
              />
            </>
          ) : (
            <input
              type="button"
              className="btn-blue full-width"
              value="Edit"
              onClick={handleButtonEdit}
              // disabled={disable}
            />
          )}
        </form>
      }
    />
  );
};

export default PersonalData;
