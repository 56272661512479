import React, { useState, useEffect } from "react";
import { url_path } from "../../constant";
import Card from "../../components/Card";
import "../../stylesheets/main.scss";
import Moment from "react-moment";
import moment from "moment";
import axios from "axios";
import Modal from "react-bootstrap/Modal";
import Swiper from "react-id-swiper";
import "../../stylesheets/main.scss";

const Account = ({ data, user_id }) => {
  const [loader, setLoader] = useState(false);
  const [userData, setUserData] = useState({});
  const [showModal, setShowModal] = useState(false);
  const [pointsNeeded, setPointsNeeded] = useState("");
  const [swiper, updateSwiper] = useState(null);
  // const infinityLogo = require("../../assets/images/logo-white.png");
  const modalInfo = {
    title: userData.next_category + " Qualification",
    customClassName: "no-margin no-shadow",
  };

  const [card_selected, setCardSelected] = useState('')
  
  var QRCode = require('qrcode.react');
  var Barcode = require('react-barcode');
  const params = {
    spaceBetween: 12,
    slidesPerView: "auto",
    centeredSlides: true,
    loop: true,
    breakpoints: {
      1199: {
        slidesPerView: 1,
      },
    },
    pagination: {
      el: ".swiper-pagination",
      type: "bullets",
      clickable: true,
    },
  };

  const goNext = () => {
    if (swiper !== null) {
      swiper.slideNext();
    }
  };

  const goPrev = () => {
    if (swiper !== null) {
      swiper.slidePrev();
    }
  };
  const handleShowQualification = () => {
    // console.log("clicked");
    setShowModal(true);
    // modalInfo.title = userData.next_category + " Qualification";
  };
  const handleHideModal = () => {
    setShowModal(false);
  };

  useEffect(() => {
    axios
      .get(`${url_path}/api/userSubscription/${user_id}`)
      .then((res) => {
        setUserData(res.data);
        if (res.data.next_category == "SILVER") {
          setPointsNeeded("10,000");
        } else if (res.data.next_category === "GOLD") {
          setPointsNeeded("25,000");
        } else if (res.data.next_category === "PLATINUM") {
          setPointsNeeded("50,000");
        }
        
      getMyInfinityCardData(res.data.card_selected)
        setLoader(true);
        
      })
      .catch(err => console.log(err));
   
  }, []);
  const getMyInfinityCardData = async (e) => {
    const { data: card_selected_ } = await axios.get(`${url_path}/api/card-imgur/${e}`)
    setCardSelected(card_selected_)
  }

  // function qualification() {
  //   console.log(userData.next_category);
  //   if (userData.next_category == "SILVER") {
  //     setPointsNeeded("10,000");
  //   } else if (userData.next_category == "GOLD") {
  //     setPointsNeeded("25,000");
  //   } else if (userData.next_category == "PLATINUM") {
  //     setPointsNeeded("50,000");
  //   }
  // }
  // console.log(userData);
  return (
    <div className="tab-member-account">
      <p className="member-account-title heading">Account</p>

      {loader ? (
        <div className="member-content container">
          <div className="swiper-content-wrapper">
            <div className="card-swiper dashboard-swiper">
              <Swiper {...params} getSwiper={updateSwiper}>
                <div className="card-swiper-holder">
                  <div className="infinity-card-img">

                  {
                    (userData.user_type == 'classic') ? (
                      <>
                      <img 
                        src={require(`../../assets/images/cards/${ userData.user_category.toLowerCase() }/tier-1.jpg`)} 
                        className="img-card"
                        alt="" 
                       
                      />
                      <img src={require('../../assets/images/logo-white.png')} className="card-logo" alt="Infinity Privilege"/>
                  </>
                    ):
                       ( card_selected ? (
                          <img 
                        src={card_selected} 
                        className="img-card"
                        alt="" 
                      />
                        ):(
                          <>
                          <img 
                            src={require(`../../assets/images/cards/${ userData.user_category.toLowerCase() }/tier-${ userData.user_tier }.jpg`)} 
                            className="img-card"
                            alt="" 
                           
                          />
                          <img src={require('../../assets/images/logo-white.png')} className="card-logo" alt="Infinity Privilege"/>
                      </>
                        ))
                        
                      }


                    <div className="card-details">
                      <div className="member-card-info">
                        <p className="member-name">{userData.full_name}</p>
                        <p className="member-card-number">
                          {userData.card_number}
                        </p>
                        <div className="member-bottom-info">
                          <p className="card-bottom-text">
                            Expires:{" "}
                            {moment(userData.subscription_expiration).format(
                              "l"
                            )}
                          </p>
                          <p className="card-bottom-text">
                            Reference: {userData.referrence_number}
                          </p>
                        </div>
                      </div>
                      <div className="tier-wrapper">
                        <img
                          src={require("../../assets/images/card-tier.jpg")}
                          className="card-tier"
                          alt="Infinity Privilege"
                        />
                        <p className="tier-text">{userData.user_tier}</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="card-swiper-holder">
                  {/* <p className="heading">My Infinity QR Code</p> */}
                  <div className="background qrbg">
                    <QRCode
                      bgColor="transparent"
                      renderAs="svg"
                      level="H"
                      size="320"
                      value={userData.card_number}
                    />
                  </div>
                </div>
                <div className="card-swiper-holder">
                  <div className="background brbg">
                    <Barcode
                      background="transparent"
                      width="3"
                      height="150"
                      value={userData.card_number}
                    />
                  </div>
                </div>
              </Swiper>
              <div className="navigations-wrapper">
                <span className="back-btn" onClick={goPrev}></span>
                <span className="back-btn next-btn" onClick={goNext}></span>
              </div>
            </div>
          </div>

          <div className="info-card">
            <div className="points-card">
              <p>Available points</p>
              <h4>{userData.points_balance}</h4>
            </div>
            <div className="referrals-card">
              <p>Number of referrals</p>
              <h4>{userData.referrals}</h4>
            </div>
            <div className="expiration-card">
              <p>Card Expiration</p>
              <h4>{moment(userData.subscription_expiration).from(moment())}</h4>
            </div>
            <div className="membership-card">
              <h4>Total collected points {userData.points_raise}</h4>
            </div>
            <div className="membership-card">
              <h4>
                Member since{" "}
                <Moment format="ll" date={userData.subscription_date} />
              </h4>
            </div>
            <div className="tier-card">
              {userData.referrals_needed !== 0 ? (
                <h4>
                  To qualify to next tier, you need{" "}
                  {userData.referrals_needed - userData.referrals} more
                  referral(s)
                </h4>
              ) : (
                <h4>You're already in Max Tier</h4>
              )}
            </div>
            <div className="category-card">
              {userData.user_category === userData.next_category ? (
                <h4>You're already in PLATINUM status</h4>
              ) : (
                <h4>
                  To qualify to {userData.next_category} status, you will need
                  to earn {userData.points_needed} more points by{" "}
                  {moment(userData.subscription_expiration).format("ll")}
                </h4>
              )}
            </div>
            <p onClick={handleShowQualification} className="text-blue">
              About {userData.next_category} Qualification
            </p>
          </div>
          <Modal
            show={showModal}
            onHide={handleHideModal}
            className="modal-dark- opacity"
          >
            <Card
              cardDetails={modalInfo}
              form={
                <form className="form-wrapper">
                  <p>
                    Earn {userData.points_needed} points within 12 consecutive
                    months to achieve a {userData.next_category} Status
                    Membership.
                  </p>
                  <br />
                  <p>
                    Your status will be valid for the next 12 months, but you
                    will need to renew your card before the expiry day.
                  </p>
                  <br />
                  <h5>Requalification:</h5>
                  <p>
                    To requalify, earn {pointsNeeded} points during your{" "}
                    {userData.next_category} Membership year.
                  </p>
                  <br />
                  <h5>Note:</h5>
                  {userData.next_category === "SILVER" ? (
                    <>
                      <p>
                        Earn 10,000 points to achieve Silver Status Membership.
                      </p>
                      <p>
                        Earn 25,000 points to achieve Gold Status Membership.
                      </p>
                      <p>
                        Earn 50,000 points to achieve Platinum Status
                        Membership.
                      </p>
                    </>
                  ) : null}
                  {userData.next_category === "GOLD" ? (
                    <>
                      <p>
                        Earn 25,000 points to achieve Gold Status Membership.
                      </p>
                      <p>
                        Earn 50,000 points to achieve Platinum Status
                        Membership.
                      </p>
                    </>
                  ) : null}
                  {userData.next_category === "PLATINUM" ? (
                    <>
                      <p>
                        Earn 50,000 points to achieve Platinum Status
                        Membership.
                      </p>
                    </>
                  ) : null}
                </form>
              }
            ></Card>
          </Modal>
        </div>
      ) : (
        <div className="lds-ellipsis">
          <div className="dots"></div>
          <div className="dots"></div>
          <div className="dots"></div>
          <div className="dots"></div>
        </div>
      )}
    </div>
  );

  // setPoints = () => {
  //   if (userData.user_category == "SILVER") {
  //     tier_category_points = "10,000";
  //   } else if (userData.user_category == "GOLD") {
  //     tier_category_points = "25,000";
  //   } else if (
  //     userData.user_category == "PLATINUM"
  //   ) {
  //     tier_category_points = "50,000";
  //   }
  // };
};

export default Account;
