export function setCookie(cname, cvalue, exdays) {
  var d = new Date();
  var cookie_string = "";
  if (exdays !== "donotremember") {
    d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
    var expires = "expires=" + d.toGMTString();
    cookie_string = cname + "=" + cvalue + ";" + expires + ";path=/";
  } else {
    cookie_string = cname + "=" + cvalue + ";path=/";
  }
  document.cookie = cookie_string;
}

export function getCookie(cname) {
  var name = cname + "=";
  var decodedCookie = decodeURIComponent(document.cookie);
  var ca = decodedCookie.split(";");
  for (var i = 0; i < ca.length; i++) {
    var c = ca[i];
    while (c.charAt(0) === " ") {
      c = c.substring(1);
    }
    if (c.indexOf(name) === 0) {
      return c.substring(name.length, c.length);
    }
  }
  return "";
}

export function checkCookie() {
  var user = getCookie("username");
  if (user !== "") {
    alert("Welcome again " + user);
  } else {
    user = prompt("Please enter your name:", "");
    if (user !== "" && user !== null) {
      setCookie("username", user, 30);
    }
  }
}
