import axios from "axios";
import React, { useEffect, useState } from "react";
import { withRouter } from "react-router";
import { Link } from "react-router-dom";
import Card from "../components/Card";
import { url_path } from "../constant";
import { setCookie } from "../lib/helpers/cookies";
import { PublicAuth } from "../lib/helpers/validation";
import useForm from "../lib/hooks/useForm.js";
import "../stylesheets/main.scss";

function Login(props) {
  console.log(url_path);
  const [notificationError, setNotificationError] = useState("");

  const [isLoading, setIsLoading] = useState(false);

  const [isAccountLocked, setIsAccountLocked] = useState(false);

  useEffect(() => {
    setNotificationError("");
    PublicAuth(props);
  }, []);

  function setTimer(date_end) {
    // Set the date we're counting down to
    setIsAccountLocked(true);
    var countDownDate = new Date(date_end).getTime();

    // Update the count down every 1 second
    var x = setInterval(function () {
      // Get today's date and time
      var now = new Date().getTime();

      // Find the distance between now and the count down date
      var distance = countDownDate - now;

      // Time calculations for days, hours, minutes and seconds
      // var days = Math.floor(distance / (1000 * 60 * 60 * 24));
      // var hours = Math.floor(
      //   (distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
      // );
      var minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
      var seconds = Math.floor((distance % (1000 * 60)) / 1000);

      // Display the result in the element with id="demo"
      // document.getElementById("demo").innerHTML = days + "d " + hours + "h "
      // + minutes + "m " + seconds + "s ";
      document.getElementById("demo").innerHTML =
        "Your account has been locked due to consecutive failed login attempts. Please try again in " +
        minutes +
        ":" +
        seconds +
        " minutes.";

      // If the count down is finished, write some text
      if (distance < 0) {
        clearInterval(x);
        // document.getElementById("demo").innerHTML = "EXPIRED";
        setIsAccountLocked(false);
        setNotificationError("");
      }
    }, 1000);
  }

  const [inputType, setInputType] = useState(true);

  function togglePassword() {
    inputType ? setInputType(false) : setInputType(true);
  }

  const iconShow = require("../assets/images/icon-show.png");
  const iconHide = require("../assets/images/icon-hide.png");
  // const icon2 = require('../assets/images/image-tip.png')

  const cardInfo = {
    title: "Log in",
  };

  /*
   * Regex Validations to Inputs
   */

  // var emailRegex = /\S+@\S+\.\S+/;

  /*
   * Initialize input state schema
   */

  const stateSchema = {
    email: { value: "", error: "" },
    password: { value: "", error: "" },
  };

  const stateValidatorSchema = {
    email: {
      required: true,
      // validator: {
      //   func: value => emailRegex.test(value),
      //   error: "Invalid Email address",
      // }
    },
    password: {
      required: true,
    },
  };
  // localStorage.removeItem("type");
  function onSubmitForm(state) {
    setIsLoading(true);
    /*
     * Destructuring state Values
     */

    const { email, password } = state;
    const body_request = {
      email,
      password,
    };
    const rmCheck = document.getElementById("checkboxInput").checked;
    axios
      .post(`${url_path}/api/auth/`, body_request)
      // .get(`${url_path}/api/users/login/${email}/${password}`)
      .then((res) => {
        // console.log(res);
        const userData = res.data;
        var secretUser = "secret@secret.com";
        var secretPassword = "secret";
        if (email === secretUser && password === secretPassword) {
          var secretToken = "token";
          var secretKey = prompt("Please enter your name:", "Harry Potter");

          if (secretKey !== null || secretKey !== "") {
            if (secretToken === secretKey) {
              props.history.push("/partner-dashboard");
              // Store
              localStorage.setItem("type", "Business Partner");
            }
          }
        }
        if (userData.data.status === "success") {
          axios.defaults.headers.common['x-auth-token'] =  userData.token;
          if (userData.user.user_role === "Administrator") {
            // Store
            // localStorage.setItem("type", "Admin");
            // localStorage.setItem("user_id", userData._id);
            if (rmCheck) {
              setCookie("token", userData.token, 15);
            } else {
              setCookie("token", userData.token, "donotremember");
            }
            props.history.push("/admin-dashboard");
            setIsLoading(false);
          }
          if (userData.user.user_role === "Business Partner") {
            // console.log(userData)
            setCookie("bp_id", userData.user.business_partner_id, 15);
            
            if (userData.user.user_status) {
              if (rmCheck) {
                setCookie("token", userData.token, 15);
              } else {
                setCookie("token", userData.token, "donotremember");
              }
              props.history.push("/partner-dashboard");
              setIsLoading(false);
            } else {
              const textError =
                "You cannot login because your account has been deactivated";
              setNotificationError(textError);
              setIsLoading(false);
            }
          }
          if (userData.user.user_role === "Member") {
            // return <Redirect to='/'/>
            if (userData.user.payment_status === "pending") {
              // Store
              // return console.log(userData);
              setCookie("type", "Member", 15);
              setCookie("user_id", userData._id, 15);
              // return console.log(getCookie("user_id"))
              // go to member page message for need to pay first
              console.log("here");
              props.history.push("/member-temporary-page");
              // return <Link to='/member-temporary-page'/>
              // window.location.replace("http://www.w3schools.com");
              setIsLoading(false);
            } else {
              if (rmCheck) {
                setCookie("token", userData.token, 15);
              } else {
                setCookie("token", userData.token, "donotremember");
              }
              props.history.push("/member-dashboard");
              setIsLoading(false);
            }
            setIsLoading(false);
          }
          if (userData.user.user_role === "Temporary") {
            setCookie("token", userData.token, 'donotremember');
          }
          setIsLoading(false);
        } else if (userData.data.status === "error") {
          const textError = userData.data.msg;
          setNotificationError(textError);
          setIsLoading(false);
        } else if (userData.data.status === "warning") {
          const textError = userData.data.msg;
          setNotificationError(textError);
          // setDateEnd(userData.attempt.date_end);
          if (userData.attempt.status) {
            setTimer(userData.attempt.date_end);
          }

          setIsLoading(false);
        } else if (userData.data.status === "404") {
          const textError = userData.data.msg;
          setNotificationError(textError);
          setIsLoading(false);
        }

      })
      .catch(function (err) {
        // console.log(err);
        // if (err) {
        //   setIsLoading(false);
        //   errorNotifIcon(
        //     "Oops!",
        //     "Can't connect to server please try again later"
        //   );
        // }
      });
  }

  const { values, errors, handleOnChange, handleOnSubmit } = useForm(
    stateSchema,
    stateValidatorSchema,
    onSubmitForm
  );

  const { email, password } = values;

  return (
    <main className="login">
      <div className="card-body">
        {!notificationError ? (
          ""
        ) : (
          <div className="section-notification">
            <div className="container">
              <div className="notification-holder">
                <p className="notification-text">{notificationError}</p>
              </div>
            </div>
          </div>
        )}
        <Card
          cardDetails={cardInfo}
          form={
            <form className="form-wrapper" onSubmit={handleOnSubmit}>
              <div className="form-field">
                <input
                  title="Email or phone"
                  type="text"
                  name="email"
                  className="form-input"
                  id="email"
                  value={email}
                  onChange={handleOnChange}
                  disabled={isAccountLocked}
                  required
                />
                <label htmlFor="email">Email address or Phone Number</label>
                {errors.email && (
                  <span className="form-error">{errors.email}</span>
                )}
              </div>
              <div className="form-field">
                <input
                  type={inputType ? "password" : "text"}
                  name="password"
                  className="form-input"
                  id="password"
                  value={password}
                  onChange={handleOnChange}
                  disabled={isAccountLocked}
                  required
                />
                <label htmlFor="password">Password</label>
                <span className="password__show" onClick={togglePassword}>
                  <img src={inputType ? iconShow : iconHide} alt="" />
                </span>
              </div>
              <div className="remember-password">
                <div className="checkbox-wrapper">
                  <input
                    type="checkbox"
                    value="remembered"
                    // value="lsRememberMe"
                    id="checkboxInput"
                    name=""
                    disabled={isAccountLocked}
                    // onClick={lsRememberMe()}
                  />
                  <label htmlFor="checkboxInput">
                    {/* <span className=""></span> */}
                    <p>Remember me for 15 days</p>
                  </label>
                </div>
                {/* {forgotPassword} */}
                <Link to="/forgot-password">
                  <p className="">Forgot password?</p>
                </Link>
              </div>
              {isLoading ? (
                <button className="btn-blue-loading disabled">
                  <span className="load open"></span> Please wait...
                </button>
              ) : (
                <>
                  <input
                    type="submit"
                    className="btn-blue"
                    disabled={isAccountLocked}
                    value="Login"
                  />

                  <div className="form-error" id="demo"></div>
                </>
              )}

              {/* {cta} */}
              <div>
                <p className="cta">
                  Don't have an account yet?{" "}
                  <Link to="/signup">
                    <span>Sign up now</span>
                  </Link>
                </p>
              </div>
              {/* <div>
                <p className="cta">
                  Classic Card Login{" "}
                  <Link to="/temp-login">
                    <span>Click here</span>
                  </Link>
                </p>
              </div> */}
              {/* {close} */}
            </form>
          }
        />
      </div>
    </main>
  );
}

export default withRouter(Login);
