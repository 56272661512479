import React, { useEffect, useState } from "react";
import { url_path } from "../constant";
import { Link } from "react-router-dom";
import { getCookie, setCookie } from "../lib/helpers/cookies";
import axios from "axios";
import Sweetalert2 from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

const Swal = withReactContent(Sweetalert2);
// import Swiper from 'react-id-swiper';
// import 'swiper/css/swiper.css';

// import { ReactComponent as IconShare } from '../assets/images/icon-transaction.svg'

const Sidebar = ({ history }) => {
  // States
  const [loading, setLoading] = useState(true);
  const [name, setName] = useState("");
  const [points, setPoints] = useState(0);
  const [notifications, setNotifications] = useState(0);
  const [userId, setUserId] = useState("");

  const sidebar = [
    {
      name: "Home",
      routeTo: "/member-dashboard",
      linkTo: "app",
      url: "",
    },
    {
      name: "About the Program",
      routeTo: "",
      linkTo: "browser",
      url: "https://infinityprivilege.com/about/",
    },
    {
      name: "Infinity Privilege Card Categories",
      routeTo: "",
      linkTo: "browser",
      url: "https://infinityprivilege.com/ip-categories/",
    },
    {
      name: "Infinity Privilege Tier Levels",
      routeTo: "",
      linkTo: "browser",
      url: "https://infinityprivilege.com/ip-tiers/",
    },
    {
      name: "Notifications",
      routeTo: "/notifications",
      linkTo: "app",
      url: "",
      notifBadge: <small className="notif-badge">{notifications}</small>,
      clickFunction: () => {
        axios
          .put(`${url_path}/api/notification/update/all/${userId}`)
          .then((res) => {
            getData();
          })
          .catch((err) => {
            console.log(err);
          });
      },
    },
    // {
    //   name: 'Special Offers',
    //   routeTo: ''
    // },
    // {
    //   name: 'My Favorites',
    //   routeTo: ''
    // },
    // {
    //   name: 'Find Partners',
    //   routeTo: ''
    // },
    {
      name: "FAQs",
      routeTo: "",
      linkTo: "browser",
      url: "https://infinityprivilege.com/faqs",
    },
    // {
    //   name: 'Apply to be a Partner',
    //   routeTo: ''
    // },
    {
      name: "News",
      routeTo: "",
      linkTo: "browser",
      url: "https://infinityprivilege.com/news/",
    },
    {
      name: "Careers",
      routeTo: "",
      linkTo: "browser",
      url: "https://infinityprivilege.com/careers/",
    },
    {
      name: "Contact Us",
      routeTo: "",
      linkTo: "browser",
      url: "https://infinityprivilege.com/contact-us/",
    },
    {
      name: "Privacy Policy",
      routeTo: "",
      linkTo: "browser",
      url: "https://infinityprivilege.com/privacy-policy/",
    },
    {
      name: "Terms & Conditions",
      routeTo: "",
      linkTo: "browser",
      url: "https://infinityprivilege.com/terms-and-conditions/",
    },
    // {
    //   name: "Invite Friends",
    //   routeTo: "/invite",
    //   linkTo: "app",
    //   url: "",
    // },
  ];

  // Functions
  const getData = async () => {
    setLoading(true);
    const token = getCookie("token");

    try {
      const { data: res } = await axios.get(`${url_path}/api/auth`, {
        headers: { "x-auth-token": token },
      });
      setName(res.user.user_first_name + " " + res.user.user_last_name);
      setUserId(res.user._id);

      const { data: customer_bank } = await axios.get(
        `${url_path}/api/customerBank/${res.user._id}`
      );
      setPoints(customer_bank.points_balance);

      const { data: notif_info } = await axios.get(
        `${url_path}/api/notification/badge/${res.user._id}`
      );
      setNotifications(notif_info);

      setLoading(false);
    } catch (err) {
      console.log(err);
    }
  };

  const signOut = () => {
    // Swal.fire({
    //   title: "Are you sure?",
    //   text: `You want to sign out`,
    //   icon: "warning",
    //   showCancelButton: true,
    //   confirmButtonColor: "#3085d6",
    //   cancelButtonColor: "#d33",
    //   confirmButtonText: "Yes",
    // }).then((result) => {
    //   if (result.value) {
    //     setCookie("type", null, null);
    //     setCookie("isLoggedIn", null, null);
    //     setCookie("token", null, null);
    //     history.push("/");
    //   }
    // });
    setCookie("type", null, null);
    setCookie("isLoggedIn", null, null);
    setCookie("token", null, null);
    history.push("/");
  };

  useEffect(() => {
    getData();

    return () => {
      setPoints();
      setName();
      setLoading();
    };
  }, []);

  return (
    <div className="sidebar-wrapper">
      <div className="sidebar-header">
        <Link to="/my-infinity-card">
          <img
            src={require("../assets/images/qr-code-sidebar.png")}
            className="img-qr img-fluid"
            alt="icon"
          />
        </Link>
        <div className="sidebar-header-content">
          {loading ? (
            <div className="lds-ellipsis">
              <div className="dots"></div>
              <div className="dots"></div>
              <div className="dots"></div>
              <div className="dots"></div>
            </div>
          ) : (
            <>
              <p className="member-name">{name}</p>
              <p className="member-points">{points} points</p>
            </>
          )}
        </div>
      </div>
      <div className="sidebar-content">
        {sidebar.map((item, i) => {
          return (
            <div
              className="sidebar-link-holder"
              key={i}
              onClick={item.clickFunction && item.clickFunction}
            >
              {item.linkTo === "app" ? (
                <>
                  <Link to={item.routeTo} className="sidebar-link">
                    <p>{item.name}</p>
                    {item.notifBadge && notifications > 0 && item.notifBadge}
                    {/* { item.notifBadge } */}
                  </Link>
                </>
              ) : (
                <>
                  <a
                    href={item.url}
                    className="sidebar-link"
                    rel="noreferrer nofollow"
                  >
                    <p>{item.name}</p>
                  </a>
                </>
              )}
            </div>
          );
        })}
        <div className="sidebar-link-holder">
          <div className="sidebar-link" onClick={signOut}>
            Sign Out
          </div>
        </div>
      </div>
    </div>
  );
};

export default Sidebar;
