import React, { useEffect } from "react";
import Breadcrumbs from "../../components/Breadcrumbs";
import "moment-timezone";

import Dashboard from "../../components/Dashboard";
import { authenticateAdminPage } from "../../lib/helpers/validation";

function Archives(props) {
  useEffect(() => {
    authenticateAdminPage(props);
  }, []);
  const linksList = [
    {
      title: "Manage Archived Business Partners",
      routeTo: "/manage-archived-business-partners",
      icon: require("../../assets/images/dashboard-img4.png"),
    },
  ];

  return (
    <main className="admin-dashboard">
      <div className="container custom-container">
        <Breadcrumbs
          size="text-large"
          placeholder="Archives"
          targetLink="/admin-dashboard"
          hasButton="false"
          customClassName="m-3"
        />
        <Dashboard links={linksList} />
      </div>
    </main>
  );
}

export default Archives;
