import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import Card from "./Card";
import { MdClose } from "react-icons/md";

function FeaturedDeals({ dealsList }) {
  const editCardDetails = {
    title: "",
    customClassName: "no-margin no-shadow",
  };

  const [showModal, setShowModal] = useState(false);

  // const handleShowModal = () => {
  //   setShowModal(true);
  // };

  const handleHideModal = () => {
    setShowModal(false);
  };
  return (
    <>
      {/* <div className="filter-wrapper">
        <p onClick={handleShowModal}>Filter</p>
      </div> */}
      <div className="dashboard-wrapper featured-deals">
        {dealsList.map((item, i) => {
          return (
            <>
              <div className="dashboard-item dashboard-swiper" key={i}>
                <div className="icon-holder">
                  <img
                    src={item.icon}
                    className="icon-img"
                    alt="Dashboard Icon"
                  />
                </div>
                <div className="content-holder swiper-content">
                  <p className="deal-title">{item.title}</p>
                  <p className="deal-description">{item.description}</p>
                  <p
                    className="deal-link"
                    // onClick={() => goToDeal(item._id)}
                  >
                    View offer
                  </p>
                </div>
              </div>
            </>
          );
        })}
        <Modal
          show={showModal}
          onHide={handleHideModal}
          className="modal-dark- opacity edit-modal"
        >
          <Card
            cardDetails={editCardDetails}
            form={
              <>
                <div className="heading-wrapper">
                  <p className="heading">Filter</p>
                  <p className="heading-close" onClick={handleHideModal}>
                    <MdClose />{" "}
                  </p>
                </div>
                <p>content</p>
              </>
            }
          />
        </Modal>
      </div>
    </>
  );
}

export default FeaturedDeals;
