import React, { Component } from "react";
import { HashRouter, Route, Switch } from "react-router-dom";
import { hot } from "react-hot-loader";
import CookieConsent from "react-cookie-consent";
import { useState } from "react";

import Header from "./components/Header";
// import Home from './containers/home'

// Default Containers
import Login from "./containers/login";
import TempLogin from "./containers/temp-login";
import Signup from "./containers/signup-wizard";
import ForgotPassword from "./containers/forgot-password";
import SignupPhone from "./containers/signup-phone";
import ForgotPasswordSuccess from "./containers/forgot-password-success";
import ResetPassword from "./containers/reset-password";
import ResetPasswordSuccess from "./containers/reset-password-success";
import SignUpComplete from "./containers/signup-complete";
import NoRouteMatch from "./containers/404";

// Admin Containers
import AdminDashboard from "./containers/Admin/admin-dashboard";
import BusinessProfile from "./containers/Admin/business-profile";
import MemberProfile from "./containers/Admin/member-profile";
import AdminTransactions from "./containers/Admin/admin-transactions";
import Settings from "./containers/Admin/settings";
import OTPRequests from "./containers/Admin/otp-requests";
import ManageMembers from "./containers/Admin/manage-members";
import ManageBusinessPartners from "./containers/Admin/manage-business-partners";
import ManageUsers from "./containers/Admin/manage-users";
import ManageTempUsers from "./containers/Admin/manage-temp-users";
import GeneralTransactions from "./containers/Admin/general-transactions";
import PaymentTransactions from "./containers/Admin/payment-transactions";
import IncomeTransactions from "./containers/Admin/income-transactions";
import PromoCode from "./containers/Admin/promo-code";
import PaymentRequest from "./containers/Admin/payment-request";
import ManagePendingMembers from "./containers/Admin/manage-pending-members";
import CardRequest from "./containers/Admin/card-request";
import ManagePoints from "./containers/Admin/admin-manage-points";
import ManageAnnouncements from "./containers/Admin/manage-announcements";
import ManageArchiveBusinessPartners from "./containers/Admin/manage-archived-business-partners";
import Archives from "./containers/Admin/archives";
// import EncashRequest from "./containers/Admin/encash-request";

// BusinessPartner Containers
import PartnerDashboard from "./containers/BussinessPartner/partner-dashboard";
import ManageDeals from "./containers/BussinessPartner/manage-deals";
import Customers from "./containers/BussinessPartner/customers";
import ManualInput from "./containers/BussinessPartner/manual-input";
import ManualPurchase from "./containers/BussinessPartner/manual-purchase";
import ManualRedeemOTP from "./containers/BussinessPartner/manual-redeem-otp";
import ManualRedeem from "./containers/BussinessPartner/manual-redeem";
import PurchaseDetails from "./containers/BussinessPartner/purchase-details";
import RedeemDetails from "./containers/BussinessPartner/redeem-details";
import Transactions from "./containers/BussinessPartner/infinity-transactions";
import Statement from "./containers/BussinessPartner/infinity-statement";
import Scanner from "./containers/BussinessPartner/scanner";
import PartnerSettings from "./containers/BussinessPartner/partner_settings";

// Member Containers
import MemberDashboard from "./containers/Member/member-dashboard";
import InfinityPoints from "./containers/Member/my-infinity-points";
import Favorites from "./containers/Member/favorites";
import TransactionHistory from "./containers/BussinessPartner/transaction-history";
import DealDetails from "./containers/deal-details";
import Help from "./containers/Member/help";
import InfinityCard from "./containers/Member/my-infinity-card";
import InfinityAccount from "./containers/Member/my-infinity-account";
import MemberAccount from "./containers/Member/my-infinity-account-account";
import MemberAccountAbout from "./containers/Member/my-infinity-account-about";
import MemberAccountTransaction from "./containers/Member/my-infinity-account-transaction";
import MemberReferrals from "./containers/Member/my-infinity-referrals";
import Request from "./containers/Member/request";
import TransferPoints from "./containers/Member/my-infinity-account-transfer-points";
import TransferVerify from "./containers/Member/my-infinity-account-transfer-verify";
import PersonalData from "./containers/Member/my-infinity-account-personal-data";
import ContactDetails from "./containers/Member/my-infinity-account-contact-details";
import MemberEarnPoints from "./containers/Member/member-earn-points";
import MemberSpendPoints from "./containers/Member/member-spend-points";
import MemberTemporaryPage from "./containers/Member/member-temporary-page";
import ChangePassword from "./containers/Member/my-infinity-account-change-password";
// import AboutProgram from "./containers/Member/about";
// import CardCategories from "./containers/Member/card-categories";
// import TierLevels from "./containers/Member/tier-levels";
// import Careers from "./containers/Member/careers";
// import Faqs from "./containers/Member/faqs";
// import PrivacyPolicy from "./containers/Member/privacy-policy";
import DealInfo from "./containers/Member/deal-info";
import Notifications from "./containers/Member/notifications";
import InviteFriend from "./containers/Member/invite-friend";
import PaymentSuccess from "./containers/payment-success";
// import EncashPoints from "./containers/Member/my-infinity-account-encash-points";

// Temporary Containers for Development
import Deals from "./containers/Admin/deals";
import Offers from "./containers/Admin/offers";
import DataGenerator from "./containers/data-generator";
import TestSetDeal from "./containers/testSetDeal";
import TestSetOffer from "./containers/testSetOffer";
import SFU from "./containers/sample-file-upload";
import List from "./containers/list";

// Dev
import MemberDeal from "./containers/Dev/manage-deal";
import ManageMemberDeal from "./containers/Dev/manage-deal-detail";
// import EmailVerification from "./containers/Admin/email-verification";
import SecretTest from "./containers/Dev/secret-test";
import SuccessPayment from "./containers/Dev/success-payment";
import CancelledPayment from "./containers/Dev/cancel-payment";

import "bootstrap/dist/css/bootstrap.min.css";
// import InfinityTransaction from "./tabs/partner/InfinityTransaction";
import Partners from "./containers/partners";
class App extends Component {
  render() {
    return (
      <HashRouter basename="/">
        <div className="App">
          <Header/>
          <Switch>
            {/* Default Routes */}
            <Route path="/" exact component={Login} />
            <Route path="/temp-login" exact component={TempLogin} />
            <Route path="/signup" exact component={Signup} />
            <Route path="/signup-with-phone" exact component={SignupPhone} />
            <Route path="/forgot-password" exact component={ForgotPassword} />
            <Route
              path="/forgot-password-success"
              exact
              component={ForgotPasswordSuccess}
            />
            <Route
              path="/reset-password/:verification_code"
              exact
              component={ResetPassword}
            />
            <Route
              path="/reset-password-success"
              exact
              component={ResetPasswordSuccess}
            />
            <Route path="/partners" exact component={Partners} />
            <Route path="/signup-complete" exact component={SignUpComplete} />
            {/* <Route path="/reset-password" exact component={ResetPassword} /> */}

            {/* Admin Routes */}
            <Route path="/admin-dashboard" exact component={AdminDashboard} />
            <Route path="/manage-users" exact component={ManageUsers} />
            <Route path="/manage-temp-users" exact component={ManageTempUsers} />
            <Route
              path="/manage-business-partners"
              exact
              component={ManageBusinessPartners}
            />
            <Route
              path="/manage-archived-business-partners"
              exact
              component={ManageArchiveBusinessPartners}
            />
            <Route
              path="/archives"
              exact
              component={Archives}
            />
            <Route
              path="/business-profile/:id"
              exact
              component={BusinessProfile}
            />
            <Route
              path="/otp-requests"
              exact
              component={OTPRequests}
            />
            <Route path="/manage-members" exact component={ManageMembers} />
            <Route path="/member-profile/:id" exact component={MemberProfile} />
            <Route
              path="/admin-transactions"
              exact
              component={AdminTransactions}
            />
            <Route
              path="/general-transactions"
              exact
              component={GeneralTransactions}
            />
            <Route
              path="/payment-transactions"
              exact
              component={PaymentTransactions}
            />
            <Route
              path="/income-transactions"
              exact
              component={IncomeTransactions}
            />
            <Route path="/settings" exact component={Settings} />
            <Route path="/deals" exact component={Deals} />
            <Route path="/offers" exact component={Offers} />
            <Route path="/manage-points" exact component={ManagePoints} />

            {/* <Route path="/email-verification" exact component={EmailVerification} /> */}
            <Route path="/promo-code" exact component={PromoCode} />
            <Route path="/payment-request" exact component={PaymentRequest} />
            <Route
              path="/pending-membership"
              exact
              component={ManagePendingMembers}
            />
            <Route path="/card-request" exact component={CardRequest} />
            {/* <Route path="/encash-request" exact component={EncashRequest} /> */}

            {/* Business Partner Routes */}
            <Route
              path="/partner-dashboard"
              exact
              component={PartnerDashboard}
            />
            <Route path="/manage-deals" exact component={ManageDeals} />
            <Route path="/customers" exact component={Customers} />
            <Route path="/manual-input" exact component={ManualInput} />
            <Route path="/manual-purchase" exact component={ManualPurchase} />
            <Route path="/manual-redeem-otp/:card_no/:user_id/:amount/:invoice/:notes" exact component={ManualRedeemOTP} />

            <Route
              path="/purchase-details"
              exact
              component={PurchaseDetails}
            />
            <Route path="/manual-redeem" exact component={ManualRedeem} />
            <Route
              path="/redeem-details/:cardNo/:amount/:invoice/:notes"
              exact
              component={RedeemDetails}
            />
            <Route
              path="/infinity-transactions"
              exact
              component={Transactions}
            />
            <Route path="/infinity-statement" exact component={Statement} />
            <Route path="/scanner" exact component={Scanner} />
            <Route path="/partner-settings" exact component={PartnerSettings} />
            <Route path="/announcements" exact component={ManageAnnouncements} />

            {/* Member Routes */}
            <Route path="/member-dashboard" exact component={MemberDashboard} />
            <Route path="/my-infinity-card" exact component={InfinityCard} />
            <Route
              path="/my-infinity-account"
              exact
              component={InfinityAccount}
            />
            <Route
              path="/my-infinity-account-account"
              exact
              component={MemberAccount}
            />
            <Route
              path="/my-infinity-account-transaction"
              exact
              component={MemberAccountTransaction}
            />
            <Route
              path="/my-infinity-account-personal-data"
              exact
              component={PersonalData}
            />
            <Route
              path="/my-infinity-account-transfer-points"
              exact
              component={TransferPoints}
            />
            <Route
              path="/my-infinity-account-contact-details"
              exact
              component={ContactDetails}
            />
            <Route
              path="/my-infinity-referrals"
              exact
              component={MemberReferrals}
            />
            <Route path="/my-referrals" exact />
            <Route path="/my-sms-info" exact />
            <Route
              path="/member-earn-points"
              exact
              component={MemberEarnPoints}
            />
            <Route
              path="/member-spend-points"
              exact
              component={MemberSpendPoints}
            />
            <Route
              path="/my-infinity-points"
              exact
              component={InfinityPoints}
            />
            <Route path="/request" exact component={Request} />
            <Route path="/favorites" exact component={Favorites} />
            <Route
              path="/transaction-history"
              exact
              component={TransactionHistory}
            />
            <Route path="/deal-details" exact component={DealDetails} />
            <Route path="/help" exact component={Help} />
            <Route
              path="/my-infinity-account-about"
              exact
              component={MemberAccountAbout}
            />
            <Route
              path="/member-temporary-page"
              exact
              component={MemberTemporaryPage}
            />
            <Route
              path="/my-infinity-account-transfer-verify"
              exact
              component={TransferVerify}
            />
            <Route
              path="/my-infinity-account-change-password"
              exact
              component={ChangePassword}
            />
            <Route path="/deal-info" exact component={DealInfo} />
            {/* <Route path="/about" exact component={AboutProgram} /> */}
            {/* <Route path="/card-categories" exact component={CardCategories} /> */}
            {/* <Route path="/tier-levels" exact component={TierLevels} /> */}
            {/* <Route path="/careers" exact component={Careers} /> */}
            {/* <Route path="/faqs" exact component={Faqs} /> */}
            {/* <Route path="/privacy-policy" exact component={PrivacyPolicy} /> */}
            <Route path="/notifications" exact component={Notifications} />
            <Route path="/invite" exact component={InviteFriend} />
            <Route path="/success-payment" exact component={PaymentSuccess} />
            {/* <Route path="/my-infinity-account-encash-points" exact component={EncashPoints} /> */}

            {/* Temporary Routes for Development */}
            <Route path="/list" exact component={List} />
            <Route path="/test-set-deal" exact component={TestSetDeal} />
            <Route path="/test-set-offer" exact component={TestSetOffer} />
            <Route path="/data-generator" exact component={DataGenerator} />
            <Route path="/sfu" exact component={SFU} />
            <Route path="/secret" exact component={MemberDeal} />
            <Route path="/manage-secret" exact component={ManageMemberDeal} />
            <Route path="/secret-test" exact component={SecretTest} />
            <Route path="/success-payment" exact component={SuccessPayment} />
            <Route path="/cancel-payment" exact component={CancelledPayment} />
            <Route path="/404" exact component={NoRouteMatch} />

            {/* No Route Match */}
            <Route component={NoRouteMatch} />
            
          </Switch>
          <CookieConsent
            location="bottom"
            buttonText="I understand"
            cookieName="cookie-consent"
            expires={150}
            style={{
              backgroundColor: "#106397",
            }}
            buttonStyle={{
              color: "#106397",
              backgroundColor: "white",
            }}
          >
            This website uses cookies to ensure you get the best performance on
            our website.
          </CookieConsent>
        </div>
      </HashRouter>
    );
  }
}

export default hot(module)(App);
