import { successNotif } from "../../lib/helpers/notifications.js";
import { url_path } from "../../constant";
import useForm from "../../lib/hooks/useForm.js";
import React, { useState, useEffect } from "react";
import Table from "../../components/Table";
import Card from "../../components/Card";
import Breadcrumbs from "../../components/Breadcrumbs";
import Sweetalert2 from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import ReactTooltip from "react-tooltip";
import axios from "axios";
import Moment from "react-moment";
import moment from "moment";
import Modal from "react-bootstrap/Modal";
import "moment-timezone";
import { ReactComponent as IconDelete } from "../../assets/images/icon-delete.svg";
import { ReactComponent as IconEdit } from "../../assets/images/icon-edit.svg";
import { ReactComponent as IconCaretDown } from "../../assets/images/icon-caret-down.svg";

import { ReactComponent as IconCalendar } from "../../assets/images/icon-calendar.svg";
import DatePicker from "react-datepicker";
const Swal = withReactContent(Sweetalert2);

const editInfo = {
  title: "Update Deal",
  hasClose: true,
  hasMessage: false,
  customClassName: "no-margin no-shadow",
};

function ManageUsers() {
  // const [data, setData] = useState([])
  const [data, setData] = useState([]);
  const [modalState, setModalState] = useState(false);
  const [currentEdit_ID, setCurrentEdit_ID] = useState("");
  // const [inputType, setInputType] = useState(true);
  const [roleIsDisabled, setRoleIsDisabled] = useState(false);

  const [loader, setLoader] = useState(false);
  // const [pagerData, setPagerData] = useState([]);
  const [searchInput, setSearchInput] = useState("");

  const [startDate, setStartDate] = useState();
  const [endDate, setEndtDate] = useState();

  const [isImageTouched, setIsImageTouched] = useState(false);
  const [imagePreviewURL, setImagePreviewURL] = useState("");
  const [file, setFile] = useState("");

  const [imageUrl, setImageUrl] = useState("");
  // for images
  const handleImageChange = (e) => {
    e.preventDefault();
    console.log(e);

    let reader = new FileReader();
    let file = e.target.files[0];

    reader.onloadend = () => {
      setFile(file);
      setImagePreviewURL(reader.result);
      setIsImageTouched(true);
    };

    reader.readAsDataURL(file);
  };

  // let imagePrev = null;
  // let imagePrevSmall = null;
  // if (imagePreviewURL) {
  //   imagePrev = (
  //     <img
  //       style={{ maxWidth: 150, maxHeight: 150, margin: 15 }}
  //       src={imagePreviewURL}
  //       alt=""
  //     />
  //   );
  //   imagePrevSmall = (
  //     <img
  //       style={{ maxWidth: 50, maxHeight: 50, margin: 15 }}
  //       src={imagePreviewURL}
  //       alt=""
  //     />
  //   );
  // } else {
  //   imagePrev = "";
  // }

  // end

  const handleDateChangeRaw = (e) => {
    e.preventDefault();
  };
  /*
   * Regex Validations to Inputs
   */

  // var nameRegex = /^[a-zA-Z ]+$/;
  // var emailRegex = /\S+@\S+\.\S+/;
  // var numberRegex = /^(09|\+639|9)\d{9}$/;
  // var passwordMinChar = /^.{8,}$/;

  /*
   * Initialize input state schema
   */
  // title,
  // deal_type,
  // description,
  // start_date,
  // end_date,
  // featured,
  // price_points,
  const stateSchema = {
    title: { value: "", error: "" },
    deal_type: { value: "", error: "" },
    description: { value: "", error: "" },
    about_shop: { value: "", error: "" },
    address: { value: "", error: "" },
    featured: { value: false, error: "" },
    price_points: { value: "", error: "" },
    // image_url: { value: "", error: "" },
  };

  /*
   * Create a schema for all inputs
   */

  const stateValidatorSchema = {
    title: {
      required: true,
    },
    deal_type: {
      required: true,
    },
    description: {
      required: true,
    },
    about_shop: {
      required: false,
    },
    address: {
      required: false,
    },
    featured: {
      required: true,
    },
    price_points: {
      required: true,
    },
  };

  /*
   * On Submit Form
   */

  function onSubmitForm(state) {
    /*
     * Destructuring state Values
     */
    // const data = [
    //   title,
    //   deal_type,
    //   description,
    //   startDate,
    //   endDate,
    //   file,
    //   featured,
    //   price_points
    // ]
    // return console.log(data);
    const client_id = "efb2261cbff967d";

    // console.log("Yey");
    console.log(file);
    // return console.log(imagePreviewURL)
    const config = {
      headers: { Authorization: `Client-ID ${client_id}` },
    };

    const {
      title,
      deal_type,
      description,
      about_shop,
      address,
      featured,
      price_points,
      // status
    } = state;
    const image = {
      image_url: isImageTouched ? imagePreviewURL : imageUrl,
    };
    // return console.log(image);

    if (currentEdit_ID !== "") {
      if (isImageTouched) {
        // update
        axios
          .post("https://api.imgur.com/3/image", file, config)
          .then((res) => {
            const req = {
              title,
              deal_type,
              description,
              about_shop,
              address,
              start_date: startDate,
              end_date: endDate,
              image_url: res.data.data.link,
              featured,
              price_points,
              // status
            };
            console.log("Add");
            axios
              .put(`${url_path}/api/member_deal/update/${currentEdit_ID}`, req)
              .then((res) => {
                console.log(res);
                const dealData = res.data;
                if (dealData.data.status === "success") {
                  let title = "Well Done!";
                  let message = dealData.data.msg;
                  successNotif(title, message);
                  hideModal();
                  getData(1, "");
                  setData(res.data.member_deal);
                  // appendNewUser(res.data.member_deal);
                  // console.log(res.data.user);
                  console.log(res.data.member_deal);
                }
              });
          })
          .catch(function (err) {
            console.log(err);
          });
      } else {
        const req = {
          title,
          deal_type,
          description,
          about_shop,
          address,
          start_date: startDate,
          end_date: endDate,
          image_url: image.image_url,
          featured,
          price_points,
          // status
        };
        console.log("Add");
        axios
          .put(`${url_path}/api/member_deal/update/${currentEdit_ID}`, req)
          .then((res) => {
            console.log(res);
            const dealData = res.data;
            if (dealData.data.status === "success") {
              let title = "Well Done!";
              let message = dealData.data.msg;
              successNotif(title, message);
              hideModal();
              getData(1, "");
              setData(res.data.member_deal);
              // appendNewUser(res.data.member_deal);
              // console.log(res.data.user);
              console.log(res.data.member_deal);
            }
          });
      }
    } else {
      // add
      axios
        .post("https://api.imgur.com/3/image", file, config)
        .then((res) => {
          const req = {
            title,
            deal_type,
            description,
            about_shop,
            address,
            start_date: startDate,
            end_date: endDate,
            image_url: res.data.data.link,
            featured,
            price_points,
            // status
          };
          console.log("Add");
          axios.post(`${url_path}/api/member_deal`, req).then((res) => {
            console.log(res);
            const dealData = res.data;
            if (dealData.data.status === "success") {
              let title = "Well Done!";
              let message = dealData.data.msg;
              successNotif(title, message);
              hideModal();
              getData(1, "");
              setData(res.data.member_deal);
              // appendNewUser(res.data.member_deal);
              // console.log(res.data.user);
              console.log(res.data.member_deal);
            }
          });
        })
        .catch(function (err) {
          console.log(err);
        });
    }
  }

  // console.log("Running");
  // console.log(currentEdit_ID);

  /*
   * Get values, errors, handling events to custom useForm Hooks
   */

  const { values, errors, handleOnChange, handleOnSubmit, disable } = useForm(
    stateSchema,
    stateValidatorSchema,
    onSubmitForm
  );

  /*
   * Get values to custom useForm Hooks
   * Pass it to input values
   */

  const {
    title,
    deal_type,
    description,
    about_shop,
    address,
    price_points,
    // image_url
  } = values;
  // console.log(image_url);

  // var status = values.status.toString();
  var featured = values.featured.toString();

  /*
   * Get User Data to Database
   * Set Data to State
   */

  function getData(pageNumber, search) {
    setLoader(false);

    // const req = { pageNumber, search };
    axios
      .get(`${url_path}/api/member_deal/`)
      .then((response) => {
        // return console.log(response.data);
        setData(response.data);
        // setPagerData(response.data.paginate);
        setLoader(true);
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  /*
   * Callback funciton to Add
   * Append data to state[data]
   */

  // function appendNewUser(data) {
  //   let user = {
  //     _id: data._id,
  //     title: data.title,
  //     deal_type: data.deal_type,
  //     description: data.description,
  //     about_shop: data.about_shop,
  //     address: data.address,
  //     start_date: data.start_date,
  //     end_date: data.end_date,
  //     price_points: data.price_points,
  //     featured: data.featured,
  //   };

  //   setData([...data, user]);
  // }

  /*
   * Mount User Data
   * Clear and Set Initial Data to Inputs
   */

  useEffect(() => {
    getData(1, "");
    // setUserData(userData)
  }, []);

  /*
   * Modal Actions Show/Hide
   */

  const hideModal = () => {
    setModalState(false);
    setCurrentEdit_ID("");
  };

  const showModal = () => {
    setModalState(true);
  };

  /*
   * Action Function Handlers
   */

  const handleShowCreate = () => {
    showModal();
    editInfo.title = "Create New Deal";
    // console.log(values)
    // console.log(errors)
    setRoleIsDisabled(false);

    values.title = "";
    values.deal_type = "";
    values.description = "";
    values.about_shop = "";
    values.address = "";
    values.featured = false;
    values.price_points = "";
    values.status = false;

    errors.title = "";
    errors.deal_type = "";
    errors.description = "";
    errors.about_shop = "";
    errors.address = "";
    errors.featured = false;
    errors.price_points = "";
  };

  const handleShowEdit = () => {
    showModal();
    editInfo.title = "Update Deal";
    setRoleIsDisabled(true);
  };

  /*
   * Disallow non valid keys to input number
   */

  // const handleInputNumberAllowedCharacter = (event) => {
  //   var invalidChars = ["-", "e"];

  //   if (invalidChars.includes(event.key)) {
  //     event.preventDefault();
  //   }
  // };

  const handleSearchInput = (event) => {
    setSearchInput(event.target.value);
  };

  const handleSearchSubmit = (event) => {
    event.preventDefault();

    getData(1, searchInput);
  };

  /*
   * Table Data
   * Includes Headings
   */

  const heading = [
    {
      Header: "Title",
      accessor: "title",
      // accessor: row => `${row.title} ${row.deal_type}`
      // filterMethod: (filter, row) =>
      //   row._original.user_first_name.startsWith(filter.value) ||
      //   row._original.user_last_name.startsWith(filter.value)
    },
    {
      Header: "Deal Type",
      accessor: "deal_type",
    },
    {
      Header: "Status",
      id: "status",
      accessor: (d) => d.status,
      Cell: (status) => (
        <div className="active-holder">
          {status.original.status ? (
            <span className="table-status-active">Active</span>
          ) : (
            <span className="table-status-inactive">Inactive</span>
          )}
        </div>
      ),
    },
    {
      Header: "Start Date",
      id: "startDate",
      accessor: (c) => <Moment format="ll" date={c.start_date} />,
      // {Moment(new Date(c.created_at)).format('ll')}
      // Cell: row => (
      //   <span>
      //     {<Moment format='L' date={row.created_at} />}
      //   </span>
      // )
    },
    {
      Header: "End Date",
      id: "endDate",
      accessor: (c) => <Moment format="ll" date={c.end_date} />,
      // {Moment(new Date(c.created_at)).format('ll')}
      // Cell: row => (
      //   <span>
      //     {<Moment format='L' date={row.created_at} />}
      //   </span>
      // )
    },
    {
      Header: "Actions",
      sortable: false,
      // filterable: false,
      Cell: (row) => (
        <div className="button-wrapper">
          <div className="button-holder">
            <button
              className="btn-icon"
              onClick={() => {
                handleShowEdit();
                // getData()

                /*
                 * Get data from user datas
                 */

                let datas = [...data];
                console.log({ USERS: datas[row.index] });
                /*
                 * Initialize CurrentID
                 */

                setCurrentEdit_ID(datas[row.index]._id);
                setImageUrl(datas[row.index].image_url);
                /*
                 * Add shorthand notation value
                 */

                const {
                  title,
                  deal_type,
                  description,
                  about_shop,
                  address,
                  featured,
                  price_points,
                  status,
                  start_date,
                  end_date,
                } = datas[row.index];

                /*
                 * Set User Data value to Current Input Fields
                 */
                console.log(start_date);
                values.title = title;
                values.deal_type = deal_type;
                values.description = description;
                values.about_shop = about_shop;
                values.address = address;
                values.featured = featured;
                values.price_points = price_points;
                values.status = status;
                setStartDate(moment(new Date(start_date)).format("ll"));
                setEndtDate(moment(new Date(end_date)).format("ll"));
              }}
            >
              <span className="icon-holder">
                <IconEdit className="icon-actions" data-tip data-for="edit" />
              </span>
            </button>
            <ReactTooltip id="edit" type="warning" effect="solid">
              <span>Edit</span>
            </ReactTooltip>
          </div>
          <div className="button-holder">
            <button
              className="btn-icon"
              onClick={() => {
                let datas = [...data];

                console.log(datas[row.index]._id);
                const deleteID = datas[row.index]._id;

                Swal.fire({
                  title: "Are you sure?",
                  text: "You won't delete this deal",
                  icon: "warning",
                  showCancelButton: true,
                  confirmButtonColor: "#3085d6",
                  cancelButtonColor: "#d33",
                  confirmButtonText: "Yes, delete it!",
                }).then((result) => {
                  if (result.value) {
                    axios
                      .delete(`${url_path}/api/member_deal/${deleteID}`)
                      .then((response) => {
                        console.log(response);
                        getData(1, searchInput);
                      });
                    let title = "Deleted!";
                    let message = "Deal has been deleted successfully.";
                    successNotif(title, message);
                    // Swal.fire(
                    //   'Deleted!',
                    //   'User has been deleted successfully!',
                    //   'success'
                    // )
                  }
                });
              }}
            >
              <span className="icon-holder">
                <IconDelete
                  className="icon-actions"
                  data-tip
                  data-for="delete"
                />
              </span>
            </button>
            <ReactTooltip id="delete" type="warning" effect="solid">
              <span>Delete</span>
            </ReactTooltip>
          </div>
        </div>
      ),
    },
  ];

  return (
    <main className="secret-page manage-pages">
      <div className="container custom-container">
        <Breadcrumbs
          size="text-large"
          // placeholder="Manage Users"
          targetLink="/admin-dashboard"
          hasButton="true"
          buttonTitle="Create New Deals"
          customClassName="m-3"
          propsFunction={handleShowCreate}
        />
        <div className="section-table">
          {/* <div className="container"> */}
          <form onSubmit={handleSearchSubmit}>
            <div className="search-holder">
              <label htmlFor="searchInput">Search: </label>
              <input
                size="large"
                name="searchInput"
                label="Search"
                value={searchInput}
                onChange={handleSearchInput}
                className="search-input"
              />
              <input type="submit" className="btn-blue" value="Search" />
            </div>
          </form>
          {loader ? (
            <>
              <Table tableHeader={heading} tableData={data} />
              {/* <ul className="custom-pagination">
                  <li className={ classNames({ 'active': pagerData.currentPage === pagerData.firstPage })} >
                    { pagerData.prevPage ? <Link to='#' onClick={() => getData(1, searchInput)}>First</Link> : 'First' }
                  </li>
                  <li className={ classNames({ 'active': !pagerData.prevPage })}>
                    { pagerData.prevPage ? <Link to='#' onClick={() => getData(pagerData.prevPage, searchInput)}>Previous</Link> : 'Previous' }
                  </li>
                  { 
                    pagerData.midPages.map((page, index) => {
                      return (
                        pagerData.currentPage === page ? 
                          <li className='active' key={index}>{page}</li>
                        :
                          <li key={index}><Link to='#' onClick={() => getData(page, searchInput)}>{ page }</Link></li>
                      )
                    })
                  }
                  <li className={ classNames({ 'active': !pagerData.nextPage })}>
                    { pagerData.nextPage ? <Link to='#' onClick={() => getData(pagerData.nextPage, searchInput)}>Next</Link> : 'Next' }
                  </li>
                  <li className={ classNames({ 'active': pagerData.currentPage === pagerData.lastPage })}>
                    { pagerData.nextPage ? <Link to='#' onClick={() => getData(pagerData.lastPage, searchInput)}>Last</Link> : 'Last'}
                  </li>
                </ul> */}
            </>
          ) : (
            <div className="lds-ellipsis">
              <div className="dots"></div>
              <div className="dots"></div>
              <div className="dots"></div>
              <div className="dots"></div>
            </div>
          )}
          {/* </div> */}
        </div>
        <Modal
          show={modalState}
          onHide={hideModal}
          className="modal-dark- opacity"
        >
          <Card
            cardDetails={editInfo}
            form={
              <form className="form-wrapper" onSubmit={handleOnSubmit}>
                <div className="form-field">
                  <input
                    id="title"
                    className="form-input"
                    type="text"
                    name="title"
                    value={title}
                    onChange={handleOnChange}
                    required
                  />
                  <label htmlFor="title">Title</label>
                  {errors.title && (
                    <span className="form-error">{errors.title}</span>
                  )}
                </div>
                <div className="form-field">
                  <input
                    id="deal_type"
                    className="form-input"
                    type="text"
                    name="deal_type"
                    value={deal_type}
                    onChange={handleOnChange}
                    required
                  />
                  <label htmlFor="deal_type">Deal Type</label>
                  {errors.deal_type && (
                    <span className="form-error">{errors.deal_type}</span>
                  )}
                </div>

                <div className="form-field form-textarea">
                  <textarea
                    id="description"
                    className="form-input"
                    type="text"
                    name="description"
                    value={description}
                    onChange={handleOnChange}
                    required
                  />
                  <label htmlFor="description">Description</label>
                  {/* <input
                    id="description"
                    className="form-input"
                    type="text"
                    name="description"
                    value={description}
                    onChange={handleOnChange}
                    required
                  />
                  <label htmlFor="description">Description</label> */}
                  {errors.description && (
                    <span className="form-error">{errors.description}</span>
                  )}
                </div>
                <div className="form-field form-textarea">
                  <textarea
                    id="about_shop"
                    className="form-input"
                    type="text"
                    name="about_shop"
                    value={about_shop}
                    onChange={handleOnChange}
                    required
                  />
                  <label htmlFor="about_shop">About Shop</label>
                  {errors.about_shop && (
                    <span className="form-error">{errors.about_shop}</span>
                  )}
                </div>
                <div className="form-field">
                  <input
                    id="address"
                    className="form-input"
                    type="text"
                    name="address"
                    value={address}
                    onChange={handleOnChange}
                    required
                  />
                  <label htmlFor="address">Adress</label>
                  {errors.address && (
                    <span className="form-error">{errors.address}</span>
                  )}
                </div>
                <div className="form-field calendar">
                  <label htmlFor="startDate">
                    Start Date<span className="text-required">*</span>
                    <IconCalendar className="form-input-calendar" />
                  </label>
                  <DatePicker
                    // dateFormat="MM-DD-YYYY"
                    selected={Date.parse(startDate)}
                    onChange={(date) => setStartDate(date)}
                    peekNextMonth
                    showMonthDropdown
                    showYearDropdown
                    dropdownMode="select"
                    // selectsStart
                    // dateFormat="MMM dd, yyyy"
                    // startDate={startDate}
                    // endDate={endDate}
                    // maxDate={endDate}
                    onChangeRaw={handleDateChangeRaw}
                    className="form-input date"
                    name="startDate"
                    placeholderText="Oct 29, 2019"
                    autoComplete="off"
                    id="startDate"
                    required
                  />
                </div>
                <div className="form-field calendar">
                  <label htmlFor="startDate">
                    End Date<span className="text-required">*</span>
                    <IconCalendar className="form-input-calendar" />
                  </label>
                  <DatePicker
                    selected={Date.parse(endDate)}
                    // dateFormat="MM-DD-YYYY"
                    onChange={(date) => setEndtDate(date)}
                    peekNextMonth
                    showMonthDropdown
                    showYearDropdown
                    dropdownMode="select"
                    // selectsStart
                    // dateFormat="MMM dd, yyyy"
                    // startDate={startDate}
                    // endDate={endDate}
                    // maxDate={endDate}
                    onChangeRaw={handleDateChangeRaw}
                    className="form-input date"
                    name="startDate"
                    placeholderText="Oct 29, 2019"
                    autoComplete="off"
                    id="startDate"
                    required
                  />
                </div>
                <div className="form-field">
                  <input
                    id="uploadFileLogo"
                    className="form-input"
                    type="file"
                    name="uploadFileLogo"
                    accept="image/*"
                    // value={address}
                    // onChange={handleOnChange}
                    // required
                    onChange={(e) => handleImageChange(e)}
                  />
                  <label htmlFor="uploadFileLogo" className="label-upload">
                    <span className="fa fa-camera"></span>
                  </label>

                  {/* <label htmlFor="adress">Description</label> */}
                  {/* {errors.adress && (
                    <span className="form-error">{errors.adress}</span>
                  )} */}
                </div>
                {/* <div className="holder-logo">
                  <img style={{maxHeight:120, maxWidth:120}} src={imagePrev ? imagePrev.props.src : logo}/>
                  
                  <Input 
                  type="file" 
                  name="uploadFileLogo" 
                  id="uploadFileLogo" 
                  accept="image/*"
                  required
                  onChange={ (e) => handleImageChange(e)} />
                  <label htmlFor="uploadFileLogo" className="label-upload">
                    <span className="fa fa-camera"></span>
                  </label>
                </div> */}
                <div className="form-field select">
                  <select
                    id="featured"
                    className="form-input select"
                    name="featured"
                    value={featured}
                    onChange={handleOnChange}
                  >
                    <option value="true">Yes</option>
                    <option value="false">No</option>
                  </select>
                  <label htmlFor="province">
                    Featured
                    <IconCaretDown className="form-select-caret-down" />
                  </label>
                </div>
                <div className="form-field">
                  <input
                    id="price_points"
                    className="form-input"
                    type="text"
                    name="price_points"
                    value={price_points}
                    onChange={handleOnChange}
                    required
                  />
                  <label htmlFor="price_points">Price Points</label>
                  {errors.price_points && (
                    <span className="form-error">{errors.price_points}</span>
                  )}
                </div>
                <div className="form-btn-wrapper form-btn-dual-wrapper">
                  <input
                    type="button"
                    className="btn-d btn-gray"
                    value="Cancel"
                    onClick={hideModal}
                  ></input>
                  <input
                    type="submit"
                    className="btn-d btn-blue"
                    value="Save Changes"
                    disabled={disable}
                  />
                </div>
              </form>
            }
          />
        </Modal>
      </div>
    </main>
  );
}

export default ManageUsers;
