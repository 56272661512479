import React from 'react'
import { Link } from 'react-router-dom'

import Card from "../components/Card"
import '../stylesheets/main.scss'
import SuccessIcon from '../assets/images/success-check.png'

const cardInfo = { 
  hasMessage: true,
  message: 'You\'ve successfully reset you password. You can now log in with your new password',
}

function ResetSuccess(props) {

  // const { back } = props;

  return (
    <main className="login">
      <div className="card-body">
        <Card cardDetails={cardInfo} 
        icon={
          <img src={SuccessIcon} className="success-icon" alt="" />
        }
        form={
          <span>
            {/* {back} */}
            <Link to="/"><button className="btn-blue">Back to login</button></Link>
          </span>
        }/>
      </div>
    </main>
  )
}

export default ResetSuccess