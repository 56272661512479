import React, { useEffect } from "react";
import Card from "../components/Card";

const PaymentSuccess = ({ history }) => {
  // States
  // const [ paymentCode, setPaymentCode ] = useState('')

  // Variables
  const cardDetails = {
    title: "Congratulations!",
  };

  // Functions
  useEffect(() => {}, []);

  return (
    <main className="login">
      <div className="card-body">
        <Card
          cardDetails={cardDetails}
          form={
            <>
              <div className="form-wrapper">
                <p>
                  You are just 1 step ahead to become an Infinity Privilege
                  member.
                </p>
                <div className="review-wrapper">
                  <div className="review-holder">
                    <p>Your Sign up Referral Code: </p>
                    {/* <p>{ paymentCode }</p> */}
                  </div>
                </div>
                {/* <p>You will receive an email confirmation shortly.</p> */}
              </div>
              <button
                className="btn-blue"
                type="button"
                onClick={() => history.push("/")}
              >
                Go to Login
              </button>
            </>
          }
        />
      </div>
    </main>
  );
};

export default PaymentSuccess;
