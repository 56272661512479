import React, { useState, useEffect } from "react";
import { url_path } from "../../constant";
import useForm from "../../lib/hooks/useForm.js";
import Breadcrumbs from "../../components/Breadcrumbs";
import Table from "../../components/Table";
import Moment from "react-moment";
import "moment-timezone";
import axios from "axios";
import { authenticateAdminPage } from "../../lib/helpers/validation";

import Modal from "react-bootstrap/Modal";
import Card from "../../components/Card";
import { ReactComponent as IconCaretDown } from "../../assets/images/icon-caret-down.svg";
import {
  successNotif,
  errorNotifIcon,
} from "../../lib/helpers/notifications.js";

const partnerInfo = {
  title: "Partner Manual Payment",
  hasClose: true,
  hasMessage: false,
  customClassName: "no-margin no-shadow",
};

const encashInfo = {
  title: "Encash",
  hasClose: true,
  hasMessage: false,
  customClassName: "no-margin no-shadow",
};
const partnerHeading = [
  {
    Header: "Date",
    accessor: "date",
    Cell: (row) => (
      <span>{<Moment format="ll" date={row.original.created_at} />}</span>
    ),
  },
  {
    Header: "Business Partner",
    accessor: "business_partner_info.business_name",
  },
  {
    Header: "Amount To Pay",
    accessor: "statement_amount",
  },
  {
    Header: "Revenue Amount",
    accessor: "revenue_amount",
  },
  {
    Header: "Balance",
    accessor: "balance",
  },
  {
    Header: "Form of Payment",
    accessor: "form_of_payment",
  },
];

const cardHolderHeading = [
  {
    Header: "Date",
    accessor: "date",
    Cell: (row) => (
      <span>{<Moment format="ll" date={row.original.created_at} />}</span>
    ),
  },
  {
    Header: "Card Holder Name",
    id: "name",
    Cell: (row) =>
      row.original.user_info.user_first_name +
      " " +
      row.original.user_info.user_last_name,
  },
  {
    Header: "Card Number",
    accessor: "user_info.card_number",
  },
  {
    Header: "No. of Points to Encash",
    accessor: "encash_points",
  },
  {
    Header: "Service Fee",
    accessor: "service_charge",
  },
  {
    Header: "Net Amount to Pay",
    accessor: "net_amount",
  },
  {
    Header: "Form of Payment",
    accessor: "encash_method",
  },
];

const PaymentTransactions = (props) => {
  const [transactionData, setTransactionData] = useState([]);
  const [transactionPagerData, setTransactionPagerData] = useState({});

  const [partnerData, setPartnerData] = useState([]);
  // const [partnerPagerData, setPartnerPagerData] = useState({});

  const [businessPartnerData, setBusinessPartnerData] = useState([]);

  const [loader, setLoader] = useState(false);

  const [partnerModalState, setPartnerModalState] = useState(false);
  const [encashModalState, setEncashModalState] = useState(false);

  const [isButtonDisabled, setIsButtonDisabled] = useState(true);

  /*
   * Initialize input state schema
   */

  const stateSchema = {
    // for business partner
    business_partner: { value: "no selected partner", error: "" },
    amount_to_pay: { value: "", error: "" },
    revenue_amount: { value: "", error: "" },
    balance: { value: "", error: "" },
    form_of_payment: { value: "", error: "" },

    // for encashment
    card_number: { value: "", error: "" },
    points_to_encash: { value: "", error: "" },
    service_fee: { value: "", error: "" },
    net_amount_to_pay: { value: "", error: "" },
    encash_form_of_payment: { value: "", error: "" },
  };

  /*
   * Create a schema for all inputs
   */

  const stateValidatorSchema = {
    // for business partner
    business_partner: {
      required: true,
    },
    amount_to_pay: {
      required: true,
    },
    revenue_amount: {
      required: true,
    },
    balance: {
      required: true,
    },
    form_of_payment: {
      required: true,
    },

    // for encashment
    card_number: {
      required: true,
    },
    points_to_encash: {
      required: true,
    },
    service_fee: {
      required: true,
    },
    net_amount_to_pay: {
      required: true,
    },
    encash_form_of_payment: {
      required: true,
    },
  };

  const getData = async (pageNumber = 1, search = "") => {
    const req = {
      pageNumber,
      search,
    };

    // Get List of Partners
    axios
      .post(`${url_path}/api/business_partners`, req)
      .then((res) => {
        setBusinessPartnerData(res.data.business_partners);
        // setTransactionPagerData(res.data.paginate);
        // console.log(res.data);
        // setLoader(true);
      })
      .catch((err) => {
        console.log(err);
      });

    // setLoading(true);
    try {
      // Manual Payment
      axios
        .post(`${url_path}/api/transactions/get_payment_transactions`, req)
        .then((res) => {
          setPartnerData(res.data.manual_payment_request);
          // setTransactionPagerData(res.data.paginate);
          // console.log(res.data);
          setLoader(true);
        })
        .catch((err) => {
          console.log(err);
        });

      const { data } = await axios.post(`${url_path}/api/encash_points`, req);
      // console.log(data);
      setTransactionData(data.encash_request);
      setTransactionPagerData(data.paginate);
      // setLoading(false);
    } catch (err) {
      console.log(err);
    }
  };
  useEffect(() => {
    authenticateAdminPage(props);
    getData();
  }, []);

  // console.log(transactionData)

  const hidePartnerModal = () => {
    setPartnerModalState(false);
    values.amount_to_pay = "";
    values.revenue_amount = "";
    values.balance = "";
    values.form_of_payment = "";

    errors.amount_to_pay = "";
    errors.revenue_amount = "";
    errors.balance = "";
    errors.form_of_payment = "";
    // setCurrentEdit_ID("");
  };

  const showPartnerModalState = () => {
    setPartnerModalState(true);
  };

  const hideEncashModal = () => {
    setEncashModalState(false);
    values.card_number = "";
    values.points_to_encash = "";
    values.service_fee = "";
    values.net_amount_to_pay = "";
    values.encash_form_of_payment = "";

    errors.card_number = "";
    errors.points_to_encash = "";
    errors.service_fee = "";
    errors.net_amount_to_pay = "";
    errors.encash_form_of_payment = "";
    // setCurrentEdit_ID("");
  };

  // const showEncashModalState = () => {
  //   setEncashModalState(true);
  // };

  const onSubmitPartnerPayment = () => {
    // setEncashModalState(true);
    console.log(errors.amount_to_pay);
    const req = {
      type: "business_partner",
      business_partner,
      amount_to_pay,
      revenue_amount,
      balance,
      form_of_payment,
    };
    if (business_partner !== "no selected partner") {
      axios
        .post(`${url_path}/api/transactions/payment_transaction`, req)
        .then((res) => {
          console.log(res.data);
          successNotif("Success", res.data.data.msg);
          hidePartnerModal();
          getData();
          setLoader(true);
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      errorNotifIcon("Oops!", "please select business partner");
      // console.log("please select business partner");
    }

    // console.log(req);
  };

  const {
    values,
    errors,
    handleOnChange,
    // handleOnSubmitPartner,
    disable,
  } = useForm(stateSchema, stateValidatorSchema);

  const {
    // for business Partner
    business_partner,
    amount_to_pay,
    revenue_amount,
    balance,
    form_of_payment,

    //for encash
    card_number,
    points_to_encash,
    service_fee,
    net_amount_to_pay,
    encash_form_of_payment,
  } = values;

  const validateNumberInput = (event) => {
    const { key } = event;

    const validKeys = ["Backspace", "Delete", "ArrowLeft", "ArrowRight"];

    if (!key.match(/\d/) && !validKeys.includes(key)) {
      event.preventDefault();
    }

    if (
      amount_to_pay !== "" &&
      revenue_amount !== "" &&
      balance !== "" &&
      form_of_payment !== ""
    ) {
      setIsButtonDisabled(false);
    } else {
      setIsButtonDisabled(true);
    }
  };
  const checkFormOfPayment = (event) => {
    if (
      amount_to_pay !== "" &&
      revenue_amount !== "" &&
      balance !== "" &&
      form_of_payment !== ""
    ) {
      setIsButtonDisabled(false);
    } else {
      setIsButtonDisabled(true);
    }
  };

  return (
    <main className="manage-pages">
      <div className="container custom-container">
        <Breadcrumbs
          size="text-large"
          placeholder="Payment Transactions"
          targetLink="/admin-transactions"
          hasButton="false"
          customClassName="m-3"
        />

        <div className="section-table">
          {/* <div className="container-fluid"> */}
          {loader ? (
            <>
              <Table tableHeader={partnerHeading} tableData={partnerData} />
            </>
          ) : (
            <div className="lds-ellipsis">
              <div className="dots"></div>
              <div className="dots"></div>
              <div className="dots"></div>
              <div className="dots"></div>
            </div>
          )}
          {/* </div> */}
          <div className="payment-transactions-button right">
            <input
              type="button"
              className="btn-blue"
              value="New"
              onClick={showPartnerModalState}
              // disabled={disable}
            />
          </div>
        </div>
        <Modal
          show={partnerModalState}
          onHide={hidePartnerModal}
          className="modal-dark- opacity"
        >
          <Card
            cardDetails={partnerInfo}
            form={
              <form className="form-wrapper">
                <div className="form-field select">
                  <select
                    id="business_partner"
                    className="form-input select"
                    name="business_partner"
                    value={business_partner}
                    onChange={handleOnChange}
                    required
                  >
                    <option value="no selected partner">
                      Select Business Partner
                    </option>
                    {businessPartnerData.map((partner, index) => (
                      <option value={partner._id} key={index}>
                        {partner.business_name}
                      </option>
                    ))}
                  </select>
                  <label htmlFor="business_partner">
                    Business Partner
                    <IconCaretDown className="form-select-caret-down" />
                  </label>
                </div>
                <div className="form-field">
                  <input
                    id="amount_to_pay"
                    className="form-input"
                    type="text"
                    name="amount_to_pay"
                    value={amount_to_pay}
                    onChange={handleOnChange}
                    onKeyDown={validateNumberInput}
                    required
                  />
                  <label htmlFor="amount_to_pay">
                    Amount to Pay<span className="text-required">*</span>
                  </label>
                  {errors.amount_to_pay && (
                    <span className="form-error">{errors.amount_to_pay}</span>
                  )}
                </div>
                <div className="form-field">
                  <input
                    id="revenue_amount"
                    className="form-input"
                    type="text"
                    name="revenue_amount"
                    value={revenue_amount}
                    onChange={handleOnChange}
                    onKeyDown={validateNumberInput}
                    required
                  />
                  <label htmlFor="revenue_amount">
                    Revenue Amount<span className="text-required">*</span>
                  </label>
                  {errors.revenue_amount && (
                    <span className="form-error">{errors.revenue_amount}</span>
                  )}
                </div>

                <div className="form-field">
                  <input
                    id="balance"
                    className="form-input"
                    type="text"
                    name="balance"
                    value={balance}
                    onChange={handleOnChange}
                    onKeyDown={validateNumberInput}
                    required
                  />
                  <label htmlFor="balance">
                    Balance<span className="text-required">*</span>
                  </label>
                  {errors.balance && (
                    <span className="form-error">{errors.balance}</span>
                  )}
                </div>
                <div className="form-field">
                  <input
                    id="form_of_payment"
                    className="form-input"
                    type="text"
                    name="form_of_payment"
                    value={form_of_payment}
                    onChange={handleOnChange}
                    onKeyDown={checkFormOfPayment}
                    required
                  />
                  <label htmlFor="form_of_payment">
                    Form of Payment<span className="text-required">*</span>
                  </label>
                  {errors.form_of_payment && (
                    <span className="form-error">{errors.form_of_payment}</span>
                  )}
                </div>
                <div className="form-btn-wrapper form-btn-dual-wrapper">
                  <input
                    type="button"
                    className="btn-d btn-gray"
                    value="Cancel"
                    onClick={hidePartnerModal}
                  ></input>
                  <input
                    type="button"
                    className="btn-d btn-blue"
                    value="Save Changes"
                    onClick={onSubmitPartnerPayment}
                    disabled={isButtonDisabled}
                    // disabled={disable}
                  />
                </div>
              </form>
            }
          />
        </Modal>

        <div className="section-table">
          {/* <div className="container-fluid"> */}
          {loader ? (
            <>
              <Table
                tableHeader={cardHolderHeading}
                tableData={transactionData}
                // show={handleChangeCheckbox}
                paginate={transactionPagerData}
                pageFunction={getData}
              />
            </>
          ) : (
            <div className="lds-ellipsis">
              <div className="dots"></div>
              <div className="dots"></div>
              <div className="dots"></div>
              <div className="dots"></div>
            </div>
          )}
          {/* </div> */}
          {/* <div className="payment-transactions-button">
            <input
              type="button"
              className="btn-blue"
              value="New"
              onClick={showEncashModalState}
              // disabled={disable}
            />
          </div> */}
        </div>
        <Modal
          show={encashModalState}
          onHide={hideEncashModal}
          className="modal-dark- opacity"
        >
          <Card
            cardDetails={encashInfo}
            form={
              <form className="form-wrapper">
                <div className="form-field">
                  <input
                    id="card_number"
                    className="form-input"
                    type="text"
                    name="card_number"
                    value={card_number}
                    onChange={handleOnChange}
                    required
                  />
                  <label htmlFor="card_number">
                    Card Number<span className="text-required">*</span>
                  </label>
                  {errors.card_number && (
                    <span className="form-error">{errors.card_number}</span>
                  )}
                </div>
                <div className="form-field">
                  <input
                    id="points_to_encash"
                    className="form-input"
                    type="text"
                    name="points_to_encash"
                    value={points_to_encash}
                    onChange={handleOnChange}
                    required
                  />
                  <label htmlFor="points_to_encash">
                    No. Of Points to Encash
                    <span className="text-required">*</span>
                  </label>
                  {errors.points_to_encash && (
                    <span className="form-error">
                      {errors.points_to_encash}
                    </span>
                  )}
                </div>
                <div className="form-field">
                  <input
                    id="service_fee"
                    className="form-input"
                    type="text"
                    name="service_fee"
                    value={service_fee}
                    onChange={handleOnChange}
                    required
                  />
                  <label htmlFor="service_fee">
                    Service Fee<span className="text-required">*</span>
                  </label>
                  {errors.service_fee && (
                    <span className="form-error">{errors.service_fee}</span>
                  )}
                </div>

                <div className="form-field">
                  <input
                    id="net_amount_to_pay"
                    className="form-input"
                    type="text"
                    name="net_amount_to_pay"
                    value={net_amount_to_pay}
                    onChange={handleOnChange}
                    required
                  />
                  <label htmlFor="net_amount_to_pay">Net Amount to Pay</label>
                  {errors.net_amount_to_pay && (
                    <span className="form-error">
                      {errors.net_amount_to_pay}
                    </span>
                  )}
                </div>
                <div className="form-field">
                  <input
                    id="encash_form_of_payment"
                    className="form-input"
                    type="text"
                    name="encash_form_of_payment"
                    value={encash_form_of_payment}
                    onChange={handleOnChange}
                    required
                  />
                  <label htmlFor="encash_form_of_payment">
                    Form of Payment
                  </label>
                  {errors.encash_form_of_payment && (
                    <span className="form-error">
                      {errors.encash_form_of_payment}
                    </span>
                  )}
                </div>
                <div className="form-btn-wrapper form-btn-dual-wrapper">
                  <input
                    type="button"
                    className="btn-d btn-gray"
                    value="Cancel"
                    onClick={hideEncashModal}
                  ></input>
                  <input
                    type="submit"
                    className="btn-d btn-blue"
                    value="Save Changes"
                    disabled={disable}
                  />
                </div>
              </form>
            }
          />
        </Modal>
      </div>
    </main>
  );
};

export default PaymentTransactions;
