import React, { useState, useEffect } from "react";
import { url_path } from "../constant";
import Card from "../components/Card";
import "../stylesheets/main.scss";
import useForm from "../lib/hooks/useForm.js";
import { withRouter } from "react-router";
import axios from "axios";
import { successNotif } from "../lib/helpers/notifications.js";
import { errorNotifIcon } from "../lib/helpers/notifications.js";


function Login(props) {
  const [loader, setLoader] = useState(false);

  // const [inputs, setInputs] = useState({});
  const [notificationError, setNotificationError] = useState("");
  // const {cta, forgotPassword, close } = props;

  // const required = (value) => {
  //   if (!value.toString().trim().length) {
  //     return <span className="form-error is-visible">Required*</span>;
  //   }
  // };

  // const email = (value) => {
  //   if (!validator.isEmail(value)) {
  //     return <span className="form-error is-visible">{value} is not a valid email.</span>
  //   }
  // };

  useEffect(() => {
    setNotificationError("");
  }, []);

  // const [inputType, setInputType] = useState(true);

  // function togglePassword() {
  //   inputType ? setInputType(false) : setInputType(true);
  // }

  // const handleSubmit = () => {
  //   props.history.push('/admin-dashboard');
  // };

  // const iconShow = require("../assets/images/icon-show.png");
  // const iconHide = require("../assets/images/icon-hide.png");
  // const icon2 = require('../assets/images/image-tip.png')

  const cardInfo = {
    title: "Data Generator",
    hasMessage: true,
    message: "This page is for generating dummy users and default data!",
  };

  // const handleInputChange = (event) => {
  //   event.persist();
  //   console.log(event.target.name)
  //   console.log(event.target.value)
  //   let name = event.target.name
  //   let value = event.target.value
  //   setInputs(inputs => ({...inputs, [name]: value}));
  // }

  /*
   * Regex Validations to Inputs
   */

  //   var emailRegex = /\S+@\S+\.\S+/;

  /*
   * Initialize input state schema
   */

  const stateSchema = {
    referral_number: { value: "", error: "" },
    number_of_user: { value: "", error: "" },
  };

  const stateValidatorSchema = {
    referral_number: {
      required: true,
    },
    number_of_user: {
      required: false,
    },
  };
  function onSubmitForm(state) {
    setLoader(true);
    const { referral_number, number_of_user } = state;

    const req = {
      referral_number,
      number_of_user,
    };

    axios
      .post(`${url_path}/api/generate_default_data/generateCustomData`, req)
      .then((res) => {
        const userData = res.data;
        if (userData.data.status === "success") {
          setLoader(false);
          let title = "Well Done!";
          let message = "Data Generated";
          successNotif(title, message);
        } else {
          setLoader(false);
          let title = "Sorry!";
          let message = userData.data.msg;
          errorNotifIcon(title, message);
        }
      });

    return console.log(referral_number);
  }
  function handleGenerate() {
    setLoader(true);
    // console.log('generating')
    axios.post(`${url_path}/api/generate_default_data`).then((res) => {
      const userData = res.data;
      setLoader(false);
      if (userData.msg === "Done") {
        let title = "Well Done!";
        let message = "Data Generated";
        successNotif(title, message);
      } else {
        let title = "Sorry!";
        let message = "you already generated default data!";
        errorNotifIcon(title, message);
      }
    });
  }

  const { values, errors, handleOnChange, handleOnSubmit, disable } = useForm(
    stateSchema,
    stateValidatorSchema,
    onSubmitForm
  );

  const { referral_number, number_of_user } = values;

  return (
    <main>
      <div className="card-body">
        {!notificationError ? (
          ""
        ) : (
          <div className="section-notification">
            <div className="container">
              <div className="notification-holder">
                <p className="notification-text">{notificationError}</p>
              </div>
            </div>
          </div>
        )}
        {!loader ? (
          <Card
            cardDetails={cardInfo}
            form={
              <form className="form-wrapper" onSubmit={handleOnSubmit}>
                <div className="form-field">
                  <input
                    type="text"
                    name="referral_number"
                    className="form-input"
                    id="referral_number"
                    value={referral_number}
                    onChange={handleOnChange}
                    required
                  />
                  <label htmlFor="referral_number">Referral Number</label>
                  {errors.referral_number && (
                    <span className="form-error">{errors.referral_number}</span>
                  )}
                </div>
                <div className="form-field">
                  <input
                    type="text"
                    name="number_of_user"
                    className="form-input"
                    id="number_of_user"
                    value={number_of_user}
                    onChange={handleOnChange}
                    required
                  />
                  <label htmlFor="number_of_user">Number of User</label>
                  {errors.number_of_user && (
                    <span className="form-error">{errors.number_of_user}</span>
                  )}
                </div>

                <input
                  type="submit"
                  className="btn-blue"
                  disabled={disable}
                  value="Generate User"
                />
                <button className="btn-blue" onClick={handleGenerate}>
                  Generate Default Data
                </button>
              </form>
            }
          />
        ) : (
          <div className="lds-ellipsis">
            <div className="dots"></div>
            <div className="dots"></div>
            <div className="dots"></div>
            <div className="dots"></div>
          </div>
        )}
      </div>
    </main>
  );
}

export default withRouter(Login);
