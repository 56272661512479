import axios from "axios";
import BootstrapSwitchButton from "bootstrap-switch-button-react";
import "moment-timezone";
import React, { useEffect, useState } from "react";
import "react-accessible-accordion/dist/fancy-example.css";
import Modal from "react-bootstrap/Modal";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Moment from "react-moment";
import PhoneInput from "react-phone-input-2";
import { Link } from "react-router-dom";
import { ReactComponent as IconCalendar } from "../../assets/images/icon-calendar.svg";
import { ReactComponent as IconCaretDown } from "../../assets/images/icon-caret-down.svg";
import Breadcrumbs from "../../components/Breadcrumbs";
// import Sweetalert2 from "sweetalert2";
import Card from "../../components/Card";
import CardSelection from "../../components/CardSelection.js";
import Filter from "../../components/Filter";
import Table from "../../components/Table";
import { url_path } from "../../constant";
// import withReactContent from "sweetalert2-react-content";
import { errorNotifIcon, successNotif } from "../../lib/helpers/notifications";
import { authenticateAdminPage } from "../../lib/helpers/validation";
import useForm from "../../lib/hooks/useForm.js";

// const Swal = withReactContent(Sweetalert2);

const ManageMembers = (props) => {
  useEffect(() => {
    authenticateAdminPage(props);
  }, []);
  // States
  const [modalState, setModalState] = useState(false);
  const [dataVal, setDataVal] = useState([]);
  const [loading, setLoading] = useState(true);
  const [isButtonLoading, setIsButtonLoading] = useState(false);
  const [searchInput, setSearchInput] = useState("");
  const [pagerData, setPagerData] = useState([]);
  const [birthDate, setBirthDate] = useState();
  const [inputType, setInputType] = useState(true);
  const [gender, setGender] = useState("Male");
  const [title, setTitle] = useState("Mr");
  const [formOfPayment, setFormOfPayment] = useState("Cash");
  // const [referral, setReferral] = useState("");
  const [step, setStep] = useState(1);
  const [phone, setPhone] = useState();
  const [dialCode, setDialCode] = useState();
  const [filter, setFilter] = useState("Name");
  const [signUpDate, setSignUpDate] = useState({
    start: new Date(),
    end: new Date(),
  });
  const [card_selected, setSelectedCard] = useState();

  // Password Icons
  const iconShow = require("../../assets/images/icon-show.png");
  const iconHide = require("../../assets/images/icon-hide.png");

  // Form RegExp
  var nameRegex = /^[a-zA-Z ]+$/;
  var emailRegex = /\S+@\S+\.\S+/;
  var passwordMinChar = /^.{8,}$/;

  // State Schema
  const stateSchema = {
    // title
    first_name: { value: "", error: "" },
    middle_name: { value: "", error: "" },
    last_name: { value: "", error: "" },
    // birthdate
    // gender

    email: { value: "", error: "" },
    confirm_email: { value: "", error: "" },
    password: { value: "", error: "" },
    address: { value: "", error: "" },
    city: { value: "", error: "" },
    zip: { value: "", error: "" },
    province: { value: "", error: "" },
    country_code: { value: "", error: "" },
    status: { value: false, error: "" },
    // test: { value: "AC", error: "" },
    referal_code: { value: "", error: "" },
    promo_code: { value: "", error: "" },
    receipt_no: { value: "", error: "" },
    card_selected: { value: "", error: "" },
  };

  // Schema Validator
  const stateValidatorSchema = {
    first_name: {
      required: true,
      validator: {
        func: (value) => nameRegex.test(value),
        error: "Invalid first name format.",
      },
    },
    middle_name: { required: false },
    last_name: {
      required: true,
      validator: {
        func: (value) => nameRegex.test(value),
        error: "Invalid last name format.",
      },
    },
    confirm_email: {
      required: true,
      validator: {
        func: (value) => value === email,
        error: "Email addresses don't match",
      },
    },
    email: {
      required: true,
      validator: {
        func: (value) => emailRegex.test(value),
        // func: (value) => value === confirm_email,
        error: "Invalid email",
      },
    },
    password: {
      required: true,
      validator: {
        func: (value) => passwordMinChar.test(value),
        error: "Minimum of 8 characters.",
      },
    },
    address: { required: true },
    city: { required: true },
    zip: { required: false },
    province: { required: true },
    country_code: { required: true },
    referal_code: { required: true },
    promo_code: { required: false },
    status: { required: true },
    receipt_no: { required: true },
  };

  // Form Submit
  const onSubmit = (state) => {
    setIsButtonLoading(true);

    const {
      first_name,
      middle_name,
      last_name,
      email,
      password,
      address,
      city,
      zip,
      province,
      country_code,
      status,
      receipt_no,
      referal_code,
      promo_code,
    } = state;

    const user = {
      user_role: "Member",
      user_title: title,
      user_first_name: first_name,
      user_middle_name: middle_name,
      user_last_name: last_name,
      user_birthdate: birthDate,
      user_gender: gender,
      user_phone: phone,
      user_email: email,
      user_password: password,
      user_address: address,
      city: city,
      zip: zip,
      province: province,
      country_code: country_code,

      user_status: status,
      form_of_payment: formOfPayment,
      receipt_no,
      referal_code: referal_code,
      promo_code: promo_code,
      register_type: "manual",

      dial_code: dialCode,
      card_selected: card_selected,
    };

    if (!first_name || !last_name || !email || !password) {
      errorNotifIcon("Error Saving!", "One of the required fields is missing.");
      setIsButtonLoading(false);
      return;
    }

    axios
      .post(`${url_path}/api/users/add`, user)
      .then((res) => {
        if (res.data.data.status === "success") {
          let title = "Well Done!";
          let message = "New Member has been added successfully.";
          successNotif(title, message);
          handleHideModal();
          setIsButtonLoading(false);
          getMemberData(1, "");
        } else {
          setIsButtonLoading(false);
          let title = "Oops!";
          let message = res.data.data.msg;
          errorNotifIcon(title, message);
        }
      })
      .catch((err) => console.log(err));
  };

  // UseForm
  const { values, errors, handleOnChange, handleOnSubmit, disable } = useForm(
    stateSchema,
    stateValidatorSchema,
    onSubmit
  );

  const {
    first_name,
    middle_name,
    last_name,
    email,
    confirm_email,
    password,
    address,
    city,
    zip,
    province,
    country_code,
    receipt_no,
    referal_code,
    promo_code,
  } = values;

  var status = values.status.toString();

  // Table Header
  const heading = [
    {
      Header: "No",
      id: "no",
      maxWidth: 50,
      filterable: false,
      Cell: (row) => {
        return <> {row.index + 1} </>;
      },
    },
    {
      Header: "Name",
      id: "name",
      Cell: (row) => {
        return (
          <Link to={"/member-profile/" + row.original.user_id._id}>
            {row.original.user_id.user_first_name +
              " " +
              row.original.user_id.user_last_name}
          </Link>
        );
      },
    },
    {
      Header: "Card No",
      maxWidth: 150,
      accessor: "user_id.card_number",
    },
    {
      Header: "Referral Code",
      maxWidth: 130,
      accessor: "user_id.reference_number",
    },
    {
      Header: "Category",
      accessor: "user_tier.tier_category_id.category_name",
    },
    {
      Header: "Tier",
      maxWidth: 80,
      accessor: "user_tier.tier_id.tier_level",
    },
    {
      Header: "Referred by",
      Cell: (row) =>
        row.original.referred_by.user_role === "Administrator" ? (
          row.original.referred_by.user_first_name +
          " " +
          row.original.referred_by.user_last_name
        ) : (
          <Link to={"/member-profile/" + row.original.referred_by._id}>
            {row.original.referred_by.user_first_name +
              " " +
              row.original.referred_by.user_last_name}
          </Link>
        ),
    },
    {
      Header: "Method of Payment",
      accessor: "transaction.form_of_payment",
    },
    {
      Header: "Member Since",
      id: "subscription_start",
      Cell: (row) => (
        <span>
          {<Moment format="ll" date={row.original.subscription_start} />}
        </span>
      ),
    },
    {
      Header: "Renewal Date",
      id: "subscribed_renewed",
      Cell: (row) => (
        <span>
          {<Moment format="ll" date={row.original.subscribed_renewed} />}
        </span>
      ),
    },
    {
      Header: "Card Validity",
      id: "subscribed_expiration",
      Cell: (row) => (
        <span>
          {<Moment format="ll" date={row.original.subscribed_expiration} />}
        </span>
      ),
    },
    {
      Header: "Status",
      width: 250,
      maxWidth: 250,
      id: "user_id.user_status",
      Cell: (row) => (
        <div className="featured-button-holder">
          <span>Deactivate</span>
          <BootstrapSwitchButton
            checked={row.original.user_id.user_status}
            onlabel=" "
            offlabel=" "
            onChange={(checked) =>
              handleUserStatus(row.original.user_id._id, checked)
            }
          />
          <span>Activate</span>
        </div>
      ),
    },
  ];
  const handleUserStatus = (user_id, status) => {
    const req = {
      user_id,
      status,
    };
    // return console.log(req)

    axios.patch(`${url_path}/api/member/status_update`, req).then((res) => {
      console.log(res);
      let title = "Well Done!";
      let msg = status
        ? "Account has been Activated."
        : "Account has been Deactivated.";
      successNotif(title, msg);
      getMemberData(1, "");
    });
  };
  // console.log(Date.now)

  // Card info
  const cardInfo = {
    title: "Create New Member",
    customClassName: "no-margin no-shadow",
  };

  const getMemberData = (pageNumber = 1, search = "") => {
    setLoading(true);

    const req = { pageNumber, search };
    axios
      .post(`${url_path}/api/userSubscription`, req)
      .then((response) => {
        setDataVal(response.data.users);
        setPagerData(response.data.paginate);
        setLoading(false);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  useEffect(() => {
    getMemberData(1, "");
  }, []);

  // Search
  const handleSearchInput = (event) => {
    setSearchInput(event.target.value);
  };

  const handleSearchSubmit = (event) => {
    setLoading(true);
    event.preventDefault();
    getMemberData(1, searchInput);
  };

  const handleResetSearch = () => {
    setSearchInput("");
    setLoading(true);
    getMemberData(1, "");
  };

  const togglePassword = () => {
    inputType ? setInputType(false) : setInputType(true);
  };

  // Show/Hide Modal
  const handleShowModal = () => {
    setModalState(true);

    values.first_name = "";
    values.middle_name = "";
    values.last_name = "";
    values.phone_number = "";
    values.email = "";
    values.password = "";
    values.address = "";
    values.city = "";
    values.zip = "";
    values.provice = "";
    values.country_code = "";
    values.birthdate = "";
    values.status = false;
    values.receipt_no = "";
    values.referal_code = "";
    values.promo_code = "";

    errors.first_name = "";
    errors.middle_name = "";
    errors.last_name = "";
    errors.phone_number = "";
    errors.email = "";
    errors.password = "";
    errors.address = "";
    errors.city = "";
    errors.zip = "";
    errors.province = "";
    errors.country_code = "";
    errors.birthdate = "";
    errors.status = "";
    errors.receipt_no = "";
    errors.referal_code = "";
    errors.promo_code = "";
  };

  const handleHideModal = () => {
    setModalState(false);
  };

  // DatePicker
  const handleDateChangeRaw = (e) => {
    e.preventDefault();
  };
  const handleNextStep = () => {
    setStep(2);
  };
  const handlePrevStep = () => {
    setStep(1);
  };

  // phone input
  const handlePhoneChanges = (value, data, event) => {
    setPhone(value);
    setDialCode(data.dialCode);
  };


  const step1ButtonDisabled = () => {
    if(!first_name || !last_name || !birthDate || !phone || !email || !confirm_email) {
      return true
    } else {
      return false
    }
  }


  return (
    <main className="manage-pages">
      <div className="container custom-container">
        <Breadcrumbs
          size="text-large"
          placeholder="Manage Members"
          targetLink="/admin-dashboard"
          customClassName="m-3"
          hasButton="true"
          buttonTitle="+ New Member"
          propsFunction={handleShowModal}
        />

        <Filter
          filterDetails={{ title: "Filter" }}
          content={
            <div className="form-wrapper container">
              <div className="row container mb-3">
                Select by date:
              </div>
              <div className="filter-date-holder row mb-4">
                <div className="col-4">
                  <DatePicker
                    selected={signUpDate.start}
                    onChange={(date) =>
                      setSignUpDate({
                        ...signUpDate,
                        start: date,
                      })
                    }
                    selectsEnd
                    dateFormat="MMM dd, yyyy"
                    onChangeRaw={(e) => e.preventDefault()}
                    className="form-input date"
                    name="signUpStartDate"
                    placeholderText="Select a date between today and 5 days in the future"
                    // placeholderText={date}
                    autoComplete="off"
                    id="signUpStartDate"
                    required
                  />
                </div>
                <div className="col-4">
                  <DatePicker
                    selected={signUpDate.end}
                    onChange={(date) =>
                      setSignUpDate({
                        ...signUpDate,
                        end: date,
                      })
                    }
                    selectsEnd
                    dateFormat="MMM dd, yyyy"
                    onChangeRaw={(e) => e.preventDefault()}
                    className="form-input date"
                    name="signUpEndDate"
                    placeholderText="Select a date between today and 5 days in the future"
                    // placeholderText={date}
                    autoComplete="off"
                    id="signUpEndDate"
                    required
                  />
                </div>
                
                
              </div>
              <div className="row container mb-3">
                Sort by:
              </div>
              <div className="radio-wrapper row">
                <div className="form-field">
                  <label className="radio">
                    <input
                      type="radio"
                      checked={filter === "Sign up"}
                      onChange={() => setFilter("Sign up")}
                    />
                    <span className="checkmark"></span>
                  </label>
                  <p className="radio-text">Sign up date</p>
                </div>
                
                <div className="form-field">
                  <label className="radio">
                    <input
                      type="radio"
                      checked={filter === "Renewal"}
                      onChange={() => setFilter("Renewal")}
                    />
                    <span className="checkmark"></span>
                  </label>
                  <p className="radio-text">Renewal Date</p>
                </div>
                
                <div className="form-field">
                  <label className="radio">
                    <input
                      type="radio"
                      checked={filter === "Reference Number"}
                      onChange={() => setFilter("Reference Number")}
                    />
                    <span className="checkmark"></span>
                  </label>
                  <p className="radio-text">Reference Number</p>
                </div>
              </div>
            </div>
          }
        />

        <div className="section-table">
          {/* <div className="container-fluid"> */}
          <form onSubmit={handleSearchSubmit}>
            <div className="search-holder">
              <label htmlFor="searchInput">Search: </label>
              <input
                size="large"
                name="searchInput"
                label="Search"
                value={searchInput}
                onChange={handleSearchInput}
                className="search-input"
              />
              <button type="submit" className="btn-blue">
                Search
              </button>
              <button
                type="reset"
                className="btn-blue"
                onClick={handleResetSearch}
              >
                Clear
              </button>
            </div>
          </form>
          {loading ? (
            <div className="lds-ellipsis">
              <div className="dots"></div>
              <div className="dots"></div>
              <div className="dots"></div>
              <div className="dots"></div>
            </div>
          ) : (
            <>
              <Table
                tableHeader={heading}
                tableData={dataVal}
                paginate={pagerData}
                pageFunction={getMemberData}
              />
            </>
          )}
          {/* </div> */}
        </div>
        <Modal
          show={modalState}
          onHide={handleHideModal}
          className="modal-dark- opacity"
        >
          <Card
            cardDetails={cardInfo}
            form={
              <form className="form-wrapper">
                {step === 1 ? (
                  <>
                    <div className="form-field select">
                      <select
                        id="title"
                        className="form-input select"
                        name="title"
                        value={title}
                        onChange={(event) => setTitle(event.target.value)}
                      >
                        <option value=""></option>
                        <option value="Mr">Mr.</option>
                        <option value="Mrs">Mrs.</option>
                        <option value="Ms">Ms</option>
                        <option value="Miss">Miss</option>
                      </select>
                      <label htmlFor="title">
                        Title
                        <IconCaretDown className="form-select-caret-down" />
                      </label>
                    </div>
                    <div className="form-field">
                      <input
                        id="first_name"
                        className="form-input"
                        type="text"
                        name="first_name"
                        value={first_name}
                        onChange={handleOnChange}
                        required
                      />
                      <label htmlFor="first_name">
                        First name<span className="text-required">*</span>
                      </label>
                      {errors.first_name && (
                        <span className="form-error">{errors.first_name}</span>
                      )}
                    </div>
                    <div className="form-field">
                      <input
                        id="middle_name"
                        className={`form-input ${
                          !middle_name ? "not-required" : ""
                        }`}
                        type="text"
                        name="middle_name"
                        value={middle_name}
                        onChange={handleOnChange}
                      />
                      <label htmlFor="middle_name">Middle name</label>
                      {errors.middle_name && (
                        <span className="form-error">{errors.middle_name}</span>
                      )}
                    </div>
                    <div className="form-field">
                      <input
                        id="last_name"
                        className="form-input"
                        type="text"
                        name="last_name"
                        value={last_name}
                        onChange={handleOnChange}
                        required
                      />
                      <label htmlFor="last_name">
                        Last name<span className="text-required">*</span>
                      </label>
                      {errors.last_name && (
                        <span className="form-error">{errors.last_name}</span>
                      )}
                    </div>
                    <div className="form-field calendar">
                      <label htmlFor="startDate">
                        Date of Birth<span className="text-required">*</span>
                        <IconCalendar className="form-input-calendar" />
                      </label>
                      <DatePicker
                        selected={birthDate}
                        onChange={(date) => setBirthDate(date)}
                        peekNextMonth
                        showMonthDropdown
                        showYearDropdown
                        dropdownMode="select"
                        // selectsStart
                        // dateFormat="MMM dd, yyyy"
                        // startDate={startDate}
                        // endDate={endDate}
                        // maxDate={endDate}
                        onChangeRaw={handleDateChangeRaw}
                        className="form-input date"
                        name="startDate"
                        placeholderText="Oct 29, 2019"
                        autoComplete="off"
                        id="startDate"
                        required
                      />
                    </div>

                    <div className="form-field select">
                      <select
                        id="gender"
                        className="form-input select"
                        name="gender"
                        value={gender}
                        onChange={(event) => setGender(event.target.value)}
                      >
                        <option value="Male">Male</option>
                        <option value="Female">Female</option>
                      </select>
                      <label htmlFor="gender">
                        Gender
                        <IconCaretDown className="form-select-caret-down" />
                      </label>
                    </div>
                    <div className="form-field phone">
                      <PhoneInput
                        id="phone"
                        inputClass={["form-input"]}
                        enableAreaCodes={true}
                        autoFormat={true}
                        country={"ph"}
                        // regions={"asia"}
                        value={phone}
                        onChange={handlePhoneChanges}
                        required
                      />
                      <label htmlFor="phone">
                        Phone<span className="text-required">*</span>
                      </label>
                      {/* <input
                          id="tel"
                          className="form-input"
                          type="number"
                          name="tel"
                          value={tel}
                          onChange={handleOnChange}
                          required
                        /> */}
                    </div>
                    <div className="form-field">
                      <input
                        id="email"
                        className="form-input"
                        type="text"
                        name="email"
                        value={email}
                        onChange={handleOnChange}
                        required
                      />
                      <label htmlFor="email">
                        Email<span className="text-required">*</span>
                      </label>
                      {errors.email && (
                        <span className="form-error">{errors.email}</span>
                      )}
                    </div>
                    <div className="form-field">
                      <input
                        id="confirm_email"
                        className="form-input"
                        type="text"
                        name="confirm_email"
                        value={confirm_email}
                        onChange={handleOnChange}
                        required
                      />
                      <label htmlFor="confirm_email">Confirm Email</label>
                      {errors.confirm_email && (
                        <span className="form-error">
                          {errors.confirm_email}
                        </span>
                      )}
                    </div>

                    <div className="form-field">
                      <br></br>
                    </div>
                    <CardSelection
                      card_selected={card_selected}
                      onChangeCard={(e) => setSelectedCard(e.target.value)}
                    ></CardSelection>
                    <div className="form-field">
                      <br></br>
                    </div>

                    <div className="form-btn-wrapper form-btn-dual-wrapper">
                      <input
                        type="button"
                        className="btn-d btn-gray"
                        value="Cancel"
                        onClick={handleHideModal}
                      ></input>
                      <input
                        type="button"
                        className={step1ButtonDisabled() === true ? "btn-gray disabled btn-d disabled" : "btn-d btn-blue"}
                        value="Continue"
                        onClick={handleNextStep}
                        disabled={step1ButtonDisabled()}
                      ></input>
                    </div>
                  </>
                ) : (
                  <>
                    <div className="form-field">
                      <input
                        id="password"
                        className={`form-input ${
                          !password ? "not-required" : ""
                        }`}
                        type={inputType ? "password" : "text"}
                        name="password"
                        value={password}
                        onChange={handleOnChange}
                      />
                      <label htmlFor="password">
                        Password<span className="text-required">*</span>
                      </label>
                      <span className="password__show" onClick={togglePassword}>
                        <img src={inputType ? iconHide : iconShow} alt="" />
                      </span>
                      {errors.password && (
                        <span className="form-error">{errors.password}</span>
                      )}
                    </div>
                    <div className="form-field">
                      <input
                        id="address"
                        className="form-input"
                        type="text"
                        name="address"
                        value={address}
                        onChange={handleOnChange}
                        required
                      />
                      <label htmlFor="address">
                        Address<span className="text-required">*</span>
                      </label>
                      {errors.address && (
                        <span className="form-error">{errors.address}</span>
                      )}
                    </div>
                    <div className="form-group">
                      <div className="form-field">
                        <input
                          id="city"
                          type="text"
                          className="form-input"
                          name="city"
                          value={city}
                          onChange={handleOnChange}
                          required
                        />
                        <label htmlFor="city">
                          City<span className="text-required">*</span>
                        </label>
                      </div>
                      {/* <div className="form-field">
                        <input
                          id="city"
                          className="form-input"
                          type="text"
                          name="city"
                          value={city}
                          onChange={handleOnChange}
                          required
                        />
                        <label htmlFor="city">
                          City<span className="text-required">*</span>
                        </label>
                        {errors.city && (
                          <span className="form-error">{errors.city}</span>
                        )}
                      </div> */}
                      <div className="form-field">
                        <input
                          id="zip"
                          className={`form-input ${!zip ? "not-required" : ""}`}
                          type="text"
                          name="zip"
                          value={zip}
                          onChange={handleOnChange}
                        />
                        <label htmlFor="zip">Zip</label>
                        {errors.zip && (
                          <span className="form-error">{errors.zip}</span>
                        )}
                      </div>
                    </div>
                    <div className="form-group">
                      <div className="form-field">
                        <input
                          id="province"
                          type="text"
                          className="form-input"
                          name="province"
                          value={province}
                          onChange={handleOnChange}
                          required
                        />
                        <label htmlFor="province">
                          Province<span className="text-required">*</span>
                        </label>
                      </div>
                      {/* <div className="form-field">
                        <input
                          id="province"
                          type="text"
                          className="form-input"
                          name="province"
                          value={province}
                          onChange={handleOnChange}
                          required
                        />
                        <label htmlFor="province">
                          Province<span className="text-required">*</span>
                        </label>
                        {errors.province && (
                          <span className="form-error">
                            {errors.province}
                          </span>
                        )}
                      </div> */}
                      <div className="form-field">
                        <input
                          id="country_code"
                          type="text"
                          className="form-input"
                          name="country_code"
                          value={country_code}
                          onChange={handleOnChange}
                          required
                        />
                        <label htmlFor="country_code">
                          Country<span className="text-required">*</span>
                        </label>
                        {errors.country_code && (
                          <span className="form-error">
                            {errors.country_code}
                          </span>
                        )}
                      </div>
                    </div>
                    <div className="form-field">
                      <input
                        id="referal_code"
                        className="form-input"
                        type="text"
                        name="referal_code"
                        value={referal_code}
                        onChange={handleOnChange}
                        required
                      />
                      <label htmlFor="referal_code">
                        Referral Code<span className="text-required">*</span>
                      </label>
                      {/* <small className="text-muted b">
                          (If you don"t have a referral code, please email us at{" "}
                          <a
                            href="mailto:referals@infinityprilege.com"
                            className="text-primary"
                          >
                            referrals@infinityprivilege.com
                          </a>{" "}
                          or send a message to this #)
                        </small> */}
                    </div>
                    <div className="form-field">
                      <input
                        id="promo_code"
                        // className="form-input"
                        className={`form-input ${
                          !promo_code ? "not-required" : ""
                        }`}
                        type="text"
                        name="promo_code"
                        value={promo_code}
                        onChange={handleOnChange}
                      />
                      <label htmlFor="promo_code">Promo Code( Optional )</label>
                      {/* <small className="text-muted b">
                          (If you don"t have a referral code, please email us at{" "}
                          <a
                            href="mailto:referals@infinityprilege.com"
                            className="text-primary"
                          >
                            referrals@infinityprivilege.com
                          </a>{" "}
                          or send a message to this #)
                        </small> */}
                    </div>
                    <div className="form-field select">
                      <select
                        id="form_of_payment"
                        className="form-input select"
                        name="form_of_payment"
                        value={formOfPayment}
                        onChange={(event) =>
                          setFormOfPayment(event.target.value)
                        }
                      >
                        <option value="Cash">Cash</option>
                        <option value="Credit/Debit">Credit/Debit</option>
                        <option value="GCash">G-Cash</option>
                      </select>
                      <label htmlFor="province">
                        Form of Payment
                        <IconCaretDown className="form-select-caret-down" />
                      </label>
                    </div>
                    <div className="form-field">
                      <input
                        id="receipt_no"
                        className="form-input"
                        type="text"
                        name="receipt_no"
                        value={receipt_no}
                        onChange={handleOnChange}
                        required
                      />
                      <label htmlFor="receipt_no">
                        Receipt No<span className="text-required">*</span>
                      </label>
                      {errors.receipt_no && (
                        <span className="form-error">{errors.receipt_no}</span>
                      )}
                    </div>
                    <div className="form-field select">
                      <select
                        id="status"
                        className="form-input select"
                        name="status"
                        value={status}
                        onChange={handleOnChange}
                      >
                        <option value="true">Active</option>
                        <option value="false">Inactive</option>
                      </select>
                      <label htmlFor="province">
                        Status
                        <IconCaretDown className="form-select-caret-down" />
                      </label>
                    </div>
                    <div className="form-btn-wrapper form-btn-dual-wrapper">
                      <input
                        type="button"
                        className="btn-d btn-gray"
                        value="Back"
                        onClick={handlePrevStep}
                      ></input>
                      {isButtonLoading ? (
                        <button class="btn-d-loading btn-blue-loading disabled">
                          <span class="load open"></span> Processing...
                        </button>
                      ) : (
                        <input
                          type="button"
                          className="btn-d btn-blue"
                          value="Submit"
                          onClick={handleOnSubmit}
                          disabled={disable}
                        />
                      )}
                    </div>
                  </>
                )}
              </form>
            }
          />
        </Modal>
      </div>
    </main>
  );
};

export default ManageMembers;
