import React, { useEffect, useState } from "react";
import { url_path } from "../../constant";
import Breadcrumbs from "../../components/Breadcrumbs";
import axios from "axios";
import { getCookie } from "../../lib/helpers/cookies";
import moment from "moment";
import Card from "../../components/Card";
import Modal from "react-bootstrap/Modal";
import { successNotif, errorNotifIcon } from "../../lib/helpers/notifications";
import Sidebar from "../../components/Sidebar";
import { MdClose } from "react-icons/md";
import { authenticateMemberPage } from "../../lib/helpers/validation";
const PesonalData = ({ history }) => {
  useEffect(() => {
    authenticateMemberPage(history);
  }, []);
  // States
  const [personalData, setPersonalData] = useState({});
  const [loading, setLoading] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const [occupation, setOccupation] = useState("");
  const [position, setPosition] = useState("");
  const [company, setCompany] = useState("");

  // Variables
  const cardDetails = {
    title: "Personal Data",
    customClassName: "",
  };

  const editDetails = {
    title: "",
    customClassName: "no-margin no-shadow",
  };

  // const [show, setShow] = useState(false);

  // const handleClose = () => setShow(false);
  // const handleShow = () => setShow(true);

  // Functions
  const getData = async () => {
    const token = getCookie("token");
    setLoading(true);

    const { data: res_user } = await axios.get(`${url_path}/api/auth`, {
      headers: { "x-auth-token": token },
    });
    const { _id: user_id } = res_user.user;

    const { data: personal_data } = await axios.get(
      `${url_path}/api/member_additional_personal_data/${user_id}`
    );
    personal_data.user_id = user_id;

    setPersonalData(personal_data);
    setLoading(false);
    // console.log(personal_data)
  };

  const handleOnSubmit = async (event) => {
    event.preventDefault();

    const req = {
      occupation,
      position,
      company_name: company,
    };
    try {
      const { data: res } = await axios.put(
        `${url_path}/api/member_additional_personal_data/${personalData.user_id}`,
        req
      );
      if (res.data.status === "success") {
        getData();
        let title = "Awesome!";
        let msg = res.data.msg;
        successNotif(title, msg);
      } else {
        let title = "Oops!";
        let msg = "There's an error while updating your details.";
        errorNotifIcon(title, msg);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const handleShowModal = () => {
    setOccupation(personalData.occupation);
    setPosition(personalData.position);
    setCompany(personalData.company_name);
    setShowModal(true);
  };

  const handleHideModal = () => {
    setShowModal(false);
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <main className="member-data member-dashboard">
      <Sidebar history={history} />
      <div className="content-wrapper">
        <Breadcrumbs
          size="text-large"
          placeholder="Back"
          targetLink="/my-infinity-account"
          hasButton="false"
        />
        {loading ? (
          <div className="lds-ellipsis">
            <div className="dots"></div>
            <div className="dots"></div>
            <div className="dots"></div>
            <div className="dots"></div>
          </div>
        ) : (
          <Card
            cardDetails={cardDetails}
            form={
              <div className="account-form">
                <div className="form-wrapper">
                  <div className="form-field">
                    <input
                      type="text"
                      name="cardNum"
                      className="form-input"
                      id="cardNum"
                      defaultValue={personalData.card_number}
                      readOnly
                      disabled
                    />
                    <label htmlFor="cardNum">
                      Infinity Privilege Card Number
                    </label>
                  </div>
                  <div className="form-field">
                    <input
                      type="text"
                      name="referenceNum"
                      className="form-input"
                      id="referenceNum"
                      defaultValue={personalData.reference_number}
                      readOnly
                      disabled
                    />
                    <label htmlFor="referenceNum">
                      Infinity Privilege Referral code
                    </label>
                  </div>
                  <div className="form-field">
                    <input
                      type="text"
                      name="firstName"
                      className="form-input"
                      id="firstName"
                      defaultValue={personalData.user_first_name}
                      readOnly
                      disabled
                    />
                    <label htmlFor="firstName">First Name</label>
                  </div>
                  <div className="form-field">
                    <input
                      type="text"
                      name="lastName"
                      className="form-input"
                      id="lastName"
                      defaultValue={personalData.user_last_name}
                      readOnly
                      disabled
                    />
                    <label htmlFor="lastName">Last Name</label>
                  </div>
                  <div className="form-field">
                    <input
                      type="text"
                      name="birthdate"
                      className="form-input"
                      id="birthdate"
                      defaultValue={moment(personalData.user_birthdate).format(
                        "LL"
                      )}
                      readOnly
                      disabled
                    />
                    <label htmlFor="birthdate">Date of Birth</label>
                  </div>
                  <p className="text-bold">Additional personal data</p>
                  <div className="form-field">
                    <input
                      type="text"
                      name="occupation"
                      className="form-input"
                      id="occupation"
                      defaultValue={personalData.occupation}
                      readOnly
                      disabled
                    />
                    <label htmlFor="occupation">Occupation</label>
                  </div>
                  <div className="form-field">
                    <input
                      type="text"
                      name="position"
                      className="form-input"
                      id="position"
                      defaultValue={personalData.position}
                      readOnly
                      disabled
                    />
                    <label htmlFor="position">Position</label>
                  </div>
                  <div className="form-field">
                    <input
                      type="text"
                      name="companyName"
                      className="form-input"
                      id="companyName"
                      defaultValue={personalData.company_name}
                      readOnly
                      disabled
                    />
                    <label htmlFor="companyName">Company Name</label>
                  </div>
                  <div className="btn-holder">
                    <button className="btn-blue" onClick={handleShowModal}>
                      Edit
                    </button>
                  </div>
                </div>
              </div>
            }
          />
        )}
      </div>

      <Modal
        show={showModal}
        onHide={handleHideModal}
        className="modal-dark- opacity edit-modal"
      >
        <Card
          cardDetails={editDetails}
          form={
            <>
              <div className="heading-wrapper">
                <p className="heading">Edit additional personal details</p>
                <p className="heading-close" onClick={handleHideModal}>
                  <MdClose />{" "}
                </p>
              </div>
              <form className="form-wrapper" onSubmit={handleOnSubmit}>
                <div className="form-field">
                  <input
                    type="text"
                    name="occupation"
                    className="form-input"
                    id="occupation"
                    value={occupation}
                    onChange={(e) => setOccupation(e.target.value)}
                    required
                  />
                  <label htmlFor="occupation">Occupation</label>
                </div>
                <div className="form-field">
                  <input
                    type="text"
                    name="position"
                    className="form-input"
                    id="position"
                    value={position}
                    onChange={(e) => setPosition(e.target.value)}
                    required
                  />
                  <label htmlFor="position">Position</label>
                </div>
                <div className="form-field">
                  <input
                    type="text"
                    name="companyName"
                    className="form-input"
                    id="companyName"
                    value={company}
                    onChange={(e) => setCompany(e.target.value)}
                    required
                  />
                  <label htmlFor="companyName">Company Name</label>
                </div>
                <div className="btn-holder">
                  <button
                    type="submit"
                    className="btn-blue"
                    onClick={handleHideModal}
                  >
                    Save
                  </button>
                </div>
              </form>
            </>
          }
        />
      </Modal>
    </main>
  );
};

export default PesonalData;
