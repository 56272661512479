import React, { useState, useEffect } from "react";
import { url_path } from "../constant";
import { 
  Card,
  Col,
  Row,
  Navbar,
  Nav,
  Form,
  FormControl,
  ListGroup,
  Button,
  Dropdown,
  Image,
  Modal,
} from "react-bootstrap";
import "../App.css";
import axios from "axios";
import list from '../assets/images/list.png'
import grid from '../assets/images/menu.png'
import map from '../assets/images/map-marker.png'

function Partners(props) {
    const [locale, setLocale] = useState([]);
    const [sortBP, setSortBP] = useState();
    const [resultPartners, setResultPartners] = useState([]);
    const [filterResultPartners, setFilterResultPartners] = useState([]);
    const [viewtype, setViewType] = useState('grid');
    const [searchInput, setSearchInput] = useState('');
    const [categorySort, setCategorySort] = useState('')
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    useEffect(() => {
      var data = {
        query: '',
        locale: '',
        category: sortBP === 'All' ? '' : sortBP,
      };
      var config = {
        method: 'post',
        url: `${url_path}/api/carousel-images/find`,
        data: data,
        // /api/carousel-images/locale
      };

      var config2 = {
        method: 'get',
        url: `${url_path}/api/carousel-images/locale`,
      };
      axios(config)
        .then(response => {
          setResultPartners(response.data.totalDocuments);
          setFilterResultPartners(response.data.totalDocuments);
        })
        .catch(function(error) {
          console.log('bp_data', error);
        });
      axios(config2)
        .then(response => {
          setLocale(response.data.locales);
        })
        .catch(function(error) {
          console.log('bp_data', error);
        });
      }, []);
      
      
      var searchFilter = filterResultPartners.filter(sortItem =>
        sortItem.business_name.toLowerCase() && sortItem.business_name.toLowerCase().includes(searchInput.toLowerCase())
      );

      var filteredItems = searchFilter.filter(sortItem => 
        sortItem.business_category_id.category_main_type && sortItem.business_category_id.category_main_type.includes(categorySort)
      );
      return (
        <>
          <Modal show={show} onHide={handleClose} scrollable>
            <Modal.Header>
              <Modal.Title >Select Locale</Modal.Title>
              <span onClick={handleClose} style={{cursor:'pointer'}}>x</span>
            </Modal.Header>
            <Modal.Body style={{height: '500px'}}>
            <ListGroup>
            <ListGroup.Item onClick={() => {
              const localFiltered = resultPartners.filter(
                sortItem => sortItem.city && sortItem.city.includes(""),
              );
              setFilterResultPartners(localFiltered);
              handleClose();
            }}>All</ListGroup.Item>
              {
                locale.map((result) => {
                  return (
                    <>
                      <ListGroup.Item onClick={() => {
                        const localFiltered = resultPartners.filter(
                          sortItem => sortItem.city && sortItem.city.includes(result),
                        );
                        setFilterResultPartners(localFiltered);
                        handleClose();
                      }}>{result}</ListGroup.Item>
                    </>
                  )
                })
              }
              </ListGroup>
            </Modal.Body>
          </Modal>
          <Navbar expand="lg">
            <>
              <Navbar.Toggle aria-controls="navbarScroll" />
              <Navbar.Collapse id="navbarScroll">
                <Nav
                  className="me-auto my-2 my-lg-0 nav-fill p-4"
                  style={{alignItems:'center'}}
                  navbarScroll
                  
                >
                  <Nav.Link className="hover-underline-animation" onClick={() =>{setCategorySort('')}}>All</Nav.Link>
                  <Nav.Link className="hover-underline-animation" onClick={() =>{setCategorySort('Food & Dining')}}>Food & Dining</Nav.Link>
                  <Nav.Link className="hover-underline-animation" onClick={() =>{setCategorySort('Beauty & Wellness')}}>Beauty & Wellness</Nav.Link>
                  <Nav.Link className="hover-underline-animation" onClick={() =>{setCategorySort('Shopping & Retail')}}>Shopping & Retail</Nav.Link>
                  <Nav.Link className="hover-underline-animation" onClick={() =>{setCategorySort('Hotel & Accommodation')}}>Hotel & Accommodation</Nav.Link>
                  <Nav.Link className="hover-underline-animation" onClick={() =>{setCategorySort('Automobiles')}}>Automobiles</Nav.Link>
                  <Nav.Link className="hover-underline-animation" onClick={() =>{setCategorySort('Events')}}>Events</Nav.Link>
                  {/* <NavDropdown title="View" id="navbarScrollingDropdown">
                    <NavDropdown.Item onClick={()=>setViewType('grid')}>Grid</NavDropdown.Item>
                    <NavDropdown.Item onClick={()=>setViewType('list')}>List</NavDropdown.Item>
                    <NavDropdown.Divider />
                    <NavDropdown.Item href="#action5">
                      Something else here
                    </NavDropdown.Item>
                  </NavDropdown> */}
                </Nav>
                <Form className="d-flex mt-4">
                  <FormControl
                    type="search"
                    placeholder="Search"
                    className="me-2"
                    aria-label="Search"
                    onChange={(e) => {setSearchInput(e.target.value)}}
                  />
                  <Button variant="success" className="mb-4 ml-4" style={{float:'left'}} onClick={()=>setViewType('grid')}>
                    <img  src={grid} height="14"/>
                  </Button>
                  <Button variant="success" className="mb-4 ml-2" style={{float:'left'}} onClick={()=>setViewType('list')}>
                    <img  src={list} height="14"/>
                  </Button>
                  <Button variant="success" className="mb-4 ml-2" onClick={handleShow} style={{float:'left'}}>
                  <img  src={map} height="14"/>
                  </Button>
                  {/* <Dropdown className="mb-4 ml-4" style={{float:'left'}}>
                    <Dropdown.Toggle variant="success" id="dropdown-basic">
                      Sort
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                      <Dropdown.Item onClick={()=>setViewType('grid')}>Grid</Dropdown.Item>
                      <Dropdown.Item onClick={()=>setViewType('list')}>List</Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown> */}
                </Form>
              </Navbar.Collapse>
            </>
          </Navbar>
          
          <div className="container">
            <div className="card-body mt-4">
              <Row xs={1} md={2} className="g-4">
                {
                  (() => {
                    if (filteredItems){
                      if(viewtype == 'grid'){
                        return filteredItems.map((item) => ( 
                          <Col md={4} style={{marginBottom: 60, borderRadius:'10px'}}>
                            <Card style={{boxShadow: "3px 3px 3px #9E9E9E"}} onClick={() => {window.open(item.wp_link, '_blank').focus();}}>
                              <Card.Img variant="top" src={item.thumbnail}/>
                            </Card>
                          </Col>
                        ))
                      }
                      else if(viewtype == 'list'){
                        return(
                          <ListGroup defaultActiveKey="#link1" className="p-2 mx-auto" stlyle={{alignItems:'center'}}>
                            {filteredItems.map((item) => ( 
                              <ListGroup.Item className="p-3" action onClick={() => {window.open(item.wp_link, '_blank').focus();}} style={{marginBottom: 10}}>
                                {item.business_name}
                              </ListGroup.Item>
                            ))}
                          </ListGroup>
                        )
                      }
                    }
                  })()
                }
              </Row>
            </div>
          </div>
        </>
      );
}

export default Partners;
