import React, { useEffect } from 'react'
import CardFavorite from "../../components/CardFavorite"
import Breadcrumbs from '../../components/Breadcrumbs'
import {authenticateMemberPage} from '../../lib/helpers/validation'
const favoritesList = [
  {
    image: require('../../assets/images/fav-img1.png'),
    name: 'Business Name',
    oldPrice: '100',
    newPrice: '46',
    discount: '55% Off',
    location: '123 Some St. Some City',
    desc: 'One 60-Minute Customized Massage'
  },
  {
    image: require('../../assets/images/fav-img2.png'),
    name: 'Business Name',
    oldPrice: '100',
    newPrice: '46',
    discount: '55% Off',
    location: '123 Some St. Some City',
    desc: 'One 60-Minute Customized Massage'
  }
]

function Favorites({history}) {
  useEffect(() => {
    authenticateMemberPage(history)
  }, []);
  return (
    <main className="favorites">
       <Breadcrumbs 
        size = 'text-large'
        placeholder = 'Favorites'
        targetLink = '/member-dashboard'
      />
      <CardFavorite store = { favoritesList } />
    </main>
  )
}

export default Favorites
