import React from 'react'
import { Link } from 'react-router-dom'
import { ReactComponent as IconPhone } from '../assets/images/icon-phone.svg'

function CardFaq({faq}) {
  return (
    <div className="container">
      <div className="faq-wrapper">
        { faq.map((item,i) => {
          return (
              <div className="faq-holder" key={i}>
                <h4 className="faq-caption">{item.caption}</h4>
                <div className="faq-card">
                  <div className="image-holder">
                    <img src={item.image} className="faq-img" alt={item.image}/>
                  </div>
                  <div className="title-holder">
                    <h4 className="title">{item.title}</h4>
                    <Link to="/help" className="btn-yellow">Go</Link>
                  </div>
                </div>
              </div>
            )
          })
        }
      </div>
      <div className="cta-wrapper">
        <h2 className="title-centered">Have questions?</h2>
        <p className="cta-desc">We'd love to hear from you. Give us a call and we can chat.</p>
        <div className="btn-holder">
          <a href="tel:1232356105" className="btn-blue"><span className="icon-holder"><IconPhone/></span>(123) 235-6105</a>
        </div>
      </div>
    </div>
  )
}

export default CardFaq