import React from "react";

const OnlineBankingTransfer = (props) => {
  // const sampleAmount = 500; // Replace this with the exact amount

  const {
    wizardStep,
    paymentMethod,
    totalPayment,
    // paymentCode,
    handleOnSubmit,
    isButtonloading,
  } = props;

  return (
    <>
      {wizardStep === 5 && paymentMethod === "Online Bank Transfer" ? (
        <div className="signup-steps">
          <h5>Online Bank Transfer</h5>
          <p>
            You choose the Online Bank Transfer option for your membership
            payment. Please proceed the following steps to process your payment:
          </p>
          <h6 className="step-title">Step 1</h6>
          <p>
            Please transfer an amount of <b>{totalPayment}</b> to the following
            bank account.
          </p>
          <div className="review-wrapper">
            <div className="review-holder">
              <p>Bank Name : </p>
              <p>Security Bank</p>
            </div>
            <div className="review-holder">
              <p>Branch : </p>
              <p>Angeles - Sto. Rosario</p>
            </div>
            <div className="review-holder">
              <p>Account Name : </p>
              <p>DCJ MARKETING SERVICES CORPORATION</p>
            </div>
            <div className="review-holder">
              <p>Account No. : </p>
              <p>0000023993711</p>
            </div>
          </div>
          {/* <h6>Payment Code</h6>
      <p>{paymentCode}</p> */}
          <h6 className="step-title">Step 2</h6>
          <p>
            On your Online Transfer remark please indicate your signup reference
            which you will receive after you press proceed.
          </p>
          <h6 className="step-title">Step 3</h6>
          <p>
            Send a screenshot image of online transaction to{" "}
            <b>billing@infinityprivelege.com</b> via email. Please mention on
            your email your fullname, tel.number, and your signup reference.
          </p>
          <p>
            If you agree with this mode of payment and with this procedure press
            proceed.
          </p>
          {isButtonloading ? (
            <button class="btn-blue-loading disabled">
              <span class="load open"></span> Please wait...
            </button>
          ) : (
            <button type="button" className="btn-blue" onClick={handleOnSubmit}>
              Proceed
            </button>
          )}
        </div>
      ) : null}
    </>
  );
};

export default OnlineBankingTransfer;
