import React, { useState, useEffect } from "react";
import { url_path } from "../../constant";
import Table from "../../components/Table";
import Breadcrumbs from "../../components/Breadcrumbs";
import Filter from "../../components/Filter";
import "react-accessible-accordion/dist/fancy-example.css";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
// import { ReactComponent as IconCalendar } from '../../assets/images/icon-calendar.svg'
import { FaCalendar } from "react-icons/fa";
import { authenticateBusinessPage } from "../../lib/helpers/validation";

import axios from "axios";
import Moment from "react-moment";
import "moment-timezone";
import { getCookie } from "../../lib/helpers/cookies";

function InfinityStatement(props) {
  
  const [data, setData] = useState([]);
  const [pagerData, setPagerData] = useState([]);
  const [filterStartDate, setFilterStartDate] = useState();
  const [filterEndDate, setFilterEndDate] = useState();
  const [balance, setBalance] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const handleSubmit = (event) => {
    event.preventDefault();
    console.log(filterStartDate);
    console.log(filterEndDate);
  };
  useEffect(() => {
    authenticateBusinessPage(props);
    getTransaction(1, false);
  }, []);

  function getTransaction(pageNumber, filter) {
    // get the user detail
    const token = getCookie("token");
    axios
      .get(`${url_path}/api/auth`, { headers: { "x-auth-token": token } })
      .then((res) => {
        const user_info = res.data;
        console.log(user_info);
        const req = {
          type: "statement",
          filter,
          start_date: filterStartDate,
          end_date: filterEndDate,
          by: "date",
          name: "",
          startAmount: "",
          endAmount: "",
          pageNumber,
          partner_staff_id: user_info.user._id,
        };

        axios
          .post(
            `${url_path}/api/transactions/infinity_statement/${user_info.user.business_partner_id}`,
            req
          )
          .then((res) => {
            const data = res.data;
            // return console.log(transactions.pageOfItems)
            // this.setState({loading: false});
            // let table = [];
            let businessBalance = data.balanceToDcj;
            console.log(data.transaction);
            setData(data.transaction);
            setPagerData(data.paginate);
            setBalance(businessBalance);
            setIsLoading(false);
            // console.warn(this.tableData)
          });
      });
  }

  const filterContent = {
    title: "Filter",
  };


  const handleDateChangeRaw = (e) => {
    e.preventDefault();
  };

  const heading = [
    {
      Header: "Name",
      accessor: "name",
      Cell: (row) =>
        row.original.user_id.user_first_name +
        " " +
        row.original.user_id.user_last_name,
      // width: 500
    },
    {
      Header: "Card Number",
      accessor: "cardNumber",
      // className: 'table-point-plus',
      Cell: (row) => row.original.user_id.card_number,
    },
    {
      Header: "Date",
      accessor: "date",
      // className: 'table-amount-peso',
      Cell: (row) => <Moment format="ll" date={row.original.created_at} />,
    },
    {
      Header: "Amount",
      accessor: "amount",
      Cell: (row) =>
        row.original.description === "Purchase"
          ? row.original.amount
          : row.original.description === "Managed Purchase"
          ? "-" + row.original.statement_amount
          : row.original.description === "Managed Redeem"
          ? "+" + row.original.statement_amount
          : row.original.amount,
    },
    {
      Header: "Balance for DCJ",
      // accessor: 'Balance for ABC',
      Cell: (row) =>
        row.original.description === "Purchase"
          ? row.original.discount_points
          : row.original.description === "Managed Purchase"
          ? 0
          : row.original.description === "Managed Redeem"
          ? row.original.statement_amount
          : row.original.discount_points,
    },
    {
      Header: "Balance for ABC",
      accessor: "bonus",
      Cell: (row) =>
        row.original.description === "Purchase"
          ? 0
          : row.original.description === "Managed Purchase"
          ? row.original.discount_points
          : row.original.description === "Managed Redeem"
          ? 0
          : row.original.statement_amount,
    },
  ];

  // var currentTransactionUser = currentUser + '’s'

  return (
    <main className="transaction-statement">
      <div className="container custom-container">
        <div className="content-wrapper">
          <Breadcrumbs
            size="text-large"
            placeholder="Infinity Statement"
            targetLink="/partner-dashboard"
            hasButton="false"
            customClassName="m-3"
          />
          <Filter
            filterDetails={filterContent}
            content={
              <form className="form-wrapper statement" onSubmit={handleSubmit}>
                <div className="filter-wrapper">
                  <div className="group-wrapper">
                    <p className="filter-heading">Filter by date</p>
                    <div className="form-group">
                      <div className="form-field calendar">
                        <p className="input-label">From</p>
                        <label htmlFor="startDate">
                          <p className="label-calendar">
                            <FaCalendar />
                          </p>
                        </label>
                        <DatePicker
                          selected={filterStartDate}
                          onChange={(date) => setFilterStartDate(date)}
                          selectsEnd
                          dateFormat="MMM dd, yyyy"
                          endDate={filterStartDate}
                          minDate={filterStartDate}
                          onChangeRaw={handleDateChangeRaw}
                          className="form-input date"
                          name="filterStartDate"
                          placeholderText="Select a date between today and 5 days in the future"
                          // placeholderText={date}
                          autoComplete="off"
                          id="filterStartDate"
                          required
                        />
                      </div>
                      <div className="form-field calendar">
                        <p className="input-label">To</p>
                        <label htmlFor="endDate">
                          <p className="label-calendar">
                            <FaCalendar />
                          </p>
                        </label>
                        <DatePicker
                          selected={filterEndDate}
                          onChange={(date) => setFilterEndDate(date)}
                          selectsEnd
                          dateFormat="MMM dd, yyyy"
                          endDate={filterEndDate}
                          minDate={filterStartDate}
                          onChangeRaw={handleDateChangeRaw}
                          className="form-input date"
                          name="filterEndDate"
                          // placeholderText={date}
                          autoComplete="off"
                          id="filterEndDate"
                          required
                        />
                      </div>
                    </div>
                  </div>
                  <input
                    className="btn-blue form-deal-submit"
                    type="submit"
                    value="Apply"
                  />
                </div>
              </form>
            }
          />
          <div className="section-table">
            {isLoading ? (
              <div className="lds-ellipsis">
                <div className="dots"></div>
                <div className="dots"></div>
                <div className="dots"></div>
                <div className="dots"></div>
              </div>
            ) : (
              <Table
                tableHeader={heading}
                tableData={data}
                paginate={pagerData}
                pageFunction={getTransaction}
              />
            )}
            <div className="statement-total">
              <p>Total balance of business partner:</p>
              <p>{balance}</p>
            </div>
          </div>
        </div>
      </div>
    </main>
  );
}

export default InfinityStatement;
