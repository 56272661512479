import React, { useState, useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import "../stylesheets/main.scss";
import { ReactComponent as IconCaretDown } from "../assets/images/icon-caret-down.svg";
import Button from "react-bootstrap/Button";
import axios from "axios";
import { url_path } from "../constant";

const CardSelection = ({ onChangeCard, card_selected, readOnly }) => {
  const [show, setShow] = useState(false);
  const [card_array, setCards] = useState([]);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const fetchData = async () => {
    try {
      const { data } = await axios.get(`${url_path}/api/card-imgur`);

      setCards(data);
    } catch (error) {
      // handleErrors(error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

    useEffect(() => {
        fetchData();
      }, []);
    
    return (
        
        <>
        <div className="form-field select">
            <input
                disabled={readOnly}
                id="card_selected"
                className="form-input"
                name="card_selected"
                onChange={onChangeCard}
                value={card_selected}
                list="cards"
            />
            <datalist id="cards">
                <option value="">Select Card Design</option>
                {
                    card_array.map(function(item, i){
                    return (<option value={item.desc}>{item.desc}</option>)
                  })
                }
            </datalist>
               
            
            <label htmlFor="card_selected">
                Card Design
                <IconCaretDown className="form-select-caret-down" />
            </label>
        </div>
        <div className="form-btn-group">
            <span className="btn-blue text-center" onClick={handleShow}>View Card Designs Available</span>
        </div>
        <Modal size="lg" show={show} onHide={handleClose}>
            <Modal.Header>
            <Modal.Title>Card Designs Available</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <p className="container">
                    <div className="row">
                    {
                        card_array.map(function(item, i){
                        return (
                            <div className="col-4">
                                <p className="card-title">{item.desc}</p>
                                <img className="card-design" src={item.image_url}/>
                            </div>
                        )
                        })
                    }
                    </div>
                </p> </Modal.Body>
            <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
                Close
            </Button>
            </Modal.Footer>
        </Modal>
        </>
    )
}

export default CardSelection;
