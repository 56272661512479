import { successNotif } from "../../lib/helpers/notifications.js";
import { url_path } from "../../constant";
import React, { useState } from "react";
import axios from "axios";
import Card from "../../components/Card";
import "../../stylesheets/main.scss";
import useForm from "../../lib/hooks/useForm.js";

// import { ReactComponent as IconCaretDown } from "../../assets/images/icon-caret-down.svg";

const ChangePassword = ({ data, user_id }) => {
  // const [userData, setUserData] = useState({});
  // const [loader, setLoader] = useState(false);
  const [readOnly, setReadOnly] = useState(true);
  // const [inputType, setInputType] = useState(true);

  const iconShow = require("../../assets/images/icon-show.png");
  const iconHide = require("../../assets/images/icon-hide.png");

  /*
   * Regex Validations to Inputs
   */

  // var nameRegex = /^[a-zA-Z ]+$/;
  // var numRegex = /^[0-9]*$/;

  /*
   * Initialize input state schema
   */

  const stateSchema = {
    password: { value: "", error: "" },
    confirm_password: { value: "", error: "" },
  };

  /*
   * Create a schema for all inputs
   */

  const stateValidatorSchema = {
    password: {
      required: true,
      // validator: {
      //   func: value => nameRegex.test(value),
      //   error: "Invalid Name"
      // }
    },
    confirm_password: {
      required: true,
      validator: {
        func: (value) => value === password,
        error: "Password Do not match",
      },
    },
  };

  function onSubmitForm(state) {
    const { password } = state;
    const request = {
      device_type: "admin_web",
      password,
    };
    // return console.log(request)
    axios
      .patch(`${url_path}/api/member/reset_password/${user_id}`, request)
      .then((res) => {
        console.log(res.data);
        if (res.data.data.status === "success") {
          const title = "Awesome!";
          const msg = res.data.data.msg;
          successNotif(title, msg);
          clearFields();
          setReadOnly(true);
        }
      });
    console.log(request);
  }

  function clearFields() {
    values.password = "";
    values.confirm_password = "";
  }

  const { values, errors, handleOnChange, handleOnSubmit, disable } = useForm(
    stateSchema,
    stateValidatorSchema,
    onSubmitForm,
    clearFields
  );

  const { password, confirm_password } = values;

  const [inputPassword, setInputPassword] = useState(true);

  function togglePassword() {
    inputPassword ? setInputPassword(false) : setInputPassword(true);
  }

  const [inputConfirmPassword, setInputConfirmPassword] = useState(true);

  function toggleConfirmPassword() {
    inputConfirmPassword
      ? setInputConfirmPassword(false)
      : setInputConfirmPassword(true);
  }

  return (
    // for send an email to change their password
    // <Card
    //   cardDetails={data}
    //   form={
    //     <form className="form-wrapper" onSubmit={handleOnSubmit}>
    //       {!readOnly ? <p>Update Contact Details</p> : null}

    //       <div className="form-field">
    //         <input
    //           id="email"
    //           className="form-input"
    //           type="text"
    //           name="email"
    //           value={email}
    //           onChange={handleOnChange}
    //           required
    //           readOnly={readOnly}
    //           disabled={readOnly}
    //         />
    //         <label htmlFor="email">Email</label>
    //         {/* {errors.email && (
    //           <span className="form-error">{errors.email}</span>
    //         )} */}
    //       </div>
    //       <input
    //         type="submit"
    //         className="btn-blue right"
    //         value="Send Email"
    //         // disabled={disable}
    //     />

    //     </form>
    //   }
    // />
    <Card
      cardDetails={data}
      form={
        <form className="form-wrapper" onSubmit={handleOnSubmit}>
          <div className="form-field">
            <input
              type={inputPassword ? "password" : "text"}
              name="password"
              className="form-input"
              id="password"
              value={password}
              onChange={handleOnChange}
              // validations={[required, isEqual]}

              required
            />
            <span className="password__show" onClick={togglePassword}>
              <img src={inputPassword ? iconHide : iconShow} alt="" />
            </span>
            <label htmlFor="password">Password</label>

            {errors.password && (
              <span className="form-error">{errors.password}</span>
            )}
          </div>
          <div className="form-field">
            <input
              type={inputConfirmPassword ? "password" : "text"}
              name="confirm_password"
              className="form-input"
              id="confirm_password"
              value={confirm_password}
              onChange={handleOnChange}
              // validations={[required, isEqual]}
              required
            />
            <span className="password__show" onClick={toggleConfirmPassword}>
              <img src={inputConfirmPassword ? iconHide : iconShow} alt="" />
            </span>
            <label htmlFor="confirm_password">Confirm Password</label>

            {errors.confirm_password && (
              <span className="form-error">{errors.confirm_password}</span>
            )}
          </div>
          <input
            type="submit"
            className="btn-blue right"
            value="Reset"
            disabled={disable}
          />
        </form>
      }
    />
  );
};

export default ChangePassword;
