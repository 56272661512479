import React from "react";

const BankDeposit = (props) => {
  const {
    wizardStep,
    paymentMethod,
    totalPayment,
    // paymentCode,
    handleOnSubmit,
    isButtonloading,
  } = props;

  return (
    <>
      {wizardStep === 5 && paymentMethod === "Bank Deposit" ? (
        <div className="signup-steps">
          <h5>Bank Deposit</h5>
          <p>
            You choose the Bank Deposit option for your membership payment.
            Please proceed the following steps to process your payment:
          </p>
          <h6 className="step-title">Step 1</h6>
          <p>
            Proceed to any Security Bank branch, use Cash Transaction Slip and
            indicate the following details:
          </p>
          <div className="review-wrapper">
            <div className="review-holder">
              <p>Company Name : </p>
              <p>DCJ MARKETING SERVICES CORPORATION</p>
            </div>
            <div className="review-holder">
              <p>Branch : </p>
              <p>Angeles - Sto. Rosario</p>
            </div>
            <div className="review-holder">
              <p>Account No. : </p>
              <p>0000023993711</p>
            </div>
            <div className="review-holder">
              <p>Amount to pay : </p>
              <p>{totalPayment}</p>
            </div>
          </div>
          <h6 className="step-title">Step 2</h6>
          <p>
            Send a copy of payment slip to{" "}
            <a className="email" href="mailto:billing@infinityprivilege.com">
              billing@infinityprivilege.com
            </a>{" "}
            via email. Please mention on your email your full name, tel no., and
            your sign up reference no. which you will receive after you press
            proceed.
          </p>
          <h6 className="step-title">Step 3</h6>
          <p>
            If you agree with this mode of payment and with this procedure press
            proceed.
          </p>
          {isButtonloading ? (
            <button class="btn-blue-loading disabled">
              <span class="load open"></span> Please wait...
            </button>
          ) : (
            <button type="button" className="btn-blue" onClick={handleOnSubmit}>
              Proceed
            </button>
          )}
        </div>
      ) : null}
    </>
  );
};

export default BankDeposit;
