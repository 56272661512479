import React, { useState, useEffect } from "react";
import Tab from "react-bootstrap/Tab";
import Nav from "react-bootstrap/Nav";
import Breadcrumbs from "../../components/Breadcrumbs";

import AddPoints from "../../tabs/points/AddPoints";
import DeductPoints from "../../tabs/points/DeductPoints";
import TransferPoints from "../../tabs/points/TransferPoints";

import { authenticateAdminPage } from "../../lib/helpers/validation";

const MemberProfile = (props) => {
  useEffect(() => {
    authenticateAdminPage(props);
  }, []);
  // States
  const [loader] = useState(false);

  // Variables

  const content = [
    {
      name: "Add Points",
      eventKey: "add",
    },
    {
      name: "Deduct Points",
      eventKey: "deduct",
    },
    {
      name: "Transfer Points",
      eventKey: "transfer",
    },
  ];

  const tabPane = {
    add: {
      title: "Add Points",
    },
    deduct: {
      title: "Deduct Points",
    },
    transfer: {
      title: "Transfer Points",
    },
  };

  return (
    <main className="member-profile">
      <div className="container custom-container">
        <Breadcrumbs
          customClassName="m-3"
          size="text-large"
          placeholder="Back"
          targetLink="/admin-transactions"
        />
        {!loader ? (
          <div className="section-member-profile">
            {/* <div className="container-fluid"> */}
            <Tab.Container
              id="member-profile-tabs"
              defaultActiveKey="add"
              mountOnEnter={true}
            >
              <div className="section-tab">
                <div className="tab-nav">
                  <Nav className="flex-column">
                    {content.map(function (item, i) {
                      return (
                        <Nav.Item key={i}>
                          <Nav.Link eventKey={item.eventKey}>
                            {item.name}
                          </Nav.Link>
                        </Nav.Item>
                      );
                    })}
                  </Nav>
                </div>
                <div className="tab-content-holder">
                  <Tab.Content>
                    <Tab.Pane eventKey="add">
                      <AddPoints data={tabPane.add} />
                    </Tab.Pane>
                    <Tab.Pane eventKey="deduct">
                      <DeductPoints data={tabPane.deduct} />
                    </Tab.Pane>
                    <Tab.Pane eventKey="transfer">
                      <TransferPoints data={tabPane.transfer} />
                    </Tab.Pane>
                  </Tab.Content>
                </div>
              </div>
            </Tab.Container>
            {/* </div> */}
          </div>
        ) : (
          <div className="lds-ellipsis">
            <div className="dots"></div>
            <div className="dots"></div>
            <div className="dots"></div>
            <div className="dots"></div>
          </div>
        )}
      </div>
    </main>
  );
};

export default MemberProfile;
